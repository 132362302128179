import React, {forwardRef, useState} from 'react';
import Input from './Input';
import {moneyFormat} from '../../lib/helpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Money = forwardRef(({currency = 'EUR', ...props}, ref) => {
    const [amount, setAmount] = useState('');

    const {setPrice, ...inputProps} = props;

    const moneyProps = {
        onChange: (value) => {
            value = value
                .replace('€', '')
                .replace(',', '.')
                .replace(/[A-Za-z!@#$%^&]/g, '');

            if (!value) {
                value = 0;
            }
            if (setPrice !== undefined) {
                setPrice(value);
            }
            setAmount(value);
            props.onChange(value);
        },
        onBlur: () => {
            const calc = eval(amount);
            if (calc) {
                props.onChange(calc.toFixed(2));
            }
        },
        formatter: moneyFormat,
        currency: currency,
        textAlign: 'right',
    };

    return <Input {...inputProps} {...moneyProps} />;
});

export default React.memo(Money);
