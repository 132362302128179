import moment from 'moment';
import React, {useState} from 'react';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useModal} from '../../../../lib/UseModal';
import {useTranslation} from 'react-i18next';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';

const EditArrival = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, setSaving] = useState(false);
    const [arrivalState, setArrivalState] = useState({
        status: null,
        statuschangedOn: null,
        expected: null,
        estimate: false,
        documents1: null,
        documents2: null,
        coc: null,
        warranty: null,
        numberOfKeys: null,
        keyStatus: null,
        firstUsedOn: null,
    });

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);

        const patch = cars.map((car) => {
            const data = {
                car_id: car.car_id,
                localisation: {
                    arrival: {},
                },
                documents: {},
            };

            let registrationOrdinal = null;
            if (car.documents.registration.second && car.documents.registration?.first?.proof_of_registration_p1 === 'ARRIVED') {
                registrationOrdinal = 'second';
            } else if (car.documents.registration.first) {
                registrationOrdinal = 'first';
            }

            if (registrationOrdinal) {
                data.documents.registration = {[registrationOrdinal]: {}};
            }

            if (arrivalState.status) {
                data.localisation.arrival.status = arrivalState.status;
            }
            if (arrivalState.statuschangedOn) {
                data.localisation.arrival.status_changed_on = arrivalState.statuschangedOn;
            }
            if (arrivalState.expected) {
                data.localisation.arrival.expected = arrivalState.expected;
            }
            if (arrivalState.estimate) {
                data.localisation.arrival.estimate = arrivalState.estimate;
            }
            if (registrationOrdinal && arrivalState.documents1) {
                data.documents.registration[registrationOrdinal].proof_of_registration_part_1 = {status: arrivalState.documents1};
            }
            if (registrationOrdinal && arrivalState.documents2) {
                data.documents.registration[registrationOrdinal].proof_of_registration_part_2 = {status: arrivalState.documents2};
            }
            if (registrationOrdinal && arrivalState.firstUsedOn) {
                data.documents.registration[registrationOrdinal].registration_date = arrivalState.firstUsedOn;
            }
            if (arrivalState.coc) {
                data.documents.certificate_of_conformity = {status: arrivalState.coc};
            }
            if (arrivalState.warranty) {
                data.documents.warranty_booklet = {status: arrivalState.warranty};
            }
            if (arrivalState.numberOfKeys) {
                data.documents.number_of_keys = arrivalState.numberOfKeys;
            }
            if (arrivalState.keyStatus) {
                data.documents.key_status = arrivalState.keyStatus;
            }

            return data;
        });

        authenticatedHttp()
            .patch('/cars', patch)
            .then(() => {
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('search.edit_arrival_status')}>
            <form onSubmit={handleSave}>
                <Modal.Body>
                    <table className="table table-documents">
                        <tbody className="form-group">
                            <tr className="form-group__subtitle-container">
                                <td colSpan={3}>
                                    <h3 className="form-group__subtitle">{t('general.car')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <strong>{t('search.arrival_status')}</strong>
                                </th>
                                <td>
                                    <Enum
                                        enumName="arrival_status"
                                        onChange={(status) => setArrivalState({...arrivalState, status})}
                                        placeholder={t('search.arrival_status')}
                                        value={arrivalState.status || ''}
                                    />
                                </td>
                                <td>
                                    <DatePicker
                                        onChange={(statuschangedOn) => setArrivalState({...arrivalState, statuschangedOn})}
                                        value={arrivalState.statuschangedOn || moment().format('YYYY-MM-DD')}
                                    />
                                </td>
                            </tr>
                            {['EXPECTED', 'ON_TRANSPORT'].includes(arrivalState.status) && (
                                <tr>
                                    <th>
                                        <strong>{t('general.expected_at_ecr')}</strong>
                                    </th>
                                    <td>
                                        <DatePicker
                                            onChange={(expected) => setArrivalState({...arrivalState, expected})}
                                            value={arrivalState.expected || moment().format('YYYY-MM-DD')}
                                        />
                                    </td>
                                    <td>
                                        <label className="checkbox">
                                            <input
                                                checked={arrivalState.estimate}
                                                onChange={(e) =>
                                                    setArrivalState({...arrivalState, estimate: e.currentTarget.checked})
                                                }
                                                type="checkbox"
                                            />
                                            <span>{t('general.estimated_date')}</span>
                                        </label>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.warranty_booklet')}</strong>
                                </th>
                                <td colSpan={2} width="200">
                                    <Enum
                                        enumName="warranty_booklet_status"
                                        onChange={(warranty) => setArrivalState({...arrivalState, warranty})}
                                        value={arrivalState.warranty}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.number_of_keys')}</strong>
                                </th>
                                <td width="200">
                                    <input
                                        className="form-control"
                                        onChange={(number) =>
                                            setArrivalState({...arrivalState, numberOfKeys: number.target.value})
                                        }
                                        type="number"
                                        value={arrivalState.numberOfKeys}
                                    />
                                </td>
                                <td width="200">
                                    <Enum
                                        enumName="key_status"
                                        onChange={(keyStatus) => setArrivalState({...arrivalState, keyStatus})}
                                        value={arrivalState.keyStatus}
                                    />
                                </td>
                            </tr>
                            <tr className="form-group__subtitle-container">
                                <td colSpan={3}>
                                    <h3 className="form-group__subtitle">{t('search.documents')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <strong>{t('car.documents.registration_date')}</strong>
                                </th>
                                <td colSpan={2}>
                                    <DatePicker
                                        onChange={(firstUsedOn) => setArrivalState({...arrivalState, firstUsedOn})}
                                        value={arrivalState.firstUsedOn}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.proof_of_registration_part_1')}</strong>
                                </th>
                                <td colSpan={2} width="200">
                                    <Enum
                                        enumName="proof_of_registration_status"
                                        onChange={(documents1) => setArrivalState({...arrivalState, documents1})}
                                        value={arrivalState.documents1}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.proof_of_registration_part_2')}</strong>
                                </th>
                                <td colSpan={2} width="200">
                                    <Enum
                                        enumName="proof_of_registration_status"
                                        onChange={(documents2) => setArrivalState({...arrivalState, documents2})}
                                        value={arrivalState.documents2}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.certificate_of_conformity')}</strong>
                                </th>
                                <td colSpan={2} width="200">
                                    <Enum
                                        enumName="certificate_of_conformity_status"
                                        onChange={(coc) => setArrivalState({...arrivalState, coc})}
                                        value={arrivalState.coc}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="blue" disabled={saving} submit>
                        {t('search.edit_arrival_status')}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditArrival;
