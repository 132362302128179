import classnames from 'classnames';
import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';

const QuarterPicker = ({disabled, error, onChange, value}) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [quarter, setQuarter] = useState(null);
    const [showMenu, toggleMenu] = useState(false);
    const ref = useRef(null);

    const handleOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            toggleMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideClick);
        if (/Q[1-4] \d{4}/.test(value)) {
            setQuarter(parseInt(value.split(' ')[0].replace('Q', '')));
            setYear(parseInt(value.split(' ')[1]));
        }

        return () => {
            document.removeEventListener('mouseup', handleOutsideClick);
        };
    }, [value]);

    const handleClick = () => {
        if (!disabled) {
            toggleMenu((prevState) => !prevState);
        }
    };

    useEffect(() => {
        if (quarter) {
            onChange(`Q${quarter} ${year}`);
        } else {
            onChange(null);
        }
    }, [quarter, year]);

    return (
        <div className="action-dropdown" ref={ref}>
            <div className="DayPickerInput">
                <input
                    autoComplete="off"
                    className={classnames({
                        'form-control': true,
                        'has-error': error,
                    })}
                    disabled={disabled}
                    onChange={(value) => {
                        if (/Q[1-4] \d{1,4}/.test(value.currentTarget.value)) {
                            setQuarter(parseInt(value.currentTarget.value.split(' ')[0].replace('Q', '')));
                            setYear(parseInt(value.currentTarget.value.split(' ')[1]));
                        } else if (!value.currentTarget.value) {
                            setQuarter(null);
                            setYear(new Date().getFullYear());
                        }
                    }}
                    onClick={handleClick}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    type="text"
                    value={quarter && year ? `Q${quarter} ${year}` : ''}
                />
                {error && <p className="error-message">{error}</p>}
            </div>
            {showMenu && (
                <div
                    className={classNames({
                        'btn--dropdown-menu': true,
                    })}
                >
                    <div className="quarterPicker">
                        <div className="header">
                            <a
                                onClick={() => {
                                    setYear(year - 1);
                                }}
                            >
                                <i className="fa fa-chevron-left " />
                            </a>
                            <strong>{year}</strong>
                            <a
                                onClick={() => {
                                    setYear(year + 1);
                                }}
                            >
                                <i className="fa fa-chevron-right" />
                            </a>
                        </div>
                        <div className="body">
                            {[1, 2, 3, 4].map((number) => (
                                <a
                                    className={`item ${quarter === number ? 'active' : null}`}
                                    key={number}
                                    onClick={() => {
                                        toggleMenu(false);
                                        setQuarter(number);
                                    }}
                                >
                                    <span>Q{number}</span>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(QuarterPicker);
