import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import {setPageTitle} from '../../../../lib/helpers';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import useFetchData from '../../../../lib/UseFetchData';
import NotFound from '../../../NotFound/NotFound';

import Detail from './Detail';
import {useTranslation} from 'react-i18next';

const LoadDetail = () => {
    const {creditNoteId} = useParams();
    const {t} = useTranslation();
    let navigate = useNavigate();

    const [creditNote, setCreditNote] = useState(null);
    const [users] = useFetchData('/users?include_inactive');

    const fetchCreditNote = () => {
        authenticatedHttp()
            .get('/credit-notes/' + creditNoteId)
            .then((response) => {
                setCreditNote(response.data.data);
                setPageTitle(t('general.credit_note'));
            })
            .catch(() => {
                navigate('/creditnotas');
            });
    };
    useEffect(() => {
        fetchCreditNote();
    }, []);

    if (!/^\d+$/.test(creditNoteId)) {
        return <NotFound />;
    }

    if (!users || !creditNote) {
        return <Spinner />;
    }

    return <Detail creditNote={creditNote} refresh={fetchCreditNote} users={users} />;
};

export default LoadDetail;
