import classnames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {moneyFormat} from '../../lib/helpers';

import Checkbox from '../../components/Form/Checkbox';

const SingleListCar = ({
    line,
    priceFormat = moneyFormat,
    selectable,
    selectedLines: [selectedLines = [], setSelectedLines = () => {}] = [],
    showIsReadyForInvoiceStatus = false,
}) => {
    const {t} = useTranslation();

    let location = '';
    if (line.type === 'CAR') {
        // inSelection = selectedCarLines.findIndex(line => line.car_id === car.car_id) > -1;
        location = line.car.localisation.location
            ? t('enum.location.' + line.car.localisation.location)
            : t('enum.arrival_status.' + line.car.localisation.arrival_status);
    }

    const description = (line) => {
        switch (line.type) {
            case 'CAR':
                return (
                    <div>
                        {showIsReadyForInvoiceStatus && (
                            <i
                                className={classnames({
                                    dot: true,
                                    'small-dot': true,
                                    'dot-green': !selectable,
                                    'dot-yellow': selectable && line.car.invoice.is_ready_for_invoice,
                                    'dot-red': selectable && !line.car.invoice.is_ready_for_invoice,
                                })}
                                data-tip={t(
                                    `${
                                        !selectable
                                            ? 'enum.sale_status.INVOICED'
                                            : line.car.invoice.is_ready_for_invoice
                                            ? 'general.ready_for_invoice'
                                            : 'general.not_ready_for_invoice'
                                    }`
                                )}
                            />
                        )}
                        <strong>{line.car.type.model.make}</strong> {line.car.type.model.name} {line.car.type.name} (
                        {line.car.car_id} - {line.car.vin} - {location})
                    </div>
                );
            case 'EXTRA':
                return line.description;
            case 'TRANSPORT':
                return t('general.transport');
        }
    };

    const handleSelect = (checked) => {
        if (checked) {
            if (line.type === 'CAR') {
                line.car_id = line.car.car_id;
            }

            setSelectedLines([...selectedLines, line]);
        } else {
            setSelectedLines(selectedLines.filter((selectedLine) => selectedLine.id !== line.id));
        }
    };

    return (
        <div
            className={classnames({
                strikethrough: !!line.relisted_for_sale_on,
            })}
        >
            <div className="lines">
                {selectable ? (
                    <>
                        <Checkbox
                            label={description(line)}
                            onChange={handleSelect}
                            value={!!selectedLines.find((selectedLine) => selectedLine.id === line.id)}
                        />
                    </>
                ) : (
                    <>
                        <span>{description(line)}</span>
                    </>
                )}
                <span className="price ms-1">{priceFormat(line.price)}</span>
            </div>
        </div>
    );
};

export default SingleListCar;
