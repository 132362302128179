import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {calculatePricingSubtotals} from '../../../lib/CarDetailForm';
import {useAuth} from '../../../lib/UseAuth';
import {setPageTitle} from '../../../lib/helpers';
import useForm, {Custom, Required, MaxLength} from '../../../lib/UseForm';
import {authenticatedHttp} from '../../../lib/zaza-client';
import {diff} from 'deep-object-diff';

import IconSave from '../../../../assets/svg/icon-save.svg';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import Detail from '../Detail/Detail';
import Spinner from '../../../components/Spinner/Spinner';
import Section from '../Detail/Section/Section';

import emptyCar from '../../../models/car.json';

const websites = ['BE', 'CH', 'DE', 'FR', 'IT', 'NL', 'ROW'];

const calculatePricing = (form, value, draft) => {
    const newPrice = value + 300;

    websites.forEach((website) => {
        draft.sales_pricing.website_pricing[website].price = newPrice;
    });
    draft.sales_pricing.eln = newPrice;
};

const Create = ({car}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    if (!user.permissions.CAN_CREATE_CARS) {
        return;
    }

    let navigate = useNavigate();

    useEffect(() => {
        setPageTitle(t('nav.create_car'));
    }, []);

    const [isSaving, toggleSaving] = useState(false);

    const saleStatusOptions = [
        {value: 'FOR_SALE', label: t('enum.sale_status.FOR_SALE')},
        {value: 'NOT_FOR_SALE', label: t('enum.sale_status.NOT_FOR_SALE')},
    ];

    const form = useForm(car, {
        onSave: ({data}) => {
            if (
                data.purchase_pricing?.costs?.purchase_price?.currency !== 'EUR' &&
                !data.purchase_pricing.costs.purchase_price?.currency_rate
            ) {
                alert(t('validation.expected_currency_rate'));
                return;
            }

            if (!isSaving) {
                toggleSaving(true);
                const changes = diff(emptyCar, data);
                const {type, ...otherChanges} = changes;
                const changedType = {...data.type, ...type};
                const patch = {...otherChanges, number_of_cars: data.number_of_cars, type: changedType};

                const promise = authenticatedHttp().post('/cars', patch);

                promise
                    .then((response) => {
                        navigate('/autos/' + response.data.car_id);
                        toggleSaving(false);
                    })
                    .catch((e) => {
                        toggleSaving(false);
                        alert('Fout bij opslaan: ' + e.response.data.message);
                    });
            }
        },
        validation: [
            {
                rule: Required,
                paths: [
                    'number_of_cars',
                    'subsidiary',
                    'initial_vin',
                    'sale.status',
                    'type.id',
                    'body_color',
                    'interior_color',
                    'interior_type',
                    'warranty.status',
                    'documents.internal_order_code',
                ],
            },
            {
                rule: MaxLength(17),
                paths: ['initial_vin'],
            },
            {
                rule: Custom((value) => value.length > 0, ['validation.min_equipment']),
                paths: ['equipment'],
            },
            {
                rule: Custom(
                    (value) => value.filter((selectedOption) => selectedOption.highlighted).length,
                    ['validation.min_highlighted_equipment']
                ),
                paths: ['equipment'],
            },
        ],
    });
    const hasNoChanges = Object.entries(form.changes).length === 0 && form.changes.constructor === Object;

    const pricing = form.data.purchase_pricing;
    const pricingTotals = useMemo(() => {
        return calculatePricingSubtotals(pricing);
    }, [pricing]);

    if (isSaving) {
        return <Spinner message={t('general.saving')} />;
    }

    return (
        <Detail
            calculatePricing={calculatePricing}
            contentHeader={() => (
                <Section>
                    <div className="row form-group form-group-vertical">
                        <div className="col-2">
                            <label htmlFor="number_of_cars">{t('car.number_of_cars')}</label>
                            <Input {...form.fields.number_of_cars()} />
                        </div>
                        <div className="col-2">
                            <label htmlFor="subsidiary">{t('search.subsidiary')}</label>
                            <Select
                                {...form.fields.subsidiary()}
                                options={user.subsidiaries.map((subsidiary) => ({
                                    value: subsidiary,
                                    label: t('enum.subsidiary.' + subsidiary),
                                }))}
                                placeholder={t('search.subsidiary')}
                            />
                        </div>
                        <div className="col-5">
                            <label htmlFor="initial_vin">{t('car.initial_vin')}</label>
                            <Input {...form.fields.initial_vin()} />
                        </div>
                        <div className="col-3">
                            <label htmlFor="sale_status">{t('car.sale_status')}</label>
                            <Select options={saleStatusOptions} {...form.fields.sale.status()} />
                        </div>
                    </div>
                </Section>
            )}
            form={form}
            header={() => (
                <Fragment>
                    <div className="title" style={{marginLeft: 50}}>
                        <div className="title-row justify-content-between">
                            <h1>{t('nav.create_car')}</h1>
                            <Button disabled={hasNoChanges} equalHeight icon={<IconSave />} onClick={form.handleSubmit}>
                                {t('general.save')}
                            </Button>
                        </div>
                    </div>
                </Fragment>
            )}
            isCreate
            pricingTotals={pricingTotals}
        />
    );
};

export default React.memo(Create);
