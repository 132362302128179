import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Select from '../../../../components/Form/Select';
import {useAuth} from '../../../../lib/UseAuth';
import {authenticatedHttp} from '../../../../lib/zaza-client';

const Seller = ({invoiceId, setLoadingMessage, userId, users = []}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const [isEditting, toggleEditing] = useState(false);
    const seller = users.find((u) => u.id === userId);

    const updateSeller = (newSellerId) => {
        setLoadingMessage(t('nav.submit') + '…');
        authenticatedHttp()
            .patch('/invoices/' + invoiceId, {seller: newSellerId})
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    if (isEditting && user.permissions.IS_ADMIN) {
        return (
            <span className="d-inline-flex w-100">
                <div className="flex-grow-1">
                    <Select
                        onChange={updateSeller}
                        options={users.map((user) => ({value: user.id, label: user.full_name}))}
                        value={userId}
                    />
                </div>
                <button className="ms-2 px-1" onClick={() => toggleEditing(false)} title={t('general.cancel')}>
                    <i className="fas fa-times" />
                </button>
            </span>
        );
    }

    return (
        <span>
            {seller ? seller.full_name : '-'}
            {user.permissions.IS_ADMIN && (
                <button className="ms-2" onClick={() => toggleEditing(true)} title={t('general.edit')}>
                    <i className="fas fa-pencil-alt" />
                </button>
            )}
        </span>
    );
};

export default Seller;
