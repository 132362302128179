import React, {Suspense} from 'react';
import {BrowserRouter, useLocation, useNavigate} from 'react-router-dom';
import {QueryParamProvider} from 'use-query-params';
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6';

import App from './App';
import {AuthProvider} from './lib/UseAuth';
import {ModalProvider} from './lib/UseModal';
import Spinner from './components/Spinner/Spinner';
import {WidgetProvider} from './lib/UseWidget';

const Root = () => (
    <Suspense fallback={<Spinner />}>
        <AuthProvider>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <ModalProvider>
                        <WidgetProvider>
                            <App />
                        </WidgetProvider>
                    </ModalProvider>
                </QueryParamProvider>
            </BrowserRouter>
        </AuthProvider>
    </Suspense>
);

// ReactRouterRoute={Route} not supported in react-router v6, workaround below from: https://github.com/pbeshai/use-query-params/issues/108

const RouteAdapter = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const adaptedHistory = React.useMemo(
        () => ({
            replace(location) {
                navigate(location, {replace: true, state: location.state});
            },
            push(location) {
                navigate(location, {replace: false, state: location.state});
            },
        }),
        [navigate]
    );
    return children({history: adaptedHistory, location});
};

export default Root;
