import React from 'react';
import {useTranslation} from 'react-i18next';

const Section = ({children, name, titleSuffix}) => {
    const {t} = useTranslation();

    return (
        <section className="section">
            {name && (
                <h2 id={name}>
                    {t('car.nav.' + name)}
                    {titleSuffix}
                </h2>
            )}
            <div className="section_body">{children}</div>
        </section>
    );
};

export default Section;
