import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import useForm from '../../lib/UseForm';
import {authenticatedHttp} from '../../lib/zaza-client';

import Button from '../Button/Button';
import Autocomplete from '../Form/Autocomplete';
import Modal from './Modal';

const MakeConsignationModal = ({carId, isOpen, onRequestClose, refreshCar}) => {
    const {t} = useTranslation();

    const [submitting, toggleSubmitting] = useState(false);

    const form = useForm(
        {
            consigned_with: null,
        },
        {
            onSave: ({data}) => {
                if (!data.consigned_with) {
                    return;
                }

                toggleSubmitting(true);

                authenticatedHttp()
                    .patch('/cars/' + carId, {
                        localisation: {
                            location: {
                                where: 'CONSIGNMENT',
                                consigned_with: data.consigned_with,
                            },
                        },
                    })
                    .then(() => {
                        onRequestClose();
                        refreshCar();
                        toggleSubmitting(false);
                    });
            },
        }
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            renderBody={() => (
                <div style={{paddingBottom: '340px'}}>
                    <Autocomplete
                        {...form.fields.consigned_with()}
                        allowClear
                        autocompleteURI="/customers/autocomplete?"
                        placeholder={t('car.customer')}
                    />
                </div>
            )}
            renderFooter={() => (
                <Button color="blue" disabled={submitting} onClick={() => form.handleSubmit()}>
                    {submitting ? t('general.submitting') : t('car.make_consignation')}
                </Button>
            )}
            renderHeader={() => (
                <h2>
                    <strong>{t('car.make_consignation')}</strong>
                </h2>
            )}
        />
    );
};

export default MakeConsignationModal;
