import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useFetchData from '../../lib/UseFetchData';
import {useAuth} from '../../lib/UseAuth';

import Autocomplete from '../../components/Form/Autocomplete';
import Select from '../../components/Form/Select';
import DatePicker from '../../components/Form/DatePicker';
import Input from '../../components/Form/Input';
import Button from '../../components/Button/Button';
import useForm from '../../lib/UseForm';
import {authenticatedHttp} from '../../lib/zaza-client';

const Filters = ({filters, setFilters, showNotInvoicedStatus, showPaidStatus, showRelistedStatus, showUnsignedStatus}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const [users] = useFetchData('/users');

    const [customer, setCustomer] = useState(filters.customer || false);

    useEffect(() => {
        setCustomer(false);
        if (filters.customer) {
            authenticatedHttp()
                .get(`/customers/${filters.customer}`)
                .then((response) => {
                    setCustomer(response.data.data);
                });
        }
    }, [filters.customer]);

    let statusOptions = [
        {value: 'draft', label: t('enum.document_status.DRAFT')},
        {value: 'sent', label: t('enum.document_status.SENT')},
    ];
    if (showPaidStatus) {
        statusOptions.push(
            {value: 'paid', label: t('enum.document_status.PAID')},
            {value: 'unpaid', label: t('enum.document_status.UNPAID')}
        );
    }
    if (showRelistedStatus) {
        statusOptions.push({value: 'show_relisted', label: t('purchase_order.show_relisted')});
    }
    if (showUnsignedStatus) {
        statusOptions.push({value: 'show_unsigned', label: t('purchase_order.show_unsigned')});
    }
    if (showNotInvoicedStatus) {
        statusOptions.push({value: 'not_invoiced', label: t('enum.document_status.NOT_INVOICED')});
    }

    const invoiceOrCreditNoteNumberForm = useForm(
        {number_from: null, number_to: null},
        {
            onSave: ({data}) => {
                setFilters((prevState) => ({
                    ...prevState,
                    number_from: data.number_from,
                    number_to: data.number_to,
                }));
            },
        }
    );

    return (
        <Fragment>
            <div className="filters">
                {filters.hasOwnProperty('subsidiary') && (
                    <div className="filter">
                        <Select
                            allowClear
                            onChange={(subsidiary) => {
                                setFilters((prevState) => ({...prevState, subsidiary: subsidiary}));
                            }}
                            options={user.subsidiaries.map((subsidiary) => ({
                                value: subsidiary,
                                label: t('enum.subsidiary.' + subsidiary),
                            }))}
                            placeholder={t('search.subsidiary')}
                            value={filters.subsidiary}
                        />
                    </div>
                )}
                {filters.hasOwnProperty('date_from') && (
                    <div className="filter">
                        <div className="form-group">
                            <DatePicker
                                onChange={(date) => {
                                    setFilters((prevState) => ({...prevState, date_from: date}));
                                }}
                                placeholder={t('search.period_from')}
                                value={filters.date_from}
                            />
                        </div>
                    </div>
                )}
                {filters.hasOwnProperty('date_to') && (
                    <div className="filter">
                        <div className="form-group">
                            <DatePicker
                                onChange={(date) => {
                                    setFilters((prevState) => ({...prevState, date_to: date}));
                                }}
                                placeholder={t('search.period_to')}
                                value={filters.date_to}
                            />
                        </div>
                    </div>
                )}
                {filters.hasOwnProperty('user') && (
                    <div className="filter">
                        <Select
                            allowClear
                            onChange={(user) => {
                                setFilters((prevState) => ({...prevState, user: user}));
                            }}
                            options={users ? users.map((user) => ({value: user.id, label: user.full_name})) : []}
                            placeholder={t('car.seller')}
                            value={filters.user}
                        />
                    </div>
                )}
                {filters.hasOwnProperty('customer') && (
                    <div className="filter">
                        <Autocomplete
                            allowClear
                            autocompleteURI="/customers/autocomplete?"
                            onChange={(customer) => {
                                setFilters((prevState) => ({...prevState, customer: customer}));
                            }}
                            placeholder={t('car.customer')}
                            value={
                                customer && typeof customer === 'object'
                                    ? {value: customer.id, label: customer.label}
                                    : filters.customer
                            }
                        />
                    </div>
                )}
                {filters.hasOwnProperty('status') && (
                    <div className="filter">
                        <Select
                            allowClear
                            onChange={(status) => {
                                setFilters((prevState) => ({...prevState, status: status}));
                            }}
                            options={statusOptions}
                            placeholder={t('general.status')}
                            value={filters.status}
                        />
                    </div>
                )}

                {filters.hasOwnProperty('number_from') && filters.hasOwnProperty('number_to') && (
                    <Fragment>
                        <div className="filter">
                            <div className="form-group">
                                <Input
                                    {...invoiceOrCreditNoteNumberForm.fields.number_from()}
                                    placeholder={t('search.period_from')}
                                    type="number"
                                />
                            </div>
                        </div>
                        <div className="filter">
                            <div className="form-group">
                                <Input
                                    {...invoiceOrCreditNoteNumberForm.fields.number_to()}
                                    placeholder={t('search.period_to')}
                                    type="number"
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            {filters.hasOwnProperty('subsidiary') && (
                <div className="filter filter-button--container">
                    <Button
                        icon={<i className="fas fa-search" />}
                        onClick={() => invoiceOrCreditNoteNumberForm.handleSubmit()}
                        sm
                    />
                </div>
            )}
        </Fragment>
    );
};

export default Filters;
