import React from 'react';
import {useTranslation} from 'react-i18next';
import {Draggable} from '@hello-pangea/dnd';

const TableColumn = ({className = '', index, tc}) => {
    const {t} = useTranslation();

    return (
        <Draggable draggableId={tc.col} index={index}>
            {(provided, snapshot) => (
                <li
                    className={`${snapshot.isDragging ? 'bg-light-green ' : ''} ` + className + ' transition-bg'}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {t('search.' + tc.col)}
                </li>
            )}
        </Draggable>
    );
};

export default TableColumn;
