import React from 'react';
import {useTranslation} from 'react-i18next';
import {moneyFormat} from '../../../lib/helpers';
import {useAuth} from '../../../lib/UseAuth';

const Margins = ({car}) => {
    const {t} = useTranslation();
    const {user} = useAuth();

    const hasParkingTime = car.margins.parking_time !== null;
    const hasParkingCost = car.margins.parking_cost !== null;
    const hasPaymentTime = car.margins.payment_time !== null;
    const hasFinancingCost = car.margins.financing_cost !== null;
    const hasSellingTime = car.margins.selling_time !== null;
    const hasFinancingTime = car.margins.financing_time !== null;

    return (
        <section className="tabs">
            <div className="tabs-nav">
                <span className="tabs-nav-title">{t('general.margins')}</span>
            </div>
            <div className="tabs-content tabs-content--margin">
                <ul className="cols">
                    {hasParkingTime && (
                        <li>
                            <span>{t('general.parking_time')}</span>
                            <span className="text-muted">
                                {car.margins.parking_time} {t('general.days')}
                            </span>
                        </li>
                    )}
                    {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && hasParkingCost && (
                        <li>
                            <span>{t('general.parking_cost')}</span>
                            <small className="text-muted">
                                {car.margins.parking_time} &times; {moneyFormat(0.9)}
                            </small>
                            <span className="text-muted">{moneyFormat(car.margins.parking_cost)}</span>
                        </li>
                    )}
                    {hasFinancingTime && (
                        <li>
                            <span>{t('general.financing_time')}</span>
                            <span className="text-muted">
                                {car.margins.financing_time} {t('general.days')}
                            </span>
                        </li>
                    )}
                    {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && hasFinancingCost && (
                        <li>
                            <span>{t('general.financing_cost')}</span>
                            <small className="text-muted">
                                {moneyFormat(car.purchase_pricing.costs.purchase_price.amount_in_euro)} &times; 1,93% / 365
                                &times;&nbsp;
                                {car.margins.financing_time}
                            </small>
                            <span className="text-muted">{moneyFormat(car.margins.financing_cost)}</span>
                        </li>
                    )}
                    {hasSellingTime && (
                        <li>
                            <span>{t('general.selling_time')}</span>
                            <span className="text-muted">
                                {car.margins.selling_time} {t('general.days')}
                            </span>
                        </li>
                    )}
                    {hasPaymentTime && (
                        <li>
                            <span>{t('general.payment_time')}</span>
                            <span className="text-muted">
                                {car.margins.payment_time} {t('general.days')}
                            </span>
                        </li>
                    )}
                    {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                        <li>
                            <span>
                                {t('search.selling_price')} - <br />
                                {t('car.purchase_pricing.total_purchase_price')}
                            </span>
                            <span className="text-muted">{moneyFormat(car.margins.margin)}</span>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default React.memo(Margins);
