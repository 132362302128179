import React, {Fragment, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {orderByProperty} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import PDIToggle from '../../../../components/CarDetail/PDIToggle';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import EquipmentSelection from './EquipmentSelection';
import Section from './Section';

const Equipment = ({disabled, form, isCreate, make, model, type}) => {
    const {i18n, t} = useTranslation();
    const {user} = useAuth();
    const [isLoading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [includedOptions, setIncludedOptions] = useState(form.data.equipment);

    const loadOptions = (cb = () => {}) => {
        authenticatedHttp()
            .get('/options')
            .then((response) => {
                setOptions(
                    response.data.data.map((option) => ({
                        id: option.id,
                        name: option.name[i18n.language],
                        description: option.description[i18n.language],
                        code: option.code,
                        make: option.make,
                        model_id: option.model_id,
                        type_id: option.type_id,
                    }))
                );
                cb();
            });
    };

    useEffect(() => {
        loadOptions(() => {
            setLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <Section name="equipment">
                <p>{t('general.loading')}</p>
            </Section>
        );
    }

    const checkedIds = form.data.equipment.map((includedOption) => includedOption.option_id);
    const highlightedIds = form.data.equipment
        .filter((includedOption) => includedOption.highlighted)
        .map((includedOption) => includedOption.option_id);

    const handleOpenModal = () => {
        setIncludedOptions(form.data.equipment);
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSave = () => {
        form.setData({...form.data, equipment: includedOptions});
        handleCloseModal();
    };

    const checkedOptions = orderByProperty(
        options.filter((option) => checkedIds.includes(option.id)),
        'name'
    );

    const equipmentErrors = form.errors.filter((error) => error.path === 'equipment').map((error) => error.message);

    return (
        <Fragment>
            <Section name="equipment">
                {equipmentErrors.length > 0 && (
                    <div className="alert alert-danger">
                        <ul>
                            {equipmentErrors.map((error) => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="columns">
                    {checkedOptions.length === 0 && <p>{t('car.equipment.none_found')}</p>}
                    {checkedOptions.map((option) => {
                        return (
                            <p
                                className={`d-flex flex-row ${highlightedIds.includes(option.id) ? 'text-bold' : ''}`}
                                key={option.id}
                            >
                                {option.code && '(' + option.code + ') '}
                                {/*TODO: Remove check when all options have a name*/}
                                {option.name !== '' && option.name ? (
                                    <Fragment>
                                        {option.name}
                                        {option.description && (
                                            <div id="tooltip">
                                                <i className="fas fa-info-circle pl-2" style={{color: '#aaa'}} />
                                                <p className="font-weight-normal option-tooltip">{option.description}</p>
                                            </div>
                                        )}
                                    </Fragment>
                                ) : (
                                    option.description
                                )}
                                {highlightedIds.includes(option.id) && (
                                    <i className="far fa-star ms-2" style={{color: 'rgb(65, 66, 68)'}} />
                                )}
                            </p>
                        );
                    })}
                </div>
                <div>
                    {!disabled && user.permissions.CAN_EDIT_EQUIPMENT && (
                        <Button color="blue" onClick={handleOpenModal} small>
                            <i className="fa fa-edit" /> {t('general.edit')}
                        </Button>
                    )}
                    &nbsp;&nbsp;
                    <PDIToggle disabled={disabled} form={form} isCreate={isCreate} />
                </div>
            </Section>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                renderBody={() => (
                    <EquipmentSelection
                        includedOptions={includedOptions}
                        loadOptions={loadOptions}
                        make={make}
                        model={model}
                        options={options}
                        setIncludedOptions={setIncludedOptions}
                        type={type}
                    />
                )}
                renderFooter={() => (
                    <Button color="blue" onClick={handleSave}>
                        {t('general.save')}
                    </Button>
                )}
                renderHeader={() => (
                    <Fragment>
                        <h2>
                            <strong>{t('car.determine_equipment')}</strong>
                        </h2>
                        <h5>{t('car.determine_equipment_description')}</h5>
                    </Fragment>
                )}
                shouldCloseOnOverlayClick={false}
            />
        </Fragment>
    );
};

export default Equipment;
