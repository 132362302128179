import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import EditMailModal from '../../../../components/Modal/EditMailModal';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';
import Layout from '../../../../components/Layout/Layout';
import Button from '../../../../components/Button/Button';
import Spinner from '../../../../components/Spinner/Spinner';
import IconSave from '../../../../../assets/svg/icon-save.svg';

import '../../Documents.scss';
import CarInfoCell from '../../Components/CarInfoCell';
import MailHistory from '../../Invoices/Detail/MailHistory';
import PaymentHistory from '../../PaymentHistory';
import RequiredAdvance from '../../RequiredAdvance';
import PurchaseOrderFiles from './PurchaseOrderFiles';
import RegisterIncomingAdvance from '../../../Cars/List/Modal/RegisterIncomingAdvance';

const Detail = ({purchaseOrder, refresh, users}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();
    let navigate = useNavigate();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [singleSubsidiary, setSingleSubsidiary] = useState(true);

    const handleSend = (mailText) => {
        setLoadingMessage(t('nav.submit') + '…');
        authenticatedHttp()
            .post('/purchase-orders/' + purchaseOrder.id + '/send', {
                mail_text: mailText,
            })
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    const editMailText = () => {
        showModal(<EditMailModal documents={[purchaseOrder]} handleSend={handleSend} />);
    };

    const cars = purchaseOrder.lines.filter((line) => line.type === 'CAR').map((line) => line.car);

    useEffect(() => {
        if (cars.length === 0) {
            return;
        }
        const firstCarSubsidiary = cars[0].subsidiary;
        const singleSubsidiary = cars.every((car) => car.subsidiary === firstCarSubsidiary);
        setSingleSubsidiary(singleSubsidiary);
        if (!singleSubsidiary) {
            alert(t('validation.different_subsidiaries'));
        }
    }, [purchaseOrder]);

    const canEdit = purchaseOrder.lines.filter((line) => line.type === 'CAR').some((line) => line.car.sale.status === 'SOLD');
    const carIds = purchaseOrder.lines.filter((line) => line.type === 'CAR').map((line) => line.car.car_id);

    return (
        <Layout
            className="documents documents-detail purchase-order-detail"
            header={() => (
                <Fragment>
                    <div className="title">
                        <h1>{t('general.purchase_order')}</h1>
                        <h2>{t('enum.document_status.' + purchaseOrder.status)}</h2>
                    </div>
                    <div>
                        <Button
                            icon={<i className="fas fa-check" />}
                            onClick={() =>
                                showModal(
                                    <RegisterIncomingAdvance
                                        cars={cars}
                                        customers={[purchaseOrder.customer.data]}
                                        refresh={refresh}
                                    />
                                )
                            }
                            outline
                        >
                            {t('general.register_incoming_advance')}
                        </Button>
                        {canEdit && (
                            <Button
                                icon={<i className="far fa-edit" />}
                                onClick={() => navigate(`/bestelbonnen/${purchaseOrder.id}/bewerken`)}
                                outline
                            >
                                {t('general.edit')}
                            </Button>
                        )}
                        <Button
                            icon={<IconSave />}
                            onClick={() => {
                                setLoadingMessage(t('general.generating_document'));
                                downloadFile(
                                    '/download/purchase-order',
                                    {purchase_order_id: purchaseOrder.id},
                                    t('general.purchase_order').toLowerCase() + '_' + purchaseOrder.id
                                ).then(() => {
                                    setLoadingMessage(null);
                                });
                            }}
                        >
                            {t('general.download_as_pdf')}
                        </Button>
                    </div>
                </Fragment>
            )}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <Fragment>
                <div className="row">
                    <div className="col">
                        <section>
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('search.subsidiary')}</label>
                                        <span>{t('enum.subsidiary.' + purchaseOrder.subsidiary)}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('car.seller')}</label>
                                        <span>{users.find((u) => u.id === purchaseOrder.created_by).full_name}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.language')}</label>
                                        <span>{t('enum.language.' + purchaseOrder.language)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.purchase_order_nr')}</label>
                                        <span>{purchaseOrder.id}</span>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.sales_channel')}</label>
                                        <span>{t('enum.sales_channel.' + purchaseOrder.channel)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.purchase_order_date')}</label>
                                        <span>{dateFormat(purchaseOrder.date)}</span>
                                    </div>
                                </div>
                                {purchaseOrder.advance_due_date && (
                                    <div className="col-4">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.advance_due_date')}</label>
                                            <span>{dateFormat(purchaseOrder.advance_due_date)}</span>
                                        </div>
                                    </div>
                                )}
                                {purchaseOrder.invoice_on && (
                                    <div className="col-4">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.invoice_on')}</label>
                                            <span>{dateFormat(purchaseOrder.invoice_on)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="form-group form-group-vertical">
                                <label>{t('general.mail_text')}</label>
                                <p className="render-line-breaks">{purchaseOrder.mail_text}</p>
                            </div>
                        </section>
                    </div>
                    <div className="col">
                        <section>
                            <div className="recipients">
                                <div className="form-group form-group-vertical">
                                    <label>{t('general.purchase_order_sent_to')}</label>
                                    <div className="customer">
                                        <a
                                            href={`/bedrijven/${purchaseOrder.customer.data.id}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {purchaseOrder.customer.data.label}
                                        </a>
                                        <div className="contacts">
                                            {purchaseOrder.contacts.map((contact, index) => (
                                                <a
                                                    className="contact"
                                                    href={`/contacten/${contact.id}`}
                                                    key={index}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {contact.full_name} {contact.email ? `(${contact.email})` : ''}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {purchaseOrder.is_imported ? (
                                <h2>{t('general.ecinda_import')}</h2>
                            ) : (
                                <Button color="blue" disabled={!singleSubsidiary} onClick={editMailText}>
                                    {purchaseOrder.status === 'SENT' ? t('general.resend') : t('general.send')}
                                </Button>
                            )}
                        </section>
                        <MailHistory document={purchaseOrder} />
                    </div>
                </div>
                <section className="no-padding items">
                    <div className="grid-table grid-table-invoices">
                        <div className="grid-table-row">
                            <div className="grid-table-cell">{t('general.items')}</div>
                            <div className="grid-table-cell">{t('general.advance')}</div>
                            <div className="grid-table-cell">{t('general.base_prices_on')}</div>
                            <div className="grid-table-cell numerical">{t('api.vat')}</div>
                            <div className="grid-table-cell numerical">{t('car.pricing.price')}</div>
                        </div>
                        {purchaseOrder.lines.map((line) => (
                            <div
                                className={`grid-table-row ${
                                    (line.relisted_for_sale_on || line.credited >= line.price) && 'disable-line'
                                }`}
                                key={line.id}
                            >
                                <div className="grid-table-cell">
                                    {line.type === 'CAR' && (
                                        <CarInfoCell
                                            car={line.car}
                                            showIsReadyForInvoiceStatus
                                            showLink
                                            showSubText={!singleSubsidiary}
                                        />
                                    )}
                                    {line.type === 'EXTRA' && <span>{line.description}</span>}
                                    {line.type === 'TRANSPORT' && <span>{t('general.transport')}</span>}
                                </div>
                                <div className="grid-table-cell">
                                    {line.relisted_for_sale_on || purchaseOrder.no_advance_required ? (
                                        '/'
                                    ) : (
                                        <RequiredAdvance line={line} />
                                    )}
                                </div>
                                <div className="grid-table-cell">
                                    {!line.relisted_for_sale_on
                                        ? line.type === 'CAR' && <span>{t('enum.price_options.' + line.base_pricing)}</span>
                                        : '/'}
                                </div>
                                <div className="grid-table-cell numerical">{line.vat_rate}%</div>
                                <div className="grid-table-cell numerical">
                                    {line.relisted_for_sale_on ? '/' : moneyFormat(line.price)}
                                    {!!line.credited && (
                                        <p className="text-end font-italic">
                                            {t('general.credited')}: {moneyFormat(line.credited)}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="car-count">
                        {t('car.number_of_cars')}:{' '}
                        {purchaseOrder.lines.filter((line) => !line.relisted_for_sale_on && line.type === 'CAR').length}
                    </p>
                    <div className="lines-footer">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td className="text-end">
                                        <strong>{t('api.subtotal')}</strong>
                                    </td>
                                    <td className="text-end" width="130">
                                        {moneyFormat(purchaseOrder.total)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <strong>{t('api.vat')}</strong>
                                    </td>
                                    <td className="text-end" width="130">
                                        {moneyFormat(purchaseOrder.vat)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <h3>
                                            <span>{t('general.total')}</span>
                                        </h3>
                                    </td>
                                    <td className="text-end" width="130">
                                        <h3>{moneyFormat(purchaseOrder.total_incl_vat)}</h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="row">
                    <div className="col-6">
                        <PaymentHistory carIds={carIds} document="purchase-orders" documentId={purchaseOrder.id} />
                    </div>
                    <div className="col-6">
                        <PurchaseOrderFiles
                            purchaseOrder={purchaseOrder}
                            refresh={refresh}
                            setLoadingMessage={setLoadingMessage}
                        />
                    </div>
                </div>
            </Fragment>
        </Layout>
    );
};

export default React.memo(Detail);
