// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.credit-note-list section {
    overflow: auto;
  }
  main.credit-note-list .grid-table {
    min-width: 1500px;
  }
  main.credit-note-list .grid-table .grid-table-row {
      grid-template-columns: 40px 120px 110px 90px 110px 110px 140px 6vw 28vw 1fr;
    }
  main.credit-note-list .grid-table .grid-table-row:first-of-type {
         grid-template-columns: 40px 120px 110px 90px 110px 110px 140px 6vw 28vw 1fr;
      }
  main.credit-note-list .content section {
    margin-top: 25px;
  }
`, "",{"version":3,"sources":["webpack://./src/js/views/Documents/CreditNotes/List/List.css"],"names":[],"mappings":"AACE;IACE,cAAc;EAChB;EACA;IACE,iBAAiB;EAOnB;EANE;MACE,2EAA2E;IAI7E;EAHE;SACG,2EAA2E;MAC9E;EAGJ;IACE,gBAAgB;EAClB","sourcesContent":["main.credit-note-list {\n  section {\n    overflow: auto;\n  }\n  .grid-table {\n    min-width: 1500px;\n    .grid-table-row {\n      grid-template-columns: 40px 120px 110px 90px 110px 110px 140px 6vw 28vw 1fr;\n      &:first-of-type {\n         grid-template-columns: 40px 120px 110px 90px 110px 110px 140px 6vw 28vw 1fr;\n      }\n    }\n  }\n  .content section {\n    margin-top: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
