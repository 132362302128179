// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.creditNote-create .also-create-purchase-order {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
    main.creditNote-create .also-create-purchase-order label {
      margin-bottom: 18px;
    }
  main.creditNote-create .items .grid-table .grid-table-row {
    grid-template-columns: 1fr 160px 70px;
  }
`, "",{"version":3,"sources":["webpack://./src/js/views/Documents/CreditNotes/Management/Create.css"],"names":[],"mappings":"AACE;IACE,aAAa;IACb,sBAAsB;IACtB,yBAAyB;EAI3B;IAHE;MACE,mBAAmB;IACrB;EAGF;IACE,qCAAqC;EACvC","sourcesContent":["main.creditNote-create {\n  .also-create-purchase-order {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    label {\n      margin-bottom: 18px;\n    }\n  }\n\n  .items .grid-table .grid-table-row {\n    grid-template-columns: 1fr 160px 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
