import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import Button from './Button';

const Dropdown = ({actions, align = 'left', buttonProps, disabled}) => {
    const [showMenu, toggleMenu] = useState(false);
    const ref = useRef(null);

    const handleOutsideClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            toggleMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideClick);

        return () => {
            document.removeEventListener('mouseup', handleOutsideClick);
        };
    }, []);

    const handleClick = () => {
        if (!disabled) {
            toggleMenu((prevState) => !prevState);
        }
    };

    const {label, ...props} = buttonProps;

    return (
        <div className="btn--dropdown-wrapper action-dropdown" ref={ref}>
            <Button disabled={disabled} onClick={handleClick} {...props}>
                {label ? <span>{label}&nbsp;</span> : false}
                <i className="fas fa-angle-down" />
            </Button>
            {showMenu && (
                <div
                    className={classNames({
                        'btn--dropdown-menu': true,
                        'btn--dropdown-menu-right': align === 'right',
                    })}
                >
                    {Object.entries(actions)
                        .filter(([, onClick]) => !!onClick)
                        .map(([title, type]) =>
                            typeof type === 'boolean' ? (
                                <p className="dropdown-title" key={title}>
                                    {title}
                                </p>
                            ) : (
                                <a
                                    key={title}
                                    onClick={(e) => {
                                        type(e);
                                        toggleMenu(false);
                                    }}
                                >
                                    {title}
                                </a>
                            )
                        )}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
