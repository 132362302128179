import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import {setPageTitle} from '../../../../lib/helpers';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import useFetchData from '../../../../lib/UseFetchData';
import NotFound from '../../../NotFound/NotFound';

import Detail from './Detail';
import {useTranslation} from 'react-i18next';

const LoadDetail = () => {
    const {invoiceId} = useParams();
    const {t} = useTranslation();
    let navigate = useNavigate();

    const [invoice, setInvoice] = useState(null);
    const [users] = useFetchData('/users?include_inactive');

    const fetchInvoice = () => {
        authenticatedHttp()
            .get('/invoices/' + invoiceId)
            .then((response) => {
                setInvoice(response.data.data);
                setPageTitle(t('general.invoice'));
            })
            .catch(() => {
                navigate('/facturen');
            });
    };
    useEffect(() => {
        if (/^\d+$/.test(invoiceId)) {
            fetchInvoice();
        }
    }, [invoiceId]);

    if (!/^\d+$/.test(invoiceId)) {
        return <NotFound />;
    }

    if (!users || !invoice) {
        return <Spinner />;
    }

    return <Detail invoice={invoice} refresh={fetchInvoice} users={users} />;
};

export default LoadDetail;
