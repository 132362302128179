import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchCustomers} from '../../../lib/FetchCustomers';
import {useAuth} from '../../../lib/UseAuth';
import useFetchData from '../../../lib/UseFetchData';
import {authenticatedHttp} from '../../../lib/zaza-client';
import HistoryItem from './HistoryItem';

const CarHistory = ({carId}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const [currentTab, setCurrentTab] = useState('history');
    const tabs = ['history', 'offers'];
    const [doFetch, setDoFetch] = useState(true);

    useEffect(() => {
        return () => {
            setDoFetch(false);
        };
    }, []);

    const [history, isLoading, isError] = useFetchData(`/cars/${carId}/history`, doFetch);

    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);

    const usersToFetch = useMemo(() => {
        if (!history) {
            return [];
        }

        return [...new Set(history.filter((eventLog) => eventLog.meta.user_id).map((eventLog) => eventLog.meta.user_id))];
    }, [history]);

    const customersToFetch = useMemo(() => {
        if (!history) {
            return [];
        }

        const customersToFetch = history
            .filter((eventLog) => eventLog.meta.customer_id)
            .map((eventLog) => eventLog.meta.customer_id);

        history
            .filter((eventLog) => eventLog.meta.customer_ids)
            .forEach((eventLog) => {
                eventLog.meta.customer_ids.forEach((customerId) => {
                    customersToFetch.push(customerId);
                });
            });

        return [...new Set(customersToFetch)];
    }, [history]);

    useEffect(() => {
        if (doFetch) {
            usersToFetch.forEach((userId) => {
                authenticatedHttp()
                    .get('/users/' + userId)
                    .then((response) => {
                        setUsers((prevState) => [...prevState, response.data.data]);
                    });
            });
        }
    }, [usersToFetch]);

    useEffect(() => {
        fetchCustomers(customersToFetch, doFetch).then((response) => {
            setCustomers(response);
        });
    }, [customersToFetch]);

    const historyFilter = (event) => {
        const eventName = event.event_name;
        const addEvents = currentTab === 'history' ? eventName !== 'QuoteWasCreated' : eventName === 'QuoteWasCreated';

        return addEvents && !(eventName === 'PurchaseDocumentWasRegistered' && !user.permissions.CAN_VIEW_PURCHASING_INFORMATION);
    };

    return (
        <section className="tabs">
            <div className="tabs-nav">
                {tabs.map((tab, index) => (
                    <a
                        className={'tabs-nav-link ' + (tab === currentTab ? 'tabs-nav-link--active' : '')}
                        key={index}
                        onClick={() => {
                            setCurrentTab(tab);
                        }}
                    >
                        {t('general.' + tab)}
                    </a>
                ))}
            </div>
            <div className="tabs-content tabs-content--margin tabs-content-history">
                {isLoading && <p>{t('general.loading')}</p>}
                {isError && <p>{t('general.loading_error')}</p>}
                {!isLoading && !isError && (
                    <ul>
                        {history.filter(historyFilter).map((eventLog) => {
                            return <HistoryItem customers={customers} eventLog={eventLog} key={eventLog.id} users={users} />;
                        })}
                    </ul>
                )}
            </div>
            {/*<!-- TODO -->*/}
            {/*<div className="tabs-bottom text-center">*/}
            {/*    <Button*/}
            {/*        color={null}*/}
            {/*        onClick={() => {*/}
            {/*            console.log('Load more history');*/}
            {/*        }}*/}
            {/*        small*/}
            {/*    >*/}
            {/*        {t('general.load_more_history')}*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </section>
    );
};

export default React.memo(CarHistory);
