import React, {Fragment, useState} from 'react';
import classnames from 'classnames';

const TextArea = ({className, disabled, error, onChange, placeholder, prefix, rows, suffix, value, ...props}) => {
    const [isEditting, setEditting] = useState(false);
    const displayValue = () => {
        const displayValue = value === null ? '' : value;
        if (!isEditting && suffix) {
            return displayValue + ' ' + suffix;
        }
        if (!isEditting && prefix) {
            return prefix + ' ' + displayValue;
        }

        return displayValue;
    };

    return (
        <Fragment>
            <textarea
                {...props}
                className={classnames({
                    'form-control': true,
                    [className]: className,
                    'has-error': value,
                    'has-value': value,
                })}
                disabled={disabled}
                onBlur={() => {
                    setEditting(false);
                }}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                onFocus={() => {
                    setEditting(true);
                }}
                placeholder={placeholder}
                rows={rows}
                value={displayValue()}
            />

            {error && <p className="error-message">{error}</p>}
        </Fragment>
    );
};

export default React.memo(TextArea);
