import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/NewModal';

const DeleteDocuments = ({handleDelete, type}) => {
    const {t} = useTranslation();

    return (
        <Modal title={t(`general.delete_${type}`)}>
            <Modal.Body>
                <p>{t(`general.delete_${type}_confirm`)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" onClick={handleDelete}>
                    {t(`general.delete_${type}`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteDocuments;
