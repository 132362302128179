import moment from 'moment';
import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

const TimePicker = ({onChange, value}) => {
    const handleTimePickerChange = (time) => {
        if (typeof time === 'string' || time instanceof String) {
            return;
        }
        onChange(time.format('HH:mm'));
    };

    return (
        <Datetime
            closeOnSelect
            dateFormat={false}
            onChange={(time) => {
                handleTimePickerChange(time);
            }}
            timeFormat
            value={value ? moment(value, 'HH:mm') : moment().hour(12).minutes(0)}
        />
    );
};

export default TimePicker;
