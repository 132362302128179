import React, {Fragment, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../../lib/UseModal';
import {moneyFormat} from '../../../../lib/helpers';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Input from '../../../../components/Form/Input';
import Modal from '../../../../components/Modal/NewModal';
import Money from '../../../../components/Form/Money';

const RegisterIncomingAdvance = ({cars, customers, refresh, showOverview = true}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const {control, handleSubmit, watch} = useForm();
    const [saving, toggleSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const allSold = cars.every((car) => car.sale.status === 'SOLD' || car.sale.status === 'INVOICED');

    const onSubmit = (data) => {
        toggleSaving(true);

        data.cars = cars.map((car) => car.car_id);

        authenticatedHttp()
            .post('/cars/advances', data)
            .then(() => {
                toggleSaving(false);
                closeModal();
                refresh();
            })
            .catch((response) => {
                toggleSaving(false);
                setErrors(
                    Object.values(response.response.data.errors).reduce((errors, keyErrors) => {
                        keyErrors.forEach((error) => errors.push(error));

                        return errors;
                    }, [])
                );
            });
    };

    if (!allSold) {
        return (
            <Modal title={t('general.register_incoming_advance')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <p>{t('search.sold_required_for_advance')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal title={t('general.register_incoming_advance')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    {showOverview && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>{t('search.vin')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.make')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.sold_to')}</strong>
                                    </th>
                                    <th className="text-end">
                                        <strong>{t('search.sold_for')}</strong>
                                    </th>
                                    <th className="text-end">
                                        <strong>{t('search.outstanding_debt')}</strong>
                                    </th>
                                    <th className="text-end">
                                        <strong>{t('search.outstanding_advance')}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {cars.map((car) => (
                                    <tr key={car.car_id}>
                                        <td>{car.vin}</td>
                                        <td>{car.mmt}</td>
                                        <td>{customers.find((customer) => customer.id === car.sale.sold_to)?.label}</td>
                                        <td className="text-end">{moneyFormat(parseFloat(car.sales_pricing.sold_for))}</td>
                                        <td className="text-end">
                                            {moneyFormat(parseFloat(car.sales_pricing.outstanding_debt))}
                                        </td>
                                        <td className="text-end">
                                            {car.sales_pricing.outstanding_advance
                                                ? moneyFormat(parseFloat(car.sales_pricing.outstanding_advance))
                                                : '-'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3} />
                                    <td className="text-end">
                                        {moneyFormat(cars.reduce((sum, car) => sum + car.sales_pricing.sold_for, 0))}
                                    </td>
                                    <td className="text-end">
                                        {moneyFormat(cars.reduce((sum, car) => sum + car.sales_pricing.outstanding_debt, 0))}
                                    </td>
                                    <td className="text-end">
                                        {moneyFormat(cars.reduce((sum, car) => sum + car.sales_pricing.outstanding_advance, 0))}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    )}
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="date">{t('general.date_paid')}</label>
                                <Controller
                                    control={control}
                                    id="date"
                                    name="date"
                                    render={({field}) => <DatePicker {...field} placeholder={t('general.date_paid')} />}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="amount">{t('general.payment_per_car')}</label>
                                <Controller
                                    control={control}
                                    defaultValue={0}
                                    id="amount"
                                    name="amount"
                                    placeholder={t('general.payment_per_car')}
                                    render={({field}) => <Money {...field} />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom: '150px'}}>
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="payment_method">{t('general.payment_method')}</label>
                                <Controller
                                    control={control}
                                    defaultValue="TRANSFER"
                                    id="payment_method"
                                    name="payment_method"
                                    placeholder={t('general.payment_method')}
                                    render={({field}) => <Enum {...field} enumName="payment_method" />}
                                />
                            </div>
                        </div>
                        {watch('payment_method', 'TRANSFER') === 'TRANSFER' && (
                            <Fragment>
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label htmlFor="bank">{t('general.bank')}</label>
                                        <Controller
                                            control={control}
                                            id="bank"
                                            name="bank"
                                            placeholder={t('general.bank')}
                                            render={({field}) => <Enum {...field} enumName="bank" />}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label htmlFor="bank_statement">{t('general.bank_statement')}</label>
                                        <Controller
                                            control={control}
                                            id="bank_statement"
                                            name="bank_statement"
                                            placeholder={t('general.bank_statement')}
                                            render={({field}) => <Input {...field} />}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    {errors && (
                        <div className="alert alert-danger">
                            <ul>
                                {errors.map((error, i) => (
                                    <li key={i}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="blue" disabled={saving} submit>
                        {t('general.register_incoming_advance')}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default RegisterIncomingAdvance;
