import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useAuth} from './lib/UseAuth';
import CompanyList from './views/Business/Companies/List';
import CompanyAdd from './views/Business/Companies/Add';
import CompanyEdit from './views/Business/Companies/Edit';
import CompanyDetail from './views/Business/Companies/Detail';
import ContactList from './views/Business/Contacts/List';
import ContactAdd from './views/Business/Contacts/Add';
import ContactEdit from './views/Business/Contacts/Edit';
import ContactDetail from './views/Business/Contacts/Detail';
import CarsList from './views/Cars/List/LoadList';
import CarsCreate from './views/Cars/Management/LoadCreate';
import LoadUpdate from './views/Cars/Management/LoadUpdate';
import Search from './views/Cars/Search/Search';
import InvoiceDetail from './views/Documents/Invoices/Detail/LoadDetail';
import InvoiceList from './views/Documents/Invoices/List/List';
import InvoiceCreate from './views/Documents/Invoices/Management/LoadCreate';
import InvoiceUpdate from './views/Documents/Invoices/Management/LoadUpdate';
import PurchaseOrderDetail from './views/Documents/PurchaseOrders/Detail/LoadDetail';
import PurchaseOrderList from './views/Documents/PurchaseOrders/List/List';
import PurchaseOrderCreate from './views/Documents/PurchaseOrders/Management/LoadCreate';
import PurchaseOrderUpdate from './views/Documents/PurchaseOrders/Management/LoadUpdate';
import QuoteDetail from './views/Documents/NewQuotes/Detail';
import QuoteList from './views/Documents/Quotes/List/List';
import QuoteCreate from './views/Documents/NewQuotes/Create';
import QuoteUpdate from './views/Documents/NewQuotes/Update';
import CreditNoteCreate from './views/Documents/CreditNotes/Management/LoadCreate';
import CreditNoteDetail from './views/Documents/CreditNotes/Detail/LoadDetail';
import CreditNoteList from './views/Documents/CreditNotes/List/List';
import CreditNoteUpdate from './views/Documents/CreditNotes/Management/LoadUpdate';
import SelectList from './views/Lists/SelectList';
import LoadList from './views/Lists/LoadList';

import NotFound from './views/NotFound/NotFound';

const Routing = () => {
    const {user} = useAuth();
    return (
        <Routes>
            <Route element={<Search />} path="/autos/zoeken" />
            <Route element={<Search />} path="/" />
            <Route element={<CarsList />} path="/autos/lijst" />
            {user.permissions.CAN_CREATE_CARS && <Route element={<CarsCreate />} path="/autos/creatie" />}
            <Route element={<LoadUpdate />} path="/autos/:carId" />
            <Route element={<QuoteList />} path="/offertes" />
            <Route element={<QuoteDetail />} path="/offertes/:quoteId" />
            <Route element={<QuoteUpdate />} path="/offertes/:quoteId/bewerken" />
            <Route element={<QuoteCreate />} path="/offertes/creatie" />
            <Route element={<PurchaseOrderList />} path="/bestelbonnen" />
            <Route element={<PurchaseOrderDetail />} path="/bestelbonnen/:purchaseOrderId" />
            <Route element={<PurchaseOrderUpdate />} path="/bestelbonnen/:purchaseOrderId/bewerken" />
            <Route element={<PurchaseOrderCreate />} path="/bestelbonnen/creatie" />
            <Route element={<InvoiceList />} path="/facturen" />
            <Route element={<InvoiceDetail />} path="/facturen/:invoiceId" />
            <Route element={<InvoiceUpdate />} path="/facturen/:invoiceId/bewerken" />
            <Route element={<InvoiceCreate />} path="/facturen/creatie" />
            <Route element={<CreditNoteList />} path="/creditnotas" />
            <Route element={<CreditNoteCreate />} path="/creditnotas/creatie" />
            <Route element={<CreditNoteDetail />} path="/creditnotas/:creditNoteId" />
            <Route element={<CreditNoteUpdate />} path="/creditnotas/:creditNoteId/bewerken" />
            <Route element={<CompanyList />} path="/bedrijven" />
            <Route element={<CompanyDetail />} path="/bedrijven/:companyId" />
            <Route element={<CompanyAdd />} path="/bedrijven/creatie" />
            <Route element={<CompanyEdit />} path="/bedrijven/:companyId/bewerken" />
            <Route element={<ContactDetail />} path="/contacten/:contactId" />
            <Route element={<ContactAdd />} path="/contacten/creatie" />
            <Route element={<ContactEdit />} path="/contacten/:contactId/bewerken" />
            <Route element={<ContactList />} path="/contacten" />
            {user.permissions.CAN_ACCESS_LISTS && <Route element={<SelectList />} path="/lijsten" />}
            {user.permissions.CAN_ACCESS_LISTS && <Route element={<LoadList />} path="/lijsten/:selectedList" />}
            <Route element={NotFound} />
        </Routes>
    );
};

export default Routing;
