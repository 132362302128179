import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';

import Modal from '../../../../components/Modal/NewModal';
import {useModal} from '../../../../lib/UseModal';

const CarErrorsDocument = ({
    cantBeSoldCarsInCountry,
    cantBeSoldOutsideEU,
    cars,
    customer,
    lines,
    remove,
    sameSupplierAsCustomer,
    setCustomer,
    watch,
}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [removedCarIds, setRemovedCarIds] = useState([]);

    const unsellableCarsCount = lines.filter((line) => {
        const carId = parseInt(line.car_id);
        return (
            !removedCarIds.includes(carId) &&
            (sameSupplierAsCustomer.includes(carId) ||
                cantBeSoldCarsInCountry.includes(carId) ||
                cantBeSoldOutsideEU.includes(carId))
        );
    }).length;

    if (unsellableCarsCount === 0) {
        closeModal();
        setCustomer(customer);
    }

    const removeCars = (carId) => {
        const index = watch('lines').findIndex((line) => parseInt(line.car_id) === carId);
        setRemovedCarIds([...removedCarIds, carId]);
        remove(index);
    };

    return (
        <Modal title={t('general.error_messages')}>
            <Modal.Body>
                <div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="pl-0 pt-3 pb-3 border-top-0" />
                                <th className="pl-0 pt-3 pb-3 border-top-0">
                                    <strong>{t('search.car_id')}</strong>
                                </th>
                                <th className="p-3 border-top-0">
                                    <strong>{t('search.vin')}</strong>
                                </th>
                                <th className="p-3 border-top-0">
                                    <strong>{t('search.make')}</strong>
                                </th>
                                <th className="pr-0 pt-3 pb-3 border-top-0" />
                                <th className="pr-0 pt-3 pb-3 border-top-0">
                                    <strong>{t('general.delete_car')}</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lines
                                .filter((line) => line.type === 'CAR')
                                .map((line) => {
                                    const carId = parseInt(line.car_id);

                                    if (
                                        !removedCarIds.includes(carId) &&
                                        (cantBeSoldCarsInCountry.includes(carId) ||
                                            cantBeSoldOutsideEU.includes(carId) ||
                                            sameSupplierAsCustomer.includes(carId))
                                    ) {
                                        const car = Array.isArray(cars)
                                            ? cars.find((car) => car.car_id === parseInt(line.car_id))
                                            : cars[line.car_id];

                                        return (
                                            <tr key={car.car_id}>
                                                <td className="pl-0 pt-3 pb-3 align-middle">
                                                    <i className="fas fa-exclamation-circle" style={{color: 'red'}} />
                                                </td>
                                                <td className="pl-0 pt-3 pb-3 align-middle">{car.car_id}</td>
                                                <td className="p-3 align-middle">{car.vin}</td>
                                                <td className="p-3 align-middle">{car.mmt}</td>
                                                <td className="p-3 align-middle">
                                                    {cantBeSoldCarsInCountry.includes(carId) &&
                                                        t('general.cant_be_sold_in', {
                                                            country: t('enum.country.' + customer.address.country_code),
                                                        })}
                                                    {cantBeSoldOutsideEU.includes(carId) && t('general.cant_be_sold_outside_EU')}
                                                    {sameSupplierAsCustomer.includes(carId) &&
                                                        t('error.same_supplier_as_customer')}
                                                </td>
                                                <td
                                                    className="pr-0 pt-3 pb-3 form-group align-middle align-content-center"
                                                    style={{display: 'flex', justifyContent: 'center', border: 'none'}}
                                                >
                                                    <Button
                                                        color="blue"
                                                        onClick={() => {
                                                            removeCars(carId);
                                                        }}
                                                    >
                                                        {t('general.delete')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    }

                                    return false;
                                })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CarErrorsDocument;
