import React, {forwardRef} from 'react';
import classnames from 'classnames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Checkbox = forwardRef(({disabled, id, label, name, onChange, semiChecked, value = false, ...props}, ref) => {
    let {className, ...inputProps} = props;

    if (semiChecked && !value) {
        className = className || '' + ' semi';
    }

    // Workaround for useForm controller
    if (props.checked) {
        value = props.checked;
    }

    return (
        <label
            className={classnames({
                disabled: disabled,
                checkbox: true,
            })}
            htmlFor={id}
        >
            <input
                {...inputProps}
                checked={value}
                className={classnames({
                    [className]: className,
                    disabled: disabled,
                })}
                disabled={disabled}
                id={id}
                name={name}
                onChange={(e) => {
                    onChange(e.target.checked);
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                type="checkbox"
            />
            {label && <span>{label}</span>}
        </label>
    );
});

export default React.memo(Checkbox);
