import {useState} from 'react';
import {produce} from 'immer';
import {diff} from 'deep-object-diff';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

const noop = () => {};

const defaultFormOptions = {
    onChange: noop,
    onSave: noop,
    validation: [],
};
const defaultInputOptions = {
    autosave: false,
    onChange: noop,
};

// Helper to set values in an object according to a path.
const setValue = (object, path, value) => {
    const lastKey = path.pop();
    let target = object;
    path.forEach((key) => {
        if (!(key in target)) {
            target[key] = {};
        }
        target = target[key];
    });
    target[lastKey] = value;
};

const isObject = (value) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

// Custom hook for the form
const useForm = (initialData, options) => {
    const {t} = useTranslation();
    const formOptions = {...defaultFormOptions, ...options};
    const [data, setData] = useState(initialData);
    const [changes, setChanges] = useState({});
    const [errors, setErrors] = useState([]);

    const doSave = (options) => {
        let errors = [];
        formOptions.validation.forEach(({paths, rule}) => {
            paths.forEach((path) => {
                if (checkNotForSaleInCountries(path, data)) {
                    const value = _.get(data, path);
                    if (!rule.validator(value)) {
                        errors = [...errors, {path, message: t(...rule.message)}];
                    }
                }
            });
        });

        if (errors.length > 0) {
            setErrors(errors);
            return;
        }

        formOptions.onSave({data, changes, options});
    };
    const setDataAndChanges = (nextState) => {
        setData(nextState);
        setChanges(diff(initialData, nextState));
    };

    // Recursive function that will loop through an object and build a second one with the same dimensions
    // But the second object will return a new object (instead of the value) with props that can be passed to an input
    const buildFormFields = (object, form = {}, basePath = []) => {
        Object.entries(object).forEach(([key, value]) => {
            const path = [...basePath];
            path.push(key);

            if (isObject(value)) {
                form[key] = {};
                buildFormFields(value, form[key], path);

                return;
            }

            // If value is an array of objects, call buildFormFields with objects from array
            if (Array.isArray(value) && value.some(isObject)) {
                form[key] = [];

                value.forEach((v, i) => {
                    form[key][i] = {};
                    const arrayPath = [...path, i];
                    buildFormFields(v, form[key][i], arrayPath);
                });

                return;
            }

            const name = path.join('.');

            // Build the props object that can be spread to an input
            form[key] = (options) => {
                const inputOptions = {...defaultInputOptions, ...options};
                const error = errors.find((error) => error.path === name);

                return {
                    name,
                    error: error ? error.message : undefined,
                    id: name,
                    value,
                    onChange: (value) => {
                        const nextState = produce(data, (draft) => {
                            setValue(draft, path, value);
                            formOptions.onChange(name, value, draft);
                            inputOptions.onChange(name, value, draft);
                        });

                        setDataAndChanges(nextState);
                        if (inputOptions.autosave) {
                            doSave();
                        }
                    },
                };
            };
        });

        return form;
    };

    const fields = buildFormFields(data);

    return {
        fields,
        data,
        changes,
        setData: setDataAndChanges,
        handleSubmit: (options) => {
            doSave(options);
        },
        errors,
    };
};

export const Required = {
    validator: (value) => !!value && value !== 'UNKNOWN',
    message: ['validation.required'],
};

export const MaxLength = (number) => ({
    validator: (value) => value.length <= number,
    message: ['validation.too_long', {number}],
});

export const Custom = (validator, message) => ({
    validator,
    message,
});

const checkNotForSaleInCountries = (validationPath, car) => {
    const paths = [
        'sales_pricing.website_pricing.BE.price',
        'sales_pricing.website_pricing.BE.catalog_price',
        'sales_pricing.website_pricing.NL.price',
        'sales_pricing.website_pricing.NL.catalog_price',
        'sales_pricing.website_pricing.FR.price',
        'sales_pricing.website_pricing.FR.catalog_price',
        'sales_pricing.website_pricing.DE.price',
        'sales_pricing.website_pricing.DE.catalog_price',
        'sales_pricing.website_pricing.CH.price',
        'sales_pricing.website_pricing.CH.catalog_price',
        'sales_pricing.website_pricing.IT.price',
        'sales_pricing.website_pricing.IT.catalog_price',
        'sales_pricing.website_pricing.ROW.price',
        'sales_pricing.website_pricing.ROW.catalog_price',
    ];

    if (paths.includes(validationPath)) {
        switch (true) {
            case validationPath.includes('BE'):
                return !(
                    car.sales_pricing.not_for_sale_in.includes('BE') ||
                    car.sales_pricing.not_for_sale_in.includes('OUTSIDE_OF_EU')
                );
            case validationPath.includes('NL'):
                return !car.sales_pricing.not_for_sale_in.includes('NL');
            case validationPath.includes('FR'):
                return !car.sales_pricing.not_for_sale_in.includes('FR');
            case validationPath.includes('DE'):
                return !car.sales_pricing.not_for_sale_in.includes('DE');
            case validationPath.includes('IT'):
                return !car.sales_pricing.not_for_sale_in.includes('IT');
            case validationPath.includes('CH'):
                return !(
                    car.sales_pricing.not_for_sale_in.includes('CH') ||
                    car.sales_pricing.not_for_sale_in.includes('OUTSIDE_OF_EU')
                );
            case validationPath.includes('ROW'):
                return !(
                    car.sales_pricing.not_for_sale_in.includes('ROW') ||
                    car.sales_pricing.not_for_sale_in.includes('OUTSIDE_OF_EU')
                );
        }
    }

    return true;
};

export default useForm;
