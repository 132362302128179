import Decimal from '@agrora/decimal';
import React, {useState} from 'react';
import Enum from '../../../../components/Form/Enum';
import Money from '../../../../components/Form/Money';
import {moneyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/Form/DatePicker';
import Modal from '../../../../components/Modal/NewModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';

const RegisterOutgoingAdvance = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, setSaving] = useState(false);
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState(0);
    const [bank, setBank] = useState(null);

    const supplier = cars[0].supplied_by.data.id;
    const sameSupplier = cars.every((car) => car.supplied_by.data.id === supplier);
    const currency = cars[0].purchase_pricing.costs.purchase_price.currency;
    const sameValuta = cars.every((car) => car.purchase_pricing.costs.purchase_price.currency === currency);

    if (!sameSupplier || !sameValuta) {
        return (
            <Modal title={t('general.register_outgoing_advance')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <p>{!sameSupplier ? t('general.same_supplier_required') : t('general.same_valuta_required')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const isSupplierBelgian = cars[0].supplied_by.data.country_code === 'BE';

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);
        cars.forEach(() => {});
        authenticatedHttp()
            .post(`/cars/outgoing-advances`, {
                car_ids: cars.map((car) => car.car_id),
                date: date,
                amount: amount,
                bank: bank,
            })
            .then(() => {
                setSaving(false);
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('general.register_outgoing_advance')}>
            <form onSubmit={handleSave}>
                <Modal.Body>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="pl-0 pt-3 pb-3 border-top-0">
                                    <strong>{t('search.car_id')}</strong>
                                </th>
                                <th className="p-3 border-top-0">
                                    <strong>{t('search.vin')}</strong>
                                </th>
                                <th className="p-3 border-top-0">
                                    <strong>{t('search.make')}</strong>
                                </th>
                                <th className="p-3 border-top-0">
                                    <strong>{t('car.purchase_pricing.purchase_price')}</strong>
                                </th>
                                {isSupplierBelgian && (
                                    <th>
                                        <strong>{t('car.purchase_pricing.purchase_price_incl_vat')}</strong>
                                    </th>
                                )}
                                <th className="text-end">
                                    <strong>{t('search.outstanding_debt')}</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {cars.map((car) => (
                                <tr key={car.car_id}>
                                    <td className="pl-0 pt-3 pb-3 align-middle">{car.car_id}</td>
                                    <td className="p-3 align-middle">{car.vin}</td>
                                    <td className="p-3 align-middle">{car.mmt}</td>
                                    <td className="p-3 text-end">
                                        {moneyFormat(car.purchase_pricing.costs.purchase_price.amount, currency)}
                                    </td>
                                    {isSupplierBelgian && (
                                        <td className={'p-3 text-end'}>
                                            {moneyFormat((car.purchase_pricing.costs.purchase_price.amount * 1.21).toFixed(2))}
                                        </td>
                                    )}
                                    <td className="text-end">
                                        {moneyFormat(parseFloat(car.purchase_pricing.supplier_outstanding_debt), currency)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3} />
                                <td className="p-3 text-end">
                                    {moneyFormat(
                                        cars.reduce((sum, car) => sum + car.purchase_pricing.costs.purchase_price.amount, 0),
                                        currency
                                    )}
                                </td>
                                {isSupplierBelgian ? (
                                    <td className={'text-end'}>
                                        {moneyFormat(
                                            cars.reduce(
                                                (sum, car) =>
                                                    sum +
                                                    parseFloat(
                                                        (car.purchase_pricing.costs.purchase_price.amount * 1.21).toFixed(2)
                                                    ),
                                                0
                                            ),
                                            currency
                                        )}
                                    </td>
                                ) : null}
                                <td className={'text-end'}>
                                    {moneyFormat(
                                        cars.reduce((sum, car) => sum + car.purchase_pricing.supplier_outstanding_debt, 0),
                                        currency
                                    )}
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <div className="row">
                        <div className="col-4" style={{minWidth: 200}}>
                            <div className="form-group form-group-vertical">
                                <label>{t('car.purchase_pricing.supplier_paid_on')}</label>
                                <DatePicker onChange={setDate} value={date} />
                            </div>
                        </div>
                        <div className="col-4" style={{minWidth: 200}}>
                            <div className="form-group form-group-vertical">
                                <label>{t('general.payment_per_car')}</label>
                                <Money currency={currency} onChange={setAmount} value={amount} />
                            </div>
                        </div>
                        <div className={'col-4'} style={{minWidth: 200}}>
                            <div className={'form-group form-group-vertical'}>
                                <label>{t('general.bank')}</label>
                                <Enum enumName={'bank'} onChange={setBank} value={bank} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="blue" disabled={saving || !date} submit>
                        {t('general.register_outgoing_payment')}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default RegisterOutgoingAdvance;
