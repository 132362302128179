import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/NewModal';
import {WIDGET_TYPE} from '../../lib/WidgetReducer';

const AddLinesModal = ({enableInvoice = true, enablePurchaseOrder = true, enableQuote = true, handleAdd}) => {
    const {t} = useTranslation();

    return (
        <Modal title={t('general.add_to_quote')}>
            <Modal.Body>
                <div className="add-cars-modal">
                    {enableQuote && (
                        <Button
                            color="blue"
                            onClick={() => {
                                handleAdd(WIDGET_TYPE.QUOTE);
                            }}
                            outline
                        >
                            {t('general.add_to_quote')}
                        </Button>
                    )}
                    {enablePurchaseOrder && (
                        <Button
                            color="blue"
                            onClick={() => {
                                handleAdd(WIDGET_TYPE.PURCHASE_ORDER);
                            }}
                            outline
                        >
                            {t('general.add_to_purchase_order')}
                        </Button>
                    )}
                    {enableInvoice && (
                        <Button
                            color="blue"
                            onClick={() => {
                                handleAdd(WIDGET_TYPE.INVOICE);
                            }}
                            outline
                        >
                            {t('general.add_to_invoice')}
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddLinesModal;
