import React from 'react';
import spinner from '../../../assets/img/spinner.gif';

const Spinner = ({message}) => {
    return (
        <div className={`spinner ${message ? `spinner-extra` : ``}`}>
            <img alt="spinner" src={spinner} />

            {message && <span>{message}</span>}
        </div>
    );
};

export default Spinner;
