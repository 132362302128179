import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../lib/zaza-client';
import {moneyFormat} from '../../lib/helpers';

import Checkbox from '../../components/Form/Checkbox';

const CollapsableListCar = ({customerId, line, selectedLines: [selectedLines, setSelectedLines]}) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [similarCars, setSimilarCars] = useState([]);

    useEffect(() => {
        if (!open) {
            setLoading(false);
            setSimilarCars([]);
            return;
        }

        if (line.type === 'CAR') {
            const carId = line.car.car_id;
            setLoading(true);
            authenticatedHttp()
                .get('/cars/' + carId + '/similar')
                .then((response) => {
                    const filteredCars = response.data.data.filter((car) => car.sale.status === 'FOR_SALE');
                    setSimilarCars(filteredCars);
                    setLoading(false);
                });
        }
    }, [open]);

    const description = (line) => {
        switch (line.type) {
            case 'CAR':
                return (
                    <span>
                        <strong>{line.car.type.model.make}</strong> {line.car.type.model.name} {line.car.type.name}
                    </span>
                );
            case 'EXTRA':
                return line.description;
            case 'TRANSPORT':
                return t('general.transport');
        }
    };

    return (
        <Fragment>
            <div className="lines">
                <Fragment>
                    {line.type !== 'CAR' ? (
                        <Checkbox
                            label={description(line)}
                            onChange={(checked) => {
                                if (checked) {
                                    setSelectedLines([...selectedLines, line]);
                                } else {
                                    setSelectedLines(selectedLines.filter((selectedLine) => selectedLine.id !== line.id));
                                }
                            }}
                            value={!!selectedLines.find((selectedLine) => selectedLine.id === line.id)}
                        />
                    ) : (
                        <Fragment>
                            <a
                                className="btn--open"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(!open);
                                }}
                            >
                                {open && <i className="far fa-caret-square-up" />}
                                {!open && <i className="far fa-caret-square-down" />}
                            </a>
                            {description(line)}
                        </Fragment>
                    )}
                    <span className="price">{moneyFormat(line.price)}</span>
                </Fragment>
            </div>
            {open && (
                <div
                    className="checkboxes"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {loading && <span>{t('general.loading')}</span>}
                    {!loading &&
                        similarCars.length > 0 &&
                        similarCars.map((car) => {
                            let label = `${car.vin} ${car.body_color} (`;

                            label += car.localisation.location
                                ? t('enum.location.' + car.localisation.location)
                                : t('enum.arrival_status.' + car.localisation.arrival_status);

                            label += ')';
                            return (
                                <Checkbox
                                    key={car.car_id}
                                    label={label}
                                    onChange={(checked) => {
                                        if (checked) {
                                            setSelectedLines([
                                                ...selectedLines,
                                                {
                                                    car_id: car.car_id,
                                                    car: car,
                                                    type: 'CAR',
                                                    description: null,
                                                    price: line.price,
                                                    base_pricing: line.base_pricing,
                                                    customer_id: customerId,
                                                },
                                            ]);
                                        } else {
                                            setSelectedLines(
                                                selectedLines.filter((selectedLine) => selectedLine.car_id !== car.car_id)
                                            );
                                        }
                                    }}
                                    value={!!selectedLines.find((selectedLine) => car.car_id === selectedLine.car_id)}
                                />
                            );
                        })}
                    {!loading && similarCars.length === 0 && <span>{t('general.no_similar_cars')}</span>}
                </div>
            )}
        </Fragment>
    );
};

export default CollapsableListCar;
