import React, {useEffect} from 'react';
import Enum from '../../../../components/Form/Enum';
import {useTranslation} from 'react-i18next';
import {dateFormat} from '../../../../lib/helpers';

import DocumentDeliveryEnum from '../../../../components/Form/DocumentDeliveryEnum';
import UploadCarDocument from '../../List/Modal/UploadCarDocument';
import {useModal} from '../../../../lib/UseModal';
import Close from '../../../../../assets/svg/close.svg';

const Registration = ({car, carHasE705, disabled, form, setShowNoRegistrationFields}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();

    let showPreregistration = form.data.documents.no_registration.e705.status === 'ACCEPTED';

    useEffect(() => {
        if (!form.data.documents.no_registration.e705.status) {
            form.setData({
                ...form.data,
                documents: {
                    ...form.data.documents,
                    no_registration: {
                        ...form.data.documents.no_registration,
                        e705: {
                            status: 'NECESSARY',
                        },
                    },
                },
            });
        }

        if (carHasE705 && form.data.documents.no_registration.e705.status !== 'ACCEPTED') {
            form.setData({
                ...form.data,
                documents: {
                    ...form.data.documents,
                    no_registration: {
                        ...form.data.documents.no_registration,
                        e705: {
                            status: 'ACCEPTED',
                        },
                        preregistration: {
                            status: 'TO_PREREGISTER',
                        },
                    },
                },
            });
        }
    }, []);

    const removeNoRegistration = () => {
        setShowNoRegistrationFields(false);
        form.setData({
            ...form.data,
            documents: {
                ...form.data.documents,
                no_registration: {
                    e705: {
                        status: null,
                    },
                    preregistration: {
                        status: null,
                    },
                },
            },
        });
    };

    return (
        <tbody className="form-group">
            <tr>
                <th colSpan={5}>
                    <h3>
                        {t(`car.documents.no_registration`)}{' '}
                        <button onClick={removeNoRegistration} type="button">
                            <Close />
                        </button>
                    </h3>
                </th>
            </tr>
            <tr>
                <th>{t('car.documents.e705')}</th>
                <td>
                    <Enum
                        disabled={disabled | carHasE705}
                        enumName="e705_status"
                        {...form.fields.documents.no_registration.e705.status({
                            onChange: (name, value, draft) => {
                                if (value === 'ACCEPTED') {
                                    if (!draft.documents.no_registration.preregistration) {
                                        draft.documents.no_registration.preregistration = {};
                                    }
                                    draft.documents.no_registration.preregistration.status = 'TO_PREREGISTER';
                                    showModal(<UploadCarDocument car={car} carDocument="E705" refresh={() => {}} />);
                                }
                            },
                        })}
                    />
                </td>
                <td className="small">
                    {form.data.documents?.no_registration?.e705?.status_changed_on
                        ? dateFormat(form.data.documents.no_registration.e705.status_changed_on)
                        : ''}
                </td>
                <td>
                    {form.data.documents.no_registration.e705.delivery && (
                        <DocumentDeliveryEnum
                            disabled={disabled}
                            documentType="e705"
                            {...form.fields.documents.no_registration.e705.delivery()}
                        />
                    )}
                </td>
                <td className="small">
                    {form.data.documents.no_registration.e705.delivery_changed_on
                        ? dateFormat(form.data.documents.no_registration.e705.delivery_changed_on)
                        : ''}
                </td>
            </tr>
            {showPreregistration && (
                <tr>
                    <th>{t('car.documents.preregistration')}</th>
                    <td>
                        <Enum
                            disabled={disabled}
                            enumName="preregistration_status"
                            {...form.fields.documents.no_registration.preregistration.status()}
                        />
                    </td>
                    <td colSpan={3} />
                </tr>
            )}
        </tbody>
    );
};

export default Registration;
