import './i18n';
import 'babel-polyfill';
import 'whatwg-fetch';
import React from 'react';
import {createRoot} from 'react-dom/client';

import Root from './Root';
const container = document.getElementById('react-mount');
const root = createRoot(container);
root.render(<Root />);
