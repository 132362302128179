import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import useFetchData from '../../../../lib/UseFetchData';

import Enum from '../../../../components/Form/Enum';
import Select from '../../../../components/Form/Select';
import Checkbox from '../../../../components/Form/Checkbox';
import languages from '../../../../enums/language.json';

const General = ({control, defaultFormValues, register, setValue, showMail = true, watch}) => {
    const {t} = useTranslation();
    const [users] = useFetchData('/users');

    return (
        <section>
            <div className="row mb-4">
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="subsidiary">{t('search.subsidiary')}</label>
                        <span className="form-control-plaintext">{t('enum.subsidiary.' + watch('subsidiary'))}</span>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="user_id">{t('car.seller')}</label>
                        <Controller
                            control={control}
                            defaultValue={defaultFormValues.userId}
                            name="user_id"
                            render={({field}) => (
                                <Select
                                    options={(users || []).map((u) => {
                                        return {value: u.id, label: u.full_name};
                                    })}
                                    placeholder={t('car.seller')}
                                    {...field}
                                />
                            )}
                            rules={{required: true}}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="language">{t('general.language')}</label>
                        <Controller
                            control={control}
                            defaultValue={defaultFormValues.language}
                            name="language"
                            render={({field}) => (
                                <Select
                                    options={languages.map((l) => {
                                        return {value: l, label: t('enum.language.' + l)};
                                    })}
                                    placeholder={t('general.language')}
                                    {...field}
                                />
                            )}
                            rules={{required: true}}
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-6 also-create-purchase-order" data-tip={t('general.also_create_purchase_order_tooltip')}>
                    <Controller
                        control={control}
                        defaultValue={defaultFormValues.createPurchaseOrder}
                        name="create_purchase_order"
                        render={({field}) => <Checkbox disabled label={t('general.also_create_purchase_order')} {...field} />}
                    />
                </div>
                {watch('create_purchase_order') && (
                    <div className="col-6">
                        <div className="form-group form-group-vertical">
                            <label htmlFor="sales_channel">{t('general.sales_channel')}</label>
                            <Controller
                                control={control}
                                defaultValue={defaultFormValues.purchaseOrderChannel}
                                id="sales_channel"
                                name="sales_channel"
                                placeholder={t('general.sales_channel')}
                                render={({field}) => <Enum enumName="sales_channel" {...field} />}
                                rules={{required: true}}
                            />
                        </div>
                    </div>
                )}
            </div>
            {showMail ? (
                <div className="form-group form-group-vertical">
                    <label htmlFor="mail_text">{t('general.mail_text')}</label>
                    <textarea
                        className="form-control"
                        defaultValue={defaultFormValues.mailText}
                        id="mail_text"
                        placeholder={t('general.mail_text')}
                        {...register('mail_text')}
                        rows="3"
                    />
                </div>
            ) : null}
        </section>
    );
};

export default General;
