import React, {Fragment, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';

import Autocomplete from '../../../../components/Form/Autocomplete';
import Checkbox from '../../../../components/Form/Checkbox';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Money from '../../../../components/Form/Money';
import Modal from '../../../../components/Modal/NewModal';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import classnames from 'classnames';

const BookTransportForm = ({
    cars,
    defaultInstruction = '',
    deliveryAddress,
    loadingAddress,
    refresh,
    showOverview = true,
    type,
}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const {control, handleSubmit, register, setValue, watch} = useForm();
    const [errors, setErrors] = useState(null);
    const [contacts, setContacts] = useState(null);
    const [saving, setSaving] = useState(false);
    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState(null);

    const onSubmit = (data) => {
        data.cars = cars.map((car) => car.car_id);
        data.type = type;

        // transform object with selected contact ids into array of emails
        data.recipients = Object.entries(data.recipients || {})
            .filter((entry) => entry[1])
            .map(([id]) => contacts.find((contact) => contact.id === id).email);

        setSaving(true);

        authenticatedHttp()
            .post('/transports', data)
            .then(() => {
                setSaving(false);
                closeModal();
                if (refresh) {
                    refresh();
                }
            })
            .catch((response) => {
                setSaving(false);
                setErrors(
                    Object.values(response.response.data.errors).reduce((errors, keyErrors) => {
                        keyErrors.forEach((error) => errors.push(error));

                        return errors;
                    }, [])
                );
            });
    };

    const transporter = watch('transporter');

    useEffect(() => {
        if (customerId) {
            authenticatedHttp()
                .get('/customers/' + customerId)
                .then((response) => {
                    const customer = response.data.data;
                    setCustomer(customer);
                    setValue('delivery_address_name', customer.name);
                    setValue('delivery_address_line', customer.address.address_line);
                    setValue('delivery_address_postal_code', customer.address.postal_code);
                    setValue('delivery_address_locality', customer.address.locality);
                    setValue('delivery_address_country', customer.address.country_code);
                    setValue('delivery_address_phone', customer.phone);
                    setValue('delivery_address_fax', customer.fax);

                    if (!customer) {
                        alert('No cutomer data could be found.');
                    }
                });
        }
    }, [customerId]);

    useEffect(() => {
        if (transporter) {
            authenticatedHttp()
                .get('/companies/' + transporter + '/contacts')
                .then((response) => {
                    setContacts(response.data.data.filter((contact) => contact.email));
                });
        }
    }, [transporter]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                {showOverview && (
                    <Fragment>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <strong>{t('enum.delivery_status.READY')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.vin')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.make')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.supplied_by')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.sold_to')}</strong>
                                    </th>
                                    <th>
                                        <strong>{t('search.direct_delivery')}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {cars.map((car) => (
                                    <tr key={car.car_id}>
                                        <td>
                                            <div className="grid-table-cell">
                                                <i
                                                    className={classnames({
                                                        dot: true,
                                                        'dot-red': car.delivery.status === 'NOT_READY',
                                                        'dot-yellow': car.delivery.status === 'READY',
                                                        'dot-green': car.delivery.status === 'DELIVERED',
                                                    })}
                                                    data-tip={t(`enum.delivery_status.${car.delivery.status}`)}
                                                />
                                            </div>
                                        </td>
                                        <td>{car.vin}</td>
                                        <td>{car.mmt}</td>
                                        <td>{car.supplied_by.data.label}</td>
                                        <td>{car.sold_to.data.label}</td>
                                        <td>{car.delivery.direct ? t(`search.yes`) : t(`search.no`)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <hr />
                    </Fragment>
                )}
                <div className="row">
                    <div className="col-4">
                        <div className="row form-group">
                            <div className="col-12">
                                <strong>{t('search.transport_from_supplier_by')}</strong>
                                <Controller
                                    control={control}
                                    name="transporter"
                                    render={({field}) => (
                                        <Autocomplete
                                            autocompleteURI="/transporters/autocomplete?"
                                            placeholder={t('search.transport_from_supplier_by')}
                                            {...field}
                                        />
                                    )}
                                />

                                {Array.isArray(contacts) && contacts.length === 0 && (
                                    <div className="alert alert-danger">{t('validation.contacts_empty')}</div>
                                )}
                                {Array.isArray(contacts) && contacts.length > 0 && (
                                    <div className="contacts">
                                        {contacts.map((contact) => (
                                            <Controller
                                                control={control}
                                                key={contact.id}
                                                name={`recipients[${contact.id}]`}
                                                render={({field}) => (
                                                    <Checkbox
                                                        {...field}
                                                        label={`
                                                            ${contact.full_name}
                                                            ${contact.email ? `(${contact.email})` : ''}
                                                        `}
                                                    />
                                                )}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <strong>{t('general.loading_address')}</strong>
                                <br />
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.name}
                                    placeholder={t('general.address.name')}
                                    {...register('loading_address_name')}
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.address_line}
                                    placeholder={t('general.address.line')}
                                    {...register('loading_address_line')}
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.postal_code}
                                    placeholder={t('general.address.postal_code')}
                                    {...register('loading_address_postal_code')}
                                />
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.locality}
                                    placeholder={t('general.address.locality')}
                                    {...register('loading_address_locality')}
                                />
                            </div>
                            <div className="col-12">
                                <Controller
                                    control={control}
                                    defaultValue={loadingAddress.country_code}
                                    id="loading_address_country"
                                    name="loading_address_country"
                                    placeholder={t('general.address.country')}
                                    render={({field}) => <Enum enumName="country" {...field} />}
                                />
                            </div>
                            <div className="col-3 col-form-label">
                                <label htmlFor="loading_address_phone" style={{padding: 0}}>
                                    {t('general.phone')}
                                </label>
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.phone}
                                    id="loading_address_phone"
                                    placeholder={t('general.phone')}
                                    {...register('loading_address_phone')}
                                />
                            </div>
                            <div className="col-3 col-form-label">
                                <label htmlFor="loading_address_fax" style={{padding: 0}}>
                                    {t('general.fax')}
                                </label>
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={loadingAddress.fax}
                                    id="loading_address_fax"
                                    placeholder={t('general.fax')}
                                    {...register('loading_address_fax')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="row form-group">
                            <div className="col-12">
                                <strong>{t('search.sold_to')}</strong>
                                <Autocomplete
                                    autocompleteURI="/customers/autocomplete?"
                                    onChange={(value) => {
                                        setCustomerId(value);
                                    }}
                                    placeholder={t('search.sold_to')}
                                    value={customer ? {value: customer.id, label: customer.label} : ''}
                                />
                            </div>
                            {customer && (
                                <div className="col-12">
                                    <div className="mt-2">
                                        <p>
                                            <a href={`/bedrijven/${customer.id}`} rel="noopener noreferrer" target="_blank">
                                                {t('general.view_in_zazinda')}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <strong>{t('general.delivery_address')}</strong>
                                <br />
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.name : ''}
                                    placeholder={t('general.address.name')}
                                    {...register('delivery_address_name')}
                                />
                            </div>
                            <div className="col-12">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.address_line : ''}
                                    placeholder={t('general.address.line')}
                                    {...register('delivery_address_line')}
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.postal_code : ''}
                                    placeholder={t('general.address.postal_code')}
                                    {...register('delivery_address_postal_code')}
                                />
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.locality : ''}
                                    placeholder={t('general.address.locality')}
                                    {...register('delivery_address_locality')}
                                />
                            </div>
                            <div className="col-12">
                                <Controller
                                    control={control}
                                    defaultValue={deliveryAddress ? deliveryAddress.country_code : ''}
                                    id="delivery_address_country"
                                    name="delivery_address_country"
                                    placeholder={t('general.address.country')}
                                    render={({field}) => <Enum enumName="country" {...field} />}
                                />
                            </div>
                            <div className="col-3 col-form-label">
                                <label htmlFor="delivery_address_phone" style={{padding: 0}}>
                                    {t('general.phone')}
                                </label>
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.phone : ''}
                                    id="delivery_address_phone"
                                    placeholder={t('general.phone')}
                                    {...register('delivery_address_phone')}
                                />
                            </div>
                            <div className="col-3 col-form-label">
                                <label htmlFor="delivery_address_fax" style={{padding: 0}}>
                                    {t('general.fax')}
                                </label>
                            </div>
                            <div className="col-9">
                                <input
                                    autoComplete="off"
                                    className="form-control"
                                    defaultValue={deliveryAddress ? deliveryAddress.fax : ''}
                                    id="delivery_address_fax"
                                    placeholder={t('general.fax')}
                                    {...register('delivery_address_fax')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 form-group">
                        <strong>{t('general.details')}</strong>
                        <div className="alert alert-primary">
                            <Controller
                                control={control}
                                defaultValue={0}
                                id="total_price"
                                name="total_price"
                                render={({field}) => <Money {...field} placeholder={t('search.total_price')} />}
                            />
                        </div>
                        <Controller
                            control={control}
                            id="cars_ready_from"
                            name="cars_ready_from"
                            render={({field}) => <DatePicker {...field} placeholder={t('search.cars_ready_from')} />}
                        />
                        <Controller
                            control={control}
                            id="cars_delivered_before"
                            name="cars_delivered_before"
                            render={({field}) => <DatePicker {...field} placeholder={t('search.cars_delivered_before')} />}
                        />
                    </div>
                    <div className="col-8">
                        <label htmlFor="instruction">{t('general.instruction')}</label>
                        <textarea
                            className="form-control"
                            defaultValue={defaultInstruction}
                            id="instruction"
                            {...register('instruction')}
                        />
                    </div>
                </div>
                {errors && (
                    <div className="alert alert-danger">
                        <ul>
                            {errors.map((error, i) => (
                                <li key={i}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" disabled={saving} submit>
                    {t('general.book_transport.label')}
                </Button>
                {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
            </Modal.Footer>
        </form>
    );
};

export default BookTransportForm;
