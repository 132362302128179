import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import classnames from 'classnames';
import {determineBaseCarPricing, moneyFormat, setPageTitle} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';
import useFetchData from '../../../../lib/UseFetchData';
import {useWidget} from '../../../../lib/UseWidget';
import {resetLines, WIDGET_TYPE} from '../../../../lib/WidgetReducer';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Button from '../../../../components/Button/Button';
import Autocomplete from '../../../../components/Form/Autocomplete';
import Checkbox from '../../../../components/Form/Checkbox';
import Layout from '../../../../components/Layout/Layout';
import SaveButtons from '../../Components/SaveButtons';
import PricingSelect from '../../PricingSelect';
import FormRow from '../../FormRow';
import Spinner from '../../../../components/Spinner/Spinner';
import General from './General';
import Money from '../../../../components/Form/Money';
import Modal from '../../../../components/Modal/Modal';

import '../../Documents.scss';
import {useModal} from '../../../../lib/UseModal';
import CarErrorsDocument from '../../../Cars/List/Modal/CarErrorsDocument';

const getPrice = (priceOption, salesPricing) => {
    const matches = priceOption.match(/WEBSITE_PRICING_([A-Z]{2,4})/);
    if (matches) {
        return salesPricing.website_pricing[matches[1]].price;
    }

    return salesPricing[priceOption.toLowerCase()] || 0;
};

const PurchaseOrderForm = ({
    cars,
    customer: [customer, setCustomer],
    defaultCarPricing,
    defaultFormValues,
    editMode,
    initialCustomerId,
    lines,
    submitUrl,
}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    let navigate = useNavigate();
    const [, dispatch] = useWidget();
    const {showModal} = useModal();
    const [users] = useFetchData('/users');
    const firstRender = useRef(true);

    useEffect(() => {
        setPageTitle(editMode ? t('general.purchase_order_edit') : t('general.purchase_order_create'));
    }, []);

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [showModals, setShowModal] = useState(null);
    const [priceBump, setPriceBump] = useState(0);
    const [loadingContacts, toggleLoadingContacts] = useState(false);
    const [carPricing, setCarPricing] = useState(determineBaseCarPricing(defaultCarPricing));
    const [singleSubsidiary, setSingleSubsidiary] = useState(true);
    const getPricingSetter = (carId) => {
        return (value) => {
            // Avoid changing state unnecessarily
            if (carPricing[carId] === value) {
                return;
            }

            setCarPricing(determineBaseCarPricing({...carPricing, [carId]: value}));
        };
    };

    const {control, formState, handleSubmit, register, setValue, watch} = useForm({
        defaultValues: {lines},
    });

    const {append, fields, remove} = useFieldArray({
        control,
        name: 'lines',
    });

    const removeRow = (index) => {
        let canDelete = true;
        if (defaultFormValues.payments) {
            defaultFormValues.payments.forEach((payment) => {
                if (payment.undone_on === null) {
                    payment.details.forEach((detail) => {
                        if (parseInt(fields[index].car_id) === parseInt(detail.car_id)) {
                            canDelete = false;
                        }
                    });
                }
            });
            if (canDelete) {
                remove(index);
                return;
            }
            alert(t('general.purchase_order_has_payment_linked'));
        } else {
            remove(index);
        }
    };

    useEffect(() => {
        if (customer) {
            setValue('language', customer.language);
        }
    }, [customer]);

    if (Object.keys(formState.errors).length > 0) {
        alert(t('general.please_fill_whole_form'));
    }

    const onSubmit = (data) => {
        setLoadingMessage(t('general.save') + '…');

        if (!data.contacts || data.contacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }

        let selectedCustomer = Object.keys(data.contacts)[0];
        let customerContacts = data.contacts[selectedCustomer];
        let selectedContacts = Object.keys(customerContacts).filter((c) => customerContacts[c] === true);

        if (selectedContacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }
        const postData = {
            subsidiary: data.subsidiary,
            date: data.purchase_order_date,
            channel: data.sales_channel,
            mail_text: data.mail_text,
            customer_id: selectedCustomer,
            contacts: selectedContacts,
            no_advance_required: data.no_advance_required,
            lines: data.lines
                ? data.lines.map((line) => {
                      let data = {
                          type: line.type,
                          price: line.price,
                      };

                      if (line.type === 'CAR') {
                          const originalLine = lines.find((originalLine) => originalLine.car_id === parseInt(line.car_id));

                          data.required_advance = originalLine.required_advance;
                          data.car_id = line.car_id;
                          data.base_pricing = carPricing[line.car_id] || carPricing.GLOBAL;
                      }

                      if (line.type === 'EXTRA') {
                          data.description = line.description;
                      }

                      return data;
                  })
                : [],
            language: data.language,
            user_id: data.user_id,
        };

        authenticatedHttp()
            .post(submitUrl, postData)
            .then((response) => {
                dispatch(resetLines(WIDGET_TYPE.PURCHASE_ORDER));
                if (parseInt(data.send_after_save)) {
                    setLoadingMessage(t('general.send') + '…');
                    const contacts = customer.contacts.filter((contact) => {
                        return selectedContacts.includes(contact.id);
                    });
                    return authenticatedHttp()
                        .post('/purchase-orders/' + response.data.id + '/send', {
                            mail_text: data.mail_text,
                            contacts: contacts.map((contact) => {
                                return {
                                    from: users.find((u) => u.id === data.user_id).email,
                                    to: contact.email,
                                    cc: [],
                                    bcc: [],
                                    subject: '',
                                };
                            }),
                        })
                        .then(() => {
                            navigate('/bestelbonnen/' + response.data.id);
                        })
                        .catch((error) => {
                            setLoadingMessage(null);
                            alert(error.response.data.message);
                        });
                } else {
                    navigate('/bestelbonnen/' + response.data.id);
                }
            })
            .catch((error) => {
                alert(error.response.data.message);
                setLoadingMessage(null);
            });
    };

    const save = () => {
        setValue('send_after_save', 0);
        handleSubmit(onSubmit)();
    };

    const saveAndSend = () => {
        if (!watch('mail_text')) {
            if (!confirm(t('general.confirm_send_without_text'))) {
                return;
            }
        }

        setValue('send_after_save', 1);
        handleSubmit(onSubmit)();
    };

    useEffect(() => {
        if (firstRender.current && editMode) {
            firstRender.current = false;
            return;
        }

        fields.forEach((field, index) => {
            const fieldName = `lines[${index}].price`;

            if (field.type !== 'CAR') {
                return;
            }

            setPriceBump(0);

            if (carPricing[field.car_id]) {
                if (carPricing[field.car_id] === 'CUSTOM') {
                    return;
                }

                setValue(fieldName, getPrice(carPricing[field.car_id], cars[field.car_id].sales_pricing));
                return;
            }

            setValue(fieldName, getPrice(carPricing.GLOBAL, cars[field.car_id].sales_pricing));
        });
    }, [carPricing]);

    useEffect(() => {
        if (initialCustomerId) {
            addCustomer(initialCustomerId);
        }
    }, [initialCustomerId]);

    const addCustomer = (customerId) => {
        if (!customerId) {
            setCustomer(null);
            toggleLoadingContacts(false);
            return;
        }

        toggleLoadingContacts(true);
        authenticatedHttp()
            .get(
                '/customers/' +
                    customerId +
                    '?include=contacts&carIds=' +
                    lines
                        .filter((line) => line.type === 'CAR')
                        .map((line) => line.car_id)
                        .join(',')
            )
            .then((response) => {
                const customer = response.data.data;
                let meta = response.data.meta;

                customer.contacts = customer.contacts.data;
                toggleLoadingContacts(false);

                // Don't add customer if he doesn't haven an address or country (required for VAT)
                if (!customer.address || !customer.address.country_code || customer.address.country_code === 'ZZ') {
                    alert(t('validation.customer_without_country'));
                    return;
                }

                // Don't add customer if the car can't be sold in the country
                if (
                    meta['cantBeSoldCarsInCountry'].length > 0 ||
                    meta['cantBeSoldOutsideEU'].length > 0 ||
                    meta['sameSupplierAsCustomer'].length > 0
                ) {
                    showModal(
                        <CarErrorsDocument
                            cantBeSoldCarsInCountry={meta['cantBeSoldCarsInCountry']}
                            cantBeSoldOutsideEU={meta['cantBeSoldOutsideEU']}
                            cars={cars}
                            customer={customer}
                            lines={lines}
                            remove={remove}
                            sameSupplierAsCustomer={meta['sameSupplierAsCustomer']}
                            setCustomer={setCustomer}
                            watch={watch}
                        />
                    );
                    return;
                }

                // Check if VAT is not empty
                if (meta['VATResponse'] === 'MISSING') {
                    alert(t('general.no_vat_for_customer', {business: customer.label}));
                    return;
                }

                // Check VAT number
                if (['INVALID', 'UNKNOWN'].includes(meta['VATResponse'])) {
                    alert(t('validation.vat_invalid', {business: customer.label}));
                }

                setCustomer(customer);
            });
    };

    const bumpPrices = () => {
        fields
            .filter((field) => field.type === 'CAR' && cars[field.car_id].sale.status !== 'INVOICED')
            .forEach((field, index) => {
                const fieldName = `lines[${index}].price`;
                const newPrice = parseFloat(field.price) + parseFloat(priceBump);
                setValue(fieldName, newPrice);
            });
    };

    useEffect(() => {
        const carLines = fields.filter((line) => line.type === 'CAR');

        let subsidiary = 'ECRENT';
        if (carLines.length > 0) {
            subsidiary = cars[carLines[0].car_id].subsidiary;
        }
        setValue('subsidiary', subsidiary);

        const singleSubsidiary = carLines.every((line) => cars[line.car_id].subsidiary === subsidiary);
        setSingleSubsidiary(singleSubsidiary);
        if (!singleSubsidiary) {
            alert(t('validation.different_subsidiaries'));
        }
    }, [fields]);

    const modals = {
        price_bump: {
            header: (
                <h2>
                    <strong>{t('general.bump_prices')}</strong>
                </h2>
            ),
            body: (
                <div className="form-group form-group-vertical">
                    <label htmlFor="bump">{t('general.sum')}</label>
                    <Money onChange={setPriceBump} value={priceBump} />
                </div>
            ),
            footer: (
                <Button
                    color="blue"
                    onClick={() => {
                        setShowModal(null);
                        bumpPrices();
                    }}
                >
                    {t('general.bump')}
                </Button>
            ),
        },
    };

    const setGlobalPricing = (value) => {
        Object.keys(carPricing).forEach((carId) => {
            setCarPricing((prevState) => ({...prevState, [carId]: value}));
        });
    };

    const modal = modals[showModals] || {};

    const noAdvanceRequired = watch('no_advance_required');

    return (
        <Layout
            className="documents documents-create purchase-order-create"
            header={() => <h1>{editMode ? t('general.purchase_order_edit') : t('general.purchase_order_create')}</h1>}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <input className="d-none" defaultValue={defaultFormValues.subsidiary} {...register('subsidiary')} />
                <input className="d-none" {...register('send_after_save')} type="number" />
                <div className="row">
                    <div className="col">
                        <General control={control} defaultFormValues={defaultFormValues} register={register} watch={watch} />
                    </div>
                    <div className="col">
                        <section>
                            {!customer && (
                                <div className="mb-4 search-customers">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.search_customer')}</label>
                                        {loadingContacts ? (
                                            <p>{t('general.loading')}</p>
                                        ) : (
                                            <Autocomplete
                                                allowClear
                                                autocompleteURI="/customers/autocomplete?"
                                                onChange={addCustomer}
                                                placeholder={t('general.search_customer')}
                                                value={customer ? {value: customer.id, label: customer.label} : ''}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="recipients">
                                {customer && (
                                    <Fragment>
                                        <strong>{t('general.send_purchase_order_to')}</strong>
                                        <div className="customer" key={customer.id}>
                                            <a
                                                onClick={() => {
                                                    setCustomer(null);
                                                    setValue('contacts', {});
                                                }}
                                            >
                                                {customer.label}
                                                <i className="far fa-times-circle" />
                                            </a>
                                            {customer.contacts.length > 0 && (
                                                <div className="contacts">
                                                    {customer.contacts.map((contact) => (
                                                        <Controller
                                                            control={control}
                                                            defaultValue={
                                                                (contact.email && contact.type === 'PURCHASING') ||
                                                                defaultFormValues.selectedContacts.includes(contact.id)
                                                            }
                                                            disabled={!contact.email}
                                                            key={contact.id}
                                                            name={`contacts[${customer.id}][${contact.id}]`}
                                                            render={({field}) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    label={`
                                                                ${contact.full_name}
                                                                ${contact.email ? `(${contact.email})` : ''}
                                                            `}
                                                                />
                                                            )}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </section>
                    </div>
                </div>
                <section className="no-padding items">
                    <div className="grid-table">
                        <div className="grid-table-row">
                            <div className="grid-table-cell">{t('general.items')}</div>
                            <div className="grid-table-cell">{t('general.advance')}</div>
                            <div className="grid-table-cell numerical">
                                {watch('lines').some((line) => line.type === 'CAR') && (
                                    <Fragment>
                                        {t('general.base_prices_on')}
                                        <PricingSelect
                                            customOption={
                                                ['VARIOUS', 'CUSTOM'].includes(carPricing.GLOBAL) ? carPricing.GLOBAL : undefined
                                            }
                                            onChange={setGlobalPricing}
                                            value={carPricing.GLOBAL}
                                        />
                                    </Fragment>
                                )}
                            </div>
                            <div className="grid-table-cell numerical column-cell">
                                <span>{t('car.pricing.price')}</span>
                                <a
                                    className="ms-3 small"
                                    onClick={() => {
                                        setShowModal('price_bump');
                                    }}
                                >
                                    {t('general.bump_prices')}
                                </a>
                            </div>
                            <div className="grid-table-cell" />
                        </div>
                        {fields.map((field, index) => {
                            const car = cars[field.car_id];

                            let disableRow = false;

                            if (field.type === 'CAR') {
                                if (car.sale.status === 'INVOICED') {
                                    disableRow = true;
                                }

                                if (editMode && editMode !== car.purchase_order.id) {
                                    disableRow = true;
                                }
                            }

                            return (
                                <FormRow
                                    allowDelete={
                                        user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS
                                            ? field.type !== 'CAR' || car.sales_pricing.already_paid === 0
                                            : false
                                    }
                                    car={car}
                                    control={control}
                                    disabled={disableRow}
                                    field={field}
                                    index={index}
                                    key={field.id}
                                    pricing={carPricing[field.car_id] || carPricing.GLOBAL}
                                    register={register}
                                    remove={removeRow}
                                    requiredAdvance={
                                        noAdvanceRequired ? undefined : lines[index] ? lines[index].required_advance : 'FIXED'
                                    }
                                    setPricing={getPricingSetter(field.car_id)}
                                    showVIN
                                    watch={watch}
                                />
                            );
                        })}
                    </div>
                    <p className="car-count">
                        {t('car.number_of_cars')}: {watch('lines').filter((line) => line.type === 'CAR').length}
                    </p>
                    <div className="lines-footer">
                        <div>
                            <a
                                className="btn--add"
                                onClick={() => {
                                    append({
                                        type: 'EXTRA',
                                        description: '',
                                        price: 0,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" /> {t('general.add_extra')}
                            </a>
                            <a
                                className={classnames({
                                    'btn--add': true,
                                    'btn--add-disabled': watch('lines').some((line) => line.type === 'TRANSPORT'),
                                })}
                                onClick={() => {
                                    append({
                                        type: 'TRANSPORT',
                                        description: '',
                                        price: 0,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" /> {t('general.add_transport')}
                            </a>
                        </div>
                        <div>
                            <h3>
                                <span>{t('general.total')}</span>
                                {moneyFormat(
                                    watch().lines.reduce((currentValue, line) => currentValue + parseFloat(line.price), 0)
                                )}
                            </h3>
                            <div>
                                <SaveButtons
                                    disabledSave={!singleSubsidiary || !customer}
                                    save={save}
                                    saveAndSend={saveAndSend}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </form>
            <Modal
                isOpen={showModals !== null}
                onRequestClose={() => {
                    setShowModal(null);
                }}
                renderBody={() => modal.body}
                renderFooter={() => modal.footer}
                renderHeader={() => modal.header}
            />
        </Layout>
    );
};

export default PurchaseOrderForm;
