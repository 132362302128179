import React, {useEffect, useState, Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import Modal from '../../../components/Modal/NewModal';

import './EditImagesModal.css';
import {useModal} from '../../../lib/UseModal';
import {authenticatedHttp} from '../../../lib/zaza-client';
import Spinner from '../../../components/Spinner/Spinner';

const EditImagesModal = ({carId, images: propsImages, refreshCar}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();

    const [addToSimilarCars, setAddToSimilarCars] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dragOrder, setDragOrder] = useState();
    const [images, setImages] = useState(propsImages.map((image, index) => ({...image, order: index})));

    useEffect(() => {
        if (!images.length) {
            closeModal();
            refreshCar();
        }
    }, [images]);

    async function handleDrag(e) {
        e.dataTransfer.dropEffect = 'move';
        setDragOrder(e.currentTarget.dataset.order);
    }

    function handleDrop(e) {
        const dropOrder = e.currentTarget.dataset.order;

        setImages(
            images.map((image) => {
                if (image.order == dropOrder) {
                    return {...image, order: dragOrder};
                }
                if (image.order == dragOrder) {
                    return {...image, order: dropOrder};
                }
                return image;
            })
        );
    }

    function setImageDescription(description, imageId) {
        setImages(
            images.map((image) => {
                if (image.id == imageId) {
                    return {...image, description};
                }
                return image;
            })
        );
    }

    function handleSave() {
        setLoading(true);
        authenticatedHttp()
            .post('/cars/' + carId + '/update-images', {images: images, add_to_similar: addToSimilarCars})
            .then((response) => {
                setLoading(false);
                closeModal();
                refreshCar();
                if (response.data.error || response.data.errors) {
                    //TODO: Proper error handling. API error messages? Error toast.
                    alert('Something went wrong updating the images');
                }
            })
            .catch(() => {
                alert('Something went wrong updating the images');
            });
    }

    function setImageLoadingState(imageId, loading) {
        setImages(
            images.map((image) => {
                if (image.id == imageId) {
                    image.loading = loading;
                }
                return image;
            })
        );
    }

    function handleDeleteClick(imageId) {
        if (!window.confirm(t('general.confirm_delete_image'))) {
            return false;
        }

        setImageLoadingState(imageId, true);

        authenticatedHttp()
            .delete('/cars/' + carId + '/images/' + imageId)
            .then((response) => {
                const filtered = images.filter((image) => image.id !== imageId);
                filtered.sort((a, b) => a.order - b.order);
                setImages(filtered.map((image, index) => ({...image, order: index})));
                if (response.data.error || response.data.errors) {
                    //TODO: Proper error handling
                    alert(`Something went wrong delete image with id: ${imageId}`);
                }
            })
            .catch(() => {
                setImageLoadingState(imageId, false);
                alert(`Something went wrong delete image with id: ${imageId}`);
            });
    }

    function handleCancel() {
        closeModal();
    }

    return (
        <Modal title={t('general.edit_images.header')}>
            <Modal.Body>
                <div className="edit_images">
                    <div className="grid">
                        {images
                            .sort((a, b) => a.order - b.order)
                            .map((image) => (
                                <Fragment key={image.id}>
                                    {image.loading && <LoadingImage />}
                                    {!image.loading && (
                                        <EditImage
                                            description={image.description}
                                            handleDeleteClick={() => handleDeleteClick(image.id)}
                                            handleDescriptionChange={(description) => setImageDescription(description, image.id)}
                                            handleDrag={handleDrag}
                                            handleDrop={handleDrop}
                                            id={image.id}
                                            order={image.order}
                                            placeholder={image.description}
                                            url={image.medium_url ? image.medium_url : image.url}
                                        />
                                    )}
                                </Fragment>
                            ))}
                    </div>
                    <Checkbox label={t('general.add_to_similar_cars')} onChange={setAddToSimilarCars} value={addToSimilarCars} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" disabled={loading} onClick={handleSave}>
                    <span>{t('general.save')}</span>
                </Button>
                <Button color="blue" onClick={handleCancel} outline>
                    <span>{t('general.cancel')}</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const LoadingImage = () => (
    <div className="edit-image">
        <Spinner />
    </div>
);

const EditImage = ({
    description,
    handleDeleteClick,
    handleDescriptionChange,
    handleDrag,
    handleDrop,
    id,
    order,
    placeholder,
    url,
}) => {
    return (
        <div
            className="edit-image"
            data-order={order}
            draggable
            onDragOver={(e) => e.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
        >
            <img alt={description} src={url} />
            <Input id={id} onChange={handleDescriptionChange} placeholder={placeholder} value={description} />
            <button className="gallery-btn gallery-delete" data-image-id={id} onClick={handleDeleteClick} />
        </div>
    );
};

export default EditImagesModal;
