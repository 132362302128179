import React from 'react';
import ListData from './ListData';

const GroupedList = ({data, listConfiguration}) => (
    <div className="row">
        {listConfiguration.lists.map((sublist) => (
            <div className={`${listConfiguration.key === 'summary' ? 'col-6' : ''} group-list`} key={sublist.key}>
                <ListData data={data[sublist.key]} listConfiguration={sublist} subtitle={sublist.title} />
            </div>
        ))}
    </div>
);

export default GroupedList;
