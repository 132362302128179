/* eslint-disable react/jsx-key,react/jsx-no-target-blank */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTable} from 'react-table';
import {dateFormat} from '../../../../lib/helpers';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import PurchaseOrderDropzone from './PurchaseOrderDropzone';

const PurchaseOrderFiles = ({purchaseOrder, refresh, setLoadingMessage}) => {
    const {t} = useTranslation();

    const filesData = purchaseOrder.purchase_order_files.map((file, index) => ({
        number: index + 1,
        date: file.fileAddedOn,
        file: file.documentFilePath,
        id: file.id,
    }));

    const removeFile = (file) => {
        const confirm = window.confirm(t('general.delete_purchase_order_file_confirm'));
        if (confirm) {
            setLoadingMessage(t('general.confirm_delete_file').replace('?', '') + '…');
            authenticatedHttp()
                .delete(`/purchase-orders/${purchaseOrder.id}/files/${file}`)
                .then(() => {
                    setLoadingMessage(null);
                    refresh();
                })
                .catch((response) => {
                    setLoadingMessage(null);
                    alert(response);
                });
        }
    };

    const columns = useMemo(() => {
        return [
            {
                Header: t('general.number'),
                accessor: 'number',
                Cell: ({value}) => <p>{value}</p>,
            },
            {
                Header: t('general.date'),
                accessor: 'date',
                Cell: ({value}) => dateFormat(value),
            },
            {
                Header: t('general.download_purchase_order'),
                accessor: 'file',
                Cell: ({value}) => (
                    <a download href={`${value}`} rel="noopener" target="_blank">
                        {t('nav.download')}
                    </a>
                ),
            },
            {
                Header: ' ',
                accessor: 'id',
                Cell: (value) => (
                    <button onClick={() => removeFile(value.value)}>
                        <i className="fas fa-times-circle m-auto" />
                    </button>
                ),
            },
        ];
    }, [purchaseOrder]);

    const {footerGroups, getTableBodyProps, getTableProps, headerGroups, prepareRow, rows} = useTable({data: filesData, columns});

    return (
        <section className="position-relative">
            <div className="d-flex d-inline-flex justify-content-between align-items-center">
                <h3>{t('general.files')}</h3>
            </div>
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps({className: column.id === 'amount' ? 'text-end' : undefined})}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps({
                                            className: cell.column.id === 'amount' ? 'text-end' : undefined,
                                        })}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map((group) => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map((column) => (
                                <td {...column.getFooterProps({className: column.id === 'amount' ? 'text-end' : undefined})}>
                                    {column.render('Footer')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
            <PurchaseOrderDropzone purchaseOrderId={purchaseOrder.id} refresh={refresh} setLoadingMessage={setLoadingMessage} />
        </section>
    );
};

export default PurchaseOrderFiles;
