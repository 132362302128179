// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.table thead, table.table tfoot {
    font-weight: bold;
  }

dl {
  display: grid;
  grid-template: auto / auto 1fr;
}

dl dt {
    grid-column: 1;
    margin-right: 1em;
  }

dl dl {
    grid-column: 2;
  }

.scrollableMailHistory {
  overflow-y:auto;
  height: 150px;
}

.scrollableMailHistory tr {
    border-bottom: 2px solid #dee2e6;
  }

.list-sticky-head {
  position: sticky;
  top: 10rem;
  background-color: white;
  border-bottom: 2px solid #dee2e6;
}

.list-table {
  margin-top: 2rem;
}

.bnp-summary {
  margin-top: 3rem;
}

.group-list:not(:first-of-type) {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/js/views/Lists/Lists.css"],"names":[],"mappings":"AACE;IACE,iBAAiB;EACnB;;AAGF;EACE,aAAa;EACb,8BAA8B;AAShC;;AAPE;IACE,cAAc;IACd,iBAAiB;EACnB;;AACA;IACE,cAAc;EAChB;;AAGF;EACE,eAAe;EACf,aAAa;AAKf;;AAHE;IACE,gCAAgC;EAClC;;AAGF;EACE,gBAAgB;EAChB,UAAU;EACV,uBAAuB;EACvB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["table.table {\n  thead, tfoot {\n    font-weight: bold;\n  }\n}\n\ndl {\n  display: grid;\n  grid-template: auto / auto 1fr;\n\n  dt {\n    grid-column: 1;\n    margin-right: 1em;\n  }\n  dl {\n    grid-column: 2;\n  }\n}\n\n.scrollableMailHistory {\n  overflow-y:auto;\n  height: 150px;\n\n  tr {\n    border-bottom: 2px solid #dee2e6;\n  }\n}\n\n.list-sticky-head {\n  position: sticky;\n  top: 10rem;\n  background-color: white;\n  border-bottom: 2px solid #dee2e6;\n}\n\n.list-table {\n  margin-top: 2rem;\n}\n\n.bnp-summary {\n  margin-top: 3rem;\n}\n\n.group-list:not(:first-of-type) {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
