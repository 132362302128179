import React from 'react';
import {useTranslation} from 'react-i18next';
import {copyToClipBoard} from '../../../../../lib/helpers';

const SummaryTable = ({data}) => {
    const {t} = useTranslation();

    return (
        <table className="table">
            <tbody>
                {Object.entries(data).map(([key, value]) => (
                    <tr key={key} onClick={() => copyToClipBoard(value)}>
                        <td>
                            <strong>{t(key)}{key === 'car.purchase_pricing.purchase_price'}</strong>
                        </td>
                        <td>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default SummaryTable;
