import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {setPageTitle} from '../../lib/helpers';
import {authenticatedHttp, downloadFile} from '../../lib/zaza-client';
import {useQueryParams, BooleanParam, NumberParam, StringParam, withDefault} from 'use-query-params';

import Button from '../../components/Button/Button';
import IconStats from '../../../assets/svg/icon-stats.svg';
import IconSave from '../../../assets/svg/icon-save.svg';
import Layout from '../../components/Layout/Layout';
import Spinner from '../../components/Spinner/Spinner';
import BanklistBNPSummary from './BanklistBNPSummary';
import GroupedList from './GroupedList';
import ListData from './ListData';
import ListSelection from './ListSelection';
import MarginInvoicesSummary from './MarginInvoicesSummary';
import SearchCriteria from './SearchCriteria';

import './Lists.css';

const ShowList = ({lists, selectedList}) => {
    const {t} = useTranslation();

    const [data, setData] = useState({state: 'idle'});
    const [loadingMessage, setLoadingMessage] = useState(null);

    const list = lists.find((list) => list.key === selectedList);

    useEffect(() => {
        if (data.state !== 'idle') {
            setData({state: 'idle'});
        }
        setPageTitle('Lijsten: ' + list.title);
    }, [selectedList]);

    const queryParamMap = {};
    if (list.search_criteria) {
        Object.entries(list.search_criteria).forEach(([key, config]) => {
            let paramType = StringParam;
            switch (config.type) {
                case 'boolean':
                    paramType = BooleanParam;
                    break;
                case 'entity':
                    paramType = NumberParam;
                    break;
            }

            queryParamMap[key] = config.default_value ? withDefault(paramType, config.default_value) : paramType;
        });
    }

    const [searchCriteria, setSearchCriteria] = useQueryParams(queryParamMap);

    const searchCriteriaIncomplete = Object.entries(searchCriteria)
        .filter(([key]) => list.search_criteria[key].type !== 'boolean' && !list.search_criteria[key].optional)
        .some(([, value]) => !value);

    const fetchList = (e) => {
        e.preventDefault();

        if (searchCriteriaIncomplete) {
            return;
        }

        setData({state: 'loading'});
        authenticatedHttp()
            .post('/lists/' + selectedList, searchCriteria)
            .then((response) => {
                setData({state: 'success', data: response.data.data});
            })
            .catch(({response}) => {
                setData({
                    state: 'error',
                    message: response.status === 400 ? response.data.message : 'Fout bij het laden van de lijst',
                });
            });
    };

    const downloadList = () => {
        if (searchCriteriaIncomplete) {
            return;
        }
        setLoadingMessage(t('general.generating_document'));
        downloadFile('/lists/' + selectedList + '/download', searchCriteria).then(() => {
            setLoadingMessage(null);
        });
    };

    return (
        <Layout header={() => <h1>Lijsten: {list.title}</h1>}>
            <ListSelection handleSubmit={fetchList} lists={lists}>
                {selectedList && (
                    <SearchCriteria criteria={list.search_criteria} searchCriteria={[searchCriteria, setSearchCriteria]}>
                        <div className="filter">
                            <Button disabled={searchCriteriaIncomplete} icon={<IconStats style={{color: '#fff'}} />} submit />
                            <Button
                                disabled={searchCriteriaIncomplete}
                                icon={<IconSave style={{color: '#fff'}} />}
                                onClick={downloadList}
                            />
                        </div>
                    </SearchCriteria>
                )}
            </ListSelection>
            {loadingMessage && <Spinner message={loadingMessage} />}
            {data.state === 'loading' && <Spinner />}
            {data.state === 'error' && (
                <section>
                    <h1>{data.message}</h1>
                </section>
            )}
            {data.state === 'success' && !list.hasOwnProperty('lists') && <ListData data={data.data} listConfiguration={list} />}
            {data.state === 'success' && list.hasOwnProperty('lists') && (
                <GroupedList data={data.data} listConfiguration={list} />
            )}
            {data.state === 'success' && selectedList === 'margin_invoices' && <MarginInvoicesSummary data={data.data} />}
            {data.state === 'success' && selectedList === 'banklist_bnp' && <BanklistBNPSummary data={data.data} />}
            <style>{`@media print { aside { display: none; } main { width: 100% }`}</style>
        </Layout>
    );
};

export default ShowList;
