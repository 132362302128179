import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import lscache from 'lscache';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Layout from '../../../../components/Layout/Layout';
import EditMailModal from '../../../../components/Modal/EditMailModal';
import Spinner from '../../../../components/Spinner/Spinner';
import CarInfoCell from '../../Components/CarInfoCell';
import PaymentHistory from '../../PaymentHistory';
import RequiredAdvance from '../../RequiredAdvance';
import CreditNoteHistory from './CreditNoteHistory';
import MailHistory from './MailHistory';
import RegisterIncomingPayment from './RegisterIncomingPayment';
import Seller from './Seller';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {useAuth} from '../../../../lib/UseAuth';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';

import '../../Documents.scss';
import Dropdown from '../../../../components/Button/Dropdown';
import RegisterIncomingAdvance from '../../../Cars/List/Modal/RegisterIncomingAdvance';

const Detail = ({invoice, refresh, users}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();
    const {user} = useAuth();
    let navigate = useNavigate();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [singleSubsidiary, setSingleSubsidiary] = useState(true);
    const rerenderCountRef = useRef(1);

    const carLines = invoice.lines.filter((line) => line.type === 'CAR');

    const combinedRefresh = () => {
        refresh();
        rerenderCountRef.current++;
    };

    const handleSend = (mailText) => {
        setLoadingMessage(t('nav.submit') + '…');
        authenticatedHttp()
            .post('/invoices/send', {
                mail_text: mailText,
                ids: [invoice.id],
            })
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    const editMailText = () => {
        showModal(<EditMailModal documents={[invoice]} handleSend={handleSend} />);
    };

    const handleCreateCreditNote = () => {
        navigate('/creditnotas/creatie?invoice_id=' + invoice.id);
    };

    useEffect(() => {
        if (carLines.length === 0) {
            return;
        }
        const firstCarSubsidiary = carLines[0].car.subsidiary;
        const singleSubsidiary = carLines.every((line) => line.car.subsidiary === firstCarSubsidiary);
        setSingleSubsidiary(singleSubsidiary);
        if (!singleSubsidiary) {
            alert(t('validation.different_subsidiaries'));
        }
    }, [invoice]);

    const cars = invoice.lines.filter((line) => line.type === 'CAR').map((line) => line.car);
    const carIds = cars.map((car) => car.car_id);

    let availableActions = {
        [t('general.download_as_pdf')]: !invoice.is_imported
            ? () => {
                  setLoadingMessage(t('general.generating_document'));
                  downloadFile(
                      '/download/invoice',
                      {invoice_id: invoice.id},
                      t('general.invoice').toLowerCase() + '_' + invoice.id
                  ).then(() => {
                      setLoadingMessage(null);
                  });
              }
            : undefined,
        [t('general.edit')]:
            user.permissions.CAN_EDIT_INVOICES && invoice.status === 'DRAFT'
                ? () => navigate(`/facturen/${invoice.id}/bewerken`)
                : undefined,
        [t('general.delete')]:
            invoice.status === 'DRAFT'
                ? () => {
                      if (confirm(t(`general.confirm`))) {
                          authenticatedHttp()
                              .delete(`/invoices/${invoice.id}`)
                              .then(() => {
                                  navigate(`/facturen`);
                              })
                              .catch(() => {
                                  alert('Could not delete invoice.');
                              });
                      }
                  }
                : undefined,
        [t('general.credit_note_create')]:
            user.permissions.CAN_CREATE_CREDIT_NOTES && (invoice.status === 'SENT' || invoice.status === 'PAID')
                ? handleCreateCreditNote
                : undefined,
        [t('general.register_incoming_advance')]: () =>
            showModal(<RegisterIncomingAdvance cars={cars} customers={[invoice.customer.data]} refresh={combinedRefresh} />),
        [t('general.register_incoming_payment')]: () =>
            showModal(<RegisterIncomingPayment invoices={[invoice]} refresh={combinedRefresh} />),
    };

    const searchResultsUrl = useMemo(() => {
        const urlParams = new URLSearchParams(lscache.get('invoices_filters'));

        return `/facturen?${urlParams.toString()}`;
    }, []);

    const invoiceNavigation = useMemo(() => {
        const invoiceIds = lscache.get('invoices_results');
        if (!invoiceIds) {
            return null;
        }

        const index = invoiceIds.indexOf(invoice.id);
        if (index === -1) {
            return null;
        }
        const prevId = index > 0 ? invoiceIds[index - 1] : null;
        const nextId = index < invoiceIds.length ? invoiceIds[index + 1] : null;

        return {
            index: index + 1,
            total: invoiceIds.length,
            prevId,
            nextId,
        };
    }, [invoice]);

    return (
        <Layout
            className="documents documents-detail invoice-detail"
            header={() => (
                <Fragment>
                    <div className="header-row">
                        <Link className="back-to-results small" to={searchResultsUrl}>
                            {t('nav.back_to_results')}
                        </Link>
                        <p className="small seperator"> - </p>
                        <Link className="to-similar small" to={`/facturen?customer=${invoice.customer.data.id}`}>
                            {t('nav.to_customer_invoices')}
                        </Link>
                        <div className="title">
                            <div className="title-row">
                                {invoiceNavigation && (
                                    <Link
                                        className={`btn-arrow btn-arrow-left ${
                                            !invoiceNavigation.prevId ? `btn-arrow-disabled` : ``
                                        }`}
                                        to={`/facturen/${invoiceNavigation.prevId}`}
                                    />
                                )}
                                <div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <h1>{t('general.invoice')}</h1>
                                        {invoiceNavigation && (
                                            <h2>
                                                {invoiceNavigation.index}/{invoiceNavigation.total}
                                            </h2>
                                        )}
                                    </div>
                                    <h2>
                                        {carLines.length > 0 || invoice.status === 'DRAFT'
                                            ? t('enum.document_status.' + invoice.status)
                                            : t('general.booked')}
                                    </h2>
                                </div>
                                {invoiceNavigation && (
                                    <Link
                                        className={`btn-arrow btn-arrow-right me-auto ${
                                            !invoiceNavigation.nextId ? `btn-arrow-disabled` : ``
                                        }`}
                                        to={`/facturen/${invoiceNavigation.nextId}`}
                                    />
                                )}
                            </div>
                        </div>
                        <Dropdown
                            actions={availableActions}
                            align="right"
                            buttonProps={{
                                label: t('general.actions'),
                            }}
                        />
                    </div>
                </Fragment>
            )}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <Fragment>
                <div className="row">
                    <div className="col">
                        <section>
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('search.subsidiary')}</label>
                                        <span>{t('enum.subsidiary.' + invoice.subsidiary)}</span>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('car.seller')}</label>
                                        <Seller
                                            invoiceId={invoice.id}
                                            setLoadingMessage={setLoadingMessage}
                                            userId={invoice.user_id}
                                            users={users}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.language')}</label>
                                        <span>{t('enum.language.' + invoice.language)}</span>
                                    </div>
                                </div>
                            </div>
                            {invoice.status !== 'DRAFT' && (
                                <div className="form-group form-group-vertical mb-4">
                                    <label>{t('general.invoice_nr')}</label>
                                    <span>{invoice.invoice_number}</span>
                                </div>
                            )}
                            <div className="row mb-4">
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label>
                                            {invoice.status === 'DRAFT' ? t('general.concept_date') : t('general.invoice_date')}
                                        </label>
                                        <span>{dateFormat(invoice.date)}</span>
                                    </div>
                                </div>
                                {invoice.status !== 'DRAFT' && (
                                    <div className="col-4">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.due_date')}</label>
                                            <span>{dateFormat(invoice.due_date)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {carLines.length > 0 ? (
                                <div className="form-group form-group-vertical">
                                    <label>{t('general.mail_text')}</label>
                                    <p className="render-line-breaks">{invoice.mail_text}</p>
                                </div>
                            ) : null}
                        </section>
                    </div>
                    <div className="col">
                        <section>
                            <div className="recipients">
                                <div className="form-group form-group-vertical">
                                    <label>{t('general.invoice_sent_to')}</label>
                                    <div className="customer">
                                        <a
                                            href={`/bedrijven/${invoice.customer.data.id}`}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {invoice.customer.data.label}
                                        </a>
                                        <div className="contacts">
                                            {invoice.contacts.map((contact, index) => (
                                                <a
                                                    className="contact"
                                                    href={`/contacten/${contact.id}`}
                                                    key={index}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {contact.full_name} {contact.email ? `(${contact.email})` : ''}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {invoice.is_imported ? (
                                <h2>{t('general.ecinda_import')}</h2>
                            ) : (
                                <Button color="blue" onClick={carLines.length > 0 ? editMailText : handleSend}>
                                    {carLines.length > 0
                                        ? invoice.status === 'SENT'
                                            ? t('general.resend')
                                            : t('general.send')
                                        : t('general.book_transport.label')}
                                </Button>
                            )}
                        </section>
                        <MailHistory document={invoice} />
                    </div>
                </div>
                <section className="no-padding items">
                    <div className="grid-table grid-table-invoices">
                        <div className="grid-table-row">
                            <div className="grid-table-cell">{t('general.items')}</div>
                            <div className="grid-table-cell">{t('general.advance')}</div>
                            <div className="grid-table-cell">{t('general.base_prices_on')}</div>
                            <div className="grid-table-cell numerical">{t('api.vat')}</div>
                            <div className="grid-table-cell numerical">{t('car.pricing.price')}</div>
                        </div>
                        {invoice.lines.map((line) => (
                            <div className="grid-table-row" key={line.id}>
                                <div className="grid-table-cell">
                                    {line.type === 'CAR' && (
                                        <CarInfoCell car={line.car} showLink showSubText={!singleSubsidiary} />
                                    )}
                                    {line.type === 'EXTRA' && <span>{line.description}</span>}
                                    {line.type === 'TRANSPORT' && <span>{t('general.transport')}</span>}
                                </div>
                                <div className="grid-table-cell">
                                    <RequiredAdvance line={line} />
                                </div>
                                <div className="grid-table-cell">
                                    {line.type === 'CAR' && <span>{t('enum.price_options.' + line.base_pricing)}</span>}
                                </div>
                                <div className="grid-table-cell numerical">{line.vat_rate}%</div>
                                <div className="grid-table-cell numerical">{moneyFormat(line.price)}</div>
                            </div>
                        ))}
                    </div>
                    <p className="car-count">
                        {t('car.number_of_cars')}: {invoice.lines.filter((line) => line.type === 'CAR').length}
                    </p>
                    <div className="lines-footer">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td className="text-end">
                                        <strong>{t('api.subtotal')}</strong>
                                    </td>
                                    <td className="text-end" width="130">
                                        {moneyFormat(invoice.total)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <strong>{t('api.vat')}</strong>
                                    </td>
                                    <td className="text-end" width="130">
                                        {moneyFormat(invoice.vat)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <h3>
                                            <span>{t('general.total')}</span>
                                        </h3>
                                    </td>
                                    <td className="text-end" width="130">
                                        <h3>{moneyFormat(invoice.total_incl_vat)}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <strong>{t('search.outstanding_debt')}</strong>
                                    </td>
                                    <td className="text-end">{moneyFormat(invoice.outstanding_debt)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="row">
                    <div className="col-6">
                        <PaymentHistory
                            carIds={carIds}
                            document="invoices"
                            documentId={invoice.id}
                            key={'payments-' + rerenderCountRef.current}
                        />
                    </div>
                    <div className="col-6">
                        <CreditNoteHistory invoiceId={invoice.id} key={'creditnotes-' + rerenderCountRef.current} />
                    </div>
                </div>
            </Fragment>
        </Layout>
    );
};

export default React.memo(Detail);
