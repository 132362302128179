import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DocumentDeliveryEnum from '../../../../components/Form/DocumentDeliveryEnum';
import {useModal} from '../../../../lib/UseModal';
import UploadCarDocument from '../../List/Modal/UploadCarDocument';
import NoRegistration from './NoRegistration';
import Section from './Section';
import Input from '../../../../components/Form/Input';
import Enum from '../../../../components/Form/Enum';
import {dateFormat} from '../../../../lib/helpers';
import Registration from './Registration';
import Button from '../../../../components/Button/Button';
import useFetchData from '../../../../lib/UseFetchData';
import RegistrationStatus from '../../../../components/CarDetail/RegistrationStatus';

const Documents = ({car, disabled, form, refresh}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();

    const [customer] = useFetchData(`/customers/${form.data.sale.sold_to}`, !!form.data.sale.sold_to);
    const [supplier] = useFetchData(`/companies/${form.data.supplier_id}`, !!form.data.supplier_id);

    const [showNoRegistrationFields, setShowNoRegistrationFields] = useState(
        !form.data.documents.registration.first && customer && customer.address.country_code === 'BE'
    );

    useEffect(() => {
        setShowNoRegistrationFields(
            !form.data.documents.registration.first && customer && customer.address.country_code === 'BE'
        );
    }, [customer]);

    const carHasE705 = car ? car.files.some((file) => file.type === 'E705') : false;
    const carHasCoc = car ? car.files.some((file) => file.type === 'COC') : false;

    const addRegistration = (ordinal, by) => () => {
        let registrationData = {
            by,
            registration_date: '0001-01-01',
            registration_country: null,
            proof_of_registration_part_1: {
                status: supplier?.address.country_code === 'BE' ? 'TO_REGISTER' : 'UNNECESSARY',
                delivery: 'NOT_DELIVERED',
            },
            proof_of_registration_part_2: {
                status: supplier?.address.country_code === 'BE' ? 'TO_REGISTER' : 'UNNECESSARY',
                delivery: 'NOT_DELIVERED',
            },
        };

        if (ordinal === 'first' && by === 'SUPPLIER') {
            registrationData.proof_of_registration_part_1.status = 'EXPECTED';
            registrationData.proof_of_registration_part_2.status = 'EXPECTED';
        }

        if (by === 'ECR') {
            registrationData.e705 = {
                status: carHasE705 ? 'ACCEPTED' : 'NECESSARY',
            };
            registrationData.preregistration = car?.documents?.registration[ordinal]?.preregistration?.status
                ? {status: car.documents.registration[ordinal].preregistration.status}
                : {status: 'UNNECESSARY'};
            registrationData.license_plate = null;
            registrationData.cancellation_from = null;
            registrationData.license_plate = null;
            registrationData.cancellation_from = null;
            registrationData.cancelled_on = null;
            // registrationData.notice_of_objection = {status: 'NECESSARY'};
        }

        form.setData({
            ...form.data,
            documents: {
                ...form.data.documents,
                registration: {...form.data.documents.registration, [ordinal]: registrationData},
            },
        });
    };

    const initialCocStatus = useRef(form.data.documents.certificate_of_conformity?.status);
    const cocStatus = form.data.documents.certificate_of_conformity?.status;
    useEffect(() => {
        if (initialCocStatus.current !== 'ARRIVED' && cocStatus === 'ARRIVED' && !carHasCoc) {
            showModal(<UploadCarDocument car={car} carDocument="COC" refresh={() => {}} />);
        }
    }, [cocStatus]);

    return (
        <Section name="documents" titleSuffix={<RegistrationStatus car={form.data} />}>
            <table className="table table-striped table-documents">
                {showNoRegistrationFields ? (
                    <NoRegistration
                        car={car}
                        carHasE705={carHasE705}
                        disabled={disabled}
                        form={form}
                        setShowNoRegistrationFields={setShowNoRegistrationFields}
                    />
                ) : (
                    <Registration
                        car={car}
                        carHasE705={carHasE705}
                        customer={customer}
                        disabled={disabled}
                        form={form}
                        ordinal="first"
                        refresh={refresh}
                        supplier={supplier}
                    >
                        <Button onClick={addRegistration('first', 'SUPPLIER')}>
                            {t('car.documents.add_first_registration') + ': ' + t('enum.registration_by.SUPPLIER')}
                        </Button>
                        <Button onClick={addRegistration('first', 'ECR')}>
                            {t('car.documents.add_first_registration') + ': ' + t('enum.registration_by.ECR')}
                        </Button>
                        {customer && customer.address.country_code === 'BE' ? (
                            <Button
                                color={'gray'}
                                onClick={() => {
                                    setShowNoRegistrationFields(true);
                                }}
                            >
                                {t('car.documents.no_registration')}
                            </Button>
                        ) : null}
                    </Registration>
                )}
                {form.data.documents.registration.first && form.data.documents.registration.first.by === 'SUPPLIER' && (
                    <Registration
                        car={car}
                        carHasE705={carHasE705}
                        customer={customer}
                        disabled={disabled}
                        form={form}
                        ordinal="second"
                        refresh={refresh}
                        supplier={supplier}
                    >
                        <Button onClick={addRegistration('second', 'ECR')}>{t('car.documents.add_second_registration')}</Button>
                    </Registration>
                )}
                <tbody className="form-group">
                    <tr>
                        <th colSpan={5}>
                            <h3>{t('car.documents.other')}</h3>
                        </th>
                    </tr>
                    {form.fields.documents.certificate_of_conformity && (
                        <tr>
                            <th>{t('car.documents.certificate_of_conformity')}</th>
                            <td>
                                <Enum
                                    disabled={disabled}
                                    enumName="certificate_of_conformity_status"
                                    {...form.fields.documents.certificate_of_conformity.status()}
                                />
                            </td>
                            <td className="small">{dateFormat(form.data.documents.certificate_of_conformity.changed_on)}</td>
                            <td>
                                {form.fields.documents.certificate_of_conformity.delivery && (
                                    <DocumentDeliveryEnum
                                        disabled={disabled}
                                        documentType="certificate_of_conformity"
                                        {...form.fields.documents.certificate_of_conformity.delivery()}
                                    />
                                )}
                            </td>
                            <td className="small">
                                {dateFormat(form.data.documents.certificate_of_conformity.delivery_changed_on)}
                            </td>
                        </tr>
                    )}
                    {form.fields.documents.warranty_booklet && (
                        <tr>
                            <th>{t('car.documents.warranty_booklet')}</th>
                            <td>
                                <Enum
                                    disabled={disabled}
                                    enumName="warranty_booklet_status"
                                    {...form.fields.documents.warranty_booklet.status()}
                                />
                            </td>
                            <td className="small">{dateFormat(form.data.documents.warranty_booklet.changed_on)}</td>
                            <td>
                                {form.fields.documents.warranty_booklet.delivery && (
                                    <DocumentDeliveryEnum
                                        disabled={disabled}
                                        documentType="warranty_booklet"
                                        {...form.fields.documents.warranty_booklet.delivery()}
                                    />
                                )}
                            </td>
                            <td className="small">{dateFormat(form.data.documents.warranty_booklet.delivery_changed_on)}</td>
                        </tr>
                    )}
                    {form.fields.documents.number_of_keys && (
                        <tr>
                            <th>{t('car.documents.number_of_keys')}</th>
                            <td>
                                <Input disabled={disabled} {...form.fields.documents.number_of_keys()} type="number" />
                            </td>
                            <td>
                                <Enum disabled={disabled} enumName="key_status" {...form.fields.documents.key_status()} />
                            </td>
                            <td>
                                {form.fields.documents.keys_delivery && (
                                    <DocumentDeliveryEnum
                                        disabled={disabled}
                                        documentType="keys"
                                        {...form.fields.documents.keys_delivery()}
                                    />
                                )}
                            </td>
                            <td className="small">{dateFormat(form.data.documents.keys_delivery_date)}</td>
                        </tr>
                    )}
                    {form.fields.documents.dkm_case_number && (
                        <tr>
                            <th>{t('car.documents.dkm_case_number')}</th>
                            <td>
                                <Input disabled={disabled} {...form.fields.documents.dkm_case_number()} />
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                    )}
                    {form.fields.documents.import_documents.status && (
                        <tr>
                            <th>{t('car.documents.import_documents')}</th>
                            <td>
                                <Enum
                                    disabled={disabled}
                                    enumName="import_documents_status"
                                    {...form.fields.documents.import_documents.status()}
                                />
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                    )}
                    {form.fields.documents.bank_leasing_case_number && (
                        <tr>
                            <th>{t('car.documents.bank_leasing_case_number')}</th>
                            <td>
                                <Input disabled={disabled} {...form.fields.documents.bank_leasing_case_number()} />
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                    )}
                </tbody>
            </table>
        </Section>
    );
};

export default Documents;
