import React, {Fragment, useEffect, useState} from 'react';

import Checkbox from '../../components/Form/Checkbox';
import DatePicker from '../../components/Form/DatePicker';
import Enum from '../../components/Form/Enum';
import Input from '../../components/Form/Input';
import Select from '../../components/Form/Select';
import {authenticatedHttp} from '../../lib/zaza-client';

const SearchCriteria = ({children, criteria, searchCriteria: [searchCriteria, setSearchCriteria]}) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (!criteria) {
            return;
        }

        const entityCriteria = Object.entries(criteria).filter(([, props]) => props.type === 'entity');

        entityCriteria.forEach(([key, props]) => {
            switch (props.entity_name) {
                case 'user':
                    authenticatedHttp()
                        .get('/users')
                        .then((response) => {
                            setOptions({
                                ...options,
                                [key]: response.data.data.map((user) => ({value: user.id, label: user.full_name})),
                            });
                        });
            }
        });
    }, [criteria]);

    return (
        <Fragment>
            {criteria &&
                Object.entries(criteria).map(([key, props]) => {
                    let input;
                    const onChange = (value) => {
                        setSearchCriteria({...searchCriteria, [key]: value});
                    };
                    const value = searchCriteria[key] || '';

                    switch (props.type) {
                        case 'boolean':
                            input = (
                                <Checkbox label={props.description} onChange={onChange} value={searchCriteria[key] || false} />
                            );
                            break;
                        case 'date':
                            input = <DatePicker onChange={onChange} placeholder={props.description} value={value} />;
                            break;
                        case 'enum':
                            input = (
                                <Enum
                                    enumName={props.enum_name}
                                    onChange={onChange}
                                    placeholder={props.description}
                                    value={value}
                                />
                            );
                            break;
                        case 'entity':
                            input = (
                                <Select
                                    onChange={onChange}
                                    options={options[key] || []}
                                    placeholder={props.description}
                                    value={value}
                                />
                            );
                            break;
                        case 'select':
                        case 'month_select':
                            // eslint-disable-next-line no-case-declarations
                            let valueMap = (value) => ({label: value, value});
                            if (props.type === 'month_select') {
                                valueMap = (value) => {
                                    const [year, month] = value.split('-');
                                    const months = {
                                        '01': 'Januari',
                                        '02': 'Februari',
                                        '03': 'Maart',
                                        '04': 'April',
                                        '05': 'Mei',
                                        '06': 'Juni',
                                        '07': 'Juli',
                                        '08': 'Augustus',
                                        '09': 'September',
                                        10: 'Oktober',
                                        11: 'November',
                                        12: 'december',
                                    };

                                    return {label: months[month] + ' ' + year, value};
                                };
                            }

                            input = (
                                <Select
                                    onChange={onChange}
                                    options={props.options.map(valueMap)}
                                    placeholder={props.description}
                                    value={value}
                                />
                            );
                            break;
                        default:
                            input = <Input onChange={onChange} placeholder={props.description} value={value} />;
                    }

                    return (
                        <div className="filter" key={key}>
                            {input}
                        </div>
                    );
                })}
            {children}
        </Fragment>
    );
};

export default SearchCriteria;
