import React, {Fragment, useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../../../lib/zaza-client';

const baseStyle = {
    borderWidth: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const PurchaseOrderDropzone = ({purchaseOrderId, refresh, setLoadingMessage}) => {
    const {t} = useTranslation();

    const onDrop = useCallback(
        (files) => {
            const acceptedFiles = files.map((file) => {
                if (file.type !== 'application/pdf') {
                    return file;
                }
            });
            if (acceptedFiles.length > 0) {
                const formData = new FormData();
                Array.from(files).forEach((file) => {
                    formData.append(`files[]`, file);
                });

                setLoadingMessage(t('general.add') + '…');
                authenticatedHttp()
                    .post(`/purchase-orders/${purchaseOrderId}/files`, formData, {
                        headers: {'Content-Type': 'multipart/form-data'},
                    })
                    .then(() => {
                        setLoadingMessage(null);
                        refresh();
                    })
                    .catch((response) => {
                        setLoadingMessage(null);
                        alert(response);
                    });
                return;
            }
            alert(t('general.upload_only_pdf'));
        },
        [purchaseOrderId]
    );

    const {getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject} = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    return (
        <Fragment>
            <div className="dropzone" {...getRootProps({style})}>
                <input {...getInputProps()} />

                <p>{t(`general.drag_drop_files`)}</p>
            </div>
        </Fragment>
    );
};

export default PurchaseOrderDropzone;
