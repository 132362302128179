import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Info from '../../../../assets/icons/Info';
import {authenticatedHttp} from '../../../lib/zaza-client';

import ColorSummary from './ColorSummary';

const CarCount = ({carId, count, searchParams}) => {
    const {t} = useTranslation();
    const startedLoading = useRef(false);

    const [similar, setSimilar] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleLoadCount = () => {
        if (startedLoading.current) {
            return;
        }

        startedLoading.current = true;
        setLoading(true);

        authenticatedHttp()
            .post(`/cars/search`, {...searchParams, similar_to: carId})
            .then((response) => {
                setSimilar(response.data.data);
                setLoading(false);
            });
    };

    return (
        <div className="grid-table-cell d-flex flex-grow-1 justify-content-center" onMouseOver={handleLoadCount}>
            <div className={'ms-2 ps-1'} id="tooltip">
                <div className={'d-flex flex-grow-1 justify-content-center align-items-center'}>
                    <span className={'me-2'}>{count}</span>
                    <Info size={20} />
                </div>
                <p>{loading ? t('general.loading') : similar ? <ColorSummary similarCars={similar} /> : count}</p>
            </div>
        </div>
    );
};

export default CarCount;
