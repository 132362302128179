import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import Spinner from '../../../../components/Spinner/Spinner';
import {dateFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import SummaryTable from '../Components/Summary/SummaryTable';

const SummaryPreregistration = ({car, form, refresh, registrationOrdinal, secondHand = false, showPreregistration}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();

    const downloadCOC = () => {
        const COCFiles = car.files.filter((file) => file.type === 'COC');

        return  <>
            {COCFiles.length > 0
                ? COCFiles.map((COCFile) => {
                    return (
                        <a href={`${COCFile.url}`} rel="noopener noreferrer" target="_blank">
                            Download
                        </a>
                    );
                })
                : '-'}
        </>
    }

    const data = secondHand
        ? {
              'car.documents.certificate_of_conformity': downloadCOC(),
              'car.registration_status': t('enum.registration_status.' + car.registration_status),
              'api.vin': car.vin,
              'car.first_used.on': car.documents.registration.first
                  ? dateFormat(car.documents.registration.first.registration_date)
                  : '-',
              'search.make': car.type.model.make,
              'car.title_model': car.type.model.name,
              'car.type.cc': car.type.cc + 'cc',
              'PK / kW': car.type.hp + ' ' + t('car.type.hp') + ' / ' + car.type.kw + ' ' + t('car.type.kw'),
              'car.type.fuel': 'enum.fuel.' + car.type.fuel,
              'car.type.emissions_standard': car.emissions_standard,
              'search.body_base_color': car.body_color_base,
          }
        : {
              'car.documents.certificate_of_conformity': downloadCOC(),
              'car.registration_status': t('enum.registration_status.' + car.registration_status),
              'api.vin': car.vin,
              'search.make': car.type.model.make,
              'car.title_model': car.type.model.name,
              'car.mileage': car.mileage,
              'api.color': car.body_color,
              'api.doors': 'enum.doors.' + car.type.model.doors,
              'car.type.fuel': 'enum.fuel.' + car.type.fuel,
              'car.type.cc': car.type.cc + 'cc',
              'PK / kW': car.type.hp + ' ' + t('car.type.hp') + ' / ' + car.type.kw + ' ' + t('car.type.kw'),
              'car.consumption.nedc_city': car.emissions.consumption.nedc_city + ' l',
              'car.consumption.nedc_road': car.emissions.consumption.nedc_road + ' l',
              'car.consumption.nedc_combined': car.emissions.consumption.nedc_combined + ' l',
              'car.emissions.nedc_co2': car.emissions.co2.nedc + 'g',
              'car.emissions.wltp_co2': car.emissions.co2.wltp + 'g',
              'search.body_base_color': car.body_color_base,
          };

    const [loadingMessage, setLoadingMessage] = useState(null);
    const noRegistrationE705 = !form.data.documents.registration.first;

    const isPreregistered = () => {
        setLoadingMessage('Saving...');

        const data = noRegistrationE705
            ? {
                  documents: {
                      no_registration: {
                          e705: {
                              status: 'ACCEPTED',
                          },
                          preregistration: {
                              status: 'PREREGISTERED',
                          },
                      },
                  },
                  update_mode: 'ONLY_THIS',
              }
            : {
                  documents: {
                      registration: {
                          [registrationOrdinal]: {
                              e705: {
                                  status: 'ACCEPTED',
                              },
                              preregistration: {
                                  status: 'PREREGISTERED',
                              },
                          },
                      },
                  },
                  update_mode: 'ONLY_THIS',
              };

        authenticatedHttp()
            .patch('/cars/' + car.car_id, data)
            .then(() => {
                refresh();
                closeModal();
            })
            .catch((response) => {
                setLoadingMessage(null);
                alert(response.message);
            });
    };

    return (
        <Fragment>
            {loadingMessage && <Spinner message={loadingMessage} />}
            <form>
                <Modal title={t('general.summary.preregistration')}>
                    <Modal.Body>
                        <SummaryTable data={data} />
                        <hr />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="blue" onClick={() => closeModal()}>
                            {t('general.close')}
                        </Button>
                        {showPreregistration && (
                            <Button color="blue" onClick={() => isPreregistered()}>
                                {t('car.documents.is_preregistered')}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </form>
        </Fragment>
    );
};

export default SummaryPreregistration;
