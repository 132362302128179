import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Printer from '../../../../assets/svg/icon-print.svg';
import Button from '../../../components/Button/Button';
import {authenticatedHttp} from '../../../lib/zaza-client';

const PrintButton = ({parameters, styles, type}) => {
    const {t} = useTranslation();
    const [state, setState] = useState('idle');
    const icon = state === 'success' ? <i className="fas fa-check" /> : <Printer />;
    useEffect(() => {
        let timer = null;

        if (state === 'success') {
            timer = setTimeout(() => setState('idle'), 2000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [state]);

    const handleClick = () => {
        setState('busy');
        authenticatedHttp()
            .post('/print/' + type, parameters)
            .then(() => {
                setState('success');
            });
    };

    return (
        <Button disabled={state === 'busy'} icon={icon} onClick={handleClick} shadow small styles={styles}>
            {t('general.print', {printable: t('enum.printable_type.' + type).toLowerCase()})}
        </Button>
    );
};

export default PrintButton;
