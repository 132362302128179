import React from 'react';
import {useTranslation} from 'react-i18next';
import classnames from 'classnames';

const ColumnHeader = ({children, className, columnConfig, orderBy, orderBySetter}) => {
    const {t} = useTranslation();

    const classes = {
        'grid-table-cell': true,
        [`grid-table-cell-${columnConfig.col}`]: true,
        clickable: columnConfig.sortable,
        numerical: columnConfig.price || columnConfig.numerical || false,
        sortable: columnConfig.sortable,
        wide: columnConfig.wide || false,
    };

    if (className) {
        classes[className] = true;
    }

    return (
        <div
            className={classnames(classes)}
            key={columnConfig.col}
            onClick={() => {
                if (!columnConfig.sortable) {
                    return;
                }
                orderBySetter(columnConfig.col, columnConfig.path);
            }}
        >
            <span title={t('search.' + columnConfig.col)}>{t('search.' + columnConfig.col)}</span>

            {orderBy.key === columnConfig.col && orderBy.direction === 'ASC' && <i className="fa fa-caret-up" />}

            {orderBy.key === columnConfig.col && orderBy.direction === 'DESC' && <i className="fa fa-caret-down" />}

            {children}
        </div>
    );
};

export default ColumnHeader;
