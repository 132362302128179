// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullscreen-image-modal {
  margin: 0;
  padding: 0;

  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

  .fullscreen-image-modal img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }

.fullscreen-image-modal-container .image-gallery-slides{
    height: 80vh !important;
    width: 100vh !important;
  }

.fullscreen-image-modal-container .image-gallery-slide,
  .fullscreen-image-modal-container .image-gallery-image {
    height: 100% !important;
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

.fullscreen-image-modal-container .image-gallery-slide div, .fullscreen-image-modal-container .image-gallery-image div {
      height: 100% !important;
    }
`, "",{"version":3,"sources":["webpack://./src/js/views/Cars/Management/FullScreenImageModal.scss"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;EAEV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AAOpB;;EALE;IACE,YAAY;IACZ,WAAW;IACX,oBAAiB;OAAjB,iBAAiB;EACnB;;AAIA;IACE,uBAAuB;IACvB,uBAAuB;EACzB;;AAEA;;IAEE,uBAAuB;IACvB,iCAA8B;OAA9B,8BAA8B;EAKhC;;AAHE;MACE,uBAAuB;IACzB","sourcesContent":[".fullscreen-image-modal {\n  margin: 0;\n  padding: 0;\n\n  height: 80vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n\n  img {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n  }\n}\n\n.fullscreen-image-modal-container {\n  .image-gallery-slides{\n    height: 80vh !important;\n    width: 100vh !important;\n  }\n\n  .image-gallery-slide,\n  .image-gallery-image {\n    height: 100% !important;\n    object-fit: contain !important;\n\n    div {\n      height: 100% !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
