import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const SupplierCard = ({carSupplier, toggleEditSupplier}) => {
    const {t} = useTranslation();

    return (
        <Fragment>
            <div>
                <Link className="link" to={`/bedrijven/${carSupplier.id}`}>
                    {carSupplier.name}
                </Link>
                <button className="ms-2" onClick={() => toggleEditSupplier(true)} title={t('general.edit')}>
                    <i className="fas fa-pencil-alt" />
                </button>
            </div>
            <p>{carSupplier.vat_number}</p>
            <p>{carSupplier.address.address_line}</p>
            <p>
                {carSupplier.address.postal_code} {carSupplier.address.country}
            </p>
            <p>{carSupplier.telephone}</p>
            <hr className="line-light" />
        </Fragment>
    );
};

export default SupplierCard;
