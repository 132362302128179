import React, {Fragment} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Money from '../../../../components/Form/Money';

function FormRow({allowDelete = true, car, control, disabled = false, enableCustomPrice = true, field, index, register, remove}) {
    const {t} = useTranslation();

    return (
        <div className="grid-table-row">
            {/* Hidden input to submit line type and ID to form hook */}
            <input className="d-none" defaultValue={field.type} {...register(`lines[${index}].type`)} />
            <input className="d-none" defaultValue={field.car_id} {...register(`lines[${index}].car_id`)} />
            <input className="d-none" defaultValue={field.invoice_line_id} {...register(`lines[${index}].invoice_line_id`)} />
            <div className="grid-table-cell">
                {field.type === 'CAR' && (
                    <Fragment>
                        <strong>
                            {car.type.model.make} {car.type.model.name}
                        </strong>
                        <p>{car.type.name}</p>
                        <a className="link--blue small" href={'/autos/' + car.car_id}>
                            {car.car_id} &ndash; {car.vin}
                        </a>
                        <p className="small">{t('enum.subsidiary.' + car.subsidiary)}</p>
                    </Fragment>
                )}
                {field.type === 'EXTRA' && (
                    <div className="form-group">
                        <input
                            autoComplete="off"
                            className="form-control"
                            defaultValue={field.description}
                            disabled={disabled}
                            placeholder={t('general.description')}
                            {...register(`lines[${index}].description`)}
                        />
                    </div>
                )}
                {field.type === 'TRANSPORT' && <span>{t('general.transport')}</span>}
            </div>
            <div className="grid-table-cell numerical">
                <div className="form-group">
                    <Controller
                        control={control}
                        defaultValue={field.price}
                        disabled={disabled || (field.type === 'CAR' && !enableCustomPrice)}
                        name={`lines[${index}].price`}
                        render={({field}) => <Money {...field} />}
                    />
                </div>
            </div>
            <div className="grid-table-cell numerical">
                {allowDelete && !disabled && (
                    <a
                        className="btn--delete"
                        onClick={() => {
                            remove(index);
                        }}
                    >
                        <i className="fa fa-trash" />
                    </a>
                )}
            </div>
        </div>
    );
}

export default FormRow;
