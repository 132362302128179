import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../lib/helpers';
import {useAuth} from '../../lib/UseAuth';
import {authenticatedHttp} from '../../lib/zaza-client';
import PrintButton from '../../views/Cars/Detail/PrintButton';
import SummaryButton from '../../views/Cars/Detail/SummaryButton';

import EditSupplier from './EditSupplier';
import SupplierCard from './SupplierCard';
import Input from '../Form/Input';
import Enum from '../Form/Enum';
import DatePicker from '../Form/DatePicker';

function SupplierDetail({car, form, refresh}) {
    const {t} = useTranslation();
    const {user} = useAuth();

    const [suppliedBy, setSuppliedBy] = useState(false);
    const [editSupplier, toggleEditSupplier] = useState(!form.data.supplier_id);
    const [isLoading, setIsLoading] = useState(false);

    const loadSupplier = async (supplierId) => {
        if (supplierId) {
            setIsLoading(true);
            setSuppliedBy(false);

            const response = await authenticatedHttp().get('/companies/' + supplierId);

            setSuppliedBy(response.data.data);
            setIsLoading(false);

            return response.data.data;
        }

        return undefined;
    };

    useEffect(() => {
        loadSupplier(form.data.supplier_id);
    }, []);

    const handleSupplierChange = async (supplierId) => {
        if (supplierId) {
            const supplier = await loadSupplier(supplierId);
            const {onChange} = form.fields.supplier_id({
                onChange: (name, value, draft) => {
                    draft.purchase_pricing.transport.from_supplier.organized_by = supplier.supplier_organizes_transport
                        ? 'SUPPLIER'
                        : 'ECR';
                },
            });
            onChange(supplierId);
            toggleEditSupplier(false);
        }
    };

    const canViewPurchaseInformation =
        user.permissions.CAN_VIEW_PURCHASING_INFORMATION ||
        (user.permissions.CAN_VIEW_SUPPLIER_DATA &&
            (!['UNNECESSARY', null, undefined].includes(form.data.documents.registration?.first?.e705?.status) ||
                !['UNNECESSARY', null, undefined].includes(form.data.documents.registration?.second?.e705?.status) ||
                !['UNNECESSARY', null, undefined].includes(form.data.documents.no_registration?.e705?.status)));

    return (
        <div className="supplier-data">
            <div className="supplier__head">
                <h3>{t('car.purchase_pricing.supplier_data')}</h3>
                <PrintButton parameters={{company_id: form.data.supplier_id}} type="ADDRESS_STICKER" />
            </div>
            <div className="supplier__body">
                {editSupplier ? (
                    <div className={'d-inline-flex container-fluid'}>
                        <EditSupplier
                            className={'container-fluid p-0 pe-4'}
                            form={form}
                            onChange={handleSupplierChange}
                            suppliedBy={suppliedBy}
                        />
                        <button className="ms-2 px-1" onClick={() => toggleEditSupplier(false)} title={t('general.cancel')}>
                            <i className="fas fa-times" />
                        </button>
                    </div>
                ) : (
                    <Fragment>
                        {isLoading && t('general.loading')}
                        {suppliedBy && <SupplierCard carSupplier={suppliedBy} toggleEditSupplier={toggleEditSupplier} />}
                    </Fragment>
                )}
                {form.data.purchase_documents.length > 0 && (
                    <div>
                        <strong>{t('general.documents')}</strong>
                        <table>
                            <tbody>
                                {form.data.purchase_documents.map((document) => (
                                    <tr key={document.id}>
                                        <td>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!confirm(t('general.confirm'))) {
                                                        return;
                                                    }

                                                    authenticatedHttp()
                                                        .delete(`/cars/purchase-documents/${document.type}/${document.number}`)
                                                        .then(() => {
                                                            refresh();
                                                        });
                                                }}
                                                style={{marginRight: '.5em'}}
                                            >
                                                <i className="fas fa-times-circle" />
                                            </button>
                                        </td>
                                        <td>{t('enum.purchase_document_type.' + document.type.toUpperCase())}</td>
                                        <td>{document.number}</td>
                                        <td>{dateFormat(document.date)}</td>
                                        {canViewPurchaseInformation ? (
                                            <>
                                                <td>
                                                    {document.documentFile && (
                                                        <a
                                                            href={`${document.documentFilePath}`}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                        >
                                                            Download
                                                        </a>
                                                    )}
                                                </td>
                                                <td className="text-end">
                                                    {moneyFormat(
                                                        document.price,
                                                        car.purchase_pricing.costs.purchase_price.currency
                                                    )}
                                                </td>
                                            </>
                                        ) : null}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="row form-group" style={{display: 'flex'}}>
                    {form.fields.purchase_type && (
                        <Fragment>
                            <div className="col-4 col-form-label">
                                <strong>{t('car.purchase_type')}</strong>
                            </div>
                            <div className="col-8">
                                <Enum enumName="purchase_type" {...form.fields.purchase_type()} />
                            </div>
                        </Fragment>
                    )}
                    {form.fields.production_date && (
                        <Fragment>
                            <div className="col-4 col-form-label">
                                <strong>{t('car.production_date')}</strong>
                            </div>
                            <div className="col-8">
                                <DatePicker
                                    {...form.fields.production_date({
                                        onChange: (name, value, draft) => {
                                            if (!value) {
                                                return;
                                            }

                                            const date = new Date(value);
                                            date.setDate(date.getDate() + 30);

                                            const quarter = Math.floor((date.getMonth() + 3) / 3);
                                            const year = date.getFullYear();

                                            draft.localisation.arrival.expected_estimate = `Q${quarter} ${year}`;
                                        },
                                    })}
                                />
                            </div>
                        </Fragment>
                    )}
                    {form.fields.documents.internal_order_code && (
                        <Fragment>
                            <div className="col-4 col-form-label">
                                <strong>{t('car.documents.internal_order_code')}</strong>
                            </div>
                            <div className="col-8">
                                <Input
                                    placeholder={t('car.documents.internal_order_code')}
                                    {...form.fields.documents.internal_order_code()}
                                />
                            </div>
                        </Fragment>
                    )}
                    {form.fields.documents.supplier_order_number && (
                        <Fragment>
                            <div className="col-4 col-form-label">
                                <strong>{t('car.documents.supplier_order_number')}</strong>
                            </div>
                            <div className="col-8">
                                <Input
                                    placeholder={t('car.documents.supplier_order_number')}
                                    {...form.fields.documents.supplier_order_number()}
                                />
                            </div>
                        </Fragment>
                    )}
                </div>
                {canViewPurchaseInformation && form.data.initial_vin === undefined ? (
                    <>
                        <hr className="line-light" />
                        <div className="flex-row">
                            <SummaryButton car={car} carSupplier={suppliedBy} form={form} refresh={refresh} text="E705" />
                            <SummaryButton
                                car={car}
                                carSupplier={suppliedBy}
                                form={form}
                                refresh={refresh}
                                text="preregistration"
                            />
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default SupplierDetail;
