//General imports
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import lscache from 'lscache';
import {useAuth} from '../../lib/UseAuth';

//Styling
import './NavBar.scss';

import IconCar from '../../../assets/svg/icon-car.svg';
import IconCarCreate from '../../../assets/svg/icon-car-create.svg';
import IconCarSearch from '../../../assets/svg/icon-car-search.svg';
import IconSearch from '../../../assets/svg/icon-search.svg';
import IconStats from '../../../assets/svg/icon-stats.svg';
import IconSettings from '../../../assets/svg/icon-settings.svg';
import IconLogout from '../../../assets/svg/icon-logout.svg';
import IconBriefcase from '../../../assets/svg/icon-briefcase.svg';
import IconReceipt from '../../../assets/svg/icon-receipt.svg';
import IconInvoice from '../../../assets/svg/icon-invoice-dollar.svg';
import IconCompany from '../../../assets/svg/icon-company.svg';
import IconContact from '../../../assets/svg/icon-contact.svg';

const Navbar = () => {
    const {logout, user} = useAuth();
    const location = useLocation();
    const {i18n, t} = useTranslation();
    const {pathname} = useLocation();
    const [showMenu, toggleMenu] = useState({cars: true, invoicing: false, stats: false});
    const [lastSearchParameters, setLastSearchParameters] = useState(null);

    useEffect(() => {
        setLastSearchParameters(lscache.get('search_parameters'));

        if (pathname.includes('facturen') || pathname.includes('creditnotas')) {
            toggleMenu((prevState) => {
                return {...prevState, ['invoicing']: true};
            });
        }
    }, [location]);

    const handleToggleMenu = (menu) => {
        toggleMenu((prevState) => {
            return {...prevState, [menu]: !prevState[menu]};
        });
    };

    const isCarsActive = () => {
        if (pathname.includes('autos')) {
            return 'active';
        }
    };

    const isInvoicingActive = () => {
        if (pathname.includes('facturen') || pathname.includes('creditnotas')) {
            return 'active';
        }
    };

    const menuClassName = (menu) => {
        if (!showMenu[menu]) {
            return 'closed';
        }
    };

    const iconClassName = (menu) => {
        return showMenu[menu] ? 'fas fa-angle-up' : 'fas fa-angle-down';
    };

    const documentsLink = (path) => {
        if (user.group_id === 2) {
            return `${path}?user=${user.id}`;
        }

        return path;
    };

    const businessLink = (path) => {
        if (user.group_id === 2) {
            return `${path}?user=${user.id}&type=CUSTOMER`;
        }

        return path;
    };

    if (!user) {
        return null;
    }

    return (
        <aside style={process.env.API_URL.includes('staging') ? {backgroundColor: '#8c0a0a'} : {}}>
            <a className="logo" href="#">
                ZAZINDA
            </a>

            <nav className="nav">
                <ul>
                    <li className={menuClassName('cars')}>
                        <a
                            className={isCarsActive()}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleToggleMenu('cars');
                            }}
                        >
                            <i className="icon">
                                <IconCar />
                            </i>
                            <p>{t('nav.cars')}</p>
                            <i className={iconClassName('cars')} />
                        </a>

                        <ul className="nav__dropdown">
                            <li>
                                <NavLink exact={'true'} to="/autos/zoeken">
                                    <i className="icon">
                                        <IconSearch />
                                    </i>
                                    <p>{t('nav.search')}</p>
                                </NavLink>
                            </li>

                            {lastSearchParameters && location.pathname !== '/autos/lijst' && (
                                <li>
                                    <NavLink to={'/autos/lijst?query=' + JSON.stringify(lastSearchParameters)}>
                                        <i className="icon">
                                            <IconCarSearch />
                                        </i>
                                        <p>{t('nav.last_search_results')}</p>
                                    </NavLink>
                                    <a
                                        className="clear"
                                        onClick={() => {
                                            lscache.remove('search_parameters');
                                            document.location.reload();
                                        }}
                                    >
                                        <i className="fas fa-times-circle" />
                                    </a>
                                </li>
                            )}

                            {user.permissions.CAN_CREATE_CARS && (
                                <li>
                                    <NavLink to="/autos/creatie">
                                        <i className="icon">
                                            <IconCarCreate />
                                        </i>
                                        <p>{t('nav.create_car')}</p>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </li>

                    <li className={menuClassName('quotes')}>
                        <NavLink to={documentsLink('/offertes')}>
                            <i className="icon">
                                <IconBriefcase />
                            </i>
                            <p>{t('nav.quotes')}</p>
                        </NavLink>
                    </li>

                    <li className={menuClassName('purchaseOrders')}>
                        <NavLink to={documentsLink('/bestelbonnen')}>
                            <i className="icon">
                                <IconReceipt />
                            </i>
                            <p>{t('nav.purchase_orders')}</p>
                        </NavLink>
                    </li>

                    <li className={menuClassName('invoicing')}>
                        <a
                            className={isInvoicingActive()}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleToggleMenu('invoicing');
                            }}
                        >
                            <i className="icon">
                                <IconInvoice />
                            </i>
                            <p>{t('nav.invoicing')}</p>
                            <i className={iconClassName('invoicing')} />
                        </a>

                        <ul className="nav__dropdown">
                            <li>
                                <NavLink to={documentsLink('/facturen')}>
                                    <i className="icon">
                                        <IconInvoice />
                                    </i>
                                    <p>{t('nav.invoices')}</p>
                                </NavLink>

                                <NavLink to={documentsLink('/creditnotas')}>
                                    <i className="icon">
                                        <IconInvoice />
                                    </i>
                                    <p>{t('general.credit_notes')}</p>
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                    {user.permissions.CAN_ACCESS_LISTS && (
                        <li>
                            <NavLink to="/lijsten">
                                <i className="icon">
                                    <IconStats style={{color: '#8b9ca6'}} />
                                </i>
                                <p>{t('nav.lists')}</p>
                            </NavLink>
                        </li>
                    )}

                    {user.permissions.CAN_ACCESS_COMPANIES ? (
                        <li>
                            <NavLink to={businessLink('/bedrijven')}>
                                <i className="icon">
                                    <IconCompany style={{color: '#8b9ca6'}} />
                                </i>
                                <p>{t('nav.companies')}</p>
                            </NavLink>
                        </li>
                    ) : null}

                    {user.permissions.CAN_ACCESS_COMPANIES ? (
                        <li>
                            <NavLink to={businessLink('/contacten')}>
                                <i className="icon">
                                    <IconContact style={{color: '#8b9ca6'}} />
                                </i>
                                <p>{t('nav.contacts')}</p>
                            </NavLink>
                        </li>
                    ) : null}

                    {user.permissions.CAN_ACCESS_SETTINGS && (
                        <li>
                            <a href={process.env.ADMIN_URL} rel="noopener noreferrer" target="_blank">
                                <i className="icon">
                                    <IconSettings />
                                </i>
                                <p>{t('nav.settings')}</p>
                            </a>
                        </li>
                    )}
                </ul>
            </nav>

            <div
                className="access"
                style={
                    process.env.API_URL.includes('staging')
                        ? {
                              backgroundColor: '#7a0d0d',
                          }
                        : {}
                }
            >
                <a
                    className="access__body"
                    onClick={logout}
                    style={
                        process.env.API_URL.includes('staging')
                            ? {
                                  borderTop: '1px solid #AB0909FF',
                              }
                            : {}
                    }
                >
                    <IconLogout />
                    <h4>
                        <strong>{t('nav.logout')}</strong>
                    </h4>
                </a>

                <div className="access__foot">
                    <p>
                        {t('nav.logged_in_as')} <strong>{user.full_name}</strong>
                    </p>
                    <select
                        onChange={(e) => {
                            i18n.changeLanguage(e.target.value);
                        }}
                        value={i18n.language || ''}
                    >
                        <option value="nl">NL</option>
                        <option value="fr">FR</option>
                        <option value="en">EN</option>
                    </select>
                </div>
            </div>
        </aside>
    );
};

export default React.memo(Navbar);
