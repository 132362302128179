"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const helpers_1 = require("../../lib/helpers");
const Money = ({ max = undefined, min = undefined, name, onManuallyChanged }) => {
    const { field } = (0, react_hook_form_1.useController)({ name });
    const ref = (0, react_1.useRef)(null);
    const manuallyChanged = (0, react_1.useRef)(false);
    const [cursor, setCursor] = (0, react_1.useState)(null);
    const [inputValue, setInputValue] = (0, react_1.useState)(field.value);
    const [editing, setEditing] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const input = ref.current;
        if (input) {
            input.setSelectionRange(cursor, cursor);
        }
    }, [ref, cursor, inputValue]);
    (0, react_1.useEffect)(() => {
        setInputValue(field.value);
    }, [field.value]);
    const handleChange = (e) => {
        setCursor(e.currentTarget.selectionStart);
        field.onChange(e.currentTarget.value);
        if (!manuallyChanged.current) {
            if (onManuallyChanged) {
                onManuallyChanged();
            }
            manuallyChanged.current = true;
        }
    };
    const displayValue = editing ? inputValue : (0, helpers_1.moneyFormat)(inputValue);
    return (react_1.default.createElement("input", { className: 'form-control text-end', max: max, min: min, onBlur: () => setEditing(false), onChange: handleChange, onFocus: () => setEditing(true), ref: ref, style: { maxWidth: 160 }, type: 'text', value: displayValue }));
};
exports.default = Money;
