import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {orderByProperty} from '../../../../lib/helpers';

import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';

const tabs = ['general', 'make_specific'];

const EquipmentSelection = ({includedOptions, loadOptions, make = null, model, options, setIncludedOptions, type}) => {
    const {t} = useTranslation();
    const [optionFilter, setOptionFilter] = useState('');
    const [currentTab, setCurrentTab] = useState('general');
    const [loadingOptions, setLoadingOptions] = useState(false);

    const reloadOptions = () => {
        if (loadingOptions) {
            return;
        }
        setLoadingOptions(true);
        loadOptions(() => {
            setLoadingOptions(false);
        });
    };

    const tabFilter =
        currentTab === 'general'
            ? (option) => !option.make
            : (option) => {
                  let result = option.make ? option.make === make : false;
                  if (option.model_id) {
                      result &= option.model_id === model; // if a model is defined on the option, check it and add to previous
                  }
                  if (option.type_id) {
                      result &= option.type_id === type; // same but with type
                  }

                  return result;
              };

    const checkedIds = includedOptions.map((includedOption) => includedOption.option_id);
    const highlightedIds = includedOptions
        .filter((includedOption) => includedOption.highlighted)
        .map((includedOption) => includedOption.option_id);

    const listOptions = orderByProperty(
        options.filter(tabFilter).filter((option) => {
            if (
                (option.description && option.description.toLowerCase().includes(optionFilter.toLowerCase())) ||
                (option.name && option.name.toLowerCase().includes(optionFilter.toLowerCase()))
            ) {
                return true;
            }
            return option.code && option.code.toLowerCase().includes(optionFilter.toLowerCase());
        }),
        'name'
    );

    const toggleOption = (id) => {
        if (checkedIds.includes(id)) {
            setIncludedOptions(includedOptions.filter((option) => option.option_id !== id));
        } else {
            setIncludedOptions([...includedOptions, {option_id: id, highlighted: false}]);
        }
    };
    const toggleHighlightedOption = (id) => {
        setIncludedOptions(
            includedOptions.map((includedOption) => {
                if (includedOption.option_id !== id) {
                    return includedOption;
                }

                return {...includedOption, highlighted: !includedOption.highlighted};
            })
        );
    };

    return (
        <div className="equipment-selection">
            <div className="tabs-nav form-group">
                <ul>
                    {tabs.map((tab) => (
                        <li key={tab}>
                            <a
                                className={'tabs-nav-link ' + (tab === currentTab ? 'tabs-nav-link--active' : '')}
                                onClick={() => {
                                    setCurrentTab(tab);
                                }}
                            >
                                {t('car.equipment.tabs.' + tab)}
                            </a>
                        </li>
                    ))}
                </ul>
                <button onClick={reloadOptions}>
                    <i className={`fas fa-sync ${loadingOptions && 'loading'}`} />
                </button>
                <Input
                    onChange={(value) => setOptionFilter(value)}
                    placeholder={t('car.filter_equipment')}
                    value={optionFilter}
                />
            </div>

            <div className="list-checkboxes-wrapper">
                {listOptions.length ? (
                    <ul className="list-checkboxes list-checkboxes--3-cols">
                        {listOptions.map((option) => {
                            const checked = checkedIds.includes(option.id);
                            const highlighted = highlightedIds.includes(option.id);
                            return (
                                <li className={checked ? 'checked' : ''} key={option.id}>
                                    <Checkbox
                                        field={`option-${option.id}`}
                                        label={
                                            option.code
                                                ? '(' +
                                                  option.code +
                                                  ') ' +
                                                  (option.name && option.name !== ''
                                                      ? option.name
                                                      : option.description && option.description)
                                                : option.name !== '' && option.name
                                                ? option.name
                                                : option.description && option.description
                                        }
                                        onChange={() => toggleOption(option.id)}
                                        value={checked}
                                    />{' '}
                                    {checked && (
                                        <button
                                            className={highlighted ? 'highlighted' : ''}
                                            onClick={() => toggleHighlightedOption(option.id)}
                                        >
                                            {highlighted ? (
                                                <i className="fas fa-star" style={{color: '#E6D400'}} />
                                            ) : (
                                                <span className="fa-stack">
                                                    <i className="fas fa-star fa-stack-1x" style={{color: '#FFF'}} />
                                                    <i className="far fa-star fa-stack-1x" style={{color: '#E1EAF0'}} />
                                                </span>
                                            )}
                                        </button>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    t('car.equipment.none_found')
                )}
            </div>
        </div>
    );
};

export default EquipmentSelection;
