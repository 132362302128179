import React from 'react';
import {useTranslation} from 'react-i18next';

const ColorSummary = ({similarCars}) => {
    const {t} = useTranslation();
    const colors = similarCars.reduce((colors, car) => {
        if (colors[car.body_color]) {
            colors[car.body_color]++;
        } else {
            colors[car.body_color] = 1;
        }

        return colors;
    }, {});

    return (
        <ul className={'ps-0'}>
            {Object.entries(colors).map(([color, count]) => (
                <li key={color}>
                    {color}: {count}
                </li>
            ))}
            <li>
                {t('general.total')}: {similarCars.length}
            </li>
        </ul>
    );
};

export default ColorSummary;
