import React from 'react';
import Scrollspy from 'react-scrollspy';

import './TopNav.scss';
import {useAuth} from '../../lib/UseAuth';
import TopNavItem from './TopNavItem';

const TopNav = () => {
    const {user} = useAuth();

    const sections = [
        'general',
        'equipment',
        'sales_pricing',
        'localisation',
        'documents',
        ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION || user.permissions.CAN_VIEW_SUPPLIER_DATA ? ['purchase_data'] : []),
        'emissions',
    ];

    return (
        <nav className="nav-secondary">
            <Scrollspy currentClassName="active" items={sections}>
                {sections.map((section) => (
                    <TopNavItem key={section} title={section} />
                ))}
            </Scrollspy>
        </nav>
    );
};

export default React.memo(TopNav);
