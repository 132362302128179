import React from 'react';

const Radio = ({id, label, name, onChange, value, ...props}) => {
    let {className, ...inputProps} = props;

    return (
        <label className="radio" htmlFor={id + '-' + value}>
            <input
                {...inputProps}
                className={className}
                id={id + '-' + value}
                name={name}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                type="radio"
                value={value}
            />
            {label && <span>{label}</span>}
        </label>
    );
};

export default React.memo(Radio);
