import React, {Fragment, useEffect, useRef} from 'react';
import Enum from '../../../../components/Form/Enum';
import {useTranslation} from 'react-i18next';
import TimePicker from '../../../../components/Form/TimePicker';
import {dateFormat} from '../../../../lib/helpers';

import DocumentDeliveryEnum from '../../../../components/Form/DocumentDeliveryEnum';
import DatePicker from '../../../../components/Form/DatePicker';
import UploadCarDocument from '../../List/Modal/UploadCarDocument';
import {useModal} from '../../../../lib/UseModal';
import Input from '../../../../components/Form/Input';
import Close from '../../../../../assets/svg/close.svg';
import PrintButton from '../PrintButton';

const Registration = ({car, carHasE705, children, customer, disabled, form, ordinal, refresh, supplier}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();

    const registration = form.fields.documents.registration[ordinal];
    const registrationData = form.data.documents.registration[ordinal];

    const initialE705Value = useRef(registrationData && registrationData.e705 ? registrationData.e705.status : null);
    const initialProofOfRegistrationPart1Value = useRef(
        registrationData && registrationData.proof_of_registration_part_1
            ? registrationData.proof_of_registration_part_1.status
            : null
    );
    const isEcrRegistration = (registrationData && registrationData.by === 'ECR') || ordinal === 'second';

    const e705Status = registrationData && registrationData.e705 && registrationData.e705.status;
    useEffect(() => {
        if (initialE705Value.current !== 'ACCEPTED' && e705Status === 'ACCEPTED' && !carHasE705) {
            showModal(<UploadCarDocument car={car} carDocument="E705" refresh={() => {}} />);
        }
    }, [e705Status]);

    const proofOfRegistrationPart1Status =
        registrationData && registrationData.proof_of_registration_part_1 && registrationData.proof_of_registration_part_1.status;
    useEffect(() => {
        if (initialProofOfRegistrationPart1Value.current !== 'ARRIVED' && proofOfRegistrationPart1Status === 'ARRIVED') {
            showModal(<UploadCarDocument car={car} carDocument="proof-of-registration" refresh={refresh} />);
        }
    }, [proofOfRegistrationPart1Status]);

    if (!registrationData) {
        return (
            <tbody className="form-group">
                <tr>
                    <td colSpan={5} style={{paddingTop: ordinal === 'second' ? '60px' : undefined}}>
                        {children}
                    </td>
                </tr>
            </tbody>
        );
    }

    const carIsSoldOrInvoiced = form.data.sale.status === 'INVOICED' || form.data.sale.status === 'SOLD';
    const isBelgianCustomer = customer && customer.address.country_code === 'BE';

    let showProofOfRegistrationFields =
        registration.proof_of_registration_part_1 &&
        (!isEcrRegistration || (registrationData.preregistration && registrationData.preregistration.status === 'PREREGISTERED'));

    if (ordinal === 'second') {
        showProofOfRegistrationFields =
            showProofOfRegistrationFields && registrationData.inspection && registrationData.inspection.status === 'INSPECTED';
    }

    let showE705Fields = registration.e705 && (isEcrRegistration || registrationData.e705.status !== 'UNNECESSARY');
    if (carIsSoldOrInvoiced && isBelgianCustomer) {
        showE705Fields = registration.e705 && true;
    }

    let showProofOfRegistrationDeliveryFields = true;
    // If there's a second registration, don't show e705 and proof of registration fields on first
    if (ordinal === 'first' && form.data.documents.registration.second) {
        showE705Fields = false;
        showProofOfRegistrationDeliveryFields = false;
    }

    let showPreregistration =
        ((registration.preregistration && registration.preregistration.status) || isEcrRegistration) &&
        registrationData.e705 &&
        registrationData.e705.status === 'ACCEPTED';

    let showInspectionStatus =
        registration.inspection &&
        registrationData.preregistration.status === 'PREREGISTERED' &&
        (isEcrRegistration || registrationData.inspection.status !== 'UNNECESSARY');

    if (supplier && supplier.address.country === 'BE' && (registrationData.by === 'ECR' || ordinal === 'second')) {
        showE705Fields = false;
        showPreregistration = false;
        showProofOfRegistrationFields = registration.proof_of_registration_part_1;
        if (ordinal === 'second') {
            showInspectionStatus = true;
        }
    }

    const handleRemoveRegistration = (e) => {
        e.preventDefault();
        form.setData({
            ...form.data,
            documents: {
                ...form.data.documents,
                registration: {...form.data.documents.registration, [ordinal]: undefined},
            },
        });
    };

    return (
        <tbody className="form-group">
            <tr>
                <th colSpan={5}>
                    <h3>
                        {t(`car.documents.${ordinal}_registration`)}{' '}
                        <button onClick={handleRemoveRegistration} type="button">
                            <Close />
                        </button>
                    </h3>
                </th>
            </tr>
            {registration.by && (
                <tr>
                    <th>{t('car.documents.registration_by')}</th>
                    <td>
                        <Enum
                            disabled={disabled || ordinal === 'second'}
                            enumName="registration_by"
                            {...registration.by({
                                onChange: (name, value, draft) => {
                                    if (value === 'ECR') {
                                        if (!draft.documents.registration[ordinal].e705) {
                                            draft.documents.registration[ordinal].e705 = {};
                                        }
                                        draft.documents.registration[ordinal].e705.status = carHasE705 ? 'ACCEPTED' : 'NECESSARY';
                                        draft.documents.registration[ordinal].preregistration.status = carHasE705
                                            ? 'TO_PREREGISTER'
                                            : null;
                                    }
                                },
                            })}
                        />
                    </td>
                    <td colSpan={3} />
                </tr>
            )}
            {showE705Fields && (
                <tr>
                    <th>{t('car.documents.e705')}</th>
                    <td>
                        <Enum
                            disabled={disabled || carHasE705}
                            enumName="e705_status"
                            {...registration.e705.status({
                                onChange: (name, value, draft) => {
                                    if (value === 'ACCEPTED') {
                                        if (!draft.documents.registration[ordinal].preregistration) {
                                            draft.documents.registration[ordinal].preregistration = {};
                                        }
                                        draft.documents.registration[ordinal].preregistration.status = 'TO_PREREGISTER';
                                    }
                                },
                            })}
                        />
                    </td>
                    <td className="small">{registrationData.e705 ? dateFormat(registrationData.e705.changed_on) : ''}</td>
                    <td>
                        {registrationData.e705.delivery && (
                            <DocumentDeliveryEnum disabled={disabled} documentType="e705" {...registration.e705.delivery()} />
                        )}
                    </td>
                    <td className="small">
                        {registrationData.e705 ? dateFormat(registrationData.e705.delivery_changed_on) : registrationData.e705}
                    </td>
                </tr>
            )}
            {showPreregistration && (
                <tr>
                    <th>{t('car.documents.preregistration')}</th>
                    <td>
                        <Enum
                            disabled={disabled}
                            enumName="preregistration_status"
                            {...registration.preregistration.status({
                                onChange: (name, value, draft) => {
                                    if (value === 'PREREGISTERED') {
                                        if (ordinal === 'second') {
                                            if (!draft.documents.registration[ordinal].inspection) {
                                                draft.documents.registration[ordinal].inspection = {date: null, time: null};
                                            }
                                            draft.documents.registration[ordinal].inspection.status = 'REQUIRED';

                                            return;
                                        }
                                        if (!draft.documents.registration[ordinal].proof_of_registration_part_1) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_1 = {};
                                        }
                                        if (!draft.documents.registration[ordinal].proof_of_registration_part_2) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_2 = {};
                                        }
                                        if (
                                            draft.documents.registration[ordinal].proof_of_registration_part_1.status ===
                                            'UNNECESSARY'
                                        ) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_1.status =
                                                'TO_REGISTER';
                                        }
                                        if (
                                            draft.documents.registration[ordinal].proof_of_registration_part_2.status ===
                                            'UNNECESSARY'
                                        ) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_2.status =
                                                'TO_REGISTER';
                                        }
                                    }
                                },
                            })}
                        />
                    </td>
                    <td colSpan={3} />
                </tr>
            )}
            {showInspectionStatus && registration.inspection && (
                <tr>
                    <th>{t('car.documents.inspection')}</th>
                    <td>
                        <Enum
                            disabled={disabled}
                            enumName="inspection_status"
                            {...registration.inspection.status({
                                onChange: (name, value, draft) => {
                                    if (value === 'INSPECTED') {
                                        if (!draft.documents.registration[ordinal].proof_of_registration_part_1) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_1 = {};
                                        }
                                        if (!draft.documents.registration[ordinal].proof_of_registration_part_2) {
                                            draft.documents.registration[ordinal].proof_of_registration_part_2 = {};
                                        }
                                        draft.documents.registration[ordinal].proof_of_registration_part_1.status = 'TO_REGISTER';
                                        draft.documents.registration[ordinal].proof_of_registration_part_2.status = 'TO_REGISTER';
                                    }
                                },
                            })}
                        />
                    </td>
                    <td>
                        <DatePicker disabled={disabled} {...registration.inspection.date()} />
                    </td>
                    <td>
                        <TimePicker {...registration.inspection.time()} />
                    </td>
                    <td className="small">{dateFormat(registrationData.inspection.changed_on)}</td>
                </tr>
            )}
            {showProofOfRegistrationFields && (
                <Fragment>
                    <tr>
                        <th width="200">{t('car.documents.proof_of_registration_part_1')}</th>
                        <td width="220">
                            <Enum
                                disabled={disabled}
                                enumName="proof_of_registration_status"
                                {...registration.proof_of_registration_part_1.status()}
                            />
                        </td>
                        <td className="small" width="220">
                            {dateFormat(registrationData.proof_of_registration_part_1.changed_on)}
                        </td>
                        <td width="220">
                            {showProofOfRegistrationDeliveryFields && registrationData.proof_of_registration_part_1.delivery && (
                                <DocumentDeliveryEnum
                                    disabled={disabled}
                                    documentType="proof_of_registration_part_1"
                                    {...registration.proof_of_registration_part_1.delivery()}
                                />
                            )}
                        </td>
                        <td className="small" width="220">
                            {showProofOfRegistrationDeliveryFields &&
                                dateFormat(registrationData.proof_of_registration_part_1.delivery_changed_on)}
                        </td>
                    </tr>
                    <tr>
                        <th>{t('car.documents.proof_of_registration_part_2')}</th>
                        <td>
                            <Enum
                                disabled={disabled}
                                enumName="proof_of_registration_status"
                                {...registration.proof_of_registration_part_2.status()}
                            />
                        </td>
                        <td className="small">{dateFormat(registrationData.proof_of_registration_part_2.changed_on)}</td>
                        <td>
                            {showProofOfRegistrationDeliveryFields && registrationData.proof_of_registration_part_2.delivery && (
                                <DocumentDeliveryEnum
                                    disabled={disabled}
                                    documentType="proof_of_registration_part_2"
                                    {...registration.proof_of_registration_part_2.delivery()}
                                />
                            )}
                        </td>
                        <td className="small">
                            {showProofOfRegistrationDeliveryFields &&
                                dateFormat(registrationData.proof_of_registration_part_2.delivery_changed_on)}
                        </td>
                    </tr>
                </Fragment>
            )}
            {registration.registration_date &&
                (registrationData.registration_date ||
                    registrationData.registration_country ||
                    (registrationData.proof_of_registration_part_1.status === 'EXPECTED' && !isEcrRegistration) ||
                    registrationData.proof_of_registration_part_1.status === 'ARRIVED') && (
                    <tr>
                        <th>{t('car.documents.registration_date_country')}</th>
                        <td>
                            <DatePicker disabled={disabled} {...registration.registration_date()} />
                        </td>
                        <td>
                            <Enum disabled={disabled} enumName="country" {...registration.registration_country()} />
                        </td>
                        <td colSpan={2} />
                    </tr>
                )}
            {registration.license_plate &&
                (registrationData.license_plate || registrationData.proof_of_registration_part_1.status === 'ARRIVED') && (
                    <tr>
                        <th>{t('car.license_plate')}</th>
                        <td>
                            <Input disabled={disabled} {...registration.license_plate()} />
                        </td>
                        <td>
                            <PrintButton
                                parameters={{car_id: form.data.car_id}}
                                styles={{marginTop: 0}}
                                type="CANCEL_LICENSE_PLATE_STICKER"
                            />
                        </td>
                        <td colSpan={2} />
                    </tr>
                )}
            {registration.cancellation_from && registrationData.proof_of_registration_part_1.status === 'ARRIVED' && (
                <tr>
                    <th>{t('car.documents.registration_cancellation_from')}</th>
                    <td>
                        <DatePicker disabled={disabled} {...registration.cancellation_from()} />
                    </td>
                    <td>
                        <DatePicker
                            disabled={disabled}
                            placeholder={t('car.documents.registration_cancelled_on')}
                            {...registration.cancelled_on({
                                onChange: (name, value, draft) => {
                                    if (!draft.documents.registration[ordinal].notice_of_objection) {
                                        draft.documents.registration[ordinal].notice_of_objection = {};
                                    }
                                    draft.documents.registration[ordinal].notice_of_objection.status = value
                                        ? 'NECESSARY'
                                        : 'UNNECESSARY';
                                },
                            })}
                        />
                    </td>
                    <td />
                    <td />
                </tr>
            )}
            {registration.notice_of_objection && registrationData.cancelled_on && (
                <tr>
                    <th>{t('car.documents.notice_of_objection')}</th>
                    <td>
                        <Enum
                            disabled={disabled}
                            enumName="notice_of_objection_status"
                            {...registration.notice_of_objection.status()}
                        />
                    </td>
                    <td>{dateFormat(registrationData.notice_of_objection.changed_on)}</td>
                    <td />
                    <td />
                </tr>
            )}
        </tbody>
    );
};

export default Registration;
