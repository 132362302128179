/* eslint-disable react/jsx-key */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTable} from 'react-table';
import {dateFormat} from '../../../../lib/helpers';

const MailHistory = ({document}) => {
    const {t} = useTranslation();

    const data = useMemo(() => {
        if (!document || document.mail_history.length < 1) {
            return [];
        }

        const rows = [];
        document.mail_history.forEach((mailHistory) => {
            rows.push({
                mail_text: mailHistory.mail_text,
                send_date: mailHistory.send_date,
                contacts: mailHistory.contacts.to,
                file: mailHistory.file,
            });
        });

        return rows;
    }, [document]);

    const columns = useMemo(
        () => [
            {
                Header: t('general.date'),
                accessor: 'send_date',
                Cell: ({value}) => dateFormat(value),
            },
            {
                Header: 'Doc',
                accessor: 'file',
                Cell: ({value}) => {
                    return (
                        <div className={'ms-1'}>
                            {value ? (
                                <a href={value} rel="noopener noreferrer" target="_blank">
                                    <img alt={'pdf-icon'} src={'/assets/img/pdf-icon.png'} width={18} />
                                </a>
                            ) : (
                                <span>/</span>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: t('api.sold_to'),
                accessor: 'contacts',
                Cell: ({value}) => {
                    if (value.length > 0) {
                        return value.join(', ');
                    }
                    return '/';
                },
            },
            {
                Header: t('general.mail_text'),
                accessor: 'mail_text',
                Cell: ({value}) => <p className="render-line-breaks">{value}</p>,
            },
        ],
        [document]
    );

    const {getTableBodyProps, getTableProps, headerGroups, prepareRow, rows} = useTable({data, columns});

    if (document.mail_history.length < 1) {
        return null;
    }

    return (
        <section className="">
            <h3>{t('general.mail_history')}</h3>
            <div className="scrollableMailHistory">
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps({className: column.id === 'amount' ? 'text-end' : undefined})}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps({
                                                className: cell.column.id === 'amount' ? 'text-end' : undefined,
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default MailHistory;
