import React, {useEffect, useState} from 'react';
import Checkbox from '../../../../components/Form/Checkbox';
import Money from '../../../../components/Form/Money';
import {useTranslation} from 'react-i18next';
import {getObjectValueAtPath} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';

const WebsitePriceLine = ({disabled, form, hidden, line}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const [price, setPrice] = useState();
    const [listPrice, setListPrice] = useState();

    useEffect(() => {
        setPrice(getObjectValueAtPath(form.website_pricing, line).price().value);
        setListPrice(getObjectValueAtPath(form.website_pricing, line).catalog_price().value);
    }, [form]);

    const formObject = getObjectValueAtPath(form.website_pricing, line);

    const disableCheckboxes = !user.permissions.CAN_PUBLISH_CARS;

    return (
        <tr style={{minHeight: 46, height: 46}}>
            <th>{t(`car.sales_pricing.${line}`)}</th>
            <td>
                {!hidden ? (
                    <Money
                        disabled={disabled}
                        onBlur={() => {
                            if (
                                form.website_pricing.PRIV.price().value > 0 &&
                                form.website_pricing.PRIV.price().value < form.website_pricing.BE.price().value
                            ) {
                                alert(t('validation.private_person_price_cant_be_lower_than_country_pricing_be'));
                            }
                        }}
                        {...formObject.price({
                            onChange: (name, value, draft) => {
                                const catalogPrice = draft.sales_pricing.website_pricing[line].catalog_price;
                                const discount = parseFloat(((catalogPrice - value) / catalogPrice) * 100).toFixed(1);
                                draft.sales_pricing.website_pricing[line].discount = discount >= 0 ? discount : 0;

                                if (name === 'sales_pricing.website_pricing.DE.price') {
                                    draft.sales_pricing.eln = value;
                                }
                            },
                        })}
                        setPrice={setPrice}
                    />
                ) : null}
            </td>
            <td className="text-center">
                {!hidden ? <Checkbox disabled={disableCheckboxes} {...formObject.show_on_website()} /> : null}
            </td>
            <td className="text-center">
                {!hidden ? <Checkbox disabled={disableCheckboxes} {...formObject.is_deal()} /> : null}
            </td>
            <td>
                {!hidden ? (
                    <Money
                        disabled={disabled}
                        {...formObject.catalog_price({
                            onChange: (name, value, draft) => {
                                const price = draft.sales_pricing.website_pricing[line].price;
                                const discount = parseFloat(((value - price) / value) * 100).toFixed(1);
                                draft.sales_pricing.website_pricing[line].discount = discount >= 0 ? discount : 0;
                                if (line === 'BE' || line === 'PRIV') {
                                    const toChange = line === 'BE' ? 'PRIV' : 'BE';
                                    draft.sales_pricing.website_pricing[toChange].catalog_price = value;
                                }
                            },
                        })}
                        setPrice={setListPrice}
                    />
                ) : null}
            </td>
            <td>
                {!hidden
                    ? ((listPrice - price) / listPrice) * 100 >= 0 && (((listPrice - price) / listPrice) * 100).toFixed(1) + '%'
                    : null}
            </td>
            <td />
        </tr>
    );
};

export default WebsitePriceLine;
