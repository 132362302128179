import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import PurchaseOrderForm from './PurchaseOrderForm';
import Spinner from '../../../../components/Spinner/Spinner';

const LoadUpdate = () => {
    const {purchaseOrderId} = useParams();
    let navigate = useNavigate();

    const [cars, setCars] = useState({});
    const [customer, setCustomer] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState(null);

    useEffect(() => {
        authenticatedHttp()
            .get('/purchase-orders/' + purchaseOrderId)
            .then((response) => {
                const purchaseOrder = response.data.data;

                purchaseOrder.lines = purchaseOrder.lines.filter((line) => line.relisted_for_sale_on === null);

                const canEdit = purchaseOrder.lines
                    .filter((line) => line.type === 'CAR')
                    .some((line) => line.car.sale.status === 'SOLD');

                if (!canEdit) {
                    navigate('/bestelbonnen/' + purchaseOrderId);
                    return;
                }
                setPurchaseOrder(purchaseOrder);
            })
            .catch(() => {
                navigate('/bestelbonnen');
            });
    }, []);

    useEffect(() => {
        if (!purchaseOrder) {
            return;
        }

        authenticatedHttp()
            .get(`/customers/${purchaseOrder.customer.data.id}?include=contacts`)
            .then((response) => {
                let customerTL = response.data.data;
                customerTL.contacts = customerTL.contacts.data;
                setCustomer(customerTL);
            });

        authenticatedHttp()
            .post('/cars/search', {
                car_id: purchaseOrder.lines.filter((line) => line.type === 'CAR').map((line) => line.car.car_id),
            })
            .then((response) => {
                const newState = response.data.data.reduce((map, car) => {
                    map[car.car_id] = car;
                    return map;
                }, {});
                setCars(newState);
            });
    }, [purchaseOrder]);

    if (!purchaseOrder || Object.keys(cars).length < purchaseOrder.lines.filter((line) => line.type === 'CAR').length) {
        return <Spinner />;
    }

    const lines = purchaseOrder.lines.map((line) => ({
        car_id: line.car ? line.car.car_id : null,
        type: line.type,
        description: line.description,
        price: line.price,
        required_advance: line.required_advance,
        relisted_for_sale_on: line.relisted_for_sale_on,
    }));

    let defaultCarPricing = {};
    purchaseOrder.lines
        .filter((line) => line.type === 'CAR')
        .forEach((line) => {
            defaultCarPricing[line.car.car_id] = line.base_pricing;
        });

    return (
        <PurchaseOrderForm
            cars={cars}
            customer={[customer, setCustomer]}
            defaultCarPricing={defaultCarPricing}
            defaultFormValues={{
                channel: purchaseOrder.channel,
                date: purchaseOrder.date,
                language: purchaseOrder.language,
                mailText: purchaseOrder.mail_text,
                noAdvanceRequired: purchaseOrder.no_advance_required,
                selectedContacts: purchaseOrder.contacts.map((contact) => contact.id),
                userId: purchaseOrder.user_id,
                subsidiary: purchaseOrder.subsidiary,
                payments: purchaseOrder.payments,
            }}
            editMode={parseInt(purchaseOrderId)}
            lines={lines}
            submitUrl={`/purchase-orders/${purchaseOrder.id}`}
        />
    );
};

export default LoadUpdate;
