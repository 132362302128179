import React, {useEffect, useReducer} from 'react';
import lscache from 'lscache';
import {WidgetReducer} from './WidgetReducer';

const WidgetContext = React.createContext();

const WidgetProvider = ({children}) => {
    const [widgetLines, dispatch] = useReducer(WidgetReducer, {}, (initialState) => {
        return lscache.get('widget_lines') || initialState;
    });

    useEffect(() => {
        if (Object.keys(widgetLines).length > 0) {
            lscache.set('widget_lines', widgetLines);
        } else {
            lscache.remove('widget_lines');
        }
    }, [widgetLines]);

    return <WidgetContext.Provider value={[widgetLines, dispatch]}>{children}</WidgetContext.Provider>;
};

const useWidget = () => React.useContext(WidgetContext);

export {WidgetProvider, useWidget};
