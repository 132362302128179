import React, {useEffect, useMemo, useState} from 'react';
import lscache from 'lscache';

import availableColumns from '../models/car_search_result_columns.json';
import {useAuth} from './UseAuth';

const LS_KEY = 'search_results_selected_columns';

const SearchColumnContext = React.createContext();

export const SearchColumnProvider = (props) => {
    const {user} = useAuth();

    const [storedColumns, setStoredColumns] = useState(() => {
        return lscache.get(LS_KEY) || [];
    });

    useEffect(() => {
        lscache.set(LS_KEY, storedColumns);
    }, [storedColumns]);

    const selectedColumns = useMemo(() => {
        const selectedColumns = [];

        storedColumns.forEach((selectedCol) => {
            const availableColumn = availableColumns.find(({col}) => col === selectedCol);
            // It's possible a stored column was removed and thus needs to be filtered out
            if (!availableColumn) {
                return;
            }

            // Filter out columns the user can't view
            if (
                ['naked_purchase_price', 'purchase_price', 'supplied_by', 'internal_order_code', 'margin'].includes(
                    selectedCol
                ) &&
                !user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ) {
                return;
            }

            selectedColumns.push(availableColumn);
        });

        return selectedColumns;
    });

    const addColumn = (column, index) => {
        // filter out the column first to avoid duplicats if we're reordering
        let columnsToSelect = storedColumns.filter((storedColumn) => storedColumn !== column);
        columnsToSelect.splice(index, 0, column);
        setStoredColumns([...columnsToSelect]);
    };
    const removeColumn = (column) => {
        setStoredColumns(storedColumns.filter((storedColumn) => storedColumn !== column));
    };

    return <SearchColumnContext.Provider value={{availableColumns, selectedColumns, addColumn, removeColumn}} {...props} />;
};

export const useSearchResultColumns = () => React.useContext(SearchColumnContext);
