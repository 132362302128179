import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import Spinner from '../../../../components/Spinner/Spinner';
import {dateFormat, moneyFormat, currencyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import SummaryTable from '../Components/Summary/SummaryTable';

const SummaryE705 = ({car, carSupplier, form, refresh, registrationOrdinal, showE705Doc}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const invoice =
        car.purchase_documents &&
        car.purchase_documents
            .filter(function (document) {
                return document.type === 'invoice';
            })
            .pop();

    const purchaseInvoices = car.purchase_documents.filter((file) => file.type === 'invoice');
    const proofOfRegistrationFiles = car.files.filter((file) => file.name.includes('Proof-of-registration - '));

    const purchasePriceString = t('car.purchase_pricing.purchase_price') + ' (' + currencyFormat(car.purchase_pricing.costs.purchase_price.currency).replace(' ', '') + ')';

    const data = {
        'car.sales_pricing.supplier_country': carSupplier.address.country,
        'api.vin': car.vin,
        'search.make': car.type.model.make,
        'car.title_model': car.type.model.name,
        'car.type.fuel': t('enum.fuel.' + car.type.fuel),
        'PK / kW': car.type.hp + ' ' + t('car.type.hp') + ' / ' + car.type.kw + ' ' + t('car.type.kw'),
        'car.type.cc': car.type.cc + 'cc',
        'car.first_used.on': car.documents.registration.first
            ? dateFormat(car.documents.registration.first.registration_date)
            : '-',
        'car.mileage': car.mileage,
        'car.supplier_vat': carSupplier.vat_number,
        'car.purchase_pricing.supplier_data': carSupplier.name,
        'search.invoice_number': invoice ? invoice.number : '',
        'general.invoice_date': invoice ? dateFormat(invoice.date) : '',
    };

    data[purchasePriceString] = car.purchase_pricing.costs.purchase_price.amount.toFixed(2).toString().replace('.', ',');
    data['enum.purchase_document_type.INVOICE'] = (<>
        {purchaseInvoices.length > 0
            ? purchaseInvoices.map((purchaseDocument) => {
                return (
                    <a href={`${purchaseDocument.documentFilePath}`} rel="noopener noreferrer" target="_blank">
                        Download
                    </a>
                );
            })
            : '-'}
    </>);
    data['car.documents.proof_of_registration'] = (
        <>
            {proofOfRegistrationFiles.length > 0
                ? proofOfRegistrationFiles.map((file) => {
                    if (file.url) {
                        return (
                            <>
                                <a href={`${file.url}`} rel="noopener noreferrer" target="_blank">
                                    Download
                                </a>
                            </>
                        );
                    }
                })
                : '-'}
        </>
    );

    const [loadingMessage, setLoadingMessage] = useState(null);
    const noRegistrationE705 = !form.data.documents.registration.first;

    const requestE705 = () => {
        setLoadingMessage('Saving...');

        const data = noRegistrationE705
            ? {
                  documents: {
                      no_registration: {
                          e705: {
                              status: 'REQUESTED',
                          },
                      },
                  },
                  update_mode: 'ONLY_THIS',
              }
            : {
                  documents: {
                      registration: {
                          [registrationOrdinal]: {
                              e705: {
                                  status: 'REQUESTED',
                              },
                          },
                      },
                  },
                  update_mode: 'ONLY_THIS',
              };

        authenticatedHttp()
            .patch('/cars/' + car.car_id, data)
            .then(() => {
                refresh();
                closeModal();
            })
            .catch((response) => {
                setLoadingMessage(null);
                alert(response.message);
            });
    };

    return (
        <Fragment>
            {loadingMessage && <Spinner message={loadingMessage} />}
            <form>
                <Modal title={t('general.summary.E705')}>
                    <Modal.Body>
                        <SummaryTable data={data} />
                        <hr />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="blue" onClick={() => closeModal()}>
                            {t('general.close')}
                        </Button>
                        {showE705Doc && (
                            <Button color="blue" onClick={() => requestE705()}>
                                {t('enum.e705_status.REQUESTED')}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </form>
        </Fragment>
    );
};

export default SummaryE705;
