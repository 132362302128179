import React, {useRef, useState} from 'react';

const ModalContext = React.createContext();

export const ModalProvider = (props) => {
    const [modal, setModal] = useState(null);
    const onCloseCallback = useRef();

    const showModal = (modal, cb) => {
        setModal(modal);

        onCloseCallback.current = cb;
    };

    const closeModal = () => {
        setModal(null);

        if (onCloseCallback.current) {
            onCloseCallback.current();
        }
    };

    return <ModalContext.Provider value={{modal, closeModal, showModal}} {...props} />;
};

export const useModal = () => React.useContext(ModalContext);
