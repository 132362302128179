import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';

import SingleListCar from '../../SingleListCar';
import Checkbox from '../../../../components/Form/Checkbox';

const ListRow = ({creditNote, selectedCreditNoteIds, setSelectedCreditNoteIds, users}) => {
    const {t} = useTranslation();
    let navigate = useNavigate();

    const handleRowSelect = (id) => {
        if (selectedCreditNoteIds.includes(id)) {
            setSelectedCreditNoteIds((prevState) => prevState.filter((option) => option !== id));
        } else {
            setSelectedCreditNoteIds((prevState) => [...prevState, id]);
        }
    };

    return (
        <div
            className="grid-table-row clickable"
            key={creditNote.id}
            onClick={(e) => {
                e.stopPropagation();
                navigate('/creditnotas/' + creditNote.id);
            }}
        >
            <div className="grid-table-cell checkbox">
                <Checkbox
                    field={`creditNote-${creditNote.id}`}
                    onChange={() => {
                        handleRowSelect(creditNote.id);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    value={selectedCreditNoteIds.includes(creditNote.id)}
                />
            </div>
            <div className="grid-table-cell">
                <span>{creditNote.credit_note_number}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.subsidiary.' + creditNote.subsidiary)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.document_status.' + creditNote.status)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{dateFormat(creditNote.date)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{creditNote.invoice_number}</span>
            </div>
            <div className="grid-table-cell small">
                <span>{creditNote.customer.data.label}</span>
            </div>
            <div className="grid-table-cell small">
                <span>
                    {creditNote.created_by
                        ? users.find((u) => u.id === creditNote.created_by).full_name
                        : t('general.employee_not_found')}
                </span>
            </div>
            <div className="grid-table-cell d-flex flex-column small similar-cars">
                {creditNote.lines.map((line) => (
                    <SingleListCar
                        key={line.id}
                        line={line}
                        priceFormat={(price) => '- ' + moneyFormat(price)}
                        selectable={false}
                    />
                ))}
            </div>
            <div className="grid-table-cell numerical">
                <strong className={'me-3'}>- {moneyFormat(creditNote.total_incl_vat)}</strong>
            </div>
        </div>
    );
};

export default React.memo(ListRow);
