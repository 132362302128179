import React from 'react';
import {useTranslation} from 'react-i18next';
import {extractCustomerIdFromLocalStorage} from '../../../../lib/helpers';

import InvoiceCustomerSelect from './InvoiceCustomerSelect';

const InvoiceCustomer = ({cars, control, customer, defaultFormValues, initialCustomerId, lines, remove, setValue, watch}) => {
    const {t} = useTranslation();
    const carsInInvoice = watch('lines')
        .filter((line) => line.type === 'CAR')
        .map((line) => cars[line.car_id]);

    const soldCarsInInvoice = carsInInvoice.filter((car) => car.sale.status === 'SOLD');
    const soldToCustomers = [...new Set(soldCarsInInvoice.map((car) => car.sold_to.data.id))];

    if (soldToCustomers.length > 1) {
        return (
            <section>
                <div className="alert alert-danger">
                    <p>{t('validation.different_customers')}</p>
                    <ul>
                        {soldCarsInInvoice.map((car) => (
                            <li key={car.car_id}>
                                {car.car_id}: {car.sold_to.data.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        );
    }

    const customerId = (() => {
        if (initialCustomerId) {
            return initialCustomerId;
        }

        if (soldToCustomers.length === 1) {
            return soldToCustomers[0];
        }

        return extractCustomerIdFromLocalStorage(watch('lines'));
    })();

    return (
        <InvoiceCustomerSelect
            cars={carsInInvoice}
            control={control}
            customer={customer}
            customerId={customerId}
            defaultFormValues={defaultFormValues}
            lines={lines}
            remove={remove}
            setValue={setValue}
            watch={watch}
        />
    );
};

export default InvoiceCustomer;
