import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import translation from 'zod-i18n-map/locales/nl/zod.json';

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: 'nl',
        fallbackLng: 'en',
        backend: {
            loadPath: process.env.API_URL + '/translations/{{lng}}',
        },
    });
i18n.addResourceBundle('en', 'zod', translation);

export default i18n;
