// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.purchase-order-list section {
    overflow: auto;
  }
  main.purchase-order-list .grid-table {
    min-width: 1500px;
  }
  main.purchase-order-list .grid-table .grid-table-row {
      grid-template-columns: 40px 110px 110px 90px 6vw 12vw 8vw 22vw 1fr;
    }
  main.purchase-order-list .grid-table .grid-table-row:first-of-type {
        grid-template-columns: 40px 110px 110px 90px 6vw 12vw 8vw 22vw 1fr;
      }
  main.purchase-order-list .content section {
    margin-top: 25px;
  }
  main.purchase-order-list .similar-cars .car {
      height: 14px;
      margin-top: 4px;
      margin-bottom: 4px;
      padding-left: 20px;
    }
  main.purchase-order-list .similar-cars .car .checkbox {
        margin-top: 4px;
        margin-left: -25px;
        width: 25px;
      }
`, "",{"version":3,"sources":["webpack://./src/js/views/Documents/PurchaseOrders/List/List.css"],"names":[],"mappings":"AACE;IACE,cAAc;EAChB;EACA;IACE,iBAAiB;EAOnB;EANE;MACE,kEAAkE;IAIpE;EAHE;QACE,kEAAkE;MACpE;EAGJ;IACE,gBAAgB;EAClB;EAEE;MACE,YAAY;MACZ,eAAe;MACf,kBAAkB;MAClB,kBAAkB;IAMpB;EALE;QACE,eAAe;QACf,kBAAkB;QAClB,WAAW;MACb","sourcesContent":["main.purchase-order-list {\n  section {\n    overflow: auto;\n  }\n  .grid-table {\n    min-width: 1500px;\n    .grid-table-row {\n      grid-template-columns: 40px 110px 110px 90px 6vw 12vw 8vw 22vw 1fr;\n      &:first-of-type {\n        grid-template-columns: 40px 110px 110px 90px 6vw 12vw 8vw 22vw 1fr;\n      }\n    }\n  }\n  .content section {\n    margin-top: 25px;\n  }\n  .similar-cars {\n    .car {\n      height: 14px;\n      margin-top: 4px;\n      margin-bottom: 4px;\n      padding-left: 20px;\n      .checkbox {\n        margin-top: 4px;\n        margin-left: -25px;\n        width: 25px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
