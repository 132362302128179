import classnames from 'classnames';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Printer from '../../../../assets/svg/icon-print.svg';
import Button from '../../../components/Button/Button';

import Autocomplete from '../../../components/Form/Autocomplete';
import DatePicker from '../../../components/Form/DatePicker';
import Enum from '../../../components/Form/Enum';
import FormGroup from '../../../components/Form/FormGroup';
import Input from '../../../components/Form/Input';
import Layout from '../../../components/Layout/Layout';
import Spinner from '../../../components/Spinner/Spinner';
import TopNav from '../../../components/TopNav/TopNav';
import {useAuth} from '../../../lib/UseAuth';

import {authenticatedHttp, downloadFile} from '../../../lib/zaza-client';
import ContentHeader from './ContentHeader';
import './Detail.scss';
import PrintButton from './PrintButton';
import Documents from './Section/Documents';
import Emissions from './Section/Emissions';
import Equipment from './Section/Equipment';
import Localisation from './Section/Localisation';
import PurchasePricing from './Section/PurchasePricing';
import SalesPricing from './Section/SalesPricing';
import Sidebar from './Sidebar';
import StandardModel from './StandardModel';
import TypeSelection from './TypeSelection';
import {useLocation} from 'react-router-dom';

const Detail = ({
    calculatePricing,
    car,
    contentHeader,
    form,
    header,
    isCreate = false,
    isDeleted = null,
    loadingMessage = null,
    pricingTotals,
    refresh = () => {},
    sections = [],
    setLoadingMessage,
    setShowModal = false,
    setVinCheck,
    showWidget = false,
    sidebar,
    vinCheck,
}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const location = useLocation();
    const selectedSection = location.hash ? location.hash.substring(1) : undefined;
    const firstRender = useRef(true);
    const [selectedMake, setSelectedMake] = useState(
        form.data.type ? form.data.type.model.make : form.data.standard_model.model.make
    );
    const hasChanges = Object.entries(form.changes).length !== 0 && form.changes.constructor === Object;

    useEffect(() => {
        if (firstRender.current && location) {
            const el = document.getElementById(selectedSection);
            if (el) {
                el.scrollIntoView();
                firstRender.current = false;
            }
        }
    }, [location]);

    useEffect(() => {
        const handleUnload = (e) => {
            if (!hasChanges) {
                return true;
            }

            const message = t('general.leave_warning');

            (e || window.event).returnValue = message;

            return message;
        };
        window.addEventListener('beforeunload', handleUnload);

        return () => window.removeEventListener('beforeunload', handleUnload);
    }, []);

    useEffect(() => {
        if (car && form.data.vin.length && form.data.vin.length === 17) {
            authenticatedHttp()
                .post('/cars/vin', {
                    vin: form.data.vin,
                    carId: car.car_id,
                    subsidiary: car.subsidiary,
                })
                .then((response) => {
                    if (response.data.vinDoubles > 0) {
                        setVinCheck(true);
                    } else {
                        setVinCheck(false);
                    }
                });
        }
    }, [form.data.vin]);

    return (
        <Fragment>
            {/* <Prompt
                message={(newLocation) => (location.pathname === newLocation.pathname ? true : t('general.leave_warning'))}
                when={hasChanges}
            /> */}
            <Layout
                className="car-detail"
                header={() => (
                    <Fragment>
                        <div className="header-row">{header()}</div>
                        <div className="header-row">
                            <TopNav sections={sections} />
                        </div>
                    </Fragment>
                )}
                showWidget={showWidget}
            >
                {loadingMessage && <Spinner message={loadingMessage} />}
                <form className="form">
                    {form.errors.length > 0 && (
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="alert alert-danger">{t('validation.errors_warning')}</div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-lg-8">
                            {contentHeader ? <ContentHeader>{contentHeader()}</ContentHeader> : null}
                            <section>
                                <a className="anchor" id="general" />
                                {form.data.type ? (
                                    <TypeSelection
                                        disabled={isDeleted}
                                        form={form}
                                        selectedMake={selectedMake}
                                        setSelectedMake={setSelectedMake}
                                    />
                                ) : (
                                    <StandardModel disabled={isDeleted} form={form} />
                                )}
                                <fieldset>
                                    <legend>{t('car.title_car')}</legend>
                                    <div className="row">
                                        {form.fields.vin && (
                                            <FormGroup field="vin">
                                                <div className="d-flex flex-row" id="vin">
                                                    <Input
                                                        {...form.fields.vin()}
                                                        className={classnames({
                                                            'has-error': form.data.vin.length > 17,
                                                        })}
                                                        disabled={isDeleted}
                                                    />
                                                    {(vinCheck || form.data.vin.length !== 17) && (
                                                        <div className={'ms-2'} id="tooltip">
                                                            <i
                                                                className="fas fa-exclamation-circle pt-3 pl-2"
                                                                style={{color: 'red'}}
                                                            />
                                                            {vinCheck && <p>{t('general.vin_exists')}</p>}
                                                            {form.data.vin.length < 17 && <p>{t('general.vin_short')}</p>}
                                                            {form.data.vin.length > 17 && (
                                                                <p>{t('validation.too_long', {number: 17})}</p>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        )}
                                        {form.fields.emissions_standard && (
                                            <FormGroup field="type.emissions_standard">
                                                <Enum
                                                    disabled={isDeleted}
                                                    enumName="emissions_standard"
                                                    {...form.fields.emissions_standard()}
                                                />
                                            </FormGroup>
                                        )}
                                        {form.fields.mileage && (
                                            <FormGroup field="mileage">
                                                <Input disabled={isDeleted} suffix="km" {...form.fields.mileage()} />
                                            </FormGroup>
                                        )}
                                        {form.fields.warranty.status && (
                                            <FormGroup field="warranty.status">
                                                <Enum
                                                    disabled={isDeleted}
                                                    enumName="warranty_status"
                                                    {...form.fields.warranty.status()}
                                                />
                                            </FormGroup>
                                        )}
                                        {form.fields.warranty.start && (
                                            <FormGroup field="warranty.start">
                                                <DatePicker disabled={isDeleted} {...form.fields.warranty.start()} />
                                            </FormGroup>
                                        )}
                                        {form.fields.warranty.period && (
                                            <FormGroup field="warranty.period">
                                                <Input disabled={isDeleted} suffix="jaar" {...form.fields.warranty.period()} />
                                            </FormGroup>
                                        )}
                                        {form.fields.interior_type && (
                                            <FormGroup field="interior_type">
                                                <Enum
                                                    disabled={isDeleted}
                                                    enumName="interior_type"
                                                    {...form.fields.interior_type()}
                                                />
                                            </FormGroup>
                                        )}
                                        {form.fields.interior_color && (
                                            <FormGroup field="interior_color">
                                                <Autocomplete
                                                    {...form.fields.interior_color()}
                                                    autocompleteURI="/autocomplete?entity=color&property=description"
                                                    disabled={isDeleted}
                                                />
                                            </FormGroup>
                                        )}
                                        {form.fields.body_color && (
                                            <FormGroup field="body_color">
                                                <Autocomplete
                                                    {...form.fields.body_color()}
                                                    autocompleteURI="/autocomplete?entity=color&property=description"
                                                    disabled={isDeleted}
                                                />
                                            </FormGroup>
                                        )}
                                        {form.fields.roof_color && (
                                            <FormGroup field="roof_color">
                                                <Autocomplete
                                                    {...form.fields.roof_color()}
                                                    allowClear
                                                    autocompleteURI="/autocomplete?entity=color&property=description"
                                                    disabled={isDeleted}
                                                />
                                            </FormGroup>
                                        )}
                                    </div>
                                </fieldset>
                                <div className="mt-4">
                                    {!!form.data.car_id && (
                                        <Fragment>
                                            <PrintButton parameters={{car_id: form.data.car_id}} type="CAR_STICKER" />
                                            <PrintButton parameters={{car_id: form.data.car_id}} type="DOCUMENT_STICKER" />
                                        </Fragment>
                                    )}
                                    {form.data.sale.sold_to && (
                                        <Fragment>
                                            <PrintButton
                                                parameters={{customer_id: form.data.sale.sold_to}}
                                                type="ADDRESS_STICKER"
                                            />
                                            <Button
                                                icon={<Printer />}
                                                onClick={() => {
                                                    setLoadingMessage(t('general.generating_document'));

                                                    downloadFile(
                                                        '/download/delivery-note',
                                                        {
                                                            car_id: form.data.car_id,
                                                            customer_id: form.data.sale.sold_to,
                                                        },
                                                        t('general.transport_contract').toLowerCase() + '_' + form.data.car_id
                                                    ).then(() => {
                                                        setLoadingMessage(null);
                                                    });
                                                }}
                                                shadow
                                                small
                                            >
                                                {t('general.download_transport_note')}
                                            </Button>
                                        </Fragment>
                                    )}
                                </div>
                            </section>
                        </div>
                        {sidebar ? <Sidebar>{sidebar()}</Sidebar> : null}
                    </div>
                    <Equipment
                        disabled={isDeleted}
                        form={form}
                        isCreate={isCreate}
                        make={selectedMake}
                        model={form.data.type ? form.data.type.model.id : null}
                        type={form.data.type ? form.data.type.id : null}
                    />
                    <SalesPricing calculatePricing={calculatePricing} disabled={isDeleted} form={form} />
                    <Localisation
                        car={car}
                        disabled={isDeleted}
                        form={form}
                        setLoadingMessage={setLoadingMessage}
                        setShowModal={setShowModal}
                    />
                    <Documents car={car} disabled={isDeleted} form={form} refresh={refresh} />
                    {user.permissions.CAN_VIEW_SUPPLIER_DATA || user.permissions.CAN_VIEW_PURCHASING_INFORMATION ? (
                        <PurchasePricing
                            car={car}
                            disabled={isDeleted}
                            form={form}
                            pricingTotals={pricingTotals}
                            refresh={refresh}
                            setLoadingMessage={setLoadingMessage}
                        />
                    ) : null}
                    <Emissions disabled={isDeleted} form={form} />
                </form>
            </Layout>
        </Fragment>
    );
};

export default Detail;
