import produce from 'immer';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../components/Button/Button';

const StandardModel = ({disabled, form}) => {
    const {t} = useTranslation();
    const standardModel = form.data.standard_model;

    const createType = () => {
        form.setData(
            produce((draft) => {
                draft.type = {};
                draft.type.model = {};
                draft.type.model.id = null;
                draft.type.model.make = standardModel.model.make;
                draft.type.name = standardModel.name;
                draft.type.fuel = standardModel.fuel;
                draft.type.cc = standardModel.cc;
                draft.type.hp = standardModel.hp;
                draft.type.kw = standardModel.kw;
                draft.type.gears = standardModel.gears;
                draft.type.cylinders = standardModel.cylinders;
            })
        );
    };

    return (
        <fieldset>
            <legend>{t('car.title_type')}</legend>
            <p className="alert alert-warning">{t('car.no_type_warning')}</p>
            <div className="row">
                <div className="col-2 font-bold">{t('car.type.model.make')}</div>
                <div className="col-4">{standardModel.model.make}</div>
                <div className="col-2 font-bold">{t('car.type.model.name')}</div>
                <div className="col-4">{standardModel.model.name}</div>
            </div>
            <div className="row">
                <div className="col-2 font-bold">{t('car.type.name')}</div>
                <div className="col-10">{standardModel.name}</div>
            </div>
            <div className="row">
                <div className="col-2 font-bold">{t('car.type.fuel')}</div>
                <div className="col-4">{t(`enum.fuel.${standardModel.fuel}`)}</div>
                <div className="col-2 font-bold">{t('car.type.gears')}</div>
                <div className="col-4">{t(`enum.gears.${standardModel.gears}`)}</div>
            </div>
            <div className="row">
                <div className="col-2 font-bold">{t('car.type.cc')}</div>
                <div className="col-4">{standardModel.cc} CC</div>
                <div className="col-2 font-bold">{t('car.type.hp')}</div>
                <div className="col-4">{standardModel.hp} HP</div>
            </div>
            <div className="row">
                <div className="col-2 font-bold">{t('car.type.kw')}</div>
                <div className="col-4">{standardModel.kw} kW</div>
                <div className="col-2 font-bold">{t('car.type.cylinders')}</div>
                <div className="col-4">{t(`enum.cylinders.${standardModel.cylinders}`)}</div>
            </div>
            <Button disabled={disabled} onClick={createType}>
                {t('car.create_type')}
            </Button>
        </fieldset>
    );
};

export default StandardModel;
