import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../lib/UseAuth';
import {authenticatedHttp} from '../../../lib/zaza-client';

import Button from '../../../components/Button/Button';
import Filters from './Filters';
import IconSave from '../../../../assets/svg/icon-save.svg';

const Header = ({orderBy, pagination, searchParams, setLoadingMessage, totals}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {user} = useAuth();

    const downloadExport = () => {
        setLoadingMessage(t('general.loading'));
        authenticatedHttp()
            .post('/cars/export', searchParams)
            .then(() => {
                setLoadingMessage(null);
                alert(t('export.requested'));
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.message);
            });
    };

    return (
        <div className="w-100">
            <div className="d-flex justify-content-between">
                <div className={'d-flex'}>
                    <a
                        className={'btn-arrow btn-arrow-left ' + (pagination.page > 1 ? '' : 'btn-arrow-disabled')}
                        onClick={() => {
                            const newPage = pagination.page - 1;
                            navigate(
                                `/autos/lijst?query=${JSON.stringify(searchParams)}&order_by=${orderBy.key},${
                                    orderBy.direction
                                }` + `&page=${newPage}&per_page=${pagination.perPage}`
                            );
                        }}
                    />

                    <div>
                        <h1 className="mb-0">
                            {t('nav.search_results')} ({totals.cars})
                        </h1>

                        <p className="mb-0 small">
                            {t('general.page')} {pagination.page} {t('general.of')} {totals.pages}
                        </p>
                    </div>

                    <a
                        className={'btn-arrow btn-arrow-right ' + (pagination.page < totals.pages ? '' : 'btn-arrow-disabled')}
                        onClick={() => {
                            const newPage = pagination.page + 1;
                            navigate(
                                `/autos/lijst?query=${JSON.stringify(searchParams)}&order_by=${orderBy.key},${
                                    orderBy.direction
                                }` + `&page=${newPage}&per_page=${pagination.perPage}`
                            );
                        }}
                    />
                </div>
                <div>
                    {Object.entries(searchParams).length > 0 && user.permissions.CAN_EXPORT_SELECTION_LIST && (
                        <div className="ms-auto">
                            <Button equalHeight icon={<IconSave />} onClick={downloadExport}>
                                {t('nav.export')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            <Filters pagination={pagination} searchParams={searchParams} />
        </div>
    );
};

export default Header;
