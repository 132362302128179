import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from './helpers';

const useMetaTransform = (eventLog, customers, users) => {
    const {t} = useTranslation();
    const meta = {...eventLog.meta};

    const moneyFormatWithCurrency = Object.keys(meta).includes('currency')
        ? (value) => moneyFormat(value, meta.currency)
        : moneyFormat;

    const keyTransformers = {
        price: moneyFormatWithCurrency,
        old_price: moneyFormat,
        new_price: moneyFormat,
        amount: moneyFormatWithCurrency,
        payment: moneyFormatWithCurrency,
        date: dateFormat,
    };

    Object.keys(meta).forEach((key) => {
        if (key in keyTransformers) {
            meta[key] = keyTransformers[key](meta[key]);
        }
    });

    if (eventLog.event_name === 'PurchaseDocumentWasRegistered') {
        meta.type = t('enum.purchase_document_type.' + meta.type);
    }

    if (eventLog.event_name === 'TransportFromSupplierOrganizerChanged') {
        meta.from = t('enum.registration_by.' + meta.from);
        meta.to = t('enum.registration_by.' + meta.to);
    }

    if (['PaymentWasRegistered', 'PaymentWasUndone'].includes(eventLog.event_name)) {
        meta.type = t('enum.payment_type.' + meta.type);
    }

    if (eventLog.meta.new_website_status) {
        meta['new_website_status'] = t('enum.website_status.' + eventLog.meta.new_website_status).toLowerCase();
    }

    if (eventLog.meta.new_website_status) {
        meta['old_website_status'] = t('enum.website_status.' + eventLog.meta.old_website_status).toLowerCase();
    }

    if (eventLog.meta.customer_id && customers.length > 0) {
        const customer = customers.find((c) => c.id === eventLog.meta.customer_id);
        if (customer) {
            meta.customer = customer.label;
        }
    }

    if (eventLog.meta.customer_ids && eventLog.meta.customer_ids.length > 0) {
        let customerNames = [];
        eventLog.meta.customer_ids.forEach((customerId) => {
            const customer = customers.find((c) => c.id === customerId);
            if (customer) {
                customerNames.push(customer.label);
            }
        });

        meta.recipients = customerNames.join(', ');
    }

    if (eventLog.meta.user_id) {
        const user = users.find((u) => u.id === eventLog.meta.user_id);
        if (user) {
            meta.user = user.full_name;
        } else {
            meta.user = t('general.unknown_user');
        }
    }

    if (eventLog.meta.location_id) {
        meta.location = t('enum.location.' + meta.location_id);
    }

    return meta;
};

export default useMetaTransform;
