import i18n from '../i18n';
import {partition} from './helpers';

/* eslint-disable no-case-declarations */
export const WIDGET_TYPE = {
    QUOTE: 'quote',
    PURCHASE_ORDER: 'purchase_order',
    INVOICE: 'invoice',
};

// Action creators
export const addCars = (widgetType, cars) => ({
    type: 'ADD_CARS',
    widgetType,
    payload: {
        cars,
    },
});

export const addCarLines = (widgetType, carLines) => ({
    type: 'ADD_CAR_LINES',
    widgetType,
    payload: {
        carLines,
    },
});

export const addTransport = (widgetType, transportPrice) => ({
    type: 'ADD_TRANSPORT',
    widgetType,
    payload: {
        transportPrice,
    },
});

export const addExtra = (widgetType, description, price) => ({
    type: 'ADD_EXTRA',
    widgetType,
    payload: {
        description,
        price,
    },
});

export const deleteLine = (widgetType, index) => ({
    type: 'DELETE_LINE',
    widgetType,
    payload: {
        index,
    },
});

export const resetLines = (widgetType) => ({
    type: 'RESET_LINES',
    widgetType,
});

export const WidgetReducer = (state, action) => {
    if (!Object.values(WIDGET_TYPE).includes(action.widgetType)) {
        return state;
    }

    const filterCars = (cars, widgetType) => {
        if (widgetType !== WIDGET_TYPE.INVOICE) {
            return cars;
        }

        const [requiresInspection, remaining] = partition(cars, (car) => {
            const inspection = car.documents.inspection;
            const status = typeof inspection === 'object' ? inspection.status : inspection;

            return status === 'REQUIRED' || status === 'PLANNED';
        });

        if (requiresInspection.length) {
            alert(i18n.t('invoice.inspection_required') + requiresInspection.map((car) => car.car_id).join(', '));
        }

        return remaining;
    };

    switch (action.type) {
        case 'ADD_CARS': {
            const cars = filterCars(action.payload.cars, action.widgetType);

            if (cars.length === 0) {
                return state;
            }

            return {
                ...state,
                [action.widgetType]: [
                    ...(state[action.widgetType] || []),
                    ...cars
                        .filter((car) => !(state[action.widgetType] || []).some((line) => line.car_id === car.car_id))
                        .map((car) => ({
                            car_id: car.car_id,
                            type: 'CAR',
                            description: null,
                            price: null,
                            base_pricing: 'WEBSITE_PRICING_BE',
                            customer_id: car.sale.sold_to || null,
                        })),
                ],
            };
        }
        case 'ADD_CAR_LINES': {
            let linesToAdd = action.payload.carLines.filter(
                (line) => !(state[action.widgetType] || []).some((existingLine) => existingLine.car_id === line.car_id)
            );

            const carIdsToAdd = filterCars(
                linesToAdd.map((line) => line.car),
                action.widgetType
            ).map((car) => car.car_id);

            linesToAdd = linesToAdd.filter((line) => carIdsToAdd.includes(line.car_id));

            return {
                ...state,
                [action.widgetType]: [
                    ...(state[action.widgetType] || []),
                    ...linesToAdd.map((line) => ({
                        car_id: line.car_id,
                        type: 'CAR',
                        description: null,
                        price: line.price,
                        base_pricing: line.base_pricing,
                        customer_id: line.customer_id,
                    })),
                ],
            };
        }
        case 'ADD_TRANSPORT':
            const transportLine = (state[action.widgetType] || []).find((line) => line.type === 'TRANSPORT');

            if (transportLine) {
                transportLine.price += action.payload.transportPrice;

                return {...state};
            }

            return {
                ...state,
                [action.widgetType]: [
                    ...(state[action.widgetType] || []),
                    {
                        car_id: null,
                        type: 'TRANSPORT',
                        description: null,
                        price: action.payload.transportPrice,
                        base_pricing: null,
                        customer_id: null,
                    },
                ],
            };
        case 'ADD_EXTRA':
            return {
                ...state,
                [action.widgetType]: [
                    ...(state[action.widgetType] || []),
                    {
                        car_id: null,
                        type: 'EXTRA',
                        description: action.payload.description,
                        price: action.payload.price,
                        base_pricing: null,
                        customer_id: null,
                    },
                ],
            };
        case 'DELETE_LINE':
            return {
                ...state,
                [action.widgetType]: (state[action.widgetType] || []).filter((line, index) => index !== action.payload.index),
            };
        case 'RESET_LINES':
            return {
                ...state,
                [action.widgetType]: [],
            };
    }
};
