import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import QuarterPicker from '../../../../components/Button/QuarterPicker';
import {useAuth} from '../../../../lib/UseAuth';
import useFetchData from '../../../../lib/UseFetchData';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';
import {dateFormat} from '../../../../lib/helpers';

import Checkbox from '../../../../components/Form/Checkbox';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import Button from '../../../../components/Button/Button';
import Section from './Section';

const Localisation = ({car, disabled, form, setLoadingMessage, setShowModal}) => {
    const {i18n, t} = useTranslation();
    const {user} = useAuth();
    const [customerConsignation, setCustomerConsignation] = useState(false);
    const [arrivalChangedByUser, setArrivalChangedByUser] = useState(false);
    const [deliveryChangedByUser, setDeliveryChangedByUser] = useState(false);
    const [locationChangedByUser, setLocationChangedByUser] = useState(false);
    const [locations] = useFetchData('/locations');
    const locationOptions = useMemo(() => {
        return locations
            ? locations.map((location) => ({
                  value: location.id,
                  label: t('enum.location.' + location.id),
                  isDisabled: location.id === 'CONSIGNMENT',
              }))
            : [];
    }, [locations]);
    const isOnOrder = form.data.localisation.arrival ? form.data.localisation.arrival.status === 'ON_ORDER' : false;
    const isConsigned = form.data.localisation.location ? form.data.localisation.location.where === 'CONSIGNMENT' : false;
    const consignedWith = form.data.localisation.location ? form.data.localisation.location.consigned_with : null;
    const arrivalChangedBy = form.data.localisation.location ? form.data.localisation.arrival.status_changed_by : null;
    const deliveryChangedBy = form.data.localisation.location ? form.data.localisation.delivery.status_changed_by : null;
    const locationChangedBy = form.data.localisation.location ? form.data.localisation.location.changed_by : null;
    const isArrived = form.data.localisation && form.data.localisation.arrival.status === 'ARRIVED';
    const isExpected = form.data.localisation && ['EXPECTED', 'ON_TRANSPORT'].includes(form.data.localisation.arrival.status);

    useEffect(() => {
        setCustomerConsignation(false);
        if (consignedWith) {
            authenticatedHttp()
                .get(`/customers/${consignedWith}`)
                .then((response) => {
                    setCustomerConsignation(response.data.data);
                });
        }
    }, [consignedWith]);

    useEffect(() => {
        setLocationChangedByUser(false);
        if (locationChangedBy) {
            authenticatedHttp()
                .get('/users/' + locationChangedBy)
                .then((response) => {
                    setLocationChangedByUser(response.data.data);
                });
        }
    }, [locationChangedBy]);

    useEffect(() => {
        setArrivalChangedByUser(false);
        if (arrivalChangedBy) {
            authenticatedHttp()
                .get('/users/' + arrivalChangedBy)
                .then((response) => {
                    setArrivalChangedByUser(response.data.data);
                });
        }
    }, [arrivalChangedBy]);

    useEffect(() => {
        setDeliveryChangedByUser(false);
        if (deliveryChangedBy) {
            authenticatedHttp()
                .get('/users/' + deliveryChangedBy)
                .then((response) => {
                    setDeliveryChangedByUser(response.data.data);
                });
        }
    }, [deliveryChangedBy]);

    return (
        <Section name="localisation">
            <table className="table table-striped table-documents">
                <tbody className="form-group">
                    <tr>
                        <th width="200">{t('car.localisation.arrival_status')}</th>
                        <td width="220">
                            <Enum
                                disabled={disabled}
                                enumName="arrival_status"
                                {...form.fields.localisation.arrival.status({
                                    onChange: (name, value, draft) => {
                                        if (value === 'ARRIVED') {
                                            draft.localisation.location.where = 'NEW_PARKING';
                                            draft.localisation.location.changed_by = user.id;
                                            draft.localisation.arrival.arrived_on = new Date().toISOString().split('T')[0];
                                        }
                                    },
                                })}
                            />
                        </td>
                        <td className="small">{dateFormat(form.data.localisation.arrival.status_changed_on)}</td>
                        <td>
                            <span className="small">{arrivalChangedByUser && arrivalChangedByUser.full_name}</span>
                        </td>
                        <td />
                    </tr>
                    {isArrived && (
                        <tr>
                            <th width="200">{t('search.arrived_on')}</th>
                            {form.fields.localisation.arrival.arrived_on ? (
                                <td className="small" width="220">
                                    <DatePicker disabled={disabled} {...form.fields.localisation.arrival.arrived_on()} />
                                </td>
                            ) : (
                                <td width="220" />
                            )}
                            <td colSpan={3} />
                        </tr>
                    )}
                    {isOnOrder && (
                        <Fragment>
                            <tr>
                                <th>{t('car.localisation.delivery_time')}</th>
                                <td>
                                    <Input
                                        disabled={disabled}
                                        suffix={t('general.week', {count: 2})}
                                        {...form.fields.localisation.arrival.delivery_time()}
                                    />
                                </td>
                                <td />
                                <td />
                                <td />
                            </tr>
                            <tr>
                                <th>{t('car.localisation.minimum_order_quantity')}</th>
                                <td>
                                    <Input disabled={disabled} {...form.fields.localisation.arrival.minimum_order_quantity()} />
                                </td>
                                <td />
                                <td />
                                <td />
                            </tr>
                        </Fragment>
                    )}
                    {isExpected && (
                        <tr>
                            <th>{t('general.expected_at_ecr')}</th>
                            <td>
                                <DatePicker disabled={disabled} {...form.fields.localisation.arrival.expected()} />
                            </td>
                            <td>
                                <QuarterPicker
                                    buttonProps={{
                                        label: t('general.actions'),
                                    }}
                                    {...form.fields.localisation.arrival.expected_estimate()}
                                />
                            </td>
                            <td />
                            <td />
                        </tr>
                    )}
                    {isArrived && (
                        <tr>
                            <th>{t('car.localisation.location')}</th>
                            <td>
                                <Select
                                    disabled={disabled}
                                    {...form.fields.localisation.location.where({
                                        onChange: (name, value, draft) => {
                                            draft.localisation.location.changed_by = user.id;
                                        },
                                    })}
                                    isOptionDisabled={(option) => option.isDisabled}
                                    options={locationOptions}
                                />
                            </td>
                            <td className="small">{dateFormat(form.data.localisation.location.changed_on)}</td>
                            <td className="small">
                                <span>{locationChangedByUser.full_name}</span>
                            </td>
                            <td />
                        </tr>
                    )}
                    {isArrived && form.data.localisation.location.where === 'BODY_SHOP' ? (
                        <tr>
                            <th>{t('car.localisation.repair_date')}</th>
                            <td className={'small'}>
                                <DatePicker disabled={disabled} {...form.fields.localisation.arrival.repair_date()} />
                            </td>
                            <td />
                            <td />
                            <td />
                        </tr>
                    ) : null}
                    {isConsigned && (
                        <tr>
                            <th>{t('car.localisation.consignation')}</th>
                            <td>{customerConsignation && <span>{customerConsignation.name}</span>}</td>
                            <td className="small">{dateFormat(form.data.localisation.location.consignation_changed_on)}</td>
                            <td>
                                {setShowModal && car.localisation.location.consigned_with && (
                                    <Fragment>
                                        <Button
                                            disabled={disabled}
                                            onClick={() => {
                                                setLoadingMessage(t('general.generating_document'));

                                                downloadFile(
                                                    '/download/consignation-contract',
                                                    {
                                                        car_id: form.data.car_id,
                                                        language: i18n.language,
                                                    },
                                                    t('general.consignation_contract').toLowerCase() + '_' + form.data.car_id
                                                ).then(() => {
                                                    setLoadingMessage(null);
                                                });
                                            }}
                                            outline
                                            small
                                        >
                                            {t('general.download_consignation_contract')}
                                        </Button>
                                        {/* Hide button for now, see ticket 1247 */}
                                        {/*<Button*/}
                                        {/*    disabled={disabled}*/}
                                        {/*    onClick={() => {*/}
                                        {/*        setShowModal('mailConsignationContract');*/}
                                        {/*    }}*/}
                                        {/*    outline*/}
                                        {/*    small*/}
                                        {/*>*/}
                                        {/*    {t('general.mail_consignation_contract')}*/}
                                        {/*</Button>*/}
                                    </Fragment>
                                )}
                            </td>
                            <td />
                        </tr>
                    )}

                    {Object.keys(form.fields.localisation.delivery).length !== 0 && (
                        <>
                            <tr>
                                <th>{t('car.localisation.delivery_scheduled_on')}</th>
                                <td className="small" colSpan={1}>
                                    <DatePicker
                                        disabled={disabled}
                                        {...form.fields.localisation.delivery.delivery_scheduled_on()}
                                    />
                                </td>
                                <td />
                                <td />
                                <td />
                            </tr>
                            <tr>
                                <th>{t('car.localisation.delivery_status')}</th>
                                <td>
                                    <Checkbox
                                        disabled={disabled}
                                        label={t('car.localisation.is_delivered')}
                                        {...form.fields.localisation.delivery.is_delivered({
                                            onChange: (name, checked, draft) => {
                                                draft.localisation.delivery.on = checked ? moment().format('YYYY-MM-DD') : null;
                                                draft.localisation.delivery.deliveryChangedBy = user.id;
                                            },
                                        })}
                                    />
                                </td>
                                <td className="small" colSpan={1}>
                                    {form.data.localisation.delivery.is_delivered ? (
                                        <DatePicker disabled={disabled} {...form.fields.localisation.delivery.on()} />
                                    ) : (
                                        <DatePicker disabled={disabled} {...form.fields.localisation.delivery.expected()} />
                                    )}
                                </td>
                                <td className="small">
                                    <span>{deliveryChangedByUser && deliveryChangedByUser.full_name}</span>
                                </td>
                                <td />
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </Section>
    );
};

export default Localisation;
