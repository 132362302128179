import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import moment from 'moment';
import Checkbox from '../../../../components/Form/Checkbox';

import DatePicker from '../../../../components/Form/DatePicker';
import Select from '../../../../components/Form/Select';
import languages from '../../../../enums/language.json';
import useFetchData from '../../../../lib/UseFetchData';

const General = ({
    control,
    defaultFormValues,
    editMode,
    invoiceCancellationMail,
    register,
    setInvoiceCancellationMail,
    watch,
}) => {
    const {t} = useTranslation();
    const [users] = useFetchData('/users');
    const [dueDate, setDueDate] = useState(defaultFormValues.dueDate);

    useEffect(() => {
        const subscription = watch((value, {name}) => {
            if (name !== 'date') {
                return;
            }

            const dueDate = moment(value.date).add(30, 'days').format('YYYY-MM-DD');
            setDueDate(dueDate);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <section>
            <div className="row mb-4">
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="subsidiary">{t('search.subsidiary')}</label>
                        <span className="form-control-plaintext">{t('enum.subsidiary.' + watch('subsidiary'))}</span>
                    </div>
                </div>
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="user_id">{t('car.seller')}</label>
                        <Controller
                            control={control}
                            defaultValue={defaultFormValues.userId}
                            name="user_id"
                            render={({field}) => (
                                <Select
                                    options={(users || []).map((u) => {
                                        return {value: u.id, label: u.full_name};
                                    })}
                                    placeholder={t('car.seller')}
                                    {...field}
                                />
                            )}
                            rules={{required: true}}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="language">{t('general.language')}</label>
                        <Controller
                            control={control}
                            defaultValue={defaultFormValues.language}
                            name="language"
                            render={({field}) => (
                                <Select
                                    options={languages.map((l) => {
                                        return {value: l, label: t('enum.language.' + l)};
                                    })}
                                    placeholder={t('general.language')}
                                    {...field}
                                />
                            )}
                            rules={{required: true}}
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                {editMode && (
                    <div className="col-6">
                        <div className="form-group form-group-vertical">
                            <label htmlFor="credit_note_number">{t('general.credit_note_number')}</label>
                            <span className="form-control-plaintext">{defaultFormValues.creditNoteNumber}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="row mb-4">
                <div className="col-4">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="date">{t('car.date')}</label>
                        <Controller
                            control={control}
                            defaultValue={defaultFormValues.date}
                            name="date"
                            render={({field}) => <DatePicker {...field} />}
                            rules={{required: true}}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group form-group-vertical">
                        <label htmlFor="due_date">{t('general.due_date')}</label>
                        <DatePicker disabled value={dueDate} />
                    </div>
                </div>
            </div>
            <div className="form-group form-group-vertical">
                <label htmlFor="mail_text">{t('general.mail_text')}</label>
                <textarea
                    className="form-control"
                    defaultValue={defaultFormValues.mailText}
                    id="mail_text"
                    placeholder={t('general.mail_text')}
                    {...register('mail_text')}
                    rows="3"
                />
            </div>
            <div className="form-group form-group-vertical">
                <Checkbox
                    label={t('general.use_is_invoice_cancellation_mail')}
                    onChange={() => setInvoiceCancellationMail(!invoiceCancellationMail)}
                    value={invoiceCancellationMail}
                />
            </div>
        </section>
    );
};

export default General;
