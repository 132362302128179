import React from 'react';
import Select from './Select';
import {useTranslation} from 'react-i18next';

const DocumentDeliveryEnum = ({documentType, ...selectProps}) => {
    const {t} = useTranslation();

    let enumData = ['NOT_DELIVERED', 'CLIENT', 'TNT', 'TRANSPORT'];

    switch (documentType) {
        case 'proof_of_registration_part_1':
        case 'proof_of_registration_part_2':
        case 'certificate_of_conformity':
        case 'e705':
            enumData = ['NOT_DELIVERED', 'TNT', 'TRANSPORT', 'CLIENT'];
            break;
        case 'warranty_booklet':
        case 'keys':
            enumData = ['NOT_DELIVERED', 'TRANSPORT', 'CLIENT', 'TNT'];
            break;
    }

    let options = enumData.map((key) => ({value: key, label: t(`enum.document_delivery.${key}`)}));

    return <Select options={options} {...selectProps} />;
};

export default React.memo(DocumentDeliveryEnum);
