import React, {Fragment, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Input from '../../../../components/Form/Input';
import Modal from '../../../../components/Modal/NewModal';
import Money from '../../../../components/Form/Money';
import {moneyFormat} from '../../../../lib/helpers';

function RegisterIncomingPayment({invoices, refresh}) {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const {control, handleSubmit, watch} = useForm();
    const [saving, toggleSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const onSubmit = (data) => {
        toggleSaving(true);

        data.invoice_ids = invoices.map((invoice) => invoice.id);
        authenticatedHttp()
            .post(`/invoices/payments`, data)
            .then(() => {
                toggleSaving(false);
                closeModal();
                refresh();
            })
            .catch((response) => {
                toggleSaving(false);
                setErrors(
                    Object.values(response.response.data.errors).reduce((errors, keyErrors) => {
                        keyErrors.forEach((error) => errors.push(error));

                        return errors;
                    }, [])
                );
            });
    };

    return (
        <Modal title={t('general.register_incoming_payment')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>{t('general.invoice_nr')}</td>
                                <td>{t('general.customer')}</td>
                                <td>{t('nav.cars')}</td>
                                <td className="text-end">{t('general.outstanding_debt')}</td>
                                <td className="text-end">{t('general.total_incl')}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((invoice) => (
                                <tr key={invoice.id}>
                                    <td>{invoice.invoice_number}</td>
                                    <td>{invoice.customer.data.label}</td>
                                    <td>
                                        {invoice.lines
                                            .filter((line) => line.type === 'CAR')
                                            .map((line) => (
                                                <p key={line.car.car_id} style={{margin: 0}}>
                                                    {line.car.mmt} ({line.car.car_id})
                                                </p>
                                            ))}
                                    </td>
                                    <td className="text-end">{moneyFormat(invoice.outstanding_debt)}</td>
                                    <td className="text-end">{moneyFormat(invoice.total_incl_vat)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3} />
                                <td className="text-end">
                                    {moneyFormat(invoices.reduce((sum, invoice) => sum + invoice.outstanding_debt, 0))}
                                </td>
                                <td className="text-end">
                                    {moneyFormat(invoices.reduce((sum, invoice) => sum + invoice.total_incl_vat, 0))}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="date">{t('general.date_paid')}</label>
                                <Controller
                                    control={control}
                                    id="date"
                                    name="date"
                                    render={({field}) => <DatePicker placeholder={t('general.date_paid')} {...field} />}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="amount">{t('general.payment')}</label>
                                <Controller
                                    control={control}
                                    defaultValue={0}
                                    id="amount"
                                    name="amount"
                                    placeholder={t('general.payment')}
                                    render={({field}) => <Money {...field} />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{paddingBottom: '150px'}}>
                        <div className="col-4">
                            <div className="form-group form-group-vertical">
                                <label htmlFor="payment_method">{t('general.payment_method')}</label>
                                <Controller
                                    control={control}
                                    defaultValue="TRANSFER"
                                    id="payment_method"
                                    name="payment_method"
                                    placeholder={t('general.payment_method')}
                                    render={({field}) => <Enum enumName="payment_method" {...field} />}
                                />
                            </div>
                        </div>
                        {watch('payment_method', 'TRANSFER') === 'TRANSFER' && (
                            <Fragment>
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label htmlFor="bank">{t('general.bank')}</label>
                                        <Controller
                                            control={control}
                                            id="bank"
                                            name="bank"
                                            placeholder={t('general.bank')}
                                            render={({field}) => <Enum enumName="bank" {...field} />}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group form-group-vertical">
                                        <label htmlFor="bank_statement">{t('general.bank_statement')}</label>
                                        <Controller
                                            control={control}
                                            id="bank_statement"
                                            name="bank_statement"
                                            placeholder={t('general.bank_statement')}
                                            render={({field}) => <Input {...field} />}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    {errors && (
                        <div className="alert alert-danger">
                            <ul>
                                {errors.map((error, i) => (
                                    <li key={i}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="blue" disabled={saving} submit>
                        {t('general.register_incoming_payment')}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default RegisterIncomingPayment;
