import React from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../../components/Form/Input';

import Section from './Section';

const Emissions = ({disabled, form}) => {
    const {t} = useTranslation();

    return (
        <Section name="emissions">
            <ul className="breakdown-grid columns-4 form-group" style={{width: '80%'}}>
                <li className="breakdown-head">
                    <h3 className="colspan-2">{t('car.consumption.label')}</h3>
                    <h3 className="colspan-2">{t('car.emissions.label')}</h3>
                </li>
                <li>
                    <strong>{t('car.consumption.nedc_city')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.nedc_city()} />
                    </span>
                    <strong>{t('car.emissions.nedc_co2')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="g/km" {...form.fields.emissions.co2.nedc()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.nedc_road')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.nedc_road()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.nedc_combined')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.nedc_combined()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.wltp_low')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.wltp_low()} />
                    </span>
                    <strong>{t('car.emissions.wltp_co2')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="g/km" {...form.fields.emissions.co2.wltp()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.wltp_medium')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.wltp_medium()} />
                    </span>
                    <strong>{t('car.emissions.coefficient_co2')}</strong>
                    <span>
                        <Input disabled={disabled} {...form.fields.emissions.co2.coefficient()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.wltp_high')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.wltp_high()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.wltp_extra_high')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.wltp_extra_high()} />
                    </span>
                </li>
                <li>
                    <strong>{t('car.consumption.wltp_combined')}</strong>
                    <span>
                        <Input disabled={disabled} suffix="l/100km" {...form.fields.emissions.consumption.wltp_combined()} />
                    </span>
                </li>
            </ul>
        </Section>
    );
};

export default Emissions;
