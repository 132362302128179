import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {authenticatedHttp} from '../../lib/zaza-client';
import {moneyFormat} from '../../lib/helpers';
import {useTranslation} from 'react-i18next';
import {deleteLine, resetLines} from '../../lib/WidgetReducer';

import Button from '../../components/Button/Button';

import IconAngleDown from '../../../assets/svg/angle-down.svg';

function Widget({dispatch, lines, type}) {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const [panelOpen, setPanelOpen] = useState(false);
    const [cars, setCars] = useState({});

    const frontendUrls = {
        quote: 'offertes',
        purchase_order: 'bestelbonnen',
        invoice: 'facturen',
    };

    useEffect(() => {
        const carLines = lines.filter((line) => line.type === 'CAR');

        if (!carLines.length) {
            return;
        }

        authenticatedHttp()
            .post('/cars/search', {
                car_id: carLines.map((line) => line.car_id),
            })
            .then((response) => {
                const newState = response.data.data.reduce((map, car) => {
                    map[car.car_id] = car;
                    return map;
                }, {});
                setCars(newState);
            });
    }, [lines]);

    if (!lines) {
        return null;
    }

    const lineDescription = (line) => {
        switch (line.type) {
            case 'CAR':
                if (!cars[line.car_id]) {
                    return t('general.loading');
                }

                return (
                    <Fragment>
                        <strong>{cars[line.car_id].type.model.make}</strong>
                        &nbsp;
                        {cars[line.car_id].type.model.name}
                        &nbsp;
                        {cars[line.car_id].type.name}
                    </Fragment>
                );

            case 'TRANSPORT':
                return (
                    <Fragment>
                        <strong>{t('general.transport')}</strong>
                        {moneyFormat(line.price)}
                    </Fragment>
                );
            case 'EXTRA':
                return (
                    <Fragment>
                        <strong>{line.description}</strong>
                        {moneyFormat(line.price)}
                    </Fragment>
                );
        }
    };

    if (!lines.length) {
        return null;
    }

    return (
        <div className="document-button">
            {panelOpen && (
                <Fragment>
                    <div className="document-button--header">
                        <span>{t('general.lines')}</span>
                        <a
                            className="btn--close"
                            onClick={() => {
                                setPanelOpen(false);
                            }}
                        >
                            <IconAngleDown />
                        </a>
                    </div>
                    <div className="document-button--list">
                        <ul>
                            {lines.map((line, index) => (
                                <li key={index}>
                                    <a onClick={() => dispatch(deleteLine(type, index))}>
                                        {lineDescription(line)}
                                        <i className="far fa-times-circle icon-default" />
                                        <i className="fas fa-times-circle icon-hover" />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Fragment>
            )}

            <div className="document-button--footer">
                {lines.length === 1 && <strong>{t('general.line_added', {amount: lines.length})}</strong>}
                {lines.length > 1 && <strong>{t('general.lines_added', {amount: lines.length})}</strong>}
                <a
                    className="btn--delete"
                    onClick={() => {
                        dispatch(resetLines(type));
                    }}
                >
                    <i className="fa fa-trash" />
                </a>

                <Button color="blue" onClick={() => navigate('/' + frontendUrls[type] + '/creatie')}>
                    {t('general.add_to_' + type)}
                </Button>

                {!panelOpen && (
                    <a
                        className="btn--open"
                        onClick={() => {
                            setPanelOpen(true);
                        }}
                    >
                        <IconAngleDown />
                    </a>
                )}
            </div>
        </div>
    );
}

export default Widget;
