import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';

import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Input from '../../../../components/Form/Input';
import Money from '../../../../components/Form/Money';
import Modal from '../../../../components/Modal/NewModal';
import {moneyFormat, resizeImages} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';

const RegisterPurchaseDocumentForm = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();

    const [saving, toggleSaving] = useState(false);
    const [errors, setErrors] = useState(null);
    const currency = cars[0].purchase_pricing.costs.purchase_price.currency;
    const [data, setData] = useState(() => {
        let initialData = {
            type: 'INVOICE',
            number: '',
            date: '',
            cars: {},
        };

        cars.forEach(
            (car) =>
                (initialData.cars[car.car_id] = car.purchase_pricing.price
                    ? car.purchase_pricing.price
                    : car.purchase_pricing.costs.purchase_price.amount)
        );

        return initialData;
    });

    useEffect(() => {
        setData({...data, number: t('general.loading')});

        const uri = data.type === 'INVOICE' ? 'invoices' : 'credit-notes';
        authenticatedHttp()
            .get(`/cars/purchase-${uri}/${cars[0].subsidiary}/next-number`)
            .then((response) => {
                setData({...data, number: response.data.number});
            });
    }, [data.type]);

    const onSubmit = async (e) => {
        e.preventDefault();

        const someCarsHaveDocuments = cars.some((car) => car.purchase_documents.length > 0);

        if (
            someCarsHaveDocuments &&
            !confirm(t('general.purchase_document_warning', {cars: cars.map((car) => car.car_id).join(', ')}))
        ) {
            return;
        }

        toggleSaving(true);

        const pdfFiles = [];
        const imageFiles = acceptedFiles.filter((file) => {
            if (file.type !== 'application/pdf') {
                return true;
            } else {
                pdfFiles.push(file);
                return false;
            }
        });

        const resizedFiles = await resizeImages(imageFiles);

        acceptedFiles.map(async (file) => {
            if (file.type !== 'application/pdf') {
                return await resizeImages([file]);
            }
        });

        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        pdfFiles.forEach((file) => {
            formData.append(`files[]`, file);
        });
        resizedFiles.forEach((file) => {
            formData.append(`files[]`, file);
        });

        authenticatedHttp()
            .post('/cars/purchase-documents', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(() => {
                toggleSaving(false);
                closeModal();
                refresh();
            })
            .catch((response) => {
                toggleSaving(false);
                setErrors(
                    Object.values(response.response.data.errors).reduce((errors, keyErrors) => {
                        keyErrors.forEach((error) => errors.push(error));

                        return errors;
                    }, [])
                );
            });
    };

    const {acceptedFiles, getInputProps, getRootProps, isDragActive} = useDropzone({
        multiple: false,
        accept: 'image/jpeg, image/png, application/pdf',
    });

    const files = acceptedFiles.map((file) => (
        <td key={file.path}>
            {file.path} - {file.size} bytes
        </td>
    ));

    return (
        <form onSubmit={onSubmit}>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="pl-0 pt-3 pb-3 border-top-0" />
                            <th className="pl-0 pt-3 pb-3 border-top-0">
                                <strong>{t('search.car_id')}</strong>
                            </th>
                            <th className="p-3 border-top-0">
                                <strong>{t('search.vin')}</strong>
                            </th>
                            <th className="p-3 border-top-0">
                                <strong>{t('search.make')}</strong>
                            </th>
                            <th className="p-3 border-top-0">
                                <strong>{t('car.purchase_pricing.purchase_price')}</strong>
                            </th>
                            <th className="pr-0 pt-3 pb-3 border-top-0">
                                <strong>{t('general.price_on_document')}</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cars.map((car) => (
                            <tr key={car.car_id}>
                                <td className="pl-0 pt-3 pb-3 align-middle">
                                    {car.purchase_documents.length > 0 && (
                                        <i className="fas fa-exclamation-circle" style={{color: 'red'}} />
                                    )}
                                </td>
                                <td className="pl-0 pt-3 pb-3 align-middle">{car.car_id}</td>
                                <td className="p-3 align-middle">{car.vin}</td>
                                <td className="p-3 align-middle">{car.mmt}</td>
                                <td className="p-3 align-middle" style={{width: 120}}>
                                    {moneyFormat(
                                        car.purchase_pricing.price
                                            ? car.purchase_pricing.price
                                            : car.purchase_pricing.costs.purchase_price.amount,
                                        currency
                                    )}
                                </td>
                                <td className="pr-0 pt-3 pb-3 form-group align-middle">
                                    <Money
                                        currency={currency}
                                        onChange={(value) => {
                                            setData({...data, cars: {...data.cars, [car.car_id]: value}});
                                        }}
                                        value={data.cars[car.car_id]}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} />
                            <td>
                                {moneyFormat(
                                    cars.reduce(
                                        (subtotal, car) => subtotal + car.purchase_pricing.costs.purchase_price.amount,
                                        0
                                    ),
                                    currency
                                )}
                            </td>
                            <td />
                        </tr>
                    </tfoot>
                </table>
                <div className="row mt-5 pb-3">
                    <div className="col-4 cellHeight">
                        <div className="form-group row">
                            <label className="col-4 col-form-label" htmlFor="type">
                                {t('api.type')}
                            </label>
                            <div className="col-8">
                                <Enum
                                    enumName="purchase_document_type"
                                    id="number"
                                    onChange={(value) => {
                                        setData({...data, type: value});
                                    }}
                                    value={data.type}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 cellHeight">
                        <div className="form-group row">
                            <label className="col-4 col-form-label" htmlFor="number">
                                {t('general.number')}
                            </label>
                            <div className="col-8">
                                <Input
                                    id="number"
                                    onChange={(value) => {
                                        setData({...data, number: value});
                                    }}
                                    value={data.number}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 cellHeight">
                        <div className="form-group row" style={{paddingBottom: '200px'}}>
                            <label className="col-4 col-form-label" htmlFor="date">
                                {t('general.date')}
                            </label>
                            <div className="col-8">
                                <DatePicker
                                    id="date"
                                    onChange={(value) => {
                                        setData({...data, date: value});
                                    }}
                                    placeholder={t('general.date')}
                                    value={data.date}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropzone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the file here ...</p>
                    ) : (
                        // eslint-disable-next-line react/no-unescaped-entities
                        <p>Drag 'n' drop a *.jpeg, *.png or *.pdf file here, or click to select file</p>
                    )}
                </div>
                {acceptedFiles.length > 0 && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="pl-0 pt-3 pb-3 border-top-0">
                                    <strong>Files</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>{files}</tr>
                        </tbody>
                    </table>
                )}
                {errors && (
                    <div className="alert alert-danger">
                        <ul>
                            {errors.map((error, i) => (
                                <li key={i}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" disabled={saving} submit>
                    {t('general.register_purchase_document')}
                </Button>
                {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
            </Modal.Footer>
        </form>
    );
};

export default RegisterPurchaseDocumentForm;
