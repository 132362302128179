import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import CreditNoteForm from './CreditNoteForm';
import Spinner from '../../../../components/Spinner/Spinner';

const LoadUpdate = () => {
    const {creditNoteId} = useParams();
    let navigate = useNavigate();

    const [customer, setCustomer] = useState(null);
    const [creditNote, setCreditNote] = useState(null);

    useEffect(() => {
        authenticatedHttp()
            .get('/credit-notes/' + creditNoteId)
            .then((response) => {
                const creditNote = response.data.data;
                if (creditNote.status !== 'DRAFT') {
                    navigate('/creditnotas/' + creditNoteId);
                    return;
                }
                setCreditNote(creditNote);
            });
    }, []);

    if (!creditNote) {
        return <Spinner />;
    }

    const lines = creditNote.lines.map((line) => ({
        invoice_line_id: line.invoice_line_id,
        car_id: line.car ? line.car.car_id : null,
        type: line.type,
        description: line.description,
        price: line.price,
    }));

    let selectedContacts = [];
    creditNote.contacts.forEach((contactId) => {
        selectedContacts.push(contactId);
    });

    const cars = {};
    creditNote.lines
        .filter((line) => line.type === 'CAR')
        .forEach((line) => {
            cars[line.car.car_id] = line.car;
        });

    return (
        <CreditNoteForm
            cars={cars}
            customer={[customer, setCustomer]}
            customerId={creditNote.customer.data.id}
            defaultFormValues={{
                channel: creditNote.channel,
                date: creditNote.date,
                dueDate: creditNote.due_date,
                creditNoteNumber: creditNote.credit_note_number,
                language: creditNote.language,
                mailText: creditNote.mail_text,
                selectedContacts,
                userId: creditNote.user_id,
                subsidiary: creditNote.subsidiary,
            }}
            editMode
            lines={lines}
            submitUrl={`/credit-notes/${creditNote.id}`}
        />
    );
};

export default LoadUpdate;
