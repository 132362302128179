import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';

import SingleListCar from '../../SingleListCar';
import Checkbox from '../../../../components/Form/Checkbox';

const ListRow = ({invoice, selectedInvoiceIds, setSelectedInvoiceIds, users}) => {
    const {t} = useTranslation();
    let navigate = useNavigate();

    const handleRowSelect = (id) => {
        if (selectedInvoiceIds.includes(id)) {
            setSelectedInvoiceIds((prevState) => prevState.filter((option) => option !== id));
        } else {
            setSelectedInvoiceIds((prevState) => [...prevState, id]);
        }
    };

    return (
        <div
            className="grid-table-row clickable"
            key={invoice.id}
            onClick={(e) => {
                e.stopPropagation();
                navigate('/facturen/' + invoice.id);
            }}
        >
            <div className="grid-table-cell checkbox">
                <Checkbox
                    field={`invoice-${invoice.id}`}
                    onChange={() => {
                        handleRowSelect(invoice.id);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    value={selectedInvoiceIds.includes(invoice.id)}
                />
            </div>
            <div className="grid-table-cell">
                <span>{invoice.invoice_number}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.subsidiary.' + invoice.subsidiary)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.document_status.' + invoice.status)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{dateFormat(invoice.date)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{dateFormat(invoice.due_date)}</span>
            </div>
            <div className="grid-table-cell small">
                <span>{invoice.customer.data.label}</span>
            </div>
            <div className="grid-table-cell small">
                <span>
                    {invoice.created_by
                        ? users.find((u) => u.id === invoice.created_by).full_name
                        : t('general.employee_not_found')}
                </span>
            </div>
            <div className="grid-table-cell d-flex flex-column small similar-cars">
                {invoice.lines.map((line) => (
                    <SingleListCar key={line.id} line={line} selectable={false} />
                ))}
            </div>
            <div className="grid-table-cell numerical">
                <strong className={'me-3'}>{moneyFormat(invoice.total_incl_vat)}</strong>
            </div>
        </div>
    );
};

export default React.memo(ListRow);
