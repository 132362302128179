import React from 'react';
import {useParams} from 'react-router-dom';
import useFetchData from '../../lib/UseFetchData';

import Layout from '../../components/Layout/Layout';
import ShowList from './ShowList';
import Spinner from '../../components/Spinner/Spinner';

import './Lists.css';
import {useAuth} from '../../lib/UseAuth';

const LoadList = () => {
    const {selectedList} = useParams();
    const {user} = useAuth();
    const [lists, isLoadingLists] = useFetchData('/lists');

    if (isLoadingLists || !user.permissions.CAN_ACCESS_LISTS) {
        return (
            <Layout header={() => <h1>Lijsten</h1>}>
                <Spinner />
            </Layout>
        );
    }

    return <ShowList lists={lists} selectedList={selectedList} />;
};

export default LoadList;
