import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import debounce from 'debounce-promise';
import classnames from 'classnames';

import {authenticatedHttp} from '../../lib/zaza-client';

const asyncOptions = (inputValue, autocompleteURI, transform) => {
    return authenticatedHttp()
        .get(autocompleteURI + `&query=${encodeURIComponent(inputValue)}`)
        .then((response) => {
            return transform(response.data);
        });
};
const debouncedOptions = debounce(asyncOptions, 250);

const Autocomplete = ({
    allowClear,
    allowCreate,
    autocompleteURI,
    disabled = false,
    error,
    name,
    onChange,
    placeholder,
    transform = (data) => data,
    value,
}) => {
    const {t} = useTranslation();
    const [previouslySelectedItems, setPreviouslySelectedItems] = useState([]);
    let defaultValue = [];
    const [isOpen, setIsOpen] = useState(false);

    const selectedItem = previouslySelectedItems.filter((item) => item.value === value);
    if (selectedItem.length > 0) {
        defaultValue = selectedItem;
    } else if (value) {
        if (!value.label) {
            value = {label: value, value: value};
        }

        defaultValue = [value];
    }

    const props = {
        className: classnames({
            'rselect-container-open': isOpen,
            'rselect-container-value': value,
            'rselect-container': !value,
            'has-error': error,
        }),
        classNamePrefix: 'rselect',
        defaultOptions: defaultValue,
        value: defaultValue,
        isClearable: allowClear,
        isDisabled: disabled,
        loadingMessage: () => t('general.loading'),
        loadOptions: (inputValue) => debouncedOptions(inputValue, autocompleteURI, transform),
        name,
        noOptionsMessage: ({inputValue}) => (inputValue ? t('general.no_results') : t('general.type_for_suggestions')),
        onChange: (item) => {
            let value = '';
            if (item) {
                value = item.value;
                setPreviouslySelectedItems([...previouslySelectedItems, item]);
            }
            onChange(value, item);
        },
        onMenuOpen: () => setIsOpen(true),
        onMenuClose: () => setIsOpen(false),
        placeholder,
    };

    const Select = allowCreate ? <AsyncCreatableSelect {...props} /> : <AsyncSelect {...props} />;

    return (
        <Fragment>
            {Select}
            {error && <p className="error-message">{error}</p>}
        </Fragment>
    );
};

export default React.memo(Autocomplete);
