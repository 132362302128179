import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';
import {setPageTitle} from '../../../../lib/helpers';
import lscache from 'lscache';

import Button from '../../../../components/Button/Button';
import Dropdown from '../../../../components/Button/Dropdown';
import Checkbox from '../../../../components/Form/Checkbox';
import EditMailModal from '../../../../components/Modal/EditMailModal';
import Layout from '../../../../components/Layout/Layout';
import Spinner from '../../../../components/Spinner/Spinner';
import RegisterIncomingAdvance from '../../../Cars/List/Modal/RegisterIncomingAdvance';
import RegisterIncomingPayment from '../Detail/RegisterIncomingPayment';
import ListRow from './ListRow';
import Filters from '../../Filters';

import '../../Documents.scss';
import './List.css';
import $ from 'jquery';

const List = () => {
    useEffect(() => {
        setPageTitle(t('nav.invoices'));
    }, []);

    const {t} = useTranslation();
    const {showModal} = useModal();
    const navigate = useNavigate();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [totals, setTotals] = useState({pages: 0, cars: 0});
    const [filters, setFilters] = useQueryParams({
        status: StringParam,
        customer: StringParam,
        user: NumberParam,
        date_from: StringParam,
        date_to: StringParam,
        number_from: StringParam,
        number_to: StringParam,
        subsidiary: StringParam,
        page: withDefault(NumberParam, 1),
        perPage: withDefault(NumberParam, 50),
        path: withDefault(StringParam, 'i.date'),
        direction: withDefault(StringParam, 'DESC'),
    });

    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [users] = useFetchData('/users?include_inactive');

    const [invoices, setInvoices] = useState(null);
    const fetchInvoices = () => {
        setLoadingMessage(t('general.loading'));
        setSelectedInvoiceIds([]);

        let query = '/invoices?';

        if (filters.subsidiary) {
            query += '&subsidiary=' + filters.subsidiary;
        }
        if (filters.status) {
            query += '&status=' + filters.status;
        }
        if (filters.customer) {
            query += '&customer_id=' + filters.customer;
        }
        if (filters.user) {
            query += '&user_id=' + filters.user;
        }
        if (filters.date_from) {
            query += '&date_from=' + filters.date_from;
        }
        if (filters.date_to) {
            query += '&date_to=' + filters.date_to;
        }
        if (filters.number_from) {
            query += '&number_from=' + filters.number_from;
        }
        if (filters.number_to) {
            query += '&number_to=' + filters.number_to;
        }

        query += '&order_by=' + filters.path + ',' + filters.direction;
        query += '&page=' + filters.page + '&per_page=' + filters.perPage;

        authenticatedHttp()
            .get(query)
            .then((response) => {
                setInvoices(response.data.data);
                setLoadingMessage(null);
                if (filters.page !== response.data.meta.page) {
                    setFilters({...filters, page: response.data.meta.page});
                }
                setTotals({
                    pages: response.data.meta.pages,
                    items: response.data.meta.total,
                });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    useEffect(fetchInvoices, [filters]);

    useEffect(() => {
        if (!invoices) {
            return;
        }

        lscache.set(
            'invoices_results',
            invoices.map((invoice) => invoice.id)
        );
    }, [invoices]);
    useEffect(() => {
        lscache.set('invoices_filters', filters);
    }, [filters]);

    const stickyColumnHeader = () => {
        let height = window.innerHeight - 60;
        height -= $('header').outerHeight();
        height -= $('#filters').outerHeight();
        height -= $('#dropdown-row').outerHeight();
        if ($('.grid-table').outerHeight() > height) {
            $('section').height(height);
        }
    };

    useEffect(stickyColumnHeader, [invoices]);

    const orderBySetter = (path) => {
        if (filters.path === path) {
            // If path is the same as current sorting path, flip the direction
            setFilters({
                ...filters,
                path,
                direction: filters.direction === 'ASC' ? 'DESC' : 'ASC',
                page: 1,
            })
        } else {
            // If path is different, update it and reset direction to descending
            setFilters({
                ...filters,
                path,
                direction: 'ASC',
                page: 1, // Reset page
            });
        }
    };

    const selectedInvoices = useMemo(() => {
        if (!invoices) {
            return [];
        }

        return invoices.filter((invoice) => selectedInvoiceIds.includes(invoice.id));
    }, [invoices, selectedInvoiceIds]);

    const selectedCars = useMemo(() => {
        if (!invoices) {
            return [];
        }

        let cars = [];
        selectedInvoices.forEach((invoice) =>
            invoice.lines.filter((line) => line.type === 'CAR').forEach((line) => cars.push(line.car))
        );

        return cars;
    }, [invoices, selectedInvoiceIds]);

    if (isError) {
        return <Layout header={() => <h1>{t('general.loading_error')}</h1>} />;
    }

    if (!invoices) {
        return <Spinner />;
    }

    const handleBulkSend = (mailText) => {
        setLoadingMessage(t('nav.submit') + '…');
        authenticatedHttp()
            .post('/invoices/send', {
                mail_text: mailText,
                ids: selectedInvoiceIds,
            })
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    return (
        <Layout
            className="documents documents-list invoice-list"
            header={() => (
                <div className="header-wrapper">
                    <div className="header-pagination">
                        <a
                            className={'btn-arrow btn-arrow-left ' + (filters.page > 1 ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page - 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                        <div>
                            <h1 className="mb-0">
                                {t('nav.invoices')} ({totals.items})
                            </h1>
                            <p className="mb-0 small">
                                {t('general.page')} {filters.page} {t('general.of')} {totals.pages}
                            </p>
                        </div>
                        <a
                            className={'btn-arrow btn-arrow-right ' + (filters.page < totals.pages ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page + 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            navigate('/facturen/creatie?empty');
                        }}
                    >
                        {t('general.invoice_create')}
                    </Button>
                </div>
            )}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <div className="filter-container">
                <Dropdown
                    actions={{
                        [t('general.send')]: (e) => {
                            e.preventDefault();
                            showModal(<EditMailModal documents={selectedInvoices} handleSend={handleBulkSend} />);
                        },
                        [t('general.register_incoming_advance')]: (e) => {
                            e.preventDefault();
                            showModal(
                                <RegisterIncomingAdvance
                                    cars={selectedCars}
                                    customers={invoices.map((invoice) => invoice.customer.data)}
                                    refresh={fetchInvoices}
                                />
                            );
                        },
                        [t('general.register_incoming_payment')]: (e) => {
                            e.preventDefault();
                            showModal(<RegisterIncomingPayment invoices={selectedInvoices} refresh={fetchInvoices} />);
                        },
                        [t('nav.download')]: (e) => {
                            e.preventDefault();
                            setLoadingMessage(t('general.generating_document'));
                            const downloadableInvoiceIds = selectedInvoiceIds.filter(
                                (invoiceId) => invoices.find((i) => i.id === invoiceId).downloadable
                            );
                            if (selectedInvoiceIds.length > downloadableInvoiceIds.length) {
                                alert(t('general.document_download_warning'));
                                setLoadingMessage(null);
                                return;
                            }
                            downloadFile(`/invoices/download`, {invoice_ids: downloadableInvoiceIds}).then(() => {
                                setLoadingMessage(null);
                            });
                        },
                    }}
                    buttonProps={{
                        label: t('general.actions'),
                    }}
                    disabled={selectedInvoiceIds.length === 0}
                />
                {selectedInvoiceIds.length > 0 && (
                    <span style={{lineHeight: '38px', marginLeft: '1em', verticalAlign: 'sub'}}>
                        {t('general.selected', {count: selectedInvoiceIds.length})}
                    </span>
                )}

                <Filters filters={filters} setFilters={setFilters} showPaidStatus />
            </div>

            <section className="no-padding">
                <div className="grid-table">
                    <div className="grid-table-row">
                        <div className="grid-table-cell checkbox">
                            <Checkbox
                                field="selection-toggle"
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedInvoiceIds(invoices.map((invoice) => invoice.id));
                                    } else {
                                        setSelectedInvoiceIds([]);
                                    }
                                }}
                                semiChecked={selectedInvoiceIds.length > 0}
                                value={selectedInvoiceIds.length === invoices.length}
                            />
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.invoiceNumber');
                            }}
                        >
                            <span>{t('general.invoice_nr')}</span>
                            {filters.path === 'i.invoiceNumber' && filters.direction === 'ASC' && (
                                <i className="fa fa-caret-up" />
                            )}
                            {filters.path === 'i.invoiceNumber' && filters.direction === 'DESC' && (
                                <i className="fa fa-caret-down" />
                            )}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.subsidiary');
                            }}
                        >
                            <span>{t('search.subsidiary')}</span>
                            {filters.key === 'i.subsidiary' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.key === 'i.subsidiary' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.status');
                            }}
                        >
                            <span>{t('general.status')}</span>
                            {filters.path === 'i.status' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'i.status' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.date');
                            }}
                        >
                            <span>{t('car.date')}</span>
                            {filters.path === 'i.date' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'i.date' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.due_date');
                            }}
                        >
                            <span>{t('general.due_date')}</span>
                            {filters.path === 'i.due_date' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'i.due_date' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div className="grid-table-cell">{t('car.customer')}</div>
                        <div className="grid-table-cell">{t('car.seller')}</div>
                        <div className="grid-table-cell">{t('general.items')}</div>
                        <div className="grid-table-cell numerical">
                            <span className={'me-3'}>{t('general.total_incl')}</span>
                        </div>
                    </div>
                    {invoices.map((invoice) => (
                        <ListRow
                            invoice={invoice}
                            key={invoice.id}
                            selectedInvoiceIds={selectedInvoiceIds}
                            setSelectedInvoiceIds={setSelectedInvoiceIds}
                            users={users}
                        />
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default React.memo(List);
