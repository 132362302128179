import Decimal from '@agrora/decimal';

export const calculatePricingSubtotals = (pricing, margins = {parking_cost: 0, financing_cost: 0}) => {
    const {purchase_price, ...costsWithoutPurchasePrice} = pricing.costs;

    const costs =
        Object.values(costsWithoutPurchasePrice).reduce((subtotal, value) => parseFloat(subtotal) + parseFloat(value), 0) +
        (pricing.transport.from_supplier ? parseFloat(pricing.transport.from_supplier.cost) : 0) +
        (!isNaN(parseFloat(purchase_price.amount))
            ? purchase_price.currency === 'EUR'
                ? parseFloat(purchase_price.amount)
                : parseFloat(purchase_price.currency_rate) && !isNaN(parseFloat(purchase_price.currency_rate))
                ? Decimal.fromNumber(purchase_price.amount)
                      .divide(Decimal.fromNumber(parseFloat(purchase_price.currency_rate.replace(',', '.'))))
                      .toFloat()
                : 0
            : 0);
    const accessories = pricing.accessories
        ? Object.values(pricing.accessories)
              .map((accessory) => accessory.cost)
              .reduce((subtotal, value) => parseFloat(subtotal) + parseFloat(value), 0)
        : 0;
    const accessories_invoiced = pricing.accessories
        ? Object.values(pricing.accessories)
              .map((accessory) => accessory.invoiced)
              .reduce((subtotal, value) => parseFloat(subtotal) + parseFloat(value), 0)
        : 0;

    const transportFromEcrCost = pricing.transport.from_ecr ? parseFloat(pricing.transport.from_ecr.cost) : 0;
    const transportFromEcrInvoiced = pricing.transport.from_ecr ? parseFloat(pricing.transport.from_ecr.invoiced) : 0;

    const grand_total =
        Math.round(
            (costs +
                accessories -
                accessories_invoiced +
                margins.parking_cost +
                margins.financing_cost +
                transportFromEcrCost -
                transportFromEcrInvoiced) *
                100
        ) / 100;

    return {
        costs,
        accessories,
        accessories_invoiced,
        grand_total,
    };
};
