import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/Modal/NewModal';
import Button from '../../../../components/Button/Button';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useModal} from '../../../../lib/UseModal';
import Select from '../../../../components/Form/Select';
import useForm from '../../../../lib/UseForm';

const EditSaleStatus = ({cars, refresh, user}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, toggleSaving] = useState(false);
    const sameSaleStatus = cars.every((car) => car.sale.status === cars[0].sale.status);
    const canSetSaleStatusToNotForSale = user.permissions.CAN_VIEW_NOT_FOR_SALE_CARS;
    const notForSaleOption = {value: 'NOT_FOR_SALE', label: t('enum.sale_status.NOT_FOR_SALE')};
    const forSaleOption = {value: 'FOR_SALE', label: t('enum.sale_status.FOR_SALE')};
    let saleStatusOptions = [];

    if (sameSaleStatus) {
        switch (cars[0].sale.status) {
            case 'FOR_SALE':
                saleStatusOptions = canSetSaleStatusToNotForSale ? [notForSaleOption] : [];
                break;
            case 'NOT_FOR_SALE':
                saleStatusOptions = [forSaleOption];
                break;
        }
    }

    const editSaleStatusForm = useForm(
        {status: null, customer_id: ''},
        {
            onSave: ({data}) => {
                toggleSaving(true);

                const patch = cars.map((car) => ({
                    car_id: car.car_id,
                    sale: {status: data.status},
                }));

                authenticatedHttp()
                    .patch('/cars', patch)
                    .then(() => {
                        toggleSaving(false);
                        closeModal();
                        refresh();
                    });
            },
        }
    );

    if (!sameSaleStatus) {
        return (
            <Modal title={t('general.not_equal_sale_status')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <p>{t('general.select_same_sale_status')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal title={t('search.edit_sale_status')}>
            <Modal.Body>
                <Select
                    allowClear
                    options={saleStatusOptions}
                    placeholder={t('search.sale_status')}
                    {...editSaleStatusForm.fields.status()}
                />
            </Modal.Body>
            <Modal.Footer>
                <div className="aligned-content">
                    <Button color="blue" disabled={saving} onClick={() => editSaleStatusForm.handleSubmit()}>
                        {t('search.edit_sale_status')}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSaleStatus;
