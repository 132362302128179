import React, {useEffect, useRef, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import classnames from 'classnames';
import ManualInvoicingWarningModal from '../../../../components/Modal/ManualInvoicingWarningModal';
import {determineBaseCarPricing, moneyFormat, setPageTitle} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {useWidget} from '../../../../lib/UseWidget';
import {resetLines, WIDGET_TYPE} from '../../../../lib/WidgetReducer';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Button from '../../../../components/Button/Button';
import SaveButtons from '../../Components/SaveButtons';
import InvoiceCustomer from './InvoiceCustomer';
import Layout from '../../../../components/Layout/Layout';
import PricingSelect from '../../PricingSelect';
import FormRow from '../../FormRow';
import Spinner from '../../../../components/Spinner/Spinner';
import General from './General';
import Money from '../../../../components/Form/Money';
import Modal from '../../../../components/Modal/Modal';

import '../../Documents.scss';
import './Create.css';

const getPrice = (priceOption, salesPricing) => {
    const matches = priceOption.match(/WEBSITE_PRICING_([A-Z]{2,4})/);
    if (matches) {
        return salesPricing.website_pricing[matches[1]].price;
    }

    return salesPricing[priceOption.toLowerCase()] || 0;
};

const InvoiceForm = ({
    cars,
    customer: [customer, setCustomer],
    customerId,
    defaultCarPricing,
    defaultFormValues,
    editMode,
    lines,
    submitUrl,
}) => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const [, dispatch] = useWidget();
    const [users] = useFetchData('/users');
    const {closeModal, showModal} = useModal();

    const transportCost = lines.reduce((subTotal, line) => subTotal + line.transport_from_ecr_invoiced, 0);

    useEffect(() => {
        setPageTitle(editMode ? t('general.invoice_edit') : t('general.invoice_create'));

        if (transportCost > 0) {
            append({
                type: 'TRANSPORT',
                description: '',
                price: transportCost,
            });
        }
    }, []);

    const {user} = useAuth();
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [oldShowModal, setOldShowModal] = useState(null);
    const [priceBump, setPriceBump] = useState(0);
    const [carPricing, setCarPricing] = useState(determineBaseCarPricing(defaultCarPricing));
    const [singleSubsidiary, setSingleSubsidiary] = useState(true);
    const firstRender = useRef(true);
    const notAutomaticallyInvoicedCars = Object.values(cars).filter((car) => !car.delivery?.is_ready_for_invoice);

    const getPricingSetter = (carId) => {
        return (value) => {
            // Avoid changing state unnecessarily
            if (carPricing[carId] === value) {
                return;
            }

            setCarPricing(determineBaseCarPricing({...carPricing, [carId]: value}));
        };
    };

    const {control, formState, handleSubmit, register, setValue, watch} = useForm({
        defaultValues: {lines},
    });

    const {append, fields, remove} = useFieldArray({
        control,
        name: 'lines',
    });

    const carLines = fields.filter((line) => line.type === 'CAR');

    if (Object.keys(formState.errors).length > 0) {
        alert(t('general.please_fill_whole_form'));
    }

    const onSubmit = (data) => {
        if (
            Object.values(cars).some((car) => car.sale.status !== 'SOLD' && car.sale.status !== 'INVOICED') &&
            !data.create_purchase_order
        ) {
            alert(t('validation.not_all_cars_sold'));
            return;
        }

        setLoadingMessage(t('general.save') + '…');

        if (!data.contacts || data.contacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }

        let selectedCustomer = Object.keys(data.contacts)[0];
        let customerContacts = data.contacts[selectedCustomer];
        let selectedContacts = Object.keys(customerContacts).filter((c) => customerContacts[c] === true);

        if (selectedContacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }

        const postData = {
            subsidiary: data.subsidiary,
            create_purchase_order: data.create_purchase_order,
            purchase_order_channel: data.sales_channel,
            mail_text: data.mail_text || '',
            customer_id: selectedCustomer,
            contacts: selectedContacts,
            lines: data.lines
                ? data.lines.map((line) => {
                      let data = {
                          type: line.type,
                          price: line.price,
                      };

                      if (line.type === 'CAR') {
                          const originalLine = lines.find((originalLine) => originalLine.car_id === parseInt(line.car_id));

                          data.required_advance = originalLine.required_advance;
                          data.car_id = line.car_id;
                          data.base_pricing = carPricing[line.car_id] || carPricing.GLOBAL;
                      }

                      if (line.type === 'EXTRA') {
                          data.description = line.description;
                      }

                      return data;
                  })
                : [],
            language: data.language,
            user_id: data.user_id,
            split_save: !!data.split_save,
        };

        authenticatedHttp()
            .post(submitUrl, postData)
            .then((response) => {
                dispatch(resetLines(WIDGET_TYPE.INVOICE));
                if (parseInt(data.send_after_save)) {
                    setLoadingMessage(t('general.send') + '…');
                    const contacts = customer.contacts.filter((contact) => {
                        return selectedContacts.includes(contact.id);
                    });
                    return authenticatedHttp()
                        .post('/invoices/send', {
                            mail_text: data.mail_text,
                            contacts: contacts.map((contact) => {
                                return {
                                    from: users.find((u) => u.id === data.user_id).email,
                                    to: contact.email,
                                    cc: [],
                                    bcc: [],
                                    subject: '',
                                };
                            }),
                            ids: response.data.ids,
                        })
                        .then(() => {
                            if (response.data.ids.length > 1) {
                                navigate('/facturen');
                                return;
                            }

                            navigate('/facturen/' + response.data.ids);
                        })
                        .catch((error) => {
                            setLoadingMessage(null);
                            alert(error.response.data.message);
                        });
                }

                if (response.data.ids.length > 1) {
                    navigate('/facturen');
                    return;
                }

                navigate('/facturen/' + response.data.ids);
            })
            .catch((response) => {
                alert(response.response.data.message);
                setLoadingMessage(null);
            });
    };

    const save = () => {
        setValue('send_after_save', 0);
        setValue('split_save', 0);
        checkForInvoicingWarnings();
    };

    const saveAndSend = () => {
        if (!watch('mail_text')) {
            if (!confirm(t('general.confirm_send_without_text'))) {
                return;
            }
        }

        setValue('split_save', 0);
        setValue('send_after_save', 1);
        checkForInvoicingWarnings();
    };

    const splitSave = () => {
        setValue('send_after_save', 0);
        setValue('split_save', 1);
        checkForInvoicingWarnings();
    };

    const splitSaveAndSend = () => {
        if (!watch('mail_text')) {
            if (!confirm(t('general.confirm_send_without_text'))) {
                return;
            }
        }
        setValue('send_after_save', 1);
        setValue('split_save', 1);
        checkForInvoicingWarnings();
    };

    const checkForInvoicingWarnings = () => {
        if (notAutomaticallyInvoicedCars.length > 0) {
            showModal(
                <ManualInvoicingWarningModal
                    cars={notAutomaticallyInvoicedCars}
                    handleContinue={() => {
                        handleSubmit(onSubmit)();
                        closeModal();
                    }}
                />
            );
            return;
        }

        handleSubmit(onSubmit)();
    };

    useEffect(() => {
        if (firstRender.current && editMode) {
            firstRender.current = false;
            return;
        }

        fields.forEach((field, index) => {
            const fieldName = `lines[${index}].price`;

            if (field.type !== 'CAR') {
                return;
            }

            setPriceBump(0);

            if (carPricing[field.car_id]) {
                if (carPricing[field.car_id] === 'CUSTOM') {
                    return;
                }

                setValue(fieldName, getPrice(carPricing[field.car_id], cars[field.car_id].sales_pricing));
                return;
            }

            setValue(fieldName, getPrice(carPricing.GLOBAL, cars[field.car_id].sales_pricing));
        });
    }, [carPricing]);

    const bumpPrices = () => {
        fields
            .filter((field) => field.type === 'CAR')
            .forEach((field, index) => {
                const fieldName = `lines[${index}].price`;
                const newPrice = parseFloat(field.price) + parseFloat(priceBump);
                setValue(fieldName, newPrice);
            });
    };

    useEffect(() => {
        let subsidiary = 'ECRENT';
        if (carLines.length > 0) {
            subsidiary = cars[carLines[0].car_id].subsidiary;
        }
        setValue('subsidiary', subsidiary);

        const singleSubsidiary = carLines.every((line) => cars[line.car_id].subsidiary === subsidiary);
        setSingleSubsidiary(singleSubsidiary);
        if (!singleSubsidiary) {
            alert(t('validation.different_subsidiaries'));
        }
    }, [fields]);

    const modals = {
        price_bump: {
            header: (
                <h2>
                    <strong>{t('general.bump_prices')}</strong>
                </h2>
            ),
            body: (
                <div className="form-group form-group-vertical">
                    <label htmlFor="bump">{t('general.sum')}</label>
                    <Money onChange={setPriceBump} value={priceBump} />
                </div>
            ),
            footer: (
                <Button
                    color="blue"
                    onClick={() => {
                        setOldShowModal(null);
                        bumpPrices();
                    }}
                >
                    {t('general.bump')}
                </Button>
            ),
        },
    };

    const setGlobalPricing = (value) => {
        Object.keys(carPricing).forEach((carId) => {
            setCarPricing((prevState) => ({...prevState, [carId]: value}));
        });
    };

    const modal = modals[oldShowModal] || {};

    return (
        <Layout
            className="documents documents-create invoice-create"
            header={() => <h1>{editMode ? t('general.invoice_edit') : t('general.invoice_create')}</h1>}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <input className="d-none" defaultValue={defaultFormValues.subsidiary} {...register('subsidiary')} />
                <input className="d-none" {...register('send_after_save')} type="number" />
                <input className="d-none" {...register('split_save')} type="number" />
                <div className="row">
                    <div className="col">
                        <General
                            control={control}
                            defaultFormValues={defaultFormValues}
                            register={register}
                            setValue={setValue}
                            showMail={carLines.length > 0}
                            watch={watch}
                        />
                    </div>
                    <div className="col">
                        <InvoiceCustomer
                            cars={cars}
                            control={control}
                            customer={[customer, setCustomer]}
                            defaultFormValues={defaultFormValues}
                            initialCustomerId={customerId}
                            lines={lines}
                            remove={remove}
                            setValue={setValue}
                            watch={watch}
                        />
                    </div>
                </div>
                <section className="no-padding items">
                    <div className="grid-table">
                        <div className="grid-table-row">
                            <div className="grid-table-cell">{t('general.items')}</div>
                            <div className="grid-table-cell">{t('general.advance')}</div>
                            <div className="grid-table-cell numerical">
                                {t('general.base_prices_on')}
                                <PricingSelect
                                    customOption={
                                        ['VARIOUS', 'CUSTOM'].includes(carPricing.GLOBAL) ? carPricing.GLOBAL : undefined
                                    }
                                    onChange={setGlobalPricing}
                                    value={carPricing.GLOBAL}
                                />
                            </div>
                            <div className="grid-table-cell numerical">
                                <span>{t('car.pricing.price')}</span>
                                <a
                                    className="ms-3 small"
                                    onClick={() => {
                                        setOldShowModal('price_bump');
                                    }}
                                >
                                    {t('general.bump_prices')}
                                </a>
                            </div>
                            <div className="grid-table-cell" />
                        </div>
                        {fields.map((field, index) => (
                            <FormRow
                                allowDelete={user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS}
                                car={cars[field.car_id]}
                                control={control}
                                field={field}
                                index={index}
                                key={field.id}
                                pricing={carPricing[field.car_id] || carPricing.GLOBAL}
                                register={register}
                                remove={remove}
                                requiredAdvance={lines[index] ? lines[index].required_advance : 'FIXED'}
                                setPricing={getPricingSetter(field.car_id)}
                                showVIN
                                watch={watch}
                            />
                        ))}
                    </div>
                    <p className="car-count">
                        {t('car.number_of_cars')}: {watch('lines').filter((line) => line.type === 'CAR').length}
                    </p>
                    <div className="lines-footer">
                        <div>
                            <a
                                className="btn--add"
                                onClick={() => {
                                    append({
                                        type: 'EXTRA',
                                        description: '',
                                        price: 0,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" /> {t('general.add_extra')}
                            </a>
                            <a
                                className={classnames({
                                    'btn--add': true,
                                    'btn--add-disabled': watch('lines').some(
                                        (line) => line.type === 'TRANSPORT' || line.sale_status === 'SOLD'
                                    ),
                                })}
                                onClick={() => {
                                    append({
                                        type: 'TRANSPORT',
                                        description: '',
                                        price: 0,
                                    });
                                }}
                            >
                                <i className="fa fa-plus" /> {t('general.add_transport')}
                            </a>
                        </div>
                        <div>
                            <h3>
                                <span>{t('general.total')}</span>
                                {moneyFormat(
                                    watch().lines.reduce((currentValue, line) => currentValue + parseFloat(line.price), 0)
                                )}
                            </h3>
                            <SaveButtons
                                book={!carLines.length > 0}
                                disableSplitSave={!singleSubsidiary || !(customer && customer.split_invoices_required)}
                                disabledSave={!singleSubsidiary || !(customer && !customer.split_invoices_required)}
                                save={save}
                                saveAndSend={saveAndSend}
                                splitSave={splitSave}
                                splitSaveAndSend={splitSaveAndSend}
                            />
                        </div>
                    </div>
                </section>
            </form>
            <Modal
                isOpen={oldShowModal !== null}
                onRequestClose={() => {
                    setOldShowModal(null);
                }}
                renderBody={() => modal.body}
                renderFooter={() => modal.footer}
                renderHeader={() => modal.header}
            />
        </Layout>
    );
};

export default InvoiceForm;
