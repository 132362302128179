import React from 'react';
import {useTranslation} from 'react-i18next';

const RegistrationStatus = ({car}) => {
    const {t} = useTranslation();

    let registrationStatus = 'NEW';
    if (car.documents.registration.first) {
        registrationStatus = 'NEW_WITH_REGISTRATION';
        if (car.mileage >= 500) {
            registrationStatus = 'SECOND_HAND';
        }
    }

    return <span className="badge badge-info">{t(`enum.registration_status.${registrationStatus}`)}</span>;
};

export default RegistrationStatus;
