import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import useFetchData from '../../../lib/UseFetchData';
import {dateFormat} from '../../../lib/helpers';

import {pageDefault, perPageDefault} from './List';

const Filters = ({pagination, searchParams}) => {
    let navigate = useNavigate();
    const {t} = useTranslation();

    const [userSoldBy] = useFetchData('/users/' + searchParams.sold_by, 'sold_by' in searchParams);
    const [userPurchasedBy] = useFetchData('/users/' + searchParams.purchased_by, 'purchased_by' in searchParams);
    const [companySupplier] = useFetchData('/companies/' + searchParams.supplied_by, 'supplied_by' in searchParams);
    const [companyTransporter] = useFetchData(
        '/companies/' + searchParams.transport_from_supplier_by,
        'transport_from_supplier_by' in searchParams
    );
    const [customerSoldTo] = useFetchData(`/customers/${searchParams.sold_to}`, 'sold_to' in searchParams);
    const [colors] = useFetchData('/colors');

    const removeFilter = (filter, value) => {
        let toRemove = searchParams[filter];

        if (Array.isArray(toRemove)) {
            let index = toRemove.indexOf(value);
            if (index !== -1) toRemove.splice(index, 1);

            if (toRemove.length === 0) {
                delete searchParams[filter];
            }
        } else {
            delete searchParams[filter];
        }

        let newQuery = '?query=' + JSON.stringify(searchParams);

        if (pagination.page && pagination.page !== pageDefault) {
            newQuery += '&page=' + pagination.page;
        }

        if (pagination.perPage && pagination.perPage !== perPageDefault) {
            newQuery += '&per_page=' + pagination.perPage;
        }

        navigate({pathname: `/autos/lijst`, search: newQuery});
    };

    const renderFilter = (field, value) => {
        if (Array.isArray(value)) {
            return value.map((v) => {
                return renderFilter(field, v);
            });
        } else {
            return (
                <li className="badge-filter" key={field + '_' + value}>
                    <span>
                        {t(`search.${field}`)}: {translateField(field, value)}
                    </span>
                    <a
                        onClick={() => {
                            removeFilter(field, value);
                        }}
                    >
                        <i className="fa fa-times" />
                    </a>
                </li>
            );
        }
    };

    const translateField = (field, value) => {
        if (field === 'sold_by') {
            return userSoldBy ? userSoldBy.full_name : '';
        }
        if (field === 'purchased_by') {
            return userPurchasedBy ? userPurchasedBy.full_name : '';
        }
        if (field === 'sold_to') {
            return customerSoldTo ? customerSoldTo.name : '';
        }
        if (field === 'supplied_by') {
            return companySupplier ? companySupplier.name : '';
        }
        if (field === 'transport_from_supplier_by') {
            return companyTransporter ? companyTransporter.name : '';
        }
        if (field === 'body_color' || field === 'interior_color') {
            return colors ? colors.find((color) => color.id === value).description : '';
        }
        if (['period_from', 'period_to', 'created_on', 'contract_expiry_from', 'contract_expiry_to'].includes(field)) {
            return dateFormat(value);
        }
        if (typeof value === 'boolean') {
            return value ? t(`search.yes`) : t(`search.no`);
        }

        if (field === 'published_websites' || field === 'not_published_websites') {
            return t('enum.price_options.WEBSITE_PRICING_' + value);
        }

        if (field === 'is_paid_by_customer' || field === 'is_paid_to_supplier') {
            return t('search.' + value.toLowerCase());
        }

        if (field === 'proof_of_registration_part_1_status' || field === 'proof_of_registration_part_2_status') {
            return t('enum.proof_of_registration_status.' + value);
        }

        if (
            [
                'proof_of_registration_part_1_delivery',
                'proof_of_registration_part_2_delivery',
                'e705_delivery',
                'certificate_of_conformity_delivery',
                'warranty_booklet_delivery',
            ].includes(field)
        ) {
            return t(`enum.document_delivery.${value}`);
        }

        if (field === 'preregistration') {
            return t('enum.preregistration_status.' + value);
        }

        if (
            [
                'arrival_status',
                'body_type',
                'certificate_of_conformity_status',
                'delivery_status',
                'document_delivery',
                'doors',
                'emissions_standard',
                'fuel',
                'gears',
                'transmission',
                'import_documents_status',
                'inspection_status',
                'location',
                'navigation_sd_card_status',
                'notice_of_objection_status',
                'purchase_type',
                'registration_status',
                'sale_status',
                'subsidiary',
                'supplier_declaration_status',
                'e705_status',
                'vat',
                'warranty_booklet_status',
                'warranty_status',
            ].includes(field)
        ) {
            return t(`enum.${field}.${value}`);
        }

        return value;
    };

    return (
        <Fragment>
            {Object.entries(searchParams).length > 0 && (
                <ul className="mt-5">
                    {Object.entries(searchParams)
                        .filter(([field]) => field !== 'date_fields')
                        .map(([field, value]) => (
                            <Fragment key={field}>{renderFilter(field, value)}</Fragment>
                        ))}
                </ul>
            )}
        </Fragment>
    );
};

export default Filters;
