import React from 'react';

import {moneyFormat} from '../../lib/helpers';

const BanklistBNPSummary = ({data}) => {
    const group85 = data.filter((row) => row['Groep'] === '85%');
    const total85 = group85.reduce((sum, row) => sum + parseFloat(row.Groepprijs), 0);
    const group75 = data.filter((row) => row['Groep'] === '75%');
    const total75 = group75.reduce((sum, row) => sum + parseFloat(row.Groepprijs), 0);
    const group60 = data.filter((row) => row['Groep'] === '60%');
    const total60 = group60.reduce((sum, row) => sum + parseFloat(row.Groepprijs), 0);
    const group0 = data.filter((row) => row['Groep'] === '0%');

    return (
        <section className="bnp-summary">
            <table>
                <tbody>
                    <tr>
                        <td>85%</td>
                        <td>{group85.length}</td>
                        <td>{moneyFormat(total85)}</td>
                    </tr>
                    <tr>
                        <td>75%</td>
                        <td>{group75.length}</td>
                        <td>{moneyFormat(total75)}</td>
                    </tr>
                    <tr>
                        <td>60%</td>
                        <td>{group60.length}</td>
                        <td>{moneyFormat(total60)}</td>
                    </tr>
                    <tr>
                        <td>0%</td>
                        <td>{group0.length}</td>
                        <td />
                    </tr>
                </tbody>
            </table>
        </section>
    );
};

export default BanklistBNPSummary;
