import {parse} from 'content-disposition';
import axios from 'axios';
import lscache from 'lscache';

export const http = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'Content-Type': `application/json; charset=utf-8`,
    },
});

export const authenticatedHttp = () => {
    const accessToken = lscache.get('zazinda_token');
    const token = accessToken ? accessToken.access_token : '';

    return axios.create({
        baseURL: process.env.API_URL,
        headers: {
            'Content-Type': `application/json; charset=utf-8`,
            Authorization: `Bearer ${token}`,
        },
    });
};

/**
 * @function upload_files Upload files.
 * @param files File form object.
 * @param car_id Auto ID
 * @param extraData Also add to similar cars.
 * @param cb Callback function returning access object
 * @returns {Object}
 * @private
 */
export const upload_files = (files, car_id, extraData, cb) => {
    const formData = new FormData();
    Object.entries(extraData).forEach(([key, value]) => {
        formData.append(key, value);
    });
    for (let i = 0; i < files.length; i++) {
        formData.append(`files[]`, files[i]);
    }
    const token = lscache.get('zazinda_token');
    if (token) {
        fetch(`${process.env.API_URL}/cars/${car_id}/files`, {
            method: `POST`,
            headers: {
                Authorization: `Bearer ${token.access_token}`,
            },
            body: formData,
        })
            .then((answer) => cb(answer))
            .catch((err) => console.log(err));
    } else {
        console.log(`Authorization error`);
    }
};

/**
 * @function upload_images Upload images.
 * @param images File form object.
 * @param car_id Auto ID
 * @param type Image type.
 * @param add_to_similar Also add to similar cars.
 * @param cb Callback function returning access object
 * @returns {Object}
 * @private
 */

export const upload_images = (images, car_id, type, add_to_similar, cb) => {
    const formData = new FormData();
    formData.append(`add_to_similar`, add_to_similar);
    formData.append(`type`, type);
    images.forEach((image) => {
        formData.append(`images[]`, image);
    });
    const token = lscache.get('zazinda_token');
    if (token) {
        fetch(`${process.env.API_URL}/cars/${car_id}/images`, {
            method: `POST`,
            headers: {
                Authorization: `Bearer ${token.access_token}`,
            },
            body: formData,
        })
            .then((answer) => cb(answer))
            .catch((err) => console.log(err));
    } else {
        console.log(`Authorization error`);
    }
};

export const downloadFile = (url, params, filename) =>
    new Promise((resolve, reject) => {
        const token = lscache.get('zazinda_token');
        if (!token) {
            reject('Authorization error');
        }

        fetch(process.env.API_URL + url, {
            method: `POST`,
            headers: {
                'Content-Type': `application/json; charset=utf-8`,
                Authorization: `Bearer ${token.access_token}`,
            },
            body: JSON.stringify(params),
        }).then((r) => {
            if (r.status !== 200) {
                reject(r.statusText);
            }

            const contentDisposition = parse(r.headers.get('Content-Disposition'));
            r.blob().then((blob_data) => {
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob_data, filename || contentDisposition.parameters.filename);
                } else {
                    const windowUrl = window.URL || window.webkitURL;
                    if (typeof windowUrl.createObjectURL === `function`) {
                        const blob = new Blob([blob_data], {
                            type: blob_data.type,
                        });
                        const url = windowUrl.createObjectURL(blob);
                        const anchor = document.createElement(`a`);
                        anchor.setAttribute(`href`, url);
                        anchor.setAttribute(`download`, contentDisposition.parameters.filename);
                        document.body.appendChild(anchor);
                        anchor.click();
                        windowUrl.revokeObjectURL(url);
                        document.body.removeChild(anchor);

                        resolve();
                    }
                }
            });
        });
    });
