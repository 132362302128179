import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import Layout from '../../../../components/Layout/Layout';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useAuth} from '../../../../lib/UseAuth';

import Spinner from '../../../../components/Spinner/Spinner';
import CreditNoteForm from './CreditNoteForm';

const LoadCreate = () => {
    const {i18n} = useTranslation();
    const {user} = useAuth();
    const [subsidiary, setSubsidiary] = useState(null);
    const location = useLocation();

    const [customer, setCustomer] = useState(null);
    const [invoiceState, setInvoice] = useState({status: 'loading'});

    const urlParams = new URLSearchParams(location.search);
    const invoiceId = urlParams.get('invoice_id');

    useEffect(() => {
        if (!invoiceId) {
            return;
        }

        authenticatedHttp()
            .get(`/invoices/${invoiceId}`)
            .then((response) => {
                const invoice = response.data.data;

                setSubsidiary(invoice.subsidiary);
                setInvoice({status: 'success', invoice});
            });
    }, []);

    if (!invoiceId) {
        return <Layout header={() => <h3>Invoice id required</h3>} />;
    }

    if (invoiceState.status === 'loading') {
        return <Spinner />;
    }

    const customerId = invoiceState.invoice.customer.data.id;
    const cars = invoiceState.invoice.lines
        .filter((line) => line.type === 'CAR')
        .map((line) => line.car)
        .reduce((map, car) => {
            map[car.car_id] = car;
            return map;
        }, {});
    const lines = invoiceState.invoice.lines
        .filter((line) => line.price > line.credited_amount)
        .map((line) => ({
            invoice_line_id: line.id,
            car_id: line.type === 'CAR' ? line.car.car_id : null,
            type: line.type,
            description: line.description,
            price: line.price - line.credited_amount,
        }));

    return (
        <CreditNoteForm
            cars={cars}
            customer={[customer, setCustomer]}
            customerId={customerId}
            defaultFormValues={{
                date: moment().format('YYYY-MM-DD'),
                dueDate: moment().add(30, 'days').format('YYYY-MM-DD'),
                language: i18n.language.toUpperCase(),
                mailText: '',
                selectedContacts: [],
                subsidiary: subsidiary,
                userId: invoiceState.invoice.user_id,
            }}
            invoiceId={invoiceId}
            lines={lines}
            submitUrl="/credit-notes"
        />
    );
};

export default LoadCreate;
