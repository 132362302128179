import React, {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import {useAuth} from '../../../../lib/UseAuth';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {moneyFormat} from '../../../../lib/helpers';

const VerifyPurchasePricingForm = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const {closeModal} = useModal();
    const [verifications, setVerifications] = useState(() =>
        cars.reduce(
            (verifications, car) => {
                if (car.purchase_pricing.commission_verified) {
                    verifications.commission.push(car.car_id);
                }
                if (car.purchase_pricing.import_duties_verified) {
                    verifications.import_duties.push(car.car_id);
                }
                if (car.purchase_pricing.transport_from_ecr_verified) {
                    verifications.transport_from_ecr.push(car.car_id);
                }
                if (car.purchase_pricing.transport_from_supplier_verified) {
                    verifications.transport_from_supplier.push(car.car_id);
                }

                return verifications;
            },
            {commission: [], import_duties: [], transport_from_ecr: [], transport_from_supplier: []}
        )
    );

    const toggleVerification = (type, carId) => {
        if (verifications[type].includes(carId)) {
            setVerifications({...verifications, [type]: verifications[type].filter((existingCarId) => existingCarId !== carId)});
        } else {
            setVerifications({...verifications, [type]: [...verifications[type], carId]});
        }
    };

    const [saving, toggleSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const onSubmit = (event) => {
        event.preventDefault();
        toggleSaving(true);

        const data = cars.map(({car_id}) => ({
            car_id,
            purchase_pricing: {
                import_duties_verified: verifications.import_duties.includes(car_id),
                commission_verified: verifications.commission.includes(car_id),
                transport: {
                    from_ecr: {
                        verified: verifications.transport_from_ecr.includes(car_id),
                    },
                    from_supplier: {
                        verified: verifications.transport_from_supplier.includes(car_id),
                    },
                },
            },
        }));

        authenticatedHttp()
            .patch('/cars', data)
            .then(() => {
                toggleSaving(false);
                closeModal();
                refresh();
            })
            .catch((response) => {
                toggleSaving(false);
                setErrors(
                    Object.values(response.response.data.errors).reduce((errors, keyErrors) => {
                        keyErrors.forEach((error) => errors.push(error));

                        return errors;
                    }, [])
                );
            });
    };

    return (
        <form onSubmit={onSubmit}>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="pl-0 pt-3 pb-3 border-top-0">
                                <strong>{t('search.car_id')}</strong>
                            </th>
                            <th className="p-3 border-top-0">
                                <strong>{t('search.vin')}</strong>
                            </th>
                            <th className="p-3 border-top-0">
                                <strong>{t('search.make')}</strong>
                            </th>
                            <th className="p-3 border-top-0 text-end">
                                <strong>{t('general.price')}</strong>
                            </th>
                            <th className="pr-0 pt-3 pb-3 border-top-0 text-end">
                                <strong>{t('car.pricing.verified')}</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cars.map((car) => (
                            <Fragment key={car.car_id}>
                                <>
                                    <tr>
                                        <td className="pl-0 pt-3 pb-3 align-top" rowSpan={4}>
                                            {car.car_id}
                                        </td>
                                        <td className="p-3 align-top" rowSpan={4}>
                                            {car.vin}
                                        </td>
                                        <td className="p-3 align-top" rowSpan={4}>
                                            {car.mmt}
                                        </td>
                                        <td className="p-3 align-top text-end border-0">
                                            {moneyFormat(car.purchase_pricing.transport_from_ecr)} <br />
                                            <span className="font-italic small">
                                                {t('car.purchase_pricing.transport_from_ecr')}
                                            </span>
                                        </td>
                                        <td className="border-0">
                                            <label className="checkbox p-1 pt-2 align-self-end justify-content-end align-items-start">
                                                <input
                                                    autoComplete="off"
                                                    checked={verifications.transport_from_ecr.includes(car.car_id)}
                                                    className="checkbox"
                                                    id={`transport_from_ecr_verified_` + car.car_id}
                                                    onChange={() => {
                                                        toggleVerification('transport_from_ecr', car.car_id);
                                                    }}
                                                    type="checkbox"
                                                />
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-3 align-top text-end border-0">
                                            {moneyFormat(car.purchase_pricing.transport_from_supplier)} <br />
                                            <span className="font-italic small">
                                                {t('car.purchase_pricing.transport_from_supplier')}
                                            </span>
                                        </td>
                                        <td className="border-0">
                                            <label className="checkbox p-1 pt-2 align-self-end justify-content-end align-items-start">
                                                <input
                                                    autoComplete="off"
                                                    checked={verifications.transport_from_supplier.includes(car.car_id)}
                                                    className="checkbox"
                                                    id={`transport_from_supplier_verified_` + car.car_id}
                                                    onChange={() => {
                                                        toggleVerification('transport_from_supplier', car.car_id);
                                                    }}
                                                    type="checkbox"
                                                />
                                            </label>
                                        </td>
                                    </tr>
                                </>
                                {user.permissions.CAN_VIEW_PURCHASING_INFORMATION ? (
                                    <>
                                        <tr>
                                            <td className="p-3 align-top text-end border-0">
                                                {moneyFormat(car.purchase_pricing.costs.commission)} <br />
                                                <span className="font-italic small">{t('car.purchase_pricing.commission')}</span>
                                            </td>
                                            <td className="border-0">
                                                <label className="checkbox p-1 pt-0 align-self-end justify-content-end align-items-start">
                                                    <input
                                                        autoComplete="off"
                                                        checked={verifications.commission.includes(car.car_id)}
                                                        className="checkbox"
                                                        id={`transport_from_supplier_verified_` + car.car_id}
                                                        onChange={() => {
                                                            toggleVerification('commission', car.car_id);
                                                        }}
                                                        type="checkbox"
                                                    />
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-3 align-top text-end">
                                                {moneyFormat(car.purchase_pricing.costs.import_duties)} <br />
                                                <span className="font-italic small">
                                                    {t('car.purchase_pricing.import_duties')}
                                                </span>
                                            </td>
                                            <td>
                                                <label className="checkbox p-1 pt-0 align-self-end justify-content-end align-items-start">
                                                    <input
                                                        autoComplete="off"
                                                        checked={verifications.import_duties.includes(car.car_id)}
                                                        className="checkbox"
                                                        id={`import_duties_` + car.car_id}
                                                        onChange={() => {
                                                            toggleVerification('import_duties', car.car_id);
                                                        }}
                                                        type="checkbox"
                                                    />
                                                </label>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <>
                                        <tr />
                                        <tr />
                                    </>
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
                {errors && (
                    <div className="alert alert-danger">
                        <ul>
                            {errors.map((error, i) => (
                                <li key={i}>{error}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" disabled={saving} submit>
                    {t('general.verify_purchase_pricing')}
                </Button>
                {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
            </Modal.Footer>
        </form>
    );
};

export default VerifyPurchasePricingForm;
