import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useForm from '../../lib/UseForm';
import {authenticatedHttp} from '../../lib/zaza-client';

import Button from '../Button/Button';
import TextArea from '../Form/TextArea';
import Modal from './Modal';
import Radio from '../Form/Radio';

const MailConsignationContractModal = ({carId, customerId, isOpen, onRequestClose}) => {
    const {i18n, t} = useTranslation();

    const [submitting, toggleSubmitting] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [contactsEmpty, setContactsEmpty] = useState(false);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        authenticatedHttp()
            .get('/customers/' + customerId + '/contacts')
            .then((response) => {
                setContacts(response.data.data);
                setContactsEmpty(response.data.data.length === 0);
            });
    }, [customerId]);

    const form = useForm(
        {
            contact: null,
            body: t('general.mail_consignation_contract_default'),
        },
        {
            onSave: ({data}) => {
                toggleSubmitting(true);

                authenticatedHttp()
                    .post('/mail/consignation-contract', {
                        language: i18n.language,
                        car_id: carId,
                        contact: data.contact,
                        body: data.body,
                    })
                    .then(() => {
                        toggleSubmitting(false);
                        onRequestClose();
                    });
            },
        }
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            renderBody={() => (
                <Fragment>
                    <div className="form-group form-group-vertical">
                        <label>{t('general.send_to')}</label>
                        {contacts.map((contact) => (
                            <Radio
                                {...form.fields.contact()}
                                disabled={!contact.email}
                                key={contact.id}
                                label={`${contact.full_name} ${contact.email ? `(${contact.email})` : ''}`}
                                value={contact.id}
                            />
                        ))}
                        {contactsEmpty && <span>{t('validation.no_contacts_in_teamleader')}</span>}
                    </div>
                    <div className="form-group form-group-vertical">
                        <label>{t('general.mail_text')}</label>
                        <TextArea {...form.fields.body()} rows="10" />
                    </div>
                </Fragment>
            )}
            renderFooter={() => (
                <Button color="blue" disabled={submitting || !form.data.contact} onClick={() => form.handleSubmit()}>
                    {submitting ? t('general.submitting') : t('general.send')}
                </Button>
            )}
            renderHeader={() => (
                <h2>
                    <strong>{t('general.mail_consignation_contract')}</strong>
                </h2>
            )}
        />
    );
};

export default MailConsignationContractModal;
