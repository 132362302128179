import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import {useWidget} from '../../../../lib/UseWidget';
import {WIDGET_TYPE} from '../../../../lib/WidgetReducer';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useAuth} from '../../../../lib/UseAuth';

import Spinner from '../../../../components/Spinner/Spinner';
import InvoiceForm from './InvoiceForm';

const LoadCreate = () => {
    const {i18n} = useTranslation();
    const {user} = useAuth();
    const [subsidiary, setSubsidiary] = useState(null);
    const [widgetLines] = useWidget();
    const location = useLocation();

    const [carState, setCarState] = useState({status: 'loading'});
    const [customer, setCustomer] = useState(null);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const {cars = {}} = carState;

    const lines = (() => {
        const urlParams = new URLSearchParams(location.search);
        const blankDocument = !!urlParams.has('empty');

        if (blankDocument) {
            return [];
        }

        const lines = widgetLines[WIDGET_TYPE.INVOICE] || [];

        lines.forEach((line) => {
            if (line.type !== 'CAR') {
                return;
            }

            line.required_advance = 'NONE';

            const car = cars[line.car_id];
            if (!car) {
                return;
            }

            line.sale_status = car.sale.status;
            line.transport_from_ecr_invoiced = car.sale.transport_from_ecr_invoiced;

            if (car.sale.status === 'SOLD') {
                line.required_advance = car.sales_pricing.required_advance_type;
                return;
            }

            if (car.purchase_type === 'CUSTOM_ORDER') {
                line.required_advance = 'PERCENTAGE';
            } else if (car.localisation.arrival_status !== 'ARRIVED') {
                line.required_advance = 'FIXED_NEW';

                if (purchaseOrders.length) {
                    const latestPurchaseOrder = purchaseOrders.reduce((previous, current) =>
                        moment(current.date).isAfter(moment(previous.date)) ? current : previous
                    );

                    if (moment(latestPurchaseOrder.date).isAfter(moment().subtract(1, 'y'))) {
                        line.required_advance = 'FIXED';
                    }
                }
            }
        });

        return lines;
    })();

    useEffect(() => {
        if (!lines.some((line) => line.car_id)) {
            setCarState({status: 'none'});
            return;
        }

        authenticatedHttp()
            .post('/cars/search', {
                car_id: lines.filter((line) => line.car_id).map((line) => line.car_id),
            })
            .then((response) => {
                setSubsidiary(response.data.data[0].subsidiary);

                const newState = response.data.data.reduce((map, car) => {
                    map[car.car_id] = car;
                    return map;
                }, {});
                setCarState({status: 'success', cars: newState});
            });
    }, []);

    useEffect(() => {
        if (!customer) {
            return;
        }

        authenticatedHttp()
            .get(`/customers/${customer.id}/purchase-orders?order_by=date,DESC&per_page=1`)
            .then((response) => {
                setPurchaseOrders(response.data.data);
            });
    }, [customer]);

    if (carState.status === 'loading') {
        return <Spinner />;
    }

    let defaultCarPricing = {};

    if (carState.status !== 'none') {
        lines
            .filter((line) => line.type === 'CAR')
            .forEach((line) => {
                if (cars[line.car_id].sale.status === 'SOLD') {
                    if (line.price !== cars[line.car_id].sales_pricing.sold_for) {
                        line.price = cars[line.car_id].sales_pricing.sold_for;
                        line.base_pricing = 'CUSTOM';
                    }
                }

                defaultCarPricing[line.car_id] = line.base_pricing || 'CUSTOM';
            });
    }

    return (
        <InvoiceForm
            cars={cars}
            customer={[customer, setCustomer]}
            defaultCarPricing={defaultCarPricing}
            defaultFormValues={{
                createPurchaseOrder:
                    Object.values(cars).length > 0 && Object.values(cars).every((car) => car.sale.status === 'FOR_SALE'),
                purchaseOrderChannel: 'ECR',
                date: moment().format('YYYY-MM-DD'),
                dueDate: moment().add(30, 'days').format('YYYY-MM-DD'),
                language: i18n.language.toUpperCase(),
                mailText: '',
                selectedContacts: [],
                subsidiary: subsidiary,
                userId: user.id,
                customer: null,
            }}
            lines={lines}
            submitUrl="/invoices"
        />
    );
};

export default LoadCreate;
