import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useWidget} from '../../../../lib/UseWidget';
import {WIDGET_TYPE} from '../../../../lib/WidgetReducer';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useAuth} from '../../../../lib/UseAuth';

import PurchaseOrderForm from './PurchaseOrderForm';
import Spinner from '../../../../components/Spinner/Spinner';
import {extractCustomerIdFromLocalStorage} from '../../../../lib/helpers';

const LoadCreate = () => {
    const {i18n} = useTranslation();
    const {user} = useAuth();
    const [widgetLines] = useWidget();
    const [subsidiary, setSubsidiary] = useState(null);

    const [cars, setCars] = useState({});
    const [customer, setCustomer] = useState(null);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [isLoadingCars, toggleIsLoadingCars] = useState(true);

    const lines = (() => {
        const urlParams = new URLSearchParams(location.search);
        const blankDocument = !!urlParams.has('empty');

        if (blankDocument) {
            return [];
        }

        const lines = widgetLines[WIDGET_TYPE.PURCHASE_ORDER] || [];

        lines.forEach((line) => {
            if (line.type !== 'CAR') {
                return;
            }

            line.required_advance = 'NONE';

            const car = cars[line.car_id];
            if (!car) {
                return;
            }

            if (car.purchase_type === 'CUSTOM_ORDER') {
                line.required_advance = 'PERCENTAGE';
            } else if (car.localisation.arrival_status !== 'ARRIVED') {
                line.required_advance = 'FIXED_NEW';

                if (purchaseOrders.length) {
                    const latestPurchaseOrder = purchaseOrders.reduce((previous, current) =>
                        moment(current.date).isAfter(moment(previous.date)) ? current : previous
                    );

                    if (moment(latestPurchaseOrder.date).isAfter(moment().subtract(1, 'y'))) {
                        line.required_advance = 'FIXED';
                    }
                }
            }
        });

        return lines;
    })();

    const initialCustomerId = extractCustomerIdFromLocalStorage(lines);

    useEffect(() => {
        if (!lines.some((line) => line.car_id)) {
            toggleIsLoadingCars(false);
            return;
        }

        authenticatedHttp()
            .post('/cars/search', {
                car_id: lines.filter((line) => line.car_id).map((line) => line.car_id),
            })
            .then((response) => {
                setSubsidiary(response.data.data[0].subsidiary);

                const newState = {};
                response.data.data.forEach((car) => (newState[car.car_id] = car));

                setCars(newState);
                toggleIsLoadingCars(false);
            });
    }, []);

    useEffect(() => {
        if (!customer) {
            return;
        }

        authenticatedHttp()
            .get(`/customers/${customer.id}/purchase-orders?order_by=date,DESC&per_page=1`)
            .then((response) => {
                setPurchaseOrders(response.data.data);
            });
    }, [customer]);

    let defaultCarPricing = {};
    lines.forEach((line) => {
        defaultCarPricing[line.car_id] = line.base_pricing || 'CUSTOM';
    });

    if (isLoadingCars) {
        return <Spinner />;
    }

    return (
        <PurchaseOrderForm
            cars={cars}
            customer={[customer, setCustomer]}
            defaultCarPricing={defaultCarPricing}
            defaultFormValues={{
                channel: 'ECR',
                date: moment().format('YYYY-MM-DD'),
                language: i18n.language.toUpperCase(),
                mailText: '',
                noAdvanceRequired: false,
                selectedContacts: [],
                userId: user.id,
                subsidiary: subsidiary,
            }}
            initialCustomerId={initialCustomerId}
            lines={lines}
            submitUrl="/purchase-orders"
        />
    );
};

export default LoadCreate;
