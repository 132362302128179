import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/Modal/NewModal';
import VerifyPurchasePricingForm from './VerifyPurchasePricingForm';

const VerifyPurchasePricing = ({cars, refresh}) => {
    const {t} = useTranslation();

    return (
        <Modal title={t('general.verify_purchase_pricing')}>
            <VerifyPurchasePricingForm cars={cars} refresh={refresh} />
        </Modal>
    );
};

export default VerifyPurchasePricing;
