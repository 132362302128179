import produce from 'immer';
import lscache from 'lscache';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';
import {authenticatedHttp} from '../../../lib/zaza-client';
import {setPageTitle} from '../../../lib/helpers';
import {SearchColumnProvider} from '../../../lib/UseSearchResultColumns';

import Layout from '../../../components/Layout/Layout';
import List, {pageDefault, perPageDefault} from './List';
import Spinner from '../../../components/Spinner/Spinner';

import './List.scss';

import availableTableColumns from '../../../models/car_search_result_columns.json';

const LoadList = () => {
    const {t} = useTranslation();
    const location = useLocation();
    let navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [cars, setCars] = useState(null);
    const [searchParams, setSearchParams] = useState({});
    const [pagination, setPagination] = useState(null);
    const [orderBy, setOrderBy] = useState({
        key: lscache.get('orderKey') ? lscache.get('orderKey') : 'make',
        path: lscache.get('orderPath') ? lscache.get('orderPath') : 'model.make',
        direction: lscache.get('orderDir') ? lscache.get('orderDir') : 'ASC',
    });
    const [totals, setTotals] = useState({pages: 0, cars: 0});

    const groupSimilar = lscache.get('group_similar') && !searchParams.similar_to;

    useEffect(() => {
        lscache.set('orderDir', orderBy.direction);
        lscache.set('orderKey', orderBy.key);
        lscache.set('orderPath', orderBy.path);
    }, [orderBy]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const queryString = urlParams.get('query');

        if (queryString) {
            const queryStringJson = JSON.parse(queryString);
            setSearchParams(queryStringJson);
            lscache.set('search_parameters', queryStringJson, 60 * 12);
        }

        if (!queryString) {
            lscache.remove('search_parameters');
        }

        const orderByString = urlParams.get('order_by');

        if (orderByString) {
            const [key, direction] = orderByString.split(',');
            const path = (() => {
                if (key === 'vin') {
                    return 'car.vin';
                }
                if (key === 'id') {
                    return 'car.id';
                }
                if (key === 'make') {
                    return 'model.make';
                }

                return availableTableColumns.find((c) => c.col === key).path;
            })();
            setOrderBy({
                key: key,
                path: path,
                direction: direction,
            });
        }

        setPagination({
            page: parseInt(urlParams.get('page')) || pageDefault,
            perPage: parseInt(urlParams.get('per_page')) || perPageDefault,
        });
    }, [location]);

    const searchCars = () => {
        const orderByString = orderBy.key + ',' + orderBy.direction;

        setCars(null);

        if (pagination === null) {
            return;
        }

        authenticatedHttp()
            .post(
                '/cars/search' +
                    '?page=' +
                    pagination.page +
                    '&per_page=' +
                    pagination.perPage +
                    '&order_by=' +
                    [orderBy.path, orderBy.direction].join(',') +
                    '&group_similar=' +
                    (groupSimilar ? '1' : '0'),
                searchParams
            )
            .then((response) => {
                setCars(response.data.data);
                if (pagination.page !== response.data.meta.page) {
                    setPagination({...pagination, page: response.data.meta.page});
                }
                setTotals({
                    pages: response.data.meta.pages,
                    cars: response.data.meta.total,
                });

                const searchResults = response.data.data.map((c) => c.car_id);
                lscache.set('search_results', searchResults);
                lscache.set('search_order_by', orderByString);
                lscache.set('search_page', pagination.page);
                lscache.set('search_per_page', pagination.perPage);
            })
            .catch(() => {
                setIsError(true);
                lscache.set('orderDir', null);
                lscache.set('orderKey', null);
                lscache.set('orderPath', null);
            });
    };

    useEffect(searchCars, [pagination, orderBy]);

    useEffect(() => {
        setPageTitle(t('nav.search_results'));
    }, []);

    const orderBySetter = (key, path) => {
        const newDirection = orderBy.direction === 'ASC' ? 'DESC' : 'ASC';

        if (orderBy.key === key) {
            // If key is the same as current sorting key, flip the direction
            setOrderBy({
                key,
                path,
                direction: newDirection,
            });
        } else {
            // If key is different, change it and reset direction to descending
            setOrderBy({
                key,
                path,
                direction: 'ASC',
            });
        }

        // Reset page
        setPagination(
            produce((draft) => {
                draft.page = 1;
            })
        );

        navigate(
            `/autos/lijst?query=${JSON.stringify(searchParams)}&order_by=${key},${newDirection}` +
                `&page=${pagination.page}&per_page=${pagination.perPage}`
        );
    };

    if (isError) {
        return <Layout header={() => <h1>{t('search.error')}</h1>} />;
    }

    if (cars === null) {
        return <Spinner />;
    }

    return (
        <SearchColumnProvider>
            <List
                cars={cars}
                groupSimilar={groupSimilar}
                orderBy={orderBy}
                orderBySetter={orderBySetter}
                pagination={pagination}
                searchCars={searchCars}
                searchParams={searchParams}
                totals={totals}
            />
        </SearchColumnProvider>
    );
};

export default LoadList;
