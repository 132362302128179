import React from 'react';

const HistoryItemToggle = ({children, eventLog}) => {
    return React.Children.map(children, (child) => React.cloneElement(child, {eventName: eventLog.event_name}));
};

HistoryItemToggle.Generic = ({children, eventName}) => {
    return [
        'CarConsignationWasCancelled',
        'CarLocationWasChanged',
        'CarWasConsignated',
        'CarWasCreated',
        'CarWasDeleted',
        'CarWasRestored',
        'CarWasListedForSale',
        'CarWasReserved',
        'CarWasUnlisted',
        'PaymentWasRegistered',
        'PaymentWasUndone',
        'PurchaseDocumentWasRegistered',
        'SupplierWasPaid',
        'TruckPriceWasUpdated',
        'PurchasePriceWasUpdated',
        'PurchasePriceCurrencyRateWasUpdated',
        'OutgoingAdvanceWasRegistered',
        'OutgoingPaymentWasRegistered',
        'TransportFromSupplierOrganizerChanged',
    ].includes(eventName)
        ? children
        : null;
};
HistoryItemToggle.Payment = ({children, eventName}) => {
    return ['PaymentWasRegistered', 'PaymentWasUndone'].includes(eventName) ? children : null;
};
HistoryItemToggle.Offered = ({children, eventName}) => {
    return eventName === 'QuoteWasCreated' ? children : null;
};
HistoryItemToggle.Sold = ({children, eventName}) => {
    return eventName === 'CarWasSold' ? children() : null;
};
HistoryItemToggle.Invoiced = ({children, eventName}) => {
    return eventName === (eventName === 'InvoiceWasBooked' ? 'InvoiceWasBooked' : 'CarWasInvoiced') ? children() : null;
};
HistoryItemToggle.Credited = ({children, eventName}) => {
    return eventName === 'CarWasCredited' ? children() : null;
};
HistoryItemToggle.Transport = ({children, eventName}) => {
    return eventName === 'TransportWasRequested' ? children : null;
};
HistoryItemToggle.Equipment = ({children, eventName}) => {
    return eventName === 'CarEquipmentWasUpdated' ? children : null;
};
HistoryItemToggle.Contact = ({children, eventName}) => {
    return ['WebsiteStatusWasUpdated', 'ContactWasAddedFromZazinda', 'ContactWasAddedFromWebsite'].includes(eventName)
        ? children
        : null;
};

export default HistoryItemToggle;
