import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../../../components/Button/Button';
import DatePicker from '../../../../components/Form/DatePicker';
import Enum from '../../../../components/Form/Enum';
import Input from '../../../../components/Form/Input';
import Modal from '../../../../components/Modal/NewModal';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';

const EditPurchasePricing = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const sameCurrency = cars.every((car) => car.purchase_pricing.currency === cars[0].purchase_pricing.currency);
    const [saving, setSaving] = useState(false);
    const [purchasePricingState, setPurchasePricingState] = useState({
        currency: null,
        currency_rate: null,
        internal_contract_number: null,
        contract_number: false,
        contract_expiry_date: null,
    });

    if (!sameCurrency) {
        return (
            <Modal title={t('car.purchase_pricing.purchase_data')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <p>{t('validation.cars_must_have_same_currency')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const handleSave = () => {
        setSaving(true);

        if (purchasePricingState.currency !== 'EUR' && !purchasePricingState.currency_rate) {
            alert(t('validation.expected_currency_rate'));
            setSaving(false);
            return;
        }

        const patch = cars.map((car) => {
            return {
                car_id: car.car_id,
                purchase_pricing: {
                    costs: {
                        purchase_price: {
                            ...purchasePricingState,
                        },
                    },
                },
            };
        });

        authenticatedHttp()
            .patch('/cars', patch)
            .then(() => {
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('car.purchase_pricing.purchase_data')}>
            <Modal.Body>
                <table className="table table-documents">
                    <tbody className="form-group">
                        <tr>
                            <th>
                                <strong>{t('general.currency')}</strong>
                            </th>
                            <td>
                                <Enum
                                    enumName="currency"
                                    onChange={(currency) => setPurchasePricingState({...purchasePricingState, currency})}
                                    placeholder={t('general.currency')}
                                    value={purchasePricingState.currency || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <strong>{t('car.expected_currency_rate')}</strong>
                            </th>
                            <td>
                                <Input
                                    allowZeroWhileEditting={true}
                                    onChange={(currency_rate) =>
                                        setPurchasePricingState({...purchasePricingState, currency_rate})
                                    }
                                    value={purchasePricingState.currency_rate || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <strong>{t('car.internal_contract_number')}</strong>
                            </th>
                            <td>
                                <Input
                                    onChange={(internal_contract_number) =>
                                        setPurchasePricingState({...purchasePricingState, internal_contract_number})
                                    }
                                    value={purchasePricingState.internal_contract_number || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <strong>{t('car.contract_number')}</strong>
                            </th>
                            <td>
                                <Input
                                    onChange={(contract_number) =>
                                        setPurchasePricingState({...purchasePricingState, contract_number})
                                    }
                                    value={purchasePricingState.contract_number || ''}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <strong>{t('car.contract_expiry_date')}</strong>
                            </th>
                            <td>
                                <DatePicker
                                    onChange={(contract_expiry_date) =>
                                        setPurchasePricingState({...purchasePricingState, contract_expiry_date})
                                    }
                                    value={purchasePricingState.contract_expiry_date}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" disabled={saving} onClick={handleSave}>
                    {t('general.save')}
                </Button>
                {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
            </Modal.Footer>
        </Modal>
    );
};

export default EditPurchasePricing;
