import {useEffect, useState} from 'react';
import {authenticatedHttp} from './zaza-client';

const useFetchData = (url, doFetch = true, transformData = (data) => data) => {
    const [{data, isError, isLoading}, setState] = useState({data: null, isLoading: doFetch, isError: false});

    const loadData = () => {
        if (doFetch) {
            if (!isLoading) {
                setState({data: null, isError: false, isLoading: true});
            }
            authenticatedHttp()
                .get(url)
                .then((response) => {
                    const data = transformData(response.data.data);
                    setState({isError: false, isLoading: false, data});
                })
                .catch(() => {
                    setState({data: null, isLoading: false, isError: true});
                });
        }
    };

    useEffect(loadData, [url]);

    return [data, isLoading, isError, loadData];
};

export default useFetchData;
