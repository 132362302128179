import React, {useEffect, useState} from 'react';
import useFetchData from '../../../lib/UseFetchData';
import {useTranslation} from 'react-i18next';
import {dateFormat} from '../../../lib/helpers';

const Location = ({arrivalStatus, localisation}) => {
    const [doFetch, setDoFetch] = useState(true);
    const {t} = useTranslation();
    const [locationChangedBy] = useFetchData(
        '/users/' + localisation.location.changed_by,
        localisation.location.changed_by !== null && doFetch
    );
    const [customerLocalisation] = useFetchData(
        `/customers/${localisation.location.consigned_with}`,
        localisation.location.consigned_with && doFetch
    );

    useEffect(() => {
        return () => {
            setDoFetch(false);
        };
    }, []);

    if (arrivalStatus !== 'ARRIVED') {
        return (
            <div className="section__head">
                <ul className="list-advertisement">
                    <li>
                        <strong>{t('car.localisation.arrival_status')}</strong>
                        <p>
                            <a className="link link--blue" href="#localisation">
                                <span>{t(`enum.arrival_status.${arrivalStatus}`)}</span>
                            </a>
                        </p>
                    </li>
                </ul>
            </div>
        );
    }

    if (!localisation.location.where) {
        return null;
    }

    return (
        <div className="section__head">
            <ul className="list-advertisement">
                <li>
                    <strong>{t('car.localisation.location')}</strong>
                    <p>
                        <a className="link link--blue" href="#localisation">
                            <span>{t(`enum.location.${localisation.location.where}`)}</span>
                        </a>
                        <small className="ms-2">
                            {locationChangedBy && `${t('car.by')} ${locationChangedBy.full_name}`}
                            {localisation.location.changed_on &&
                                ` ${t('car.on')} ${dateFormat(localisation.location.changed_on)}`}
                        </small>
                    </p>
                </li>
                {localisation.location.where === 'CONSIGNMENT' && customerLocalisation && (
                    <li>
                        <strong>{t('car.customer')}</strong>
                        <a
                            className="link link--blue"
                            href={customerLocalisation.teamleader_url}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span>{customerLocalisation.name}</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default React.memo(Location);
