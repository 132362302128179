import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Dropdown from '../../../components/Button/Dropdown';
import DeleteCarModal from '../../../components/Modal/DeleteCarModal';
import RestoreCarModal from '../../../components/Modal/RestoreCarModal';
import {useAuth} from '../../../lib/UseAuth';
import {useModal} from '../../../lib/UseModal';
import {useWidget} from '../../../lib/UseWidget';
import {addCars, WIDGET_TYPE} from '../../../lib/WidgetReducer';
import BookTransportFromEcr from '../List/Modal/BookTransportFromEcr';
import BookTransportFromSupplier from '../List/Modal/BookTransportFromSupplier';
import EditSaleStatus from '../List/Modal/EditSaleStatus';
import RegisterIncomingAdvance from '../List/Modal/RegisterIncomingAdvance';
import RegisterOutgoingAdvance from '../List/Modal/RegisterOutgoingAdvance';
import RegisterOutgoingPayment from '../List/Modal/RegisterOutgoingPayment';
import RegisterPurchaseDocument from '../List/Modal/RegisterPurchaseDocument';

const Actions = ({car, refreshCar}) => {
    const {t} = useTranslation();
    const [, dispatch] = useWidget();
    const {showModal} = useModal();
    const {user} = useAuth();

    const actions = {};

    actions.forSaleActions = {
        [t('general.add_to_quote')]: () => {
            dispatch(addCars(WIDGET_TYPE.QUOTE, [car]));
        },
        [t('general.add_to_purchase_order')]: () => {
            dispatch(addCars(WIDGET_TYPE.PURCHASE_ORDER, [car]));
        },
    };

    actions.invoice = {
        [t('general.add_to_invoice')]: () => {
            dispatch(addCars(WIDGET_TYPE.INVOICE, [car]));
        },
    };

    actions.registerIncomingAdvance = {
        [t('general.register_incoming_advance')]: (e) => {
            e.preventDefault();
            showModal(<RegisterIncomingAdvance cars={[car]} customers={[]} refresh={refreshCar} showOverview={false} />);
        },
    };

    actions.saleStatus = {
        [t('search.edit_sale_status')]: (e) => {
            e.preventDefault();
            showModal(<EditSaleStatus cars={[car]} refresh={refreshCar} user={user} />);
        },
    };

    actions.actions = {
        [t('general.book_transport.from_supplier')]: (e) => {
            e.preventDefault();
            showModal(<BookTransportFromSupplier cars={[car]} refresh={refreshCar} showOverview={false} />);
        },
        [t('general.book_transport.from_ecr')]: (e) => {
            e.preventDefault();
            showModal(<BookTransportFromEcr cars={[car]} refresh={refreshCar} showOverview={false} />);
        },
    };

    actions.registerPurchaseDocument = {
        [t('general.register_purchase_document')]: (e) => {
            e.preventDefault();
            showModal(<RegisterPurchaseDocument cars={[car]} refresh={refreshCar} />);
        },
    };

    actions.registerOutgoingAdvance = {
        [t('general.register_outgoing_advance')]: (e) => {
            e.preventDefault();
            showModal(<RegisterOutgoingAdvance cars={[car]} refresh={refreshCar} />);
        },
    };
    actions.registerOutgoingPayment = {
        [t('general.register_outgoing_payment')]: (e) => {
            e.preventDefault();
            showModal(<RegisterOutgoingPayment cars={[car]} refresh={refreshCar} />);
        },
    };
    actions.deleteCar = {
        [t('general.delete_car')]: (e) => {
            e.preventDefault();
            showModal(<DeleteCarModal cars={[car]} refresh={refreshCar} />);
        },
    };
    actions.restoreCar = {
        [t('general.restore_car')]: (e) => {
            e.preventDefault();
            showModal(<RestoreCarModal cars={[car]} refresh={refreshCar} />);
        },
    };

    const availableActions =
        car.sale.status === 'FOR_SALE'
            ? {
                  ...actions.forSaleActions,
                  ...actions.invoice,
                  ...actions.actions,
                  ...actions.saleStatus,
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerPurchaseDocument),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingAdvance),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingPayment),
                  ...(user.permissions.CAN_DELETE_CARS && actions.deleteCar),
              }
            : car.sale.status === 'SOLD'
            ? {
                  ...actions.invoice,
                  ...actions.actions,
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerPurchaseDocument),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingAdvance),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingPayment),
                  ...actions.registerIncomingAdvance,
              }
            : car.sale.status === 'INVOICED'
            ? {
                  ...actions.actions,
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerPurchaseDocument),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingAdvance),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingPayment),
                  ...actions.registerIncomingAdvance,
                  ...(user.permissions.CAN_DELETE_CARS && actions.deleteCar),
              }
            : car.sale.status === 'NOT_FOR_SALE'
            ? {
                  ...actions.actions,
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerPurchaseDocument),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingAdvance),
                  ...(user.permissions.CAN_VIEW_PURCHASING_INFORMATION && actions.registerOutgoingPayment),
                  ...actions.saleStatus,
              }
            : {...actions.actions};

    const deleteActions = {...(user.permissions.CAN_ACCESS_FINANCE_LISTS && actions.restoreCar)};

    if (car.date.deleted_on) {
        if (Object.keys(deleteActions).length < 1) {
            return;
        }

        return (
            <>
                <Dropdown
                    actions={deleteActions}
                    align={'right'}
                    buttonProps={{
                        label: t('general.actions'),
                        equalHeight: true,
                    }}
                />
            </>
        );
    }

    return (
        <>
            <Dropdown
                actions={availableActions}
                align="right"
                buttonProps={{
                    label: t('general.actions'),
                    equalHeight: true,
                }}
            />
        </>
    );
};

export default Actions;
