import React from 'react';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../../lib/zaza-client';

const Files = ({carId, files = [], refreshCar, setLoadingMessage}) => {
    const {t} = useTranslation();

    const deleteFile = (fileId) => {
        setLoadingMessage(t('general.deleting_file'));
        authenticatedHttp()
            .delete('/cars/' + carId + '/files/' + fileId)
            .then(() => {
                refreshCar();
                setLoadingMessage(null);
            });
    };

    return (
        <div className="files">
            <ul>
                {files.map((file) => {
                    return (
                        <li key={file.id}>
                            <a href={file.url} rel="noopener noreferrer" target="_blank">
                                {file.name}
                            </a>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (window.confirm(t('general.confirm_delete_file'))) {
                                        deleteFile(file.id);
                                    }
                                }}
                            >
                                <i className="fa fa-times-circle" />
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default React.memo(Files);
