import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../components/Button/Button';
import useFetchData from '../../../lib/UseFetchData';
import {useModal} from '../../../lib/UseModal';
import SummaryE705 from './Modal/Summary-E705';
import SummaryPreregistration from './Modal/Summary-Preregistration';

const SummaryButton = ({car, carSupplier, form, refresh, text}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();

    const [customer] = useFetchData(`/customers/${form.data.sale.sold_to}`, !!form.data.sale.sold_to);
    const carIsSoldOrInvoiced = form.data.sale.status === 'INVOICED' || form.data.sale.status === 'SOLD';
    const isBelgianCustomer = customer && customer.address.country_code === 'BE';

    let e705FilledOut = false;
    let registrationOrdinal = undefined;
    if (form.data.documents.registration) {
        if (
            form.data.documents.registration.first &&
            form.data.documents.registration.first.e705 &&
            form.data.documents.registration.first.e705.status !== 'NOT_NECESSARY'
        ) {
            e705FilledOut = true;
            registrationOrdinal = 'first';
        }
        if (
            form.data.documents.registration.second &&
            form.data.documents.registration.second.e705 &&
            form.data.documents.registration.second.e705.status !== 'NOT_NECESSARY'
        ) {
            e705FilledOut = true;
            registrationOrdinal = 'second';
        }
    }

    const showPreregistration =
        ((registrationOrdinal && form.fields.documents.registration[registrationOrdinal].preregistration) ||
            form.data.documents.no_registration.e705.status === 'ACCEPTED') &&
        carIsSoldOrInvoiced &&
        isBelgianCustomer;

    const showE705Doc =
        form.data.documents.registration?.second?.e705?.status === 'NECESSARY' ||
        (form.data.documents.registration?.first?.e705?.status === 'NECESSARY' &&
            !form.data.documents.registration?.second?.e705?.status) ||
        form.data.documents.no_registration?.e705?.status === 'NECESSARY';

    const handleClick = () => {
        if (text === 'E705') {
            showModal(
                <SummaryE705
                    car={car}
                    carSupplier={carSupplier}
                    form={form}
                    refresh={refresh}
                    registrationOrdinal={registrationOrdinal}
                    showE705Doc={showE705Doc}
                />
            );
            return;
        }
        if (car.registration_status === 'SECOND_HAND' || car.registration_status === 'NEW_WITH_REGISTRATION') {
            showModal(
                <SummaryPreregistration
                    car={car}
                    form={form}
                    refresh={refresh}
                    registrationOrdinal={registrationOrdinal}
                    secondHand
                    showPreregistration={showPreregistration}
                />
            );
            return;
        }
        showModal(<SummaryPreregistration car={car} form={form} refresh={refresh} showPreregistration={showPreregistration} />);
    };

    return (
        <Button onClick={handleClick} shadow small>
            {t('general.summary.' + text)}
        </Button>
    );
};

export default SummaryButton;
