import React, {useState} from 'react';
import Enum from '../../../../components/Form/Enum';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useModal} from '../../../../lib/UseModal';
import {useTranslation} from 'react-i18next';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import Select from '../../../../components/Form/Select';

const EditNoticeOfObjection = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, setSaving] = useState(false);
    const [noticeOfObjection, setNoticeOfObjection] = useState(null);

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);

        const patch = cars.map((car) => {
            const data = {
                car_id: car.car_id,
                documents: {
                    registration: {},
                },
            };

            if (noticeOfObjection !== null) {
                if (car.documents.registration.second === null) {
                    data.documents.registration = {
                        first: {
                            notice_of_objection: {
                                status: noticeOfObjection,
                            },
                        },
                    };
                    return data;
                }

                data.documents.registration = {
                    second: {
                        notice_of_objection: {
                            status: noticeOfObjection,
                        },
                    },
                };
            }

            return data;
        });

        authenticatedHttp()
            .patch('/cars', patch)
            .then(() => {
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('general.modify_notice_of_objection')}>
            <form onSubmit={handleSave}>
                <Modal.Body>
                    <table className="table">
                        <tbody className="form-group">
                            <tr>
                                <th className={'border-none'}>
                                    <strong>{t('car.documents.notice_of_objection')}</strong>
                                </th>

                                <td className={'border-none'}>
                                    <Enum
                                        allowClear
                                        enumName="notice_of_objection_status"
                                        onChange={(value) => setNoticeOfObjection(value)}
                                        placeholder={t('car.documents.notice_of_objection')}
                                        value={noticeOfObjection}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer>
                    <Button color="blue" submit>
                        {t('general.modify_notice_of_objection')}
                    </Button>

                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditNoticeOfObjection;
