import React from 'react';
import {useTranslation} from 'react-i18next';
import Dropdown from '../../../components/Button/Dropdown';
import {useAuth} from '../../../lib/UseAuth';
import {useModal} from '../../../lib/UseModal';
import {useWidget} from '../../../lib/UseWidget';
import {addCars, WIDGET_TYPE} from '../../../lib/WidgetReducer';
import {downloadFile} from '../../../lib/zaza-client';
import BookTransportFromEcr from './Modal/BookTransportFromEcr';
import BookTransportFromSupplier from './Modal/BookTransportFromSupplier';
import CreateNewsLetterModal from './Modal/CreateNewsLetterModal';
import DeleteCars from './Modal/DeleteCars';
import EditArrival from './Modal/EditArrival';
import EditDelivery from './Modal/EditDelivery';
import EditNoticeOfObjection from './Modal/EditNoticeOfObjection';
import EditPurchasePricing from './Modal/EditPurchasePricing';
import EditSaleStatus from './Modal/EditSaleStatus';
import RegisterIncomingAdvance from './Modal/RegisterIncomingAdvance';
import RegisterOutgoingAdvance from './Modal/RegisterOutgoingAdvance';
import RegisterOutgoingPayment from './Modal/RegisterOutgoingPayment';
import RegisterPurchaseDocument from './Modal/RegisterPurchaseDocument';
import RestoreCars from './Modal/RestoreCars';
import VerifyPurchasePricing from './Modal/VerifyPurchasePricing';
import CompareCarsModal from './Modal/CompareCarsModal';

const BulkActions = ({groupSimilar, refresh, selectedCars, setLoadingMessage, unselectCars}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();
    const {user} = useAuth();
    const [, dispatch] = useWidget();

    const allowCompare = selectedCars.length > 1 && selectedCars.length <= 4;

    // Group all types with permission checks
    const groupedActions = {
        [t('general.add_to_quote')]: (e) => {
            e.preventDefault();

            dispatch(
                addCars(
                    WIDGET_TYPE.QUOTE,
                    selectedCars.filter((car) => car.sale.status === 'FOR_SALE')
                )
            );

            unselectCars();
        },
        [t('nav.compare')]: allowCompare
            ? (e) => {
                  e.preventDefault();

                  showModal(<CompareCarsModal cars={selectedCars} groupSimilar={groupSimilar} />);
              }
            : null,
        [t('general.make_newsletter')]: user.permissions.CAN_CREATE_NEWSLETTER
            ? (e) => {
                  e.preventDefault();
                  showModal(<CreateNewsLetterModal cars={selectedCars} groupSimilar={groupSimilar} />);
              }
            : null,
    };

    const sales = {
        [t('search.edit_sale_status')]: (e) => {
            e.preventDefault();
            showModal(<EditSaleStatus cars={selectedCars} refresh={refresh} user={user} />);
        },
        [t('general.add_to_quote')]: (e) => {
            e.preventDefault();

            dispatch(
                addCars(
                    WIDGET_TYPE.QUOTE,
                    selectedCars.filter((car) => car.sale.status === 'FOR_SALE')
                )
            );

            unselectCars();
        },
        [t('general.add_to_purchase_order')]: (e) => {
            e.preventDefault();

            dispatch(
                addCars(
                    WIDGET_TYPE.PURCHASE_ORDER,
                    selectedCars.filter((car) => car.sale.status === 'FOR_SALE')
                )
            );

            unselectCars();
        },
        [t('general.add_to_invoice')]: (e) => {
            e.preventDefault();
            dispatch(
                addCars(
                    WIDGET_TYPE.INVOICE,
                    selectedCars.filter((car) => car.sale.status === 'FOR_SALE' || car.sale.status === 'SOLD')
                )
            );

            unselectCars();
        },
    };

    const logistics = {
        [t('general.book_transport.from_supplier')]: (e) => {
            e.preventDefault();
            showModal(<BookTransportFromSupplier cars={selectedCars} />);
        },
        [t('general.book_transport.from_ecr')]: (e) => {
            e.preventDefault();
            showModal(<BookTransportFromEcr cars={selectedCars} />);
        },
        [t('search.edit_arrival_status')]: (e) => {
            e.preventDefault();
            showModal(<EditArrival cars={selectedCars} refresh={refresh} />);
        },
        [t('search.edit_delivery')]: (e) => {
            e.preventDefault();
            showModal(<EditDelivery cars={selectedCars} refresh={refresh} />);
        },
    };

    const finance = {
        [t('general.register_purchase_document')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(<RegisterPurchaseDocument cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.register_outgoing_advance')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(<RegisterOutgoingAdvance cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.register_outgoing_payment')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(<RegisterOutgoingPayment cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.verify_purchase_pricing')]: user.permissions.CAN_VERIFY_OUTGOING_COSTS
            ? (e) => {
                  e.preventDefault();
                  showModal(<VerifyPurchasePricing cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.register_incoming_advance')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(
                      <RegisterIncomingAdvance
                          cars={selectedCars}
                          customers={selectedCars.map((car) => car.sold_to.data)}
                          refresh={refresh}
                      />
                  );
              }
            : null,
        [t('general.modify_notice_of_objection')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(<EditNoticeOfObjection cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.modify_purchase_pricing')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            ? (e) => {
                  e.preventDefault();
                  showModal(<EditPurchasePricing cars={selectedCars} refresh={refresh} />);
              }
            : null,
    };

    const general = {
        [t('nav.download')]: (e) => {
            e.preventDefault();

            setLoadingMessage(t('general.generating_document'));
            downloadFile(`/cars/download`, {car_ids: selectedCars.map((car) => car.car_id)}).then(() => {
                setLoadingMessage(null);
            });
        },
        [t('nav.compare')]: allowCompare
            ? (e) => {
                  e.preventDefault();

                  showModal(<CompareCarsModal cars={selectedCars} groupSimilar={groupSimilar} />);
              }
            : null,
        [t('general.make_newsletter')]: user.permissions.CAN_CREATE_NEWSLETTER
            ? (e) => {
                  e.preventDefault();
                  showModal(<CreateNewsLetterModal cars={selectedCars} groupSimilar={groupSimilar} />);
              }
            : null,
        [t('general.delete_cars')]: user.permissions.CAN_DELETE_CARS
            ? (e) => {
                  e.preventDefault();
                  showModal(<DeleteCars cars={selectedCars} refresh={refresh} />);
              }
            : null,
        [t('general.restore_car')]: user.permissions.CAN_DELETE_CARS
            ? (e) => {
                  e.preventDefault();
                  showModal(<RestoreCars cars={selectedCars} refresh={refresh} />);
              }
            : null,
    };

    // Check if all object values are null, if true => hide title
    const showSalesTitle = Object.values(sales).some((value) => value);
    const showLogisticsTitle = Object.values(sales).some((value) => value);
    const showFinancesTitle = Object.values(finance).some((value) => value);
    const showGeneralTitle = Object.values(general).some((value) => value);

    // Map all actions with optional titles
    const actions = {
        [t('general.sales')]: showSalesTitle ? true : null,
        ...sales,
        [t('general.logistics')]: showLogisticsTitle ? true : null,
        ...logistics,
        [t('general.finance')]: showFinancesTitle ? true : null,
        ...finance,
        [t('car.nav.general')]: showGeneralTitle ? true : null,
        ...general,
    };

    const availableActions = groupSimilar ? {...groupedActions} : {...actions};

    return (
        <div>
            <Dropdown
                actions={availableActions}
                buttonProps={{
                    label: t('general.actions'),
                }}
                disabled={selectedCars.length === 0}
            />
            {selectedCars.length > 0 && (
                <span style={{lineHeight: '38px', marginLeft: '1em', verticalAlign: 'sub'}}>
                    {t('general.selected', {count: selectedCars.length})}
                </span>
            )}
        </div>
    );
};

export default BulkActions;
