import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';
import {useAuth} from '../../../../lib/UseAuth';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {setPageTitle} from '../../../../lib/helpers';

import Button from '../../../../components/Button/Button';
import Dropdown from '../../../../components/Button/Dropdown';
import Checkbox from '../../../../components/Form/Checkbox';
import Layout from '../../../../components/Layout/Layout';
import Spinner from '../../../../components/Spinner/Spinner';
import RegisterIncomingAdvance from '../../../Cars/List/Modal/RegisterIncomingAdvance';
import ListRow from './ListRow';
import Filters from '../../Filters';

import '../../Documents.scss';
import './List.css';
import $ from 'jquery';
import SetInvoiceOnModal from './SetInvoiceOnModal';

const List = () => {
    useEffect(() => {
        setPageTitle(t('nav.purchase_orders'));
    }, []);

    const {t} = useTranslation();
    const {user} = useAuth();
    const {showModal} = useModal();
    const navigate = useNavigate();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [totals, setTotals] = useState({pages: 0, items: 0});
    const [filters, setFilters] = useQueryParams({
        status: StringParam,
        customer: StringParam,
        user: NumberParam,
        date_from: StringParam,
        date_to: StringParam,
        subsidiary: StringParam,
        number_from: StringParam,
        number_to: StringParam,
        page: withDefault(NumberParam, 1),
        perPage: withDefault(NumberParam, 50),
        path: withDefault(StringParam, 'po.date'),
        direction: withDefault(StringParam, 'DESC'),
    });

    const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
    const [users] = useFetchData('/users?include_inactive');

    const [purchaseOrders, setPurchaseOrders] = useState(null);
    const fetchPurchaseOrders = () => {
        setLoadingMessage(t('general.loading'));
        setSelectedPurchaseOrderIds([]);

        let query = '/purchase-orders?';

        if (filters.subsidiary) {
            query += '&subsidiary=' + filters.subsidiary;
        }
        if (filters.status) {
            query += '&status=' + filters.status;
        }
        if (filters.customer) {
            query += '&customer_id=' + filters.customer;
        }
        if (filters.user) {
            query += '&user_id=' + filters.user;
        }
        if (filters.date_from) {
            query += '&date_from=' + filters.date_from;
        }
        if (filters.date_to) {
            query += '&date_to=' + filters.date_to;
        }
        if (filters.number_from) {
            query += '&number_from=' + filters.number_from;
        }
        if (filters.number_to) {
            query += '&number_to=' + filters.number_to;
        }

        query += '&order_by=' + filters.path + ',' + filters.direction;
        query += '&page=' + filters.page + '&per_page=' + filters.perPage;

        authenticatedHttp()
            .get(query)
            .then((response) => {
                setPurchaseOrders(response.data.data);
                setLoadingMessage(null);
                setTotals({
                    pages: response.data.meta.pages,
                    items: response.data.meta.total,
                });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    useEffect(fetchPurchaseOrders, [filters, ]);

    const stickyColumnHeader = () => {
        let height = window.innerHeight - 60;
        height -= $('header').outerHeight();
        height -= $('.content > .d-flex').outerHeight();
        if ($('.grid-table').outerHeight() > height) {
            $('section').height(height);
        }
    };

    useEffect(stickyColumnHeader, [purchaseOrders]);

    const orderBySetter = (path) => {
        if (filters.path === path) {
            // If path is the same as current sorting path, flip the direction
            setFilters({
                ...filters,
                path,
                direction: filters.direction === 'ASC' ? 'DESC' : 'ASC',
                page: 1,
            })
        } else {
            // If path is different, update it and reset direction to descending
            setFilters({
                ...filters,
                path,
                direction: 'ASC',
                page: 1, // Reset page
            });
        }
    };

    const selectedPurchaseOrders = useMemo(
        () =>
            purchaseOrders ? purchaseOrders.filter((purchaseOrder) => selectedPurchaseOrderIds.includes(purchaseOrder.id)) : [],
        [purchaseOrders, selectedPurchaseOrderIds]
    );

    const selectedCars = useMemo(() => {
        if (!purchaseOrders) {
            return [];
        }

        let cars = [];
        selectedPurchaseOrders.forEach((purchaseOrder) =>
            purchaseOrder.lines.filter((line) => line.type === 'CAR').forEach((line) => cars.push(line.car))
        );

        return cars;
    }, [purchaseOrders, selectedPurchaseOrderIds]);

    if (isError) {
        return <Layout header={() => <h1>{t('general.loading_error')}</h1>} />;
    }

    if (!purchaseOrders) {
        return <Spinner />;
    }

    return (
        <Layout
            className="documents documents-list purchase-order-list"
            header={() => (
                <div className="header-wrapper">
                    <div className="header-pagination">
                        <a
                            className={'btn-arrow btn-arrow-left ' + (filters.page > 1 ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page - 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                        <div>
                            <h1 className="mb-0">
                                {t('nav.purchase_orders')} ({totals.items})
                            </h1>
                            <p className="mb-0 small">
                                {t('general.page')} {filters.page} {t('general.of')} {totals.pages}
                            </p>
                        </div>
                        <a
                            className={'btn-arrow btn-arrow-right ' + (filters.page < totals.pages ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page + 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            navigate('/bestelbonnen/creatie?empty');
                        }}
                    >
                        {t('general.purchase_order_create')}
                    </Button>
                </div>
            )}
            showWidget
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <div className="filter-container">
                <Dropdown
                    actions={{
                        [t('general.register_incoming_advance')]: (e) => {
                            e.preventDefault();
                            const cars = selectedCars.filter((car) => {
                                return ['SOLD', 'INVOICED'].includes(car.sale.status);
                            });
                            showModal(
                                <RegisterIncomingAdvance
                                    cars={cars}
                                    customers={purchaseOrders.map((purchaseOrder) => purchaseOrder.customer.data)}
                                    refresh={fetchPurchaseOrders}
                                />
                            );
                        },
                        [t('general.invoice_on')]: user.permissions.CAN_VIEW_PURCHASING_INFORMATION
                            ? (e) => {
                                  e.preventDefault();
                                  showModal(
                                      <SetInvoiceOnModal
                                          selectedPurchaseOrders={selectedPurchaseOrders}
                                          setLoadingMessage={setLoadingMessage}
                                      />
                                  );
                              }
                            : null,
                    }}
                    buttonProps={{
                        label: t('general.actions'),
                    }}
                    disabled={selectedPurchaseOrderIds.length === 0}
                />
                {selectedPurchaseOrderIds.length > 0 && (
                    <span style={{lineHeight: '38px', marginLeft: '1em', verticalAlign: 'sub'}}>
                        {t('general.selected', {count: selectedPurchaseOrderIds.length})}
                    </span>
                )}
                <Filters filters={filters} setFilters={setFilters} showNotInvoicedStatus showRelistedStatus showUnsignedStatus />
            </div>
            <section className="no-padding">
                <div className="grid-table">
                    <div className="grid-table-row">
                        <div className="grid-table-cell checkbox">
                            <Checkbox
                                field="selection-toggle"
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedPurchaseOrderIds(purchaseOrders.map((purchaseOrder) => purchaseOrder.id));
                                    } else {
                                        setSelectedPurchaseOrderIds([]);
                                    }
                                }}
                                semiChecked={selectedPurchaseOrderIds.length > 0}
                                value={selectedPurchaseOrderIds.length === purchaseOrders.length}
                            />
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('po.id');
                            }}
                        >
                            <span>{t('general.purchase_order_nr')}</span>
                            {filters.path === 'po.id' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'po.id' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('po.subsidiary');
                            }}
                        >
                            <span>{t('search.subsidiary')}</span>
                            {filters.path === 'po.subsidiary' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'po.subsidiary' && filters.direction === 'DESC' && (
                                <i className="fa fa-caret-down" />
                            )}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('po.status');
                            }}
                        >
                            <span>{t('general.status')}</span>
                            {filters.path === 'po.status' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'po.status' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('po.date');
                            }}
                        >
                            <span>{t('car.date')}</span>
                            {filters.path === 'po.date' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'po.date' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div className="grid-table-cell">{t('car.customer')}</div>
                        <div className="grid-table-cell">{t('car.seller')}</div>
                        <div className="grid-table-cell w-100">{t('general.items')}</div>
                        <div className="grid-table-cell numerical">
                            <span className={'me-3'}>{t('general.total_incl')}</span>
                        </div>
                    </div>
                    {purchaseOrders.map((purchaseOrder) => {
                        return (
                            <ListRow
                                key={purchaseOrder.id}
                                purchaseOrder={purchaseOrder}
                                selectedPurchaseOrderIds={selectedPurchaseOrderIds}
                                setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
                                users={users}
                            />
                        );
                    })}
                </div>
            </section>
        </Layout>
    );
};

export default React.memo(List);
