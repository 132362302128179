import React, {forwardRef} from 'react';
import {orderByProperty} from '../../lib/helpers';
import Select from './Select';
import {useTranslation} from 'react-i18next';

const SORTED_ENUMS = ['country', 'emissions_standard'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Enum = forwardRef(({enumName, ...selectProps}, ref) => {
    const {t} = useTranslation();

    const data = require(`../../enums/${enumName}.json`);

    let options = data.map((key) => ({
        value: key,
        label: t(`enum.${enumName !== 'country_with_outside_of_eu' ? enumName : 'country'}.${key}`),
    }));

    if (SORTED_ENUMS.includes(enumName)) {
        options = orderByProperty(options, 'label');
    }

    return <Select options={options} {...selectProps} />;
});

export default React.memo(Enum);
