import produce from 'immer';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Enum from '../../../components/Form/Enum';
import FormGroup from '../../../components/Form/FormGroup';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import useFetchData from '../../../lib/UseFetchData';
import {authenticatedHttp} from '../../../lib/zaza-client';

const TypeSelection = ({disabled, form, selectedMake, setSelectedMake}) => {
    const {t} = useTranslation();
    const [enableTypeFields, toggleTypeFields] = useState(false);
    const [doFetch, setDoFetch] = useState(true);

    useEffect(() => {
        return () => {
            setDoFetch(false);
        };
    }, []);

    // Makes
    const [makes] = useFetchData('/makes');
    const makeOptions = useMemo(() => {
        if (makes) {
            return makes.map((make) => ({value: make.name, label: make.name}));
        }

        if (form.data.type.model.make) {
            return [{value: form.data.type.model.make, label: form.data.type.model.make}];
        }

        return [];
    }, [makes]);

    // Selected make
    const [modelOptions, setModelOptions] = useState(
        form.data.type.model.id ? [{label: form.data.type.model.name, value: form.data.type.model.id}] : []
    );
    const fetchModelsForMake = () => {
        if (!selectedMake) {
            return;
        }

        if (doFetch) {
            authenticatedHttp()
                .get('/models/' + selectedMake)
                .then((response) => {
                    setModelOptions(response.data.data.map((model) => ({label: model.name, value: model.id})));
                });
        }
    };
    useEffect(fetchModelsForMake, [selectedMake]);

    // Selected type
    const [types, setTypes] = useState([]);
    const fetchTypes = () => {
        if (!form.data.type.model.id) {
            return;
        }

        authenticatedHttp()
            .get('/types?model_id=' + form.data.type.model.id)
            .then((response) => {
                setTypes(response.data.data);
            });
    };
    useEffect(() => {
        fetchTypes();
    }, [form.data.type.model.id]);

    let typeOptions = useMemo(() => {
        if (types.length > 0) {
            return types.map((type) => ({label: type.name, value: type.id}));
        }

        if (form.data.type.id) {
            return [{label: form.data.type.name, value: form.data.type.id}];
        }

        return [];
    }, [types]);

    const [selectedType, setSelectedType] = useState(form.data.type.id);
    const handleTypeChange = (value) => {
        setSelectedType(value);
        const type = types.find((type) => type.id === value);
        type.model = form.data.type.model;

        form.setData({...form.data, type});
        toggleTypeFields(value === null);
    };

    const handleTypeCreate = (value) => {
        toggleTypeFields(true);
        setSelectedType(null);

        setTypes([
            ...types,
            {
                id: null,
                name: value,
                fuel: form.data.type.fuel,
                cc: form.data.type.cc,
                hp: form.data.type.hp,
                kw: form.data.type.kw,
                gears: form.data.type.gears,
                cylinders: form.data.type.cylinders,
                battery_capacity: form.data.type.battery_capacity,
                range: form.data.type.range,
            },
        ]);
        form.setData(
            produce((draft) => {
                draft.type.id = null;
                draft.type.name = value;
            })
        );
    };

    const makeError = form.errors.find((error) => error.path === 'type.model.make');
    const typeError = form.errors.find((error) => error.path === 'type.id');

    return (
        <fieldset>
            <legend>{t('car.title_type')}</legend>
            <div className="row">
                <FormGroup field="type.model.make">
                    <Select
                        disabled={disabled}
                        error={makeError ? makeError.message : undefined}
                        onChange={(value) => {
                            setSelectedMake(value);
                            setSelectedType(null);
                        }}
                        options={makeOptions}
                        value={selectedMake}
                    />
                </FormGroup>
                <FormGroup field="type.model.name">
                    <Select
                        {...form.fields.type.model.id()}
                        disabled={disabled || modelOptions.length === 0}
                        options={modelOptions}
                    />
                </FormGroup>
                <div className="col-12">
                    <div className="form-group row">
                        <label className="col-2 col-form-label" htmlFor="type.name">
                            {t(`car.type.name`)}
                        </label>
                        <div className="col-10">
                            <Select
                                disabled={disabled || form.data.type.model.id === null}
                                error={typeError ? typeError.message : undefined}
                                onChange={handleTypeChange}
                                onCreate={handleTypeCreate}
                                options={typeOptions}
                                value={selectedType}
                            />
                        </div>
                    </div>
                </div>
                <FormGroup field="type.fuel">
                    <Enum enumName="fuel" {...form.fields.type.fuel()} disabled={!enableTypeFields} />
                </FormGroup>
                <FormGroup field="type.gears">
                    <Enum enumName="gears" {...form.fields.type.gears()} disabled={!enableTypeFields} />
                </FormGroup>
                <FormGroup field="type.cc">
                    <Input {...form.fields.type.cc()} disabled={!enableTypeFields} />
                </FormGroup>
                <FormGroup field="type.kw">
                    <Input
                        suffix="kW"
                        {...form.fields.type.kw({
                            onChange: (name, value, draft) => {
                                draft.type.hp = Math.round(parseFloat(value) * 1.36);
                            },
                        })}
                        disabled={!enableTypeFields}
                    />
                </FormGroup>
                <FormGroup field="type.hp">
                    <Input suffix="PK" {...form.fields.type.hp()} disabled={!enableTypeFields} />
                </FormGroup>
                <FormGroup field="type.cylinders">
                    <Enum enumName="cylinders" {...form.fields.type.cylinders()} disabled={!enableTypeFields} />
                </FormGroup>
                {form.data.type.fuel === 'ELECTRIC' && (
                    <Fragment>
                        <FormGroup field="type.battery_capacity">
                            <Input suffix="kWh" {...form.fields.type.battery_capacity()} disabled={!enableTypeFields} />
                        </FormGroup>
                        <FormGroup field="type.range">
                            <Input suffix="km" {...form.fields.type.range()} disabled={!enableTypeFields} />
                        </FormGroup>
                    </Fragment>
                )}
            </div>
        </fieldset>
    );
};

export default TypeSelection;
