import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import {useAuth} from '../../../../lib/UseAuth';
import {useModal} from '../../../../lib/UseModal';
import {useSearchResultColumns} from '../../../../lib/UseSearchResultColumns';
import TableColumn from './TableColumn';
import {DragDropContext, Droppable} from '@hello-pangea/dnd';
import 'overlayscrollbars/overlayscrollbars.css';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

const TableColumnsConfig = () => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const {addColumn, availableColumns, removeColumn, selectedColumns} = useSearchResultColumns();
    const [columnFilter, setColumnFilter] = useState('');

    const remainingColumns = availableColumns.filter(
        (availableColumn) =>
            !selectedColumns.includes(availableColumn) &&
            t('search.' + availableColumn.col)
                .toLowerCase()
                .includes(columnFilter.toLowerCase()) &&
            !(
                ['naked_purchase_price', 'purchase_price', 'supplied_by', 'internal_order_code', 'margin'].includes(
                    availableColumn.col
                ) && !user.permissions.CAN_VIEW_PURCHASING_INFORMATION
            )
    );

    const {closeModal} = useModal();

    const onDragEnd = (result) => {
        // We are removing a column
        if (result.destination.droppableId === 'available') {
            removeColumn(result.draggableId);
            return;
        }

        // Adding or moving a column
        if (result.destination.droppableId === 'selected') {
            addColumn(result.draggableId, result.destination.index);
        }
    };

    return (
        <Modal subtitle={t('search.drag_columns')} title={t('general.columns')}>
            <Modal.Body className="no-scroll">
                <div className="mb-2 pe-2 w-50">
                    <input
                        className="form-control"
                        onChange={(e) => setColumnFilter(e.currentTarget.value)}
                        placeholder={t('nav.search')}
                        type="text"
                        value={columnFilter}
                    />
                </div>
                <div className="d-flex search-columns gap-3">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={'available'}>
                            {(provided) => (
                                <OverlayScrollbarsComponent>
                                    <ul className={'column-selection'} ref={provided.innerRef} {...provided.droppableProps}>
                                        {remainingColumns.map((column, index) => {
                                            return <TableColumn index={index} key={column.col} tc={column} />;
                                        })}
                                        {provided.placeholder}
                                    </ul>
                                </OverlayScrollbarsComponent>
                            )}
                        </Droppable>
                        <Droppable droppableId={'selected'}>
                            {(provided) => (
                                <OverlayScrollbarsComponent>
                                    <ul className={'column-selection'} ref={provided.innerRef} {...provided.droppableProps}>
                                        {selectedColumns.map((column, index) => {
                                            return <TableColumn index={index} key={column.col} tc={column} />;
                                        })}
                                        {provided.placeholder}
                                    </ul>
                                </OverlayScrollbarsComponent>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button color="blue" onClick={closeModal}>
                    {t('general.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TableColumnsConfig;
