import _ from 'lodash';
import Resizer from 'react-image-file-resizer';
import {format, isValid, parseISO, parseJSON} from 'date-fns';

export const setPageTitle = (title) => {
    document.title = 'ZaZinda - ' + title;
};

export const currencyFormat = (currency) => {
    let symbol = '';
    switch (currency) {
        case 'EUR':
            symbol = '€ ';
            break;
        case 'NOK':
        case 'SEK':
            symbol = 'kr ';
            break;
        case 'CZK':
            symbol = 'Kč ';
            break;
        case 'DKK':
            symbol = 'kr. ';
            break;
        case 'HUF':
            symbol = 'Ft ';
            break;
        case 'PLN':
            symbol = 'zł ';
            break;
    }

    return symbol;
}

export const moneyFormat = (price, currency = 'EUR') => {
    return currencyFormat(currency) + roundDecimals(price);
};

export const roundDecimals = (numeric, digits = 2) => {
    return parseFloat(numeric).toLocaleString('nl-BE', {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

export const dateFormat = (date) => {
    if (!date) {
        return '';
    }
    return format(isValid(parseJSON(date)) ? parseJSON(date) : parseISO(date), 'dd/MM/yyyy');
};

export const orderOptions = (a, b) => {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    return 0;
};

export const extractCustomerIdFromLocalStorage = (initialLines) => {
    if (initialLines.length === 0) {
        return null;
    }

    const firstLineWithCustomerId = initialLines.find((line) => line.customer_id);
    return firstLineWithCustomerId ? firstLineWithCustomerId.customer_id : null;
};

export const getObjectValueAtPath = (obj, path, fallback) => {
    return _.get(obj, path, fallback);
};

export const orderByProperty = (collection, prop) => {
    return _.orderBy(collection, [prop], ['asc']);
};

export const determineBaseCarPricing = (defaultCarPricing) => {
    let basePricings = [];

    // Don't account for any global pricing already set to recalculate
    let {GLOBAL, ...remainingBasePricing} = defaultCarPricing;
    Object.values(remainingBasePricing).forEach((pricing) => {
        if (!basePricings.includes(pricing)) {
            basePricings.push(pricing);
        }
    });

    GLOBAL = basePricings.length === 1 ? basePricings[0] : 'VARIOUS';

    return {...defaultCarPricing, GLOBAL};
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const copyToClipBoard = (text) => {
    if (window.isSecureContext) {
        navigator.clipboard.writeText(text);
    }
};
export const resizeImages = async (files) => {
    return new Promise((resolve) => {
        const promises = [];

        function resize(file) {
            return new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file,
                    2440,
                    10000,
                    'PNG',
                    85,
                    0,
                    (uri) => {
                        resolve(uri);
                    },
                    'file'
                );
            });
        }

        files.forEach((file) => {
            promises.push(resize(file));
        });

        Promise.all(promises).then((resizedFiles) => {
            resolve(resizedFiles);
        });
    });
};

export const partition = (arr, predicate) => {
    return arr.reduce(
        (partitioned, current) => {
            if (predicate(current)) {
                partitioned[0].push(current);
            } else {
                partitioned[1].push(current);
            }

            return partitioned;
        },
        [[], []]
    );
};
