import _ from 'lodash';

const typeFields = [
    'type.model.make',
    'type.model.id',
    'type.id',
    'type.fuel',
    'type.gears',
    'type.cc',
    'type.kw',
    'type.hp',
    'type.cylinders',
    'type.battery_capacity',
    'type.range',
];

const baseDocumentFields = [
    'documents.navigation_sd_card.status',
    'documents.supplier_declaration.status',
    'documents.proof_of_registration_cancelled_on',
    'documents.finally_cancelled_on',
    'documents.eur1',
    'documents.notice_of_objection.status',
    'documents.import_documents.status',
    'documents.dkm_case_number',
    'documents.bank_leasing_case_number',
    'documents.internal_order_code',
    'documents.supplier_order_number',
    'documents.number_of_keys',
    'documents.key_status',
    'documents.keys_delivery',
];

const localisationFields = ['localisation.arrival.expected', 'localisation.arrival.expected_estimate'];

const websites = ['BE', 'NL', 'CH', 'DE', 'FR', 'IT', 'ROW', 'PRIV'];

const websitePricingFields = (showOnWebsite, price, deal) => {
    const fields = [];
    websites.forEach((website) => {
        fields.push(
            ...[`sales_pricing.website_pricing.${website}.catalog_price`, `sales_pricing.website_pricing.${website}.discount`]
        );

        if (deal) {
            fields.push(`sales_pricing.website_pricing.${website}.is_deal`);
        }
        if (showOnWebsite) {
            fields.push(`sales_pricing.website_pricing.${website}.show_on_website`);
        }
        if (price) {
            fields.push(`sales_pricing.website_pricing.${website}.price`);
        }
    });

    return fields;
};

const copyPaths = {
    base: [
        ...typeFields,
        'subsidiary',
        'warranty.period',
        'equipment',
        'sales_pricing.truck',
        'sales_pricing.consignment',
        'sales_pricing.t1',
        'sales_pricing.eln',
        'sales_pricing.is_margin_car',
        'sales_pricing.production_country',
    ],
    color: [
        ...typeFields,
        ...baseDocumentFields,
        ...localisationFields,
        'subsidiary',
        'emissions_standard',
        'mileage',
        'registration_country',
        'interior_type',
        'warranty.start',
        'warranty.status',
        'warranty.period',
        'supplier_reference',
        'purchase_type',
        'emissions_standard',
        'equipment',
        'supplier_reference',
        'supplied_by',
        'purchase_pricing.purchased_by',
        'purchase_pricing.commission_to',
        'sales_pricing.truck',
        'sales_pricing.consignment',
        'sales_pricing.t1',
        'sales_pricing.eln',
        'sales_pricing.is_margin_car',
        'sales_pricing.production_country',
        'sales_pricing.not_for_sale_in',
        'documents.proof_of_registration_part_1.status',
        'documents.proof_of_registration_part_2.status',
    ],
    atradis: [
        ...typeFields,
        ...baseDocumentFields,
        ...localisationFields,
        'emissions_standard',
        'mileage',
        'interior_type',
        'body_color',
        'roof_color',
        'interior_color',
        'warranty.start',
        'warranty.status',
        'warranty.period',
        'supplier_reference',
        'purchase_type',
        'emissions_standard',
        'equipment',
        'purchase_pricing.commission_to',
        'purchase_pricing.purchased_by',
        'purchase_pricing.costs.commission',
        'purchase_pricing.costs.import_duties',
        'sales_pricing.is_margin_car',
        'sales_pricing.production_country',
        'sales_pricing.not_for_sale_in',
    ],
};

function copyCar(copyType, data, car) {
    const newData = _.cloneDeep(data);

    let paths = [];

    switch (copyType) {
        case 'base':
            paths = [...copyPaths[copyType], ...websitePricingFields(false, true, false)];
            break;
        case 'atradis':
            newData.subsidiary = 'ATRADIS';
            newData.initial_vin = car.vin;
            newData.sale.status = 'NOT_FOR_SALE';
            websites.forEach((website) => {
                newData.sales_pricing.website_pricing[website].price = car.purchase_pricing.costs.purchase_price.amount;
            });
            newData.sales_pricing.truck = car.purchase_pricing.costs.purchase_price.amount;
            newData.purchase_pricing.costs.purchase_price.amount = car.purchase_pricing.costs.purchase_price.amount - 200;
            newData.localisation.status = 'EXPECTED';

            paths = [
                ...copyPaths[copyType],
                ...Object.keys(data.emissions).map((key) => 'emissions.' + key),
                ...websitePricingFields(false, false, true),
            ];
            break;
        case 'color':
            paths = [
                ...copyPaths[copyType],
                ...Object.keys(data.purchase_pricing.costs).map((key) => 'purchase_pricing.costs.' + key),
                ...Object.keys(data.emissions).map((key) => 'emissions.' + key),
                ...websitePricingFields(true, true, true),
            ];
            break;
    }

    paths.forEach((path) => {
        let value = _.get(car, path);
        _.set(newData, path, _.isArray(value) ? _.cloneDeep(value) : value);
    });

    return newData;
}

export default copyCar;
