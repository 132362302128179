import React from 'react';
import lscache from 'lscache';
import {useForm, Controller} from 'react-hook-form';
import Button from '../../components/Button/Button';
import Input from '../../components/Form/Input';
import {useAuth} from '../../lib/UseAuth';

import './Signin.scss';

const Signin = () => {
    const {error, login} = useAuth();
    const {control, handleSubmit} = useForm();

    return (
        <main className="signin">
            <div className="content">
                <a className="logo" href="#">
                    ZAZINDA
                </a>
                <section className="form-group">
                    <form onSubmit={handleSubmit(login)}>
                        <p>
                            <label htmlFor="username">Username</label>
                            <Controller
                                control={control}
                                defaultValue={lscache.get('zazinda_username') || ''}
                                name="username"
                                render={({field}) => <Input {...field} id="username" />}
                            />
                        </p>
                        <p>
                            <label htmlFor="password">Password</label>
                            <Controller
                                control={control}
                                defaultValue=""
                                name="password"
                                render={({field}) => <Input {...field} id="password" type="password" />}
                            />
                        </p>
                        {error && <p>{error}</p>}
                        <Button color="blue" submit>
                            Sign in
                        </Button>
                    </form>
                </section>
            </div>
        </main>
    );
};

export default Signin;
