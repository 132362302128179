import React from 'react';

const MarginInvoicesSummary = ({data}) => {
    const negativeBrutoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Bruto Marge%']) < 0).length;
    });
    const lowBrutoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Bruto Marge%']) >= 0 && parseFloat(row['Bruto Marge%']) <= 0.11).length;
    });
    const highBrutoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Bruto Marge%']) > 0.11).length;
    });
    const negativeNettoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Netto Marge%']) < 0).length;
    });
    const lowNettoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Netto Marge%']) >= 0 && parseFloat(row['Netto Marge%']) <= 0.05).length;
    });
    const highNettoMargin = React.useMemo(() => {
        return data.filter((row) => parseFloat(row['Netto Marge%']) > 0.05).length;
    });

    return (
        <section>
            <dl>
                <dt>aantal wagens met negatieve bruto %</dt>
                <dd>{negativeBrutoMargin}</dd>
                <dt>aantal wagens met bruto marge &lt;= 11%</dt>
                <dd>{lowBrutoMargin}</dd>
                <dt>aantal wagens met bruto marge &gt; 11%</dt>
                <dd>{highBrutoMargin}</dd>
                <dt>aantal wagens verkocht met verlies</dt>
                <dd>{negativeNettoMargin}</dd>
                <dt>aantal wagens met netto marge &lt;= 5%</dt>
                <dd>{lowNettoMargin}</dd>
                <dt>aantal wagens met netto marge &gt; 5%</dt>
                <dd>{highNettoMargin}</dd>
            </dl>
        </section>
    );
};

export default MarginInvoicesSummary;
