import React from 'react';
import {useTranslation} from 'react-i18next';

const FormGroup = ({children, field}) => {
    const {t} = useTranslation();

    return (
        <div className="col-6">
            <div className="form-group row">
                <label className="col-4 col-form-label" htmlFor={field}>
                    {t(`car.${field}`)}
                </label>
                <div className="col-8">{children}</div>
            </div>
        </div>
    );
};

export default React.memo(FormGroup);
