import React from 'react';
import {useTranslation} from 'react-i18next';
import {moneyFormat} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';

import Checkbox from '../../../../components/Form/Checkbox';
import Enum from '../../../../components/Form/Enum';
import Money from '../../../../components/Form/Money';
import Section from './Section';
import WebsitePriceLine from './WebsitePriceLine';

const SalesPricing = ({calculatePricing, disabled, form}) => {
    const {t} = useTranslation();
    const {user} = useAuth();

    // isSold is separated from disableInputs in order to disable inputs but keep checkboxes enabled in some cases
    const isSold = form.data.sale.status === 'SOLD' || form.data.sale.status === 'INVOICED';
    const isInvoiced = form.data.sale.status === 'INVOICED';
    const disableInputs = disabled || isSold || !user.permissions.CAN_EDIT_PRICES;
    const disableConsignation =
        disabled || isInvoiced || !(user.permissions.CAN_EDIT_PRICES || user.permissions.CAN_EDIT_B2C_AND_CONSIGNMENT_PRICES);

    return (
        <Section name="sales_pricing">
            {isSold && (
                <p>
                    <strong>{t('car.sold_for', {sold_for: moneyFormat(form.data.sales_pricing.sold_for)})}</strong>
                </p>
            )}

            <table className="table table-striped table-country-pricing">
                <thead>
                    <tr>
                        <th width="200" />
                        <th className="text-end" width="220">
                            {t('car.sales_pricing.price')}
                        </th>
                        <th className="text-center" width="50">
                            {t('car.sales_pricing.show_on_website')}
                        </th>
                        <th className="text-center" width="50">
                            {t('car.sales_pricing.deal')}
                        </th>
                        <th className="text-end" width="220">
                            {t('car.sales_pricing.catalog_price')}
                        </th>
                        <th width="120">{t('car.sales_pricing.discount')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody className="form-group">
                    <tr>
                        <th>{t('car.sales_pricing.truck')}</th>
                        <td>
                            <Money
                                disabled={disableInputs}
                                {...form.fields.sales_pricing.truck({
                                    onChange: (name, value, draft) => {
                                        value = parseInt(value);

                                        if (isNaN(value) || value < 0) {
                                            return;
                                        }

                                        calculatePricing(form, value, draft);
                                    },
                                })}
                            />
                        </td>
                        <td colSpan={5} />
                    </tr>
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={
                            form.data.sales_pricing.not_for_sale_in.includes('BE')
                        }
                        line="BE"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={form.data.sales_pricing.not_for_sale_in.includes('NL')}
                        line="NL"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={form.data.sales_pricing.not_for_sale_in.includes('FR')}
                        line="FR"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={form.data.sales_pricing.not_for_sale_in.includes('DE')}
                        line="DE"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={form.data.sales_pricing.not_for_sale_in.includes('IT')}
                        line="IT"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={
                            form.data.sales_pricing.not_for_sale_in.includes('CH') ||
                            form.data.sales_pricing.not_for_sale_in.includes('OUTSIDE_OF_EU')
                        }
                        line="CH"
                    />
                    <WebsitePriceLine
                        disabled={disableInputs}
                        form={form.fields.sales_pricing}
                        hidden={
                            form.data.sales_pricing.not_for_sale_in.includes('ROW') ||
                            form.data.sales_pricing.not_for_sale_in.includes('OUTSIDE_OF_EU')
                        }
                        line="ROW"
                    />
                    <tr>
                        <th>{t('car.sales_pricing.t1')}</th>
                        <td>
                            <Money disabled={disableInputs} {...form.fields.sales_pricing.t1()} />
                        </td>
                        <td colSpan={5} />
                    </tr>
                    <tr>
                        <th>{t('car.sales_pricing.eln')}</th>
                        <td>
                            <Money disabled={disableInputs} {...form.fields.sales_pricing.eln()} />
                        </td>
                        <td className="text-center">
                            <Checkbox disabled={disableInputs} {...form.fields.sales_pricing.list_eln()} />
                        </td>
                        <td colSpan={4} />
                    </tr>
                    <tr>
                        <th>{t('car.sales_pricing.consignment')}</th>
                        <td>
                            <Money
                                disabled={disableConsignation}
                                onBlur={() => {
                                    if (
                                        form.fields.sales_pricing.consignment().value > 0 &&
                                        form.fields.sales_pricing.consignment().value < form.fields.sales_pricing.truck().value
                                    ) {
                                        alert(t('validation.consignation_price_cant_be_lower_than_truck_price'));
                                    }
                                }}
                                {...form.fields.sales_pricing.consignment()}
                            />
                        </td>
                        <td colSpan={5} />
                    </tr>
                    <WebsitePriceLine
                        disabled={disableInputs && (!user.permissions.CAN_EDIT_B2C_AND_CONSIGNMENT_PRICES || isSold)}
                        enabled={user.permissions.CAN_EDIT_B2C_AND_CONSIGNMENT_PRICES && !isSold}
                        form={form.fields.sales_pricing}
                        line="PRIV"
                    />
                    <tr>
                        <th>{t('car.sales_pricing.is_margin_car')}</th>
                        <td>
                            <Checkbox
                                disabled={disableInputs}
                                label={t('car.sales_pricing.is_margin_car')}
                                {...form.fields.sales_pricing.is_margin_car()}
                            />
                        </td>
                        <td colSpan={5} />
                    </tr>
                    <tr>
                        <th>{t('car.sales_pricing.production_country')}</th>
                        <td colSpan={3}>
                            <Enum
                                disabled={disableInputs}
                                enumName="country"
                                {...form.fields.sales_pricing.production_country()}
                            />
                        </td>
                        <td colSpan={3} />
                    </tr>
                    <tr>
                        <th>{t('car.sales_pricing.not_for_sale_in')}</th>
                        <td colSpan={3}>
                            <Enum
                                allowMultiple
                                disabled={disableInputs}
                                enumName="country_with_outside_of_eu"
                                {...form.fields.sales_pricing.not_for_sale_in()}
                            />
                        </td>
                        <td colSpan={3} />
                    </tr>
                    {form.fields.sales_pricing.direct_delivery && (
                        <tr>
                            <th>{t('car.sales_pricing.direct_delivery')}</th>
                            <td colSpan={3}>
                                <Checkbox {...form.fields.sales_pricing.direct_delivery()} />
                            </td>
                            <td colSpan={3} />
                        </tr>
                    )}
                    {form.fields.sales_pricing.estimated_repair_cost && form.data.registration_status === 'SECOND_HAND' && (
                        <tr>
                            <th>{t('car.sales_pricing.estimated_repair_cost')}</th>
                            <td colSpan={3}>
                                <Money disabled={disableInputs} {...form.fields.sales_pricing.estimated_repair_cost()} />
                            </td>
                            <td colSpan={3} />
                        </tr>
                    )}
                </tbody>
            </table>
        </Section>
    );
};

export default SalesPricing;
