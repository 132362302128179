import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {useWidget} from '../../../../lib/UseWidget';
import {addCarLines, addExtra, addTransport} from '../../../../lib/WidgetReducer';
import AddLinesModal from '../../AddLinesModal';

import CollapsableListCar from '../../CollapsableListCar';
import Checkbox from '../../../../components/Form/Checkbox';

const ListRow = ({quote, selectedQuoteIds, setSelectedQuoteIds, users}) => {
    let navigate = useNavigate();
    const {t} = useTranslation();
    const {closeModal, showModal} = useModal();
    const [selectedLines, setSelectedLines] = useState([]);
    const [, dispatch] = useWidget();

    const handleRowSelect = (id) => {
        if (selectedQuoteIds.includes(id)) {
            setSelectedQuoteIds((prevState) => prevState.filter((option) => option !== id));
        } else {
            setSelectedQuoteIds((prevState) => [...prevState, id]);
        }
    };

    let customerId = null;
    if (quote.customers.data.length === 1) {
        customerId = quote.customers.data[0].customer.data.id;
    }

    const addLines = (e) => {
        e.stopPropagation();
        showModal(
            <AddLinesModal
                handleAdd={(type) => {
                    dispatch(
                        addCarLines(
                            type,
                            selectedLines.filter((line) => line.type === 'CAR')
                        )
                    );

                    selectedLines.forEach((line) => {
                        if (line.type === 'EXTRA') {
                            dispatch(addExtra(type, line.description, line.price));
                        }
                        if (line.type === 'TRANSPORT') {
                            dispatch(addTransport(type, line.price));
                        }
                    });

                    setSelectedLines([]);
                    closeModal();
                }}
            />
        );
    };

    return (
        <div
            className="grid-table-row clickable"
            key={quote.id}
            onClick={(e) => {
                e.stopPropagation();
                navigate('/offertes/' + quote.id);
            }}
        >
            <div className="grid-table-cell checkbox">
                <Checkbox
                    field={`quote-${quote.id}`}
                    onChange={() => {
                        handleRowSelect(quote.id);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    value={selectedQuoteIds.includes(quote.id)}
                />
            </div>
            <div className="grid-table-cell">
                <span>{quote.id}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.subsidiary.' + quote.subsidiary)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.document_status.' + quote.status)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{dateFormat(quote.date)}</span>
            </div>
            <div className="grid-table-cell small">
                {quote.customers.data.map((d) => (
                    <span key={d.customer.data.id}>{d.customer.data.label}</span>
                ))}
            </div>
            <div className="grid-table-cell small">
                <span>
                    {quote.created_by ? users.find((u) => u.id === quote.created_by).full_name : t('general.employee_not_found')}
                </span>
            </div>
            <div className="grid-table-cell d-flex flex-column small similar-cars">
                {quote.lines.map((line) => (
                    <CollapsableListCar
                        customerId={customerId}
                        key={line.id}
                        line={line}
                        selectedLines={[selectedLines, setSelectedLines]}
                    />
                ))}
                {selectedLines.length > 0 && (
                    <a className="btn--add" onClick={addLines}>
                        <i className="far fa-plus-square" />
                        {t('general.add')}
                    </a>
                )}
            </div>
            <div className="grid-table-cell numerical">
                <strong>{moneyFormat(quote.total)}</strong>
            </div>
        </div>
    );
};

export default ListRow;
