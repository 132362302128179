import React, {Fragment} from 'react';

import {useModal} from '../../lib/UseModal';

import Close from '../../../assets/svg/close.svg';

const Modal = ({children, subtitle, title}) => {
    const {closeModal} = useModal();

    const handleClick = (e) => {
        e.preventDefault();
        closeModal();
    };

    return (
        <Fragment>
            <div className="popup__head">
                {title && (
                    <h2>
                        <strong>{title}</strong>
                    </h2>
                )}
                {subtitle && <h5>{subtitle}</h5>}
                <a className="btn-close" href="#" id="btn-close" onClick={handleClick}>
                    <Close />
                </a>
            </div>
            {children}
        </Fragment>
    );
};

Modal.Body = ({children, className}) => <div className={`popup__body ${className}`}>{children}</div>;

Modal.Footer = ({children}) => <div className="popup__foot">{children}</div>;

export default Modal;
