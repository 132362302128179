import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import lscache from 'lscache';
import {moneyFormat, setPageTitle} from '../../../../lib/helpers';
import useFetchData from '../../../../lib/UseFetchData';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Layout from '../../../../components/Layout/Layout';
import SaveButtons from '../../Components/SaveButtons';
import CreditNoteCustomerSelect from './CreditNoteCustomerSelect';
import FormRow from './FormRow';
import Spinner from '../../../../components/Spinner/Spinner';
import General from './General';

import '../../Documents.scss';
import './Create.css';

const CreditNoteForm = ({
    cars,
    customer: [customer, setCustomer],
    customerId,
    defaultFormValues,
    editMode,
    invoiceCancellation,
    invoiceId,
    lines,
    submitUrl,
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [users] = useFetchData('/users');
    const [invoiceCancellationMail, setInvoiceCancellationMail] = useState(invoiceCancellation);

    useEffect(() => {
        setPageTitle(editMode ? t('general.credit_note_edit') : t('general.credit_note_create'));
    }, []);

    const [loadingMessage, setLoadingMessage] = useState(null);

    useEffect(() => {
        if (customer) {
            setValue('language', customer.language);
        }
    }, [customer]);

    const {control, formState, handleSubmit, register, setValue, watch} = useForm({
        defaultValues: {lines},
    });

    const {fields, remove} = useFieldArray({
        control,
        name: 'lines',
    });

    if (Object.keys(formState.errors).length > 0) {
        alert(t('general.please_fill_whole_form'));
    }

    const onSubmit = (data) => {
        setLoadingMessage(t('general.save') + '…');

        if (!data.contacts || data.contacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }

        let selectedCustomer = Object.keys(data.contacts)[0];
        let customerContacts = data.contacts[selectedCustomer];
        let selectedContacts = Object.keys(customerContacts).filter((c) => customerContacts[c] === true);

        if (selectedContacts.length === 0) {
            alert(t('validation.contacts_empty'));
            setLoadingMessage(null);
            return;
        }

        const postData = {
            subsidiary: data.subsidiary,
            channel: data.sales_channel,
            date: data.date,
            due_date: data.due_date,
            mail_text: data.mail_text,
            customer_id: selectedCustomer,
            contacts: selectedContacts,
            lines: data.lines.map((line) => {
                let data = {
                    type: line.type,
                    price: line.price,
                    invoice_line_id: line.invoice_line_id,
                };

                if (line.type === 'CAR') {
                    data.car_id = line.car_id;
                }

                if (line.type === 'EXTRA') {
                    data.description = line.description;
                }

                return data;
            }),
            language: data.language,
            user_id: data.user_id,
        };

        if (invoiceId) {
            postData.invoice_id = invoiceId;
        }

        authenticatedHttp()
            .post(submitUrl, postData)
            .then((response) => {
                if (parseInt(data.send_after_save)) {
                    setLoadingMessage(t('general.send') + '…');
                    const contacts = customer.contacts.filter((contact) => {
                        return selectedContacts.includes(contact.id);
                    });
                    return authenticatedHttp()
                        .post('/credit-notes/send', {
                            mail_text: data.mail_text,
                            contacts: contacts.map((contact) => {
                                return {
                                    from: users.find((u) => u.id === data.user_id).email,
                                    to: contact.email,
                                    cc: [],
                                    bcc: [],
                                    subject: '',
                                };
                            }),
                            is_invoice_cancellation: invoiceCancellationMail,
                            ids: [response.data.ids],
                        })
                        .then(() => {
                            if (editMode) {
                                lscache.remove('credit_note_customer');
                            }

                            navigate('/creditnotas/' + response.data.ids);
                        })
                        .catch((error) => {
                            setLoadingMessage(null);
                            alert(error.response.data.message);
                        });
                } else {
                    navigate('/creditnotas/' + response.data.ids);
                }
            });
    };

    const save = () => {
        setValue('send_after_save', 0);
        handleSubmit(onSubmit)();
    };

    const saveAndSend = () => {
        if (!watch('mail_text')) {
            if (!confirm(t('general.confirm_send_without_text'))) {
                return;
            }
        }

        setValue('send_after_save', 1);
        handleSubmit(onSubmit)();
    };

    useEffect(() => {
        const carLines = fields.filter((line) => line.type === 'CAR');

        let subsidiary = 'ECRENT';
        if (carLines.length > 0) {
            subsidiary = cars[carLines[0].car_id].subsidiary;
        }
        setValue('subsidiary', subsidiary);
    }, [fields]);

    return (
        <Layout
            className="documents documents-create creditNote-create"
            header={() => <h1>{editMode ? t('general.credit_note_edit') : t('general.credit_note_create')}</h1>}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <input className="d-none" defaultValue={defaultFormValues.subsidiary} {...register('subsidiary')} />
                <input className="d-none" {...register('send_after_save')} type="number" />
                <div className="row">
                    <div className="col">
                        <General
                            control={control}
                            defaultFormValues={defaultFormValues}
                            editMode={editMode}
                            invoiceCancellationMail={invoiceCancellationMail}
                            register={register}
                            setInvoiceCancellationMail={setInvoiceCancellationMail}
                            setValue={setValue}
                            watch={watch}
                        />
                    </div>
                    <div className="col">
                        <CreditNoteCustomerSelect
                            control={control}
                            customer={[customer, setCustomer]}
                            customerId={customerId}
                            defaultFormValues={defaultFormValues}
                            setValue={setValue}
                        />
                    </div>
                </div>
                <section className="no-padding items">
                    <div className="grid-table">
                        <div className="grid-table-row">
                            <div className="grid-table-cell">{t('general.items')}</div>
                            <div className="grid-table-cell numerical column-cell">
                                <span>{t('car.pricing.price')}</span>
                            </div>
                            <div className="grid-table-cell" />
                        </div>
                        {fields.map((field, index) => (
                            <FormRow
                                car={cars[field.car_id]}
                                control={control}
                                field={field}
                                index={index}
                                key={field.id}
                                register={register}
                                remove={remove}
                            />
                        ))}
                    </div>
                    <p className="car-count">
                        {t('car.number_of_cars')}: {watch('lines').filter((line) => line.type === 'CAR').length}
                    </p>
                    <div className="lines-footer">
                        <div />
                        <div>
                            <h3>
                                <span>{t('general.total')}</span>
                                {moneyFormat(
                                    watch().lines.reduce((currentValue, line) => currentValue + parseFloat(line.price), 0)
                                )}
                            </h3>
                            <div>
                                <SaveButtons disabledSave={false} save={save} saveAndSend={saveAndSend} />
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        </Layout>
    );
};

export default CreditNoteForm;
