import React from 'react';
import Close from '../../../assets/svg/close.svg';
import ReactModal from 'react-modal';

const Modal = ({isOpen, onRequestClose, renderBody, renderFooter, renderHeader, shouldCloseOnOverlayClick = true}) => {
    const handleClick = (e) => {
        e.preventDefault();
        onRequestClose();
    };

    return (
        <ReactModal
            ariaHideApp={false}
            className="popup__inner"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="popup"
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            {renderHeader && (
                <div className="popup__head">
                    {renderHeader()}
                    <a className="btn-close" href="#" id="btn-close" onClick={handleClick}>
                        <Close />
                    </a>
                </div>
            )}
            {renderBody && <div className="popup__body">{renderBody()}</div>}
            {renderFooter && <div className="popup__foot">{renderFooter()}</div>}
        </ReactModal>
    );
};

export default Modal;
