import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/Modal/NewModal';
import RegisterPurchaseDocumentForm from './RegisterPurchaseDocumentForm';

function getSupplierId(car) {
    if (typeof car.supplied_by === 'string') {
        return car.supplied_by;
    }

    return car.supplied_by.data.id;
}

function getCurrency(car) {
    return car.purchase_pricing.costs.purchase_price.currency;
}

const RegisterPurchaseDocument = ({cars, refresh, showOverview = true}) => {
    const {t} = useTranslation();
    const sameSupplier = cars.every((car) => getSupplierId(car) === getSupplierId(cars[0]));
    const sameCurrency = cars.every((car) => getCurrency(car) === getCurrency(cars[0]));

    if (!sameSupplier || !sameCurrency) {
        return (
            <Modal title={t('general.register_purchase_document')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <ul>
                            <li>
                                {!sameSupplier ? t('general.supplier_is_not_identical') : null}
                                {!sameSupplier && !sameCurrency ? <br /> : null}
                                {!sameCurrency ? t('validation.cars_must_have_same_currency') : null}
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal title={t('general.register_purchase_document')}>
            <RegisterPurchaseDocumentForm cars={cars} refresh={refresh} showOverview={showOverview} />
        </Modal>
    );
};

export default RegisterPurchaseDocument;
