import React from 'react';

import {useAuth} from './lib/UseAuth';

import Signin from './views/Signin/Signin';
import Routes from './Routes';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.scss';

const App = () => {
    const {user} = useAuth();

    return user ? <Routes /> : <Signin />;
};

export default App;
