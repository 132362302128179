import lscache from 'lscache';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import IconSave from '../../../../assets/svg/icon-save.svg';

import Button from '../../../components/Button/Button';
import Dropdown from '../../../components/Button/Dropdown';
import Dropzone from '../../../components/Form/Dropzone';
import MailConsignationContractModal from '../../../components/Modal/MailConsignationContractModal';
import MakeConsignationModal from '../../../components/Modal/MakeConsignationModal';
import SaveSearchedModal from '../../../components/Modal/SaveSearchedModal';
import UndoConsignationModal from '../../../components/Modal/UndoConsignationModal';
import Spinner from '../../../components/Spinner/Spinner';
import {calculatePricingSubtotals} from '../../../lib/CarDetailForm';
import {setPageTitle} from '../../../lib/helpers';
import {useAuth} from '../../../lib/UseAuth';
import useFetchData from '../../../lib/UseFetchData';
import useForm from '../../../lib/UseForm';
import {useModal} from '../../../lib/UseModal';
import {authenticatedHttp} from '../../../lib/zaza-client';
import Detail from '../Detail/Detail';
import CarHistory from '../Detail/CarHistory';
import Location from '../Detail/Location';
import Margins from '../Detail/Margins';
import Media from '../Detail/Media';
import Status from '../Detail/Status';
import Actions from './Actions';
import RegistrationStatus from '../../../components/CarDetail/RegistrationStatus';

const websites = ['BE', 'CH', 'DE', 'FR', 'IT', 'NL', 'ROW', 'PRIV'];

const calculatePricing = (form, value, draft) => {
    const difference = parseInt(form.data.sales_pricing.truck) - value;

    websites.forEach((website) => {
        if (draft.sales_pricing.website_pricing[website].price > 0) {
            const newPrice = parseInt(draft.sales_pricing.website_pricing[website].price) - difference;
            draft.sales_pricing.website_pricing[website].price = newPrice > 0 ? newPrice : 0;
        }
    });
    if (parseInt(draft.sales_pricing.consignment) > 0) {
        const newConsignmentPrice = parseInt(draft.sales_pricing.consignment) - difference;
        draft.sales_pricing.consignment = newConsignmentPrice > 0 ? newConsignmentPrice : 0;
    }
    if (parseInt(draft.sales_pricing.eln) > 0) {
        const newElnPrice = parseInt(draft.sales_pricing.eln) - difference;
        draft.sales_pricing.eln = newElnPrice > 0 ? newElnPrice : 0;
    }
    if (parseInt(draft.sales_pricing.t1) > 0) {
        const newT1Price = parseInt(draft.sales_pricing.t1) - difference;
        draft.sales_pricing.t1 = newT1Price > 0 ? newT1Price : 0;
    }
};

function noSingleCarChanges(changes) {
    if (changes.vin || changes.body_color || changes.interior_color || changes.roof_color) {
        return false;
    }
    if (changes.sales_pricing && changes.sales_pricing.direct_delivery) {
        return false;
    }
    if (changes.documents) {
        return false;
    }
    if (
        changes.localisation &&
        changes.localisation.customer_notification &&
        changes.localisation.customer_notification.is_notified
    ) {
        return false;
    }
    if (changes.localisation && changes.localisation.delivery && changes.localisation.delivery.is_delivered) {
        return false;
    }
    if (changes.purchase_pricing && (changes.purchase_pricing.accessories || changes.purchase_pricing.supplier_paid_on)) {
        return false;
    }

    return true;
}

const Update = ({car, nextCarId, previousCarId, refreshCar}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const {user} = useAuth();
    const {showModal} = useModal();
    const [loadingMessage, setLoadingMessage] = useState(null);
    const [showLegacyModal, setShowLegacyModal] = useState(null);
    const [similarCars] = useFetchData(`/cars/${car.car_id}/similar`);
    const [lastSearchParameters, setLastSearchParameters] = useState(null);
    const orderBy = lscache.get('search_order_by');
    const page = lscache.get('search_page');
    const perPage = lscache.get('search_per_page');
    const isDeleted = car && car.date.deleted_on;
    const [vinCheck, setVinCheck] = useState(false);

    useEffect(() => {
        setLastSearchParameters(lscache.get('search_parameters'));
    }, [location]);

    const searchResults = useMemo(() => lscache.get('search_results') || [], []);
    const allSearchedCars = searchResults
        ? `${t('car.save.all_searched')} (${searchResults.length})`
        : t('car.save.all_searched');
    const allSimilarCarsLabel = similarCars ? `${t('car.save.all_similar')} (${similarCars.length})` : t('car.save.all_similar');
    const forSaleSimilarCarsLabel = similarCars
        ? `${t('car.save.for_sale_similar')} (${similarCars.filter((car) => car.sale.status === 'FOR_SALE').length})`
        : t('car.save.for_sale_similar');

    const handleSave = () => {
        refreshCar();
    };

    const carHasType = car.type !== undefined;
    const typeOrStandardModel = carHasType ? car.type : car.standard_model;

    useEffect(() => {
        setPageTitle(
            `${t('nav.detail')}: ${typeOrStandardModel.model.make || ''} ${typeOrStandardModel.model.name || ''} ${
                typeOrStandardModel.name || ''
            }`
        );
    }, []);

    const [isSaving, toggleSaving] = useState(false);

    const form = useForm(car, {
        onSave: ({changes, data, options: {car_ids, update_mode}}) => {
            if ('vin' in changes && changes.vin.length !== 17) {
                let warning = null;
                if (changes.vin.length < 17) {
                    warning = confirm(t('general.vin_short'));
                }
                if (changes.vin.length > 17) {
                    warning = confirm(t('validation.too_long', {number: 17}));
                }
                if (!warning) {
                    return;
                }
            }

            if ('vin' in changes && vinCheck) {
                const warning = confirm(t('general.vin_exists'));
                if (!warning) {
                    return;
                }
            }

            if (
                data.purchase_pricing?.costs?.purchase_price?.currency !== 'EUR' &&
                !data.purchase_pricing.costs.purchase_price?.currency_rate
            ) {
                alert(t('validation.expected_currency_rate'));
                return;
            }

            if (!['ONLY_THIS', 'FOR_MULTIPLE'].includes(update_mode) && !window.confirm(t('general.confirm_update'))) {
                return;
            }

            if (data.type === undefined) {
                alert(t('car.cant_save_warning'));

                return;
            }

            if (update_mode === 'ALL_SIMILAR' || update_mode === 'FOR_SALE_SIMILAR') {
                if (!similarCars) {
                    alert(t('general.please_wait_until_similar_cars_loaded'));
                }

                let similarConsignated = similarCars.filter((car) => car.localisation.location === 'CONSIGNMENT');
                if (update_mode === 'FOR_SALE_SIMILAR') {
                    similarConsignated = similarConsignated.filter((car) => car.sale_satus === 'FOR_SALE');
                }

                // Ask user confirmation before saving and automatically sending new consignation contracts
                if (!!changes.sales_pricing && similarConsignated.length > 0) {
                    if (!confirm(t('validation.similar_cars_consignated'))) {
                        return;
                    }
                }
            }

            if (!isSaving) {
                toggleSaving(true);
                const patch = {...changes, update_mode};
                // if we are updating equipment, we want to send the full object
                if (patch.equipment) {
                    patch.equipment = data.equipment;
                }
                // if we are updating extra_type, we want to send the full object
                if (patch.extra_type) {
                    patch.extra_type = data.extra_type;
                }
                // if we are updating not_for_sale_in, we want to send the full object
                if (patch.sales_pricing && patch.sales_pricing.not_for_sale_in) {
                    patch.sales_pricing.not_for_sale_in = data.sales_pricing.not_for_sale_in;
                }
                // If a registration was removed, the key is present in the patch object, but will be undefined.
                // In that case, send the registration as `false`, which will handle the deletion in the API
                if (patch.documents && patch.documents.registration) {
                    if (
                        {...patch.documents.registration}.hasOwnProperty('first') &&
                        patch.documents.registration.first === undefined
                    ) {
                        patch.documents.registration.first = false;
                    }
                    if (
                        {...patch.documents.registration}.hasOwnProperty('second') &&
                        patch.documents.registration.second === undefined
                    ) {
                        patch.documents.registration.second = false;
                    }
                }

                const saveEndpoint = update_mode === 'FOR_MULTIPLE' ? '/cars' : '/cars/' + data.car_id;
                const finalPatch =
                    update_mode === 'FOR_MULTIPLE'
                        ? car_ids.map((car_id) => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              const {update_mode, ...patchWithoutUpdateMode} = patch;

                              return {...patchWithoutUpdateMode, car_id};
                          })
                        : patch;

                authenticatedHttp()
                    .patch(saveEndpoint, finalPatch)
                    .then(() => {
                        toggleSaving(false);
                        handleSave();
                    })
                    .catch((e) => {
                        toggleSaving(false);
                        alert('Fout bij opslaan: ' + e.response.data.message);
                    });
            }
        },
    });

    const hasNoChanges = Object.entries(form.changes).length === 0;

    const purchasePricing = form.data.purchase_pricing;
    const margins = car.margins;
    const pricingTotals = useMemo(() => {
        return calculatePricingSubtotals(purchasePricing, margins);
    }, [purchasePricing]);

    const isConsignable =
        car.localisation.location.consigned_with === null &&
        car.sales_pricing.consignment > 0 &&
        car.localisation.arrival.status === 'ARRIVED';
    const isConsigned = car.localisation.location.where === 'CONSIGNMENT';

    if (isSaving) {
        return <Spinner />;
    }

    return (
        <>
            <Detail
                calculatePricing={calculatePricing}
                car={car}
                contentHeader={() => (
                    <>
                        <Status car={car} />
                        <Location arrivalStatus={form.data.localisation.arrival.status} localisation={form.data.localisation} />
                    </>
                )}
                form={form}
                header={() => {
                    let url = '/autos/lijst';
                    if (lastSearchParameters) {
                        url =
                            url +
                            '?query=' +
                            JSON.stringify(lastSearchParameters) +
                            '&order_by=' +
                            orderBy +
                            '&page=' +
                            page +
                            '&per_page=' +
                            perPage;
                    }

                    let copyActions = {
                        [t('general.copy.base')]: () => {
                            navigate('/autos/creatie?copy=base&from=' + car.car_id);
                        },
                        [t('general.copy.color')]: () => {
                            navigate('/autos/creatie?copy=color&from=' + car.car_id);
                        },
                    };
                    if (car.subsidiary === 'ECRENT') {
                        copyActions[t('general.copy.atradis')] = () => {
                            navigate('/autos/creatie?copy=atradis&from=' + car.car_id);
                        };
                    }

                    return (
                        <>
                            <a
                                className="back-to-results small"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(url + '#' + car.car_id);
                                }}
                            >
                                {t('nav.back_to_results')}
                            </a>
                            <p className="small seperator"> - </p>
                            <a
                                className="to-similar small"
                                href={`/autos/lijst?query={"subsidiary":["${car.subsidiary}"],"similar_to":${car.car_id}${
                                    user.permissions.CAN_VIEW_NOT_FOR_SALE_CARS ? ', "sale_status":["ALL_SALES_STATUSES"]' : ''
                                }}`}
                            >
                                {t('search.to_similar_cars')}
                            </a>
                            <div className="title">
                                <div className="title-row">
                                    <a
                                        className={`btn-arrow btn-arrow-left ${!previousCarId ? `btn-arrow-disabled` : ``}`}
                                        data-tip={t('search.prev_car_in_results')}
                                        onClick={() => {
                                            navigate('/autos/' + previousCarId + location.hash);
                                        }}
                                    />
                                    <div>
                                        <div className="d-flex flex-row justify-content-between">
                                            <h1>{typeOrStandardModel.model.make}</h1>
                                            {searchResults.length > 0 && (
                                                <h2>
                                                    {searchResults.findIndex((c) => c === parseInt(car.car_id)) + 1}/
                                                    {searchResults.length}
                                                </h2>
                                            )}
                                        </div>
                                        <h2>
                                            {typeOrStandardModel.model.name}&nbsp;
                                            {typeOrStandardModel.name}
                                            &nbsp;&ndash;&nbsp;
                                            {car.body_color}
                                        </h2>
                                        <h3 className={`${car.subsidiary === 'ATRADIS' && 'atradisText'}`}>
                                            {t('car.id')} {car.car_id}
                                            &nbsp;&ndash;&nbsp;
                                            {t('car.vin')} {car.vin}
                                            &nbsp;&ndash;&nbsp;
                                            {t('enum.subsidiary.' + car.subsidiary)}
                                            <RegistrationStatus car={form.data} />
                                        </h3>
                                    </div>
                                    <a
                                        className={`btn-arrow btn-arrow-right me-auto ${!nextCarId ? `btn-arrow-disabled` : ``}`}
                                        data-tip={t('search.next_car_in_results')}
                                        onClick={() => {
                                            navigate('/autos/' + nextCarId + location.hash);
                                        }}
                                    />
                                </div>
                                <div className="title-row highlighted-equipment">
                                    <p className="small">{car.highlighted_equipment}</p>
                                </div>
                            </div>
                            {user.permissions.CAN_CREATE_CARS && (
                                <Dropdown
                                    actions={copyActions}
                                    buttonProps={{
                                        equalHeight: true,
                                        icon: <i className="far fa-copy" />,
                                    }}
                                    equalHeight
                                />
                            )}
                            {!car.date.deleted_on ? (
                                <>
                                    {isConsignable && (
                                        <Button equalHeight onClick={() => setShowLegacyModal('makeConsignation')}>
                                            {t('car.make_consignation')}
                                        </Button>
                                    )}
                                    {isConsigned && (
                                        <Button equalHeight onClick={() => setShowLegacyModal('undoConsignation')}>
                                            {t('car.undo_consignation')}
                                        </Button>
                                    )}
                                    <Actions car={car} refreshCar={refreshCar} />
                                    {noSingleCarChanges(form.changes) ? (
                                        <Dropdown
                                            actions={{
                                                [t('car.save.only_this')]: () => form.handleSubmit({update_mode: 'ONLY_THIS'}),
                                                [allSimilarCarsLabel]: () => form.handleSubmit({update_mode: 'ALL_SIMILAR'}),
                                                [forSaleSimilarCarsLabel]: () =>
                                                    form.handleSubmit({update_mode: 'FOR_SALE_SIMILAR'}),
                                                [allSearchedCars]: () =>
                                                    showModal(
                                                        <SaveSearchedModal
                                                            carIds={searchResults}
                                                            changes={form.changes}
                                                            handleSubmit={form.handleSubmit}
                                                        />
                                                    ),
                                            }}
                                            align="right"
                                            buttonProps={{
                                                label: t('general.save'),
                                                equalHeight: true,
                                                icon: <IconSave />,
                                            }}
                                            disabled={hasNoChanges}
                                        />
                                    ) : (
                                        <Button
                                            equalHeight
                                            icon={<IconSave />}
                                            onClick={() => form.handleSubmit({update_mode: 'ONLY_THIS'})}
                                        >
                                            {t('general.save')}
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <Actions car={car} refreshCar={refreshCar} />
                            )}
                        </>
                    );
                }}
                isDeleted={isDeleted}
                loadingMessage={loadingMessage}
                pricingTotals={pricingTotals}
                refresh={refreshCar}
                setLoadingMessage={setLoadingMessage}
                setShowModal={setShowLegacyModal}
                setVinCheck={setVinCheck}
                showWidget
                sidebar={() => (
                    <>
                        {!isDeleted && (
                            <Dropzone
                                carId={form.data.car_id}
                                refreshCar={refreshCar}
                                setLoadingMessage={setLoadingMessage}
                                type="default"
                            />
                        )}
                        <Media
                            carId={form.data.car_id}
                            files={form.data.files || []}
                            form={form}
                            images360={form.data.images['360'] || []}
                            imagesDefault={form.data.images['default'] || []}
                            refreshCar={refreshCar}
                            setLoadingMessage={setLoadingMessage}
                        />
                        <Margins car={car} />
                        <CarHistory carId={form.data.car_id} />
                    </>
                )}
                vinCheck={vinCheck}
            />
            <MakeConsignationModal
                carId={car.car_id}
                isOpen={showLegacyModal === 'makeConsignation'}
                onRequestClose={() => setShowLegacyModal(null)}
                refreshCar={refreshCar}
            />
            <UndoConsignationModal
                carId={car.car_id}
                isOpen={showLegacyModal === 'undoConsignation'}
                onRequestClose={() => setShowLegacyModal(null)}
                refreshCar={refreshCar}
            />
            <MailConsignationContractModal
                carId={car.car_id}
                customerId={car.localisation.location.consigned_with}
                isOpen={showLegacyModal === 'mailConsignationContract'}
                onRequestClose={() => setShowLegacyModal(null)}
            />
        </>
    );
};

export default Update;
