import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import {setPageTitle} from '../../../../lib/helpers';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import useFetchData from '../../../../lib/UseFetchData';
import NotFound from '../../../NotFound/NotFound';

import Detail from './Detail';
import {useTranslation} from 'react-i18next';

const LoadDetail = () => {
    const {purchaseOrderId} = useParams();
    const {t} = useTranslation();
    let navigate = useNavigate();

    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const [users] = useFetchData('/users?include_inactive');

    const fetchPurchaseOrder = () => {
        setPurchaseOrder(null);
        authenticatedHttp()
            .get('/purchase-orders/' + purchaseOrderId)
            .then((response) => {
                setPurchaseOrder(response.data.data);
                setPageTitle(t('general.purchase_order'));
            })
            .catch(() => {
                navigate('/bestelbonnen');
            });
    };
    useEffect(() => {
        if (/^\d+$/.test(purchaseOrderId)) {
            fetchPurchaseOrder();
        }
    }, []);

    if (!/^\d+$/.test(purchaseOrderId)) {
        return <NotFound />;
    }

    if (!users || !purchaseOrder) {
        return <Spinner />;
    }

    return <Detail purchaseOrder={purchaseOrder} refresh={fetchPurchaseOrder} users={users} />;
};

export default LoadDetail;
