import ImageGallery from 'react-image-gallery';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

import {authenticatedHttp} from '../../../lib/zaza-client';
import {useAuth} from '../../../lib/UseAuth';
import {useModal} from '../../../lib/UseModal';
import EditImagesModal from '../Management/EditImagesModal';
import IconThumbnails from '../../../../assets/svg/icon-thumbnails.svg';
import FullScreenImageModal from '../Management/FullScreenImageModal';

const FullscreenCarousel = ({carId, images = [], refreshCar, setLoadingMessage}) => {
    const {t} = useTranslation();
    const {user} = useAuth();
    const galleryRef = React.createRef();
    const {showModal} = useModal();

    const getCurrentImage = () => {
        return galleryRef.current ? images[galleryRef.current.getCurrentIndex()] : images[0];
    };

    const getCurrentImageIndex = () => {
        return galleryRef.current ? galleryRef.current.state.currentIndex : 0;
    };

    const galleryFullscreenButton = (onClick, isFullscreen) => {
        const currentIndex = getCurrentImageIndex();
        return (
            <button
                className={`gallery-btn gallery-fullscreen ${isFullscreen ? ' active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    showModal(<FullScreenImageModal currentIndex={currentIndex} images={images} />);
                }}
            />
        );
    };

    const galleryCustomControls = () => {
        const currentImage = getCurrentImage();
        const displayDeleteButton = currentImage.uploaded_by === user.id;

        const handlePhotosClick = (e) => {
            e.preventDefault();
            showModal(<EditImagesModal carId={carId} images={images} refreshCar={refreshCar} />);
        };

        const handleDeleteClick = (e) => {
            e.preventDefault();

            if (!window.confirm(t('general.confirm_delete_image'))) {
                return false;
            }

            setLoadingMessage(t('general.deleting_image'));
            authenticatedHttp()
                .delete('/cars/' + carId + '/images/' + currentImage.id)
                .then(() => {
                    refreshCar();
                    setLoadingMessage(null);
                });
        };

        return (
            <Fragment>
                <button className="gallery-btn gallery-photos" onClick={handlePhotosClick}>
                    <IconThumbnails style={{width: '16px'}} />
                </button>
                {displayDeleteButton && <button className="gallery-btn gallery-delete" onClick={handleDeleteClick} />}
            </Fragment>
        );
    };

    const galleryLeftNav = (onClick) => {
        return (
            <button
                className="gallery-btn gallery-prev"
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
            />
        );
    };

    const galleryRightNav = (onClick) => {
        return (
            <button
                className="gallery-btn gallery-next"
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
            />
        );
    };

    return (
        <ImageGallery
            items={images.map((image) => ({
                original: image.medium_url,
                fullscreen: image.large_url,
                thumbnail: image.thumbnail_url,
            }))}
            ref={galleryRef}
            renderCustomControls={galleryCustomControls}
            renderFullscreenButton={galleryFullscreenButton}
            renderLeftNav={galleryLeftNav}
            renderRightNav={galleryRightNav}
            showIndex={images.length > 1}
            showNav={images.length > 1}
            showPlayButton={false}
            showThumbnails={false}
            useBrowserFullscreen={false}
        />
    );
};

export default React.memo(FullscreenCarousel);
