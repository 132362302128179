import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';

import Button from '../../../../components/Button/Button';
import Dropdown from '../../../../components/Button/Dropdown';
import Checkbox from '../../../../components/Form/Checkbox';
import Layout from '../../../../components/Layout/Layout';
import Spinner from '../../../../components/Spinner/Spinner';
import {setPageTitle} from '../../../../lib/helpers';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import DeleteDocuments from '../../DeleteDocuments';

import '../../Documents.scss';
import Filters from '../../Filters';
import './List.css';
import ListRow from './ListRow';

const List = () => {
    useEffect(() => {
        setPageTitle(t('nav.quotes'));
    }, []);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {closeModal, showModal} = useModal();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [totals, setTotals] = useState({pages: 0, items: 0});
    const [filters, setFilters] = useQueryParams({
        status: StringParam,
        customer: StringParam,
        user: NumberParam,
        date_from: StringParam,
        date_to: StringParam,
        subsidiary: StringParam,
        page: withDefault(NumberParam, 1),
        perPage: withDefault(NumberParam, 50),
        path: withDefault(StringParam, 'q.date'),
        direction: withDefault(StringParam, 'DESC'),
    });

    const [selectedQuoteIds, setSelectedQuoteIds] = useState([]);
    const [users] = useFetchData('/users?include_inactive');

    const deleteQuotes = () => {
        closeModal();
        setLoadingMessage(t('general.delete') + '…');

        let promises = [];

        selectedQuoteIds.forEach((quoteId) => {
            promises.push(authenticatedHttp().delete('/quotes/' + quoteId));
        });

        // eslint-disable-next-line no-undef
        Promise.all(promises).then(() => {
            setLoadingMessage(null);
            setSelectedQuoteIds([]);
            fetchQuotes();
        });
    };

    const [quotes, setQuotes] = useState(null);
    const fetchQuotes = () => {
        setLoadingMessage(t('general.loading'));

        let query = '/quotes?';

        if (filters.subsidiary) {
            query += '&subsidiary=' + filters.subsidiary;
        }
        if (filters.status) {
            query += '&status=' + filters.status;
        }
        if (filters.customer) {
            query += '&customer_id=' + filters.customer;
        }
        if (filters.user) {
            query += '&user_id=' + filters.user;
        }
        if (filters.date_from) {
            query += '&date_from=' + filters.date_from;
        }
        if (filters.date_to) {
            query += '&date_to=' + filters.date_to;
        }

        query += '&order_by=' + filters.path + ',' + filters.direction;
        query += '&page=' + filters.page + '&per_page=' + filters.perPage;

        authenticatedHttp()
            .get(query)
            .then((response) => {
                setQuotes(response.data.data);
                setLoadingMessage(null);
                if (filters.page !== response.data.meta.page) {
                    setFilters({...filters, page: response.data.meta.page});
                }
                setTotals({
                    pages: response.data.meta.pages,
                    items: response.data.meta.total,
                });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    useEffect(fetchQuotes, [filters]);

    const stickyColumnHeader = () => {
        let height = window.innerHeight - 60;
        height -= $('header').outerHeight();
        height -= $('.content > .d-flex').outerHeight();
        if ($('.grid-table').outerHeight() > height) {
            $('section').height(height);
        }
    };

    useEffect(stickyColumnHeader, [quotes]);

    const orderBySetter = (path) => {
        if (filters.path === path) {
            // If path is the same as current sorting path, flip the direction
            setFilters({
                ...filters,
                path,
                direction: filters.direction === 'ASC' ? 'DESC' : 'ASC',
                page: 1,
            })
        } else {
            // If path is different, update it and reset direction to descending
            setFilters({
                ...filters,
                path,
                direction: 'ASC',
                page: 1, // Reset page
            });
        }
    };

    if (isError) {
        return <Layout header={() => <h1>{t('general.loading_error')}</h1>} />;
    }

    if (!quotes) {
        return <Spinner />;
    }

    return (
        <Layout
            className="documents documents-list quote-list"
            header={() => (
                <div className="header-wrapper">
                    <div className="header-pagination">
                        <a
                            className={'btn-arrow btn-arrow-left ' + (filters.page > 1 ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page - 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                        <div>
                            <h1 className="mb-0">
                                {t('nav.quotes')} ({totals.items})
                            </h1>
                            <p className="mb-0 small">
                                {t('general.page')} {filters.page} {t('general.of')} {totals.pages}
                            </p>
                        </div>
                        <a
                            className={'btn-arrow btn-arrow-right ' + (filters.page < totals.pages ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page + 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                    </div>
                    <Button
                        onClick={() => {
                            navigate('/offertes/creatie?empty');
                        }}
                    >
                        {t('general.quote_create')}
                    </Button>
                </div>
            )}
            showWidget
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <div className="filter-container">
                <Dropdown
                    actions={{
                        [t('general.delete')]: (e) => {
                            e.preventDefault();
                            showModal(<DeleteDocuments handleDelete={deleteQuotes} type="quotes" />);
                        },
                    }}
                    buttonProps={{
                        label: t('general.actions'),
                    }}
                    disabled={selectedQuoteIds.length === 0}
                />
                {selectedQuoteIds.length > 0 && (
                    <span style={{lineHeight: '38px', marginLeft: '1em', verticalAlign: 'sub'}}>
                        {t('general.selected', {count: selectedQuoteIds.length})}
                    </span>
                )}
                <Filters filters={filters} setFilters={setFilters} />
            </div>
            <section className="no-padding">
                <div className="grid-table">
                    <div className="grid-table-row">
                        <div className="grid-table-cell checkbox">
                            <Checkbox
                                field="selection-toggle"
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedQuoteIds(quotes.map((quote) => quote.id));
                                    } else {
                                        setSelectedQuoteIds([]);
                                    }
                                }}
                                semiChecked={selectedQuoteIds.length > 0}
                                value={selectedQuoteIds.length === quotes.length}
                            />
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('q.id');
                            }}
                        >
                            <span>{t('general.quote_nr')}</span>
                            {filters.path === 'q.id' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'q.id' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('q.subsidiary');
                            }}
                        >
                            <span>{t('search.subsidiary')}</span>
                            {filters.path === 'q.subsidiary' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'q.subsidiary' && filters.direction === 'DESC' && (
                                <i className="fa fa-caret-down" />
                            )}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('q.status');
                            }}
                        >
                            <span>{t('general.status')}</span>
                            {filters.path === 'q.status' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'q.status' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('q.date');
                            }}
                        >
                            <span>{t('car.date')}</span>
                            {filters.path === 'q.date' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'q.date' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div className="grid-table-cell">{t('general.customers')}</div>
                        <div className="grid-table-cell">{t('car.seller')}</div>
                        <div className="grid-table-cell">{t('general.items')}</div>
                        <div className="grid-table-cell numerical collapse-padding">{t('car.pricing.price')}</div>
                        <div className="grid-table-cell numerical">{t('general.total')}</div>
                    </div>
                    {quotes.map((quote) => (
                        <ListRow
                            key={quote.id}
                            quote={quote}
                            selectedQuoteIds={selectedQuoteIds}
                            setSelectedQuoteIds={setSelectedQuoteIds}
                            users={users}
                        />
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default React.memo(List);
