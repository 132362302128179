import React, {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import Autocomplete from '../../../../components/Form/Autocomplete';
import Checkbox from '../../../../components/Form/Checkbox';
import {useModal} from '../../../../lib/UseModal';
import CarErrorsDocument from '../../../Cars/List/Modal/CarErrorsDocument';

const InvoiceCustomer = ({
    cars,
    control,
    customer: [customer, setCustomer],
    customerId: initialCustomerId,
    defaultFormValues,
    lines,
    remove,
    setValue,
    watch,
}) => {
    const {t} = useTranslation();
    const [customerId, setCustomerId] = useState(initialCustomerId);
    const [loadingCustomer, toggleLoadingCustomer] = useState(false);

    const allowEdit = useRef(!initialCustomerId);
    const {showModal} = useModal();

    useEffect(() => {
        if (!customerId) {
            return;
        }

        toggleLoadingCustomer(true);

        let carsId = [];
        cars.forEach((item) => {
            carsId.push(item['car_id']);
        });

        authenticatedHttp()
            .get('/customers/' + customerId + '?include=contacts&carIds=' + carsId.join(','))
            .then((response) => {
                let customerTL = response.data.data;
                let meta = response.data.meta;
                customerTL.contacts = customerTL.contacts.data;
                toggleLoadingCustomer(false);

                setValue('language', customerTL.language);

                // Don't add customer if he doesn't haven an address or country (required for VAT)
                if (!customerTL.address || !customerTL.address.country_code || customerTL.address.country_code === 'ZZ') {
                    alert(t('validation.customer_without_country'));
                    setCustomerId(null);

                    return;
                }

                if (!meta) {
                    setCustomer(customerTL);

                    return;
                }

                // Don't add customer if the car can't be sold in the country
                if (
                    meta['cantBeSoldCarsInCountry'].length > 0 ||
                    meta['cantBeSoldOutsideEU'].length > 0 ||
                    meta['sameSupplierAsCustomer'].length > 0
                ) {
                    showModal(
                        <CarErrorsDocument
                            cantBeSoldCarsInCountry={meta['cantBeSoldCarsInCountry']}
                            cantBeSoldOutsideEU={meta['cantBeSoldOutsideEU']}
                            cars={cars}
                            customer={customerTL}
                            lines={lines}
                            remove={remove}
                            sameSupplierAsCustomer={meta['sameSupplierAsCustomer']}
                            setCustomer={setCustomer}
                            watch={watch}
                        />
                    );
                    setCustomerId(null);

                    return;
                }

                // Check if VAT is not empty
                if (meta['VATResponse'] === 'MISSING') {
                    alert(t('general.no_vat_for_customer', {business: customerTL.label}));
                    setCustomerId(null);

                    return;
                }

                // Check VAT number
                if (['INVALID', 'UNKNOWN'].includes(meta['VATResponse'])) {
                    alert(t('validation.vat_invalid', {business: customerTL.label}));
                }

                setCustomer(customerTL);
            });
    }, [customerId]);

    if (loadingCustomer) {
        return (
            <section>
                <strong>{t('general.send_invoice_to')}</strong>
                <p>{t('general.loading')}</p>
            </section>
        );
    }

    if (!customer) {
        return (
            <section>
                <div className="mb-4 search-customers">
                    <div className="form-group form-group-vertical">
                        <label>{t('general.search_customer')}</label>
                        <Autocomplete
                            allowClear
                            autocompleteURI="/customers/autocomplete?"
                            onChange={setCustomerId}
                            placeholder={t('general.search_customer')}
                            value={customer ? {value: customer.id, label: customer.label} : ''}
                        />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="recipients">
                <strong>{t('general.send_invoice_to')}</strong>
                <div className="customer" key={customer.id}>
                    {allowEdit.current ? (
                        <a
                            onClick={() => {
                                setCustomer(null);
                                setCustomerId(null);
                            }}
                        >
                            {customer.label}
                            <i className="far fa-times-circle" />
                        </a>
                    ) : (
                        <p>{customer.label}</p>
                    )}
                    {customer.contacts.length > 0 && (
                        <div className="contacts">
                            {customer.contacts.map((contact) => (
                                <Controller
                                    control={control}
                                    defaultValue={
                                        (contact.email && contact.type === 'PURCHASING') ||
                                        defaultFormValues.selectedContacts.includes(contact.id)
                                    }
                                    disabled={!contact.email}
                                    key={contact.id}
                                    name={`contacts[${customer.id}][${contact.id}]`}
                                    render={({field}) => (
                                        <Checkbox
                                            {...field}
                                            label={`
                                                ${contact.full_name}
                                                ${contact.email ? `(${contact.email})` : ''}
                                            `}
                                        />
                                    )}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default InvoiceCustomer;
