import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/Modal/NewModal';
import Spinner from '../../../../components/Spinner/Spinner';
import useFetchData from '../../../../lib/UseFetchData';
import BookTransportForm from './BookTransportForm';

const BookTransportFromEcr = ({cars, refresh = false, showOverview = true}) => {
    const {t} = useTranslation();
    const sameCustomer = cars.every((car) => car.sale.sold_to === cars[0].sale.sold_to);

    const customerId = sameCustomer && cars[0].sale.sold_to !== undefined ? cars[0].sale.sold_to : null;
    const [customer, isLoading] = useFetchData('/customers/' + customerId, customerId);

    if (isLoading) {
        return <Spinner message={t('general.loading')} />;
    }

    if (!sameCustomer) {
        return (
            <Modal title={t('general.book_transport.from_ecr')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <p>{t('search.same_customer_required')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const deliveryAddress = customer
        ? {
              name: customer.name,
              phone: customer.phone,
              fax: customer.fax,
              ...customer.address,
          }
        : undefined;

    const loadingAddress = {
        name: 'EC Rent NV',
        address_line: 'Boomsesteenweg 934',
        postal_code: '2610',
        locality: 'Wilrijk',
        country_code: 'BE',
        phone: '03/870.76.75',
        fax: '03/870.76.72',
    };

    return (
        <Modal title={t('general.book_transport.from_ecr')}>
            <BookTransportForm
                cars={cars}
                deliveryAddress={deliveryAddress}
                loadingAddress={loadingAddress}
                refresh={refresh}
                showOverview={showOverview}
                type="FROM_ECR"
            />
        </Modal>
    );
};

export default BookTransportFromEcr;
