/* eslint-disable react/jsx-key */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTable} from 'react-table';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import useFetchData from '../../../../lib/UseFetchData';

const CreditNoteHistory = ({invoiceId}) => {
    const {t} = useTranslation();

    const [creditNotes, isLoading] = useFetchData(`/invoices/${invoiceId}/credit-notes`);

    const data = useMemo(() => {
        if (!creditNotes) {
            return [];
        }

        const rows = [];
        creditNotes.forEach((creditNote) => {
            creditNote.lines.forEach((line) => {
                rows.push({
                    number: {id: creditNote.id, number: creditNote.credit_note_number},
                    date: creditNote.date,
                    type: line.type,
                    car_id: line.type === 'CAR' ? line.car.car_id : '-',
                    amount: line.price * (1 + line.vat_rate / 100),
                });
            });
        });

        return rows;
    }, [creditNotes]);

    const columns = useMemo(
        () => [
            {
                Header: t('general.number'),
                accessor: 'number',
                Cell: ({value}) => <a href={`/creditnotas/${value.id}`}>{value.number || value.id}</a>,
            },
            {
                Header: t('general.date'),
                accessor: 'date',
                Cell: ({value}) => dateFormat(value),
            },
            {
                Header: t('api.type'),
                accessor: 'type',
            },
            {
                Header: t('search.car_id'),
                accessor: 'car_id',
            },
            {
                Header: t('general.sum'),
                accessor: 'amount',
                Cell: ({value}) => moneyFormat(value),
                Footer: (info) => moneyFormat(info.rows.reduce((sum, row) => row.values.amount + sum, 0)),
            },
        ],
        [creditNotes]
    );
    const {footerGroups, getTableBodyProps, getTableProps, headerGroups, prepareRow, rows} = useTable({data, columns});

    if (isLoading) {
        return (
            <section>
                <h3>{t('general.loading')}</h3>
            </section>
        );
    }

    return (
        <section>
            <h3>{t('general.credit_notes')}</h3>
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps({className: column.id === 'amount' ? 'text-end' : undefined})}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps({
                                            className: cell.column.id === 'amount' ? 'text-end' : undefined,
                                        })}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map((group) => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map((column) => (
                                <td {...column.getFooterProps({className: column.id === 'amount' ? 'text-end' : undefined})}>
                                    {column.render('Footer')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </section>
    );
};

export default CreditNoteHistory;
