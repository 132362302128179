import classnames from 'classnames';
import $ from 'jquery';
import lscache from 'lscache';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import Scroll from 'react-scroll';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Layout from '../../../components/Layout/Layout';
import Spinner from '../../../components/Spinner/Spinner';
import {setPageTitle} from '../../../lib/helpers';
import {useModal} from '../../../lib/UseModal';
import {useSearchResultColumns} from '../../../lib/UseSearchResultColumns';
import camera from '../../../../assets/img/cameraicon.png';

import BulkActions from './BulkActions';
import CarCount from './CarCount';
import ColumnBody from './ColumnBody';
import ColumnHeader from './ColumnHeader';
import Header from './Header';

import './List.scss';
import TableColumnsConfig from './Modal/TableColumnsConfig';

export const pageDefault = 1;
export const perPageDefault = 100;

const List = ({cars, groupSimilar, orderBy, orderBySetter, pagination, searchCars, searchParams, totals}) => {
    useEffect(() => {
        setPageTitle(t('nav.search_results'));
    }, []);

    let navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const [selectedCarIds, setSelectedCarIds] = useState([]);
    const unselectCars = () => setSelectedCarIds([]);
    const {showModal} = useModal();
    const scroller = Scroll.scroller;
    const [loadingMessage, setLoadingMessage] = useState(null);
    const {selectedColumns} = useSearchResultColumns();
    const [equipmentWidth, setEquipmentWidth] = useState('wide');
    const toggleEquipmentWidth = (e) => {
        e.stopPropagation();
        setEquipmentWidth(equipmentWidth === 'wide' ? 'ultrawide' : 'wide');
    };

    useEffect(() => {
        let height = window.innerHeight - 60;
        height -= $('header').outerHeight();
        height -= $('.content > .d-flex').outerHeight();
        if ($('.grid-table').outerHeight() > height) {
            $('section').height(height);
        }
    }, [cars]);

    const selectedCars = useMemo(
        () => (cars ? cars.filter((car) => selectedCarIds.includes(car.car_id)) : []),
        [cars, selectedCarIds]
    );

    const handleSelect = (id) => {
        if (selectedCarIds.includes(id)) {
            setSelectedCarIds((prevState) => prevState.filter((option) => option !== id));
        } else {
            setSelectedCarIds((prevState) => [...prevState, id]);
        }
    };

    useEffect(() => {
        if (cars && cars.length && window.location.hash) {
            scroller.scrollTo('to-car-' + window.location.hash.substring(1), {
                containerId: 'car-list-wrapper',
                offset: -41,
            });
        }
    }, [cars, window.location.hash]);

    return (
        <Layout
            className="cars-list"
            header={() => (
                <Header
                    orderBy={orderBy}
                    pagination={pagination}
                    searchParams={searchParams}
                    setLoadingMessage={setLoadingMessage}
                    totals={totals}
                />
            )}
            showWidget
        >
            {loadingMessage && <Spinner message={loadingMessage} />}

            <div className="d-flex justify-content-between">
                <BulkActions
                    groupSimilar={groupSimilar}
                    refresh={searchCars}
                    selectedCars={selectedCars}
                    setLoadingMessage={setLoadingMessage}
                    unselectCars={unselectCars}
                />
                <div className="d-flex justify-content-between">
                    <div className="p-2 pt-4 pr-3">
                        <Checkbox
                            disabled={!!searchParams.similar_to}
                            label={t('general.group_by_similar')}
                            onChange={(checked) => {
                                lscache.set('group_similar', checked);

                                let url = location.pathname + location.search;
                                navigate(url);
                            }}
                            value={groupSimilar}
                        />
                    </div>

                    <Button
                        color="gray"
                        onClick={() => {
                            showModal(<TableColumnsConfig />);
                        }}
                    >
                        <i className="fas fa-table" />
                        &nbsp;
                        {t('general.columns')}
                    </Button>
                </div>
            </div>

            <div className={'overflow-auto'}>
                <section className="no-padding no-rounded-corners position-relative mb-10" id="car-list-wrapper">
                    <div className={`grid-table grid-table-${selectedColumns.length + 4}-cols`}>
                        <div className="grid-table-row">
                            <div className="grid-table-cell checkbox">
                                <Checkbox
                                    field="selection-toggle"
                                    onChange={(checked) => {
                                        if (checked) {
                                            setSelectedCarIds(cars.map((car) => car.car_id));
                                        } else {
                                            setSelectedCarIds([]);
                                        }
                                    }}
                                    semiChecked={selectedCarIds.length > 0}
                                    value={selectedCarIds.length === cars.length}
                                />
                            </div>

                            <div className="grid-table-cell" />

                            {groupSimilar && (
                                <div className="grid-table-cell">
                                    <span>{t('general.amount')}</span>
                                </div>
                            )}

                            {!groupSimilar && (
                                <div
                                    className="grid-table-cell clickable sortable"
                                    onClick={() => {
                                        orderBySetter('id', 'car.id');
                                    }}
                                >
                                    <span>{t('car.id')}</span>

                                    {orderBy.key === 'id' && (
                                        <i
                                            className={classnames({
                                                fa: true,
                                                'fa-caret-up': orderBy.direction === 'ASC',
                                                'fa-caret-down': orderBy.direction === 'DESC',
                                            })}
                                        />
                                    )}
                                </div>
                            )}

                            {!groupSimilar && (
                                <div
                                    className="grid-table-cell wide clickable sortable"
                                    onClick={() => {
                                        orderBySetter('vin', 'car.vin');
                                    }}
                                >
                                    <span>{t('search.vin')}</span>

                                    {orderBy.key === 'vin' && (
                                        <i
                                            className={classnames({
                                                fa: true,
                                                'fa-caret-up': orderBy.direction === 'ASC',
                                                'fa-caret-down': orderBy.direction === 'DESC',
                                            })}
                                        />
                                    )}
                                </div>
                            )}

                            <div
                                className="grid-table-cell wide clickable sortable"
                                onClick={() => {
                                    orderBySetter('make', 'model.make');
                                }}
                            >
                                <span>{t('search.mmt')}</span>

                                {orderBy.key === 'make' && (
                                    <i
                                        className={classnames({
                                            fa: true,
                                            'fa-caret-up': orderBy.direction === 'ASC',
                                            'fa-caret-down': orderBy.direction === 'DESC',
                                        })}
                                    />
                                )}
                            </div>

                            {selectedColumns.map((tc) => (
                                <ColumnHeader
                                    className={tc.col === 'equipment' ? equipmentWidth : undefined}
                                    columnConfig={tc}
                                    key={tc.col}
                                    orderBy={orderBy}
                                    orderBySetter={orderBySetter}
                                >
                                    {tc.col === 'equipment' && (
                                        <button onClick={toggleEquipmentWidth}>
                                            <i className={`fa fa-caret-${equipmentWidth === 'wide' ? 'right' : 'left'}`} />
                                        </button>
                                    )}
                                </ColumnHeader>
                            ))}
                        </div>

                        {cars.map((car) => {
                            const greenBorder =
                                car.purchase_pricing.supplier_paid_on && car.localisation.arrival_status === 'EXPECTED';
                            let Element = Scroll.Element;
                            return (
                                <Element
                                    className={classnames({
                                        'grid-table-row': true,
                                        clickable: true,
                                        checked: selectedCarIds.includes(car.car_id),
                                        deleted: car.date.deleted_on,
                                    })}
                                    id={'to-car-' + car.car_id}
                                    key={car.car_id}
                                    name={'to-car-' + car.car_id}
                                    onClick={() => {
                                        lscache.set('previous_page', location.pathname + location.search);

                                        let targetUrl = '/autos/' + car.car_id;
                                        if (groupSimilar) {
                                            targetUrl =
                                                '/autos/lijst?query=' +
                                                JSON.stringify({
                                                    ...searchParams,
                                                    similar_to: car.car_id,
                                                });
                                        }

                                        navigate(targetUrl);
                                    }}
                                >
                                    <div
                                        className={classnames({
                                            'grid-table-cell': true,
                                            checkbox: true,
                                            'green-border': greenBorder,
                                            'blue-border': car.subsidiary === 'ATRADIS',
                                        })}
                                        data-tip={greenBorder ? t('general.supplier_paid_not_arrived') : null}
                                    >
                                        <Checkbox
                                            field={`car-${car.car_id}`}
                                            onChange={() => {
                                                handleSelect(car.car_id);
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            value={selectedCarIds.includes(car.car_id)}
                                        />
                                    </div>

                                    <div className={'d-inline-flex align-items-center'} style={{width: 60}}>
                                        <i
                                            className={classnames({
                                                dot: true,
                                                'dot-red': car.sale.status === 'NOT_FOR_SALE',
                                                'dot-gray': car.sale.status === 'FOR_SALE',
                                                'dot-yellow': car.sale.status === 'SOLD',
                                                'dot-green': car.sale.status === 'INVOICED',
                                            })}
                                            data-tip={t(`enum.sale_status.${car.sale.status}`)}
                                        />

                                        <i
                                            className={classnames({
                                                dot: true,
                                                'dot-red': car.delivery.status === 'NOT_READY',
                                                'dot-yellow': car.delivery.status === 'READY',
                                                'dot-green': car.delivery.status === 'DELIVERED',
                                            })}
                                            data-tip={t(`enum.delivery_status.${car.delivery.status}`)}
                                        />

                                        <img
                                            alt={'camera'}
                                            className={car.hasImages ? 'opacity-100' : 'opacity-0'}
                                            height={24}
                                            src={camera}
                                            width={24}
                                        />
                                    </div>

                                    {groupSimilar && (
                                        <CarCount carId={car.car_id} count={car.count} searchParams={searchParams} />
                                    )}

                                    {!groupSimilar && <div className="grid-table-cell">{car.car_id}</div>}
                                    {!groupSimilar && <div className="grid-table-cell wide">{car.vin}</div>}

                                    <div className="grid-table-cell wide">
                                        <h3>
                                            {car.type.model.make} {car.type.model.name}
                                        </h3>

                                        <p>{car.type.name}</p>
                                    </div>

                                    {selectedColumns.map((tc) => (
                                        <ColumnBody
                                            car={car}
                                            className={tc.col === 'equipment' ? equipmentWidth : undefined}
                                            columnConfig={tc}
                                            key={tc.col}
                                        />
                                    ))}
                                </Element>
                            );
                        })}
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default List;
