import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import produce from 'immer';
import {resizeImages} from '../../lib/helpers';

import {upload_files, upload_images} from '../../lib/zaza-client';
import useForm from '../../lib/UseForm';
import Button from '../Button/Button';
import Modal from './Modal';
import Checkbox from '../Form/Checkbox';
import Radio from '../Form/Radio';

const UploadModal = ({carId, clearDropped, files, isOpen, onRequestClose, refreshCar, setLoading}) => {
    const {t} = useTranslation();

    const form = useForm(
        {
            type: null,
            add_to_similar: false,
        },
        {
            onSave: async () => {
                if (form.data.type === 'documents') {
                    upload_files(files, carId, {add_to_similar: form.data.add_to_similar}, () => {
                        setLoading(false);
                        clearDropped();
                        refreshCar();
                    });
                } else {
                    const resizedFiles = await resizeImages(files);
                    upload_images(resizedFiles, carId, form.data.type, form.data.add_to_similar, () => {
                        setLoading(false);
                        clearDropped();
                        refreshCar();
                    });
                }
                onRequestClose();
                setLoading(true);
            },
        }
    );

    const closeModal = () => {
        onRequestClose();
        clearDropped();

        form.setData(
            produce((draft) => {
                draft.type = null;
                draft.add_to_similar = false;
            })
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            renderBody={() => (
                <Fragment>
                    <div className="block">
                        <strong>
                            {files.length} {t('general.files').toLowerCase()} &ndash; {files.map((f) => f.name).join(', ')}
                        </strong>
                    </div>
                    <p className="mb-3">
                        <strong>{t('general.select_filetype')}:</strong>
                    </p>
                    <div className="mb-2">
                        <Radio {...form.fields.type()} label={t('general.photos')} value="default" />
                        <Radio {...form.fields.type()} label={t('general.photo_360')} value="360" />
                        <Radio {...form.fields.type()} label={t('general.documents')} value="documents" />
                    </div>
                    <div className="mt-5">
                        <Checkbox {...form.fields.add_to_similar()} label={t('general.add_to_similar_cars')} />
                    </div>
                </Fragment>
            )}
            renderFooter={() => (
                <Fragment>
                    <Button color="blue" disabled={form.data.type === null} onClick={() => form.handleSubmit()}>
                        <span>{t('general.save')}</span>
                    </Button>
                    <Button color="blue" onClick={closeModal} outline>
                        <span>{t('general.cancel')}</span>
                    </Button>
                </Fragment>
            )}
            renderHeader={() => (
                <h2>
                    <strong>{t('general.upload_modal_title')}</strong>
                </h2>
            )}
        />
    );
};

export default UploadModal;
