import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import {useModal} from '../../../../lib/UseModal';
import {useWidget} from '../../../../lib/UseWidget';
import {addCarLines, addExtra, addTransport} from '../../../../lib/WidgetReducer';
import AddLinesModal from '../../AddLinesModal';

import SingleListCar from '../../SingleListCar';
import Checkbox from '../../../../components/Form/Checkbox';

const ListRow = ({purchaseOrder, selectedPurchaseOrderIds, setSelectedPurchaseOrderIds, users}) => {
    let navigate = useNavigate();
    const {t} = useTranslation();
    const {closeModal, showModal} = useModal();
    const [selectedLines, setSelectedLines] = useState([]);
    const [, dispatch] = useWidget();

    const handleRowSelect = (id) => {
        if (selectedPurchaseOrderIds.includes(id)) {
            setSelectedPurchaseOrderIds((prevState) => prevState.filter((option) => option !== id));
        } else {
            setSelectedPurchaseOrderIds((prevState) => [...prevState, id]);
        }
    };

    const addLines = (e) => {
        e.stopPropagation();
        showModal(
            <AddLinesModal
                enablePurchaseOrder={false}
                enableQuote={false}
                handleAdd={(type) => {
                    dispatch(
                        addCarLines(
                            type,
                            selectedLines.filter((line) => line.type === 'CAR')
                        )
                    );

                    selectedLines.forEach((line) => {
                        if (line.type === 'EXTRA') {
                            dispatch(addExtra(type, line.description, line.price));
                        }
                        if (line.type === 'TRANSPORT') {
                            dispatch(addTransport(type, line.price));
                        }
                    });

                    setSelectedLines([]);
                    closeModal();
                }}
            />
        );
    };

    return (
        <div
            className="grid-table-row clickable"
            key={purchaseOrder.id}
            onClick={(e) => {
                e.stopPropagation();
                navigate('/bestelbonnen/' + purchaseOrder.id);
            }}
        >
            <div className="grid-table-cell checkbox">
                <Checkbox
                    field={`purchaseOrder-${purchaseOrder.id}`}
                    onChange={() => {
                        handleRowSelect(purchaseOrder.id);
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    value={selectedPurchaseOrderIds.includes(purchaseOrder.id)}
                />
            </div>
            <div className="grid-table-cell">
                <span>{purchaseOrder.id}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.subsidiary.' + purchaseOrder.subsidiary)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{t('enum.document_status.' + purchaseOrder.status)}</span>
            </div>
            <div className="grid-table-cell">
                <span>{dateFormat(purchaseOrder.date)}</span>
            </div>
            <div className="grid-table-cell small">
                <span>{purchaseOrder.customer.data.label}</span>
            </div>
            <div className="grid-table-cell small">
                <span>
                    {purchaseOrder.created_by
                        ? users.find((u) => u.id === purchaseOrder.created_by).full_name
                        : t('general.employee_not_found')}
                </span>
            </div>
            <div className="grid-table-cell d-flex flex-column small similar-cars">
                {purchaseOrder.lines.map((line) => {
                    let selectable = true;
                    if (line.type === 'CAR') {
                        selectable =
                            line.car &&
                            !line.car.date.deleted_on &&
                            line.car.sale.status === 'SOLD' &&
                            !line.relisted_for_sale_on;

                        return (
                            <SingleListCar
                                key={line.id}
                                line={line}
                                selectable={selectable}
                                selectedLines={[selectedLines, setSelectedLines]}
                                showIsReadyForInvoiceStatus
                            />
                        );
                    }
                })}
                {selectedLines.length > 0 && (
                    <a className="btn--add" onClick={addLines}>
                        <i className="far fa-plus-square" />
                        {t('general.add')}
                    </a>
                )}
            </div>
            <div className="grid-table-cell numerical">
                <strong className={'me-3'}>{moneyFormat(purchaseOrder.total_vat_included)}</strong>
            </div>
        </div>
    );
};

export default React.memo(ListRow);
