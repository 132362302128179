import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import {useModal} from '../../../../lib/UseModal';
import {upload_files} from '../../../../lib/zaza-client';

function UploadCarDocument({car, carDocument, refresh}) {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, toggleSaving] = useState(false);

    const {acceptedFiles, getInputProps, getRootProps, isDragActive} = useDropzone({
        multiple: false,
        accept: 'application/pdf',
    });

    const onSubmit = () => {
        toggleSaving(true);

        upload_files(acceptedFiles, car.car_id, {add_to_similar: '0', car_document: carDocument}, () => {
            toggleSaving(false);
            closeModal();
            refresh();
        });
    };

    const files = acceptedFiles.map((file) => (
        <td key={file.path}>
            {file.path} - {file.size} bytes
        </td>
    ));

    const title = `Upload ${carDocument.replaceAll('-', ' ').replace(/^\w/, (c) => c.toUpperCase())} - ${car.vin}`;

    return (
        <Modal title={title}>
            <form>
                <Modal.Body>
                    <div className="dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>{t(`general.drag_drop_files`)}</p>
                    </div>
                    {acceptedFiles.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="pl-0 pt-3 pb-3 border-top-0">
                                        <strong>Files</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>{files}</tr>
                            </tbody>
                        </table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button color="blue" disabled={saving} onClick={onSubmit}>
                        Upload {title}
                    </Button>
                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default UploadCarDocument;
