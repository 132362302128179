"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchCustomers = void 0;
const zaza_client_1 = require("../lib/zaza-client");
const fetchCustomers = (customersToFetch, doFetch = true) => {
    return new Promise((resolve, reject) => {
        if (doFetch) {
            return (0, zaza_client_1.authenticatedHttp)()
                .post('/customers', {
                customer_ids: customersToFetch,
            })
                .then((response) => {
                resolve(response.data.data);
            })
                .catch(() => {
                reject();
            });
        }
        resolve([]);
    });
};
exports.fetchCustomers = fetchCustomers;
