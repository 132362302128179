import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Files from './Files';
import NoImages from './NoImages';
import FullscreenCarousel from './FullscreenCarousel';

import 'react-image-gallery/styles/css/image-gallery.css';

const Media = ({carId, files, form, images360, imagesDefault, refreshCar, setLoadingMessage}) => {
    const {t} = useTranslation();
    const [currentTab, setCurrentTab] = useState('photos');
    const tabs = ['photos', 'photo_360', 'files', 'notes'];

    return (
        <section className="tabs">
            <div className="tabs-nav">
                {tabs.map((tab, index) => (
                    <a
                        className={'tabs-nav-link ' + (tab === currentTab ? 'tabs-nav-link--active' : '')}
                        key={index}
                        onClick={() => {
                            setCurrentTab(tab);
                        }}
                    >
                        {tab === 'notes' && form.fields.notes().value !== '' && (
                            <i className="fas fa-exclamation-circle pe-2" style={{color: 'red'}} />
                        )}

                        {t('general.' + tab)}
                    </a>
                ))}
            </div>
            <div className="tabs-content">
                {currentTab === 'photos' && (
                    <>
                        {imagesDefault.length === 0 && <NoImages carId={carId} />}

                        {imagesDefault.length > 0 && (
                            <FullscreenCarousel
                                carId={carId}
                                images={imagesDefault}
                                refreshCar={refreshCar}
                                setLoadingMessage={setLoadingMessage}
                            />
                        )}
                    </>
                )}
                {currentTab === 'photo_360' && (
                    <>
                        {images360.length > 0 && (
                            <FullscreenCarousel
                                carId={carId}
                                images={images360}
                                refreshCar={refreshCar}
                                setLoadingMessage={setLoadingMessage}
                            />
                        )}
                    </>
                )}
                {currentTab === 'files' && (
                    <Files carId={carId} files={files} refreshCar={refreshCar} setLoadingMessage={setLoadingMessage} />
                )}
                {currentTab === 'notes' && (
                    <textarea
                        onChange={(value) => form.fields.notes().onChange(value.target.value)}
                        style={{
                            width: '100%',
                            height: 150,
                        }}
                        value={form.fields.notes().value}
                    />
                )}
            </div>
        </section>
    );
};

export default React.memo(Media);
