import React, {Fragment, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../lib/zaza-client';
import useForm from '../../lib/UseForm';
import useFetchData from '../../lib/UseFetchData';
import {useAuth} from '../../lib/UseAuth';

import Button from '../Button/Button';
import Modal from './Modal';
import Select from '../Form/Select';

const UndoConsignationModal = ({carId, isOpen, onRequestClose, refreshCar}) => {
    const {t} = useTranslation();
    const {user} = useAuth();

    const [submitting, toggleSubmitting] = useState(false);
    const [users] = useFetchData('/users');
    const [locations] = useFetchData('/locations');
    const locationOptions = useMemo(() => {
        return locations
            ? locations
                  .filter((location) => location.id !== 'CONSIGNMENT')
                  .map((location) => ({value: location.id, label: t('enum.location.' + location.id)}))
            : [];
    }, [locations]);

    const form = useForm(
        {
            where: null,
            changed_by: user.id,
        },
        {
            onSave: ({data}) => {
                if (!data.where || !data.changed_by) {
                    return;
                }

                toggleSubmitting(true);

                authenticatedHttp()
                    .patch('/cars/' + carId, {
                        localisation: {
                            location: {
                                where: data.where,
                                changed_by: data.changed_by,
                            },
                        },
                    })
                    .then(() => {
                        onRequestClose();
                        refreshCar();
                        toggleSubmitting(false);
                    });
            },
        }
    );

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            renderBody={() => (
                <Fragment>
                    <p>{t('general.cancel_consignation_confirm')}</p>
                    <div className="form-group form-group-vertical">
                        <label>{t('car.localisation.location')}</label>
                        <Select {...form.fields.where()} options={locationOptions} />
                    </div>
                    <div className="form-group form-group-vertical">
                        <label>{t('car.localisation.location_changed_by')}</label>
                        <Select
                            allowClear
                            options={users ? users.map((user) => ({value: user.id, label: user.full_name})) : []}
                            placeholder={t('car.localisation.location_changed_by')}
                            {...form.fields.changed_by()}
                        />
                    </div>
                </Fragment>
            )}
            renderFooter={() => (
                <Button color="blue" disabled={submitting} onClick={() => form.handleSubmit()}>
                    {submitting ? t('general.submitting') : t('car.undo_consignation')}
                </Button>
            )}
            renderHeader={() => (
                <h2>
                    <strong>{t('car.undo_consignation')}</strong>
                </h2>
            )}
        />
    );
};

export default UndoConsignationModal;
