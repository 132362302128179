import classnames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {dateFormat} from '../../lib/helpers';

import Checkbox from '../Form/Checkbox';

const PDIToggle = ({disabled, form, isCreate}) => {
    const {t} = useTranslation();

    const togglePdiChecked = (checked) => {
        if (checked) {
            const date = new Date().toISOString().split('T')[0];
            form.setData((prevState) => ({...prevState, pre_delivery_inspection_passed_on: date}));

            return;
        }

        form.setData((prevState) => ({...prevState, pre_delivery_inspection_passed_on: null}));
    };

    const togglePdiNeeded = (checked) => {
        if (checked) {
            form.setData((prevState) => ({...prevState, pre_delivery_inspection_needed: true}));

            return;
        }

        form.setData((prevState) => ({...prevState, pre_delivery_inspection_needed: false}));
    };

    const pdiComplete =
        form.data.pre_delivery_inspection_passed_on !== null && form.data.pre_delivery_inspection_passed_on !== undefined;
    const pdiNeeded = form.data.pre_delivery_inspection_needed;
    const toggleLabel = pdiComplete
        ? t('car.pdi.label_checked', {date: dateFormat(form.data.pre_delivery_inspection_passed_on)})
        : t('car.pdi.label');

    return (
        <div
            className={classnames({
                pdi_toggle: true,
            })}
        >
            <span className="pdi_toggle-title">{t('car.pdi.title')}</span>
            <label
                className={classnames({
                    'pdi_toggle-label': true,
                    complete: pdiNeeded,
                    'no-round': pdiNeeded && !isCreate,
                })}
                htmlFor="pdi_needed"
            >
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{__html: 'Nodig'}} />
                <Checkbox disabled={disabled} id="pdi_needed" name="pdi_needed" onChange={togglePdiNeeded} value={pdiNeeded} />
            </label>
            {form.data.pre_delivery_inspection_needed && !isCreate && (
                <label
                    className={classnames({
                        'pdi_toggle-label': true,
                        complete: pdiComplete,
                    })}
                    htmlFor="pdi_complete"
                >
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{__html: toggleLabel}} />
                    <Checkbox
                        disabled={disabled}
                        id="pdi_complete"
                        name="pdi_complete"
                        onChange={togglePdiChecked}
                        value={pdiComplete}
                    />
                </label>
            )}
        </div>
    );
};

export default PDIToggle;
