import classnames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {dateFormat, getObjectValueAtPath, roundDecimals} from '../../../lib/helpers';

const ColumnBody = ({car, className, columnConfig}) => {
    const {t} = useTranslation();
    const getCarProperty = () => {
        const value = getObjectValueAtPath(car, columnConfig.path);

        const columnNames = ['sold_for', 'required_advance', 'outstanding_advance'];

        if (
            columnConfig.col.includes('website_pricing_') &&
            car.sales_pricing.not_for_sale_in.some((option) => {
                return columnConfig.col === `website_pricing_${option.toLowerCase()}`;
            })
        ) {
            return '-';
        }

        if (columnNames.includes(columnConfig.col) && value === null) {
            return '-';
        }

        if (columnConfig.price) {
            return roundDecimals(value, 0);
        }

        if (columnConfig.date) {
            return dateFormat(value);
        }

        if (columnConfig.prefix) {
            return (
                <span>
                    {columnConfig.prefix} {value}
                </span>
            );
        }

        if (columnConfig.suffix) {
            return (
                <span>
                    {value} {columnConfig.suffix}
                </span>
            );
        }

        if (columnConfig.enum) {
            if (!value) {
                return null;
            }
            return <span>{t(`enum.${columnConfig.enum}.${value}`)}</span>;
        }

        if (columnConfig.boolean) {
            return <span>{value ? t('search.yes') : t('search.no')}</span>;
        }

        return <span>{value}</span>;
    };

    const classes = {
        'grid-table-cell': true,
        numerical: columnConfig.price || columnConfig.numerical || false,
        small: columnConfig.small || false,
        wide: columnConfig.wide || false,
    };

    if (className) {
        classes[className] = true;
    }

    return (
        <div className={classnames(classes)} key={columnConfig.col}>
            {getCarProperty(columnConfig, car)}
        </div>
    );
};

export default ColumnBody;
