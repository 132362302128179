import React, {Fragment} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Money from '../../components/Form/Money';
import PricingSelect from './PricingSelect';
import {moneyFormat} from '../../lib/helpers';

function FormRow({
    allowDelete = true,
    car,
    control,
    disabled = false,
    enableCustomPrice = true,
    field,
    index,
    pricing,
    register,
    remove,
    requiredAdvance,
    setPricing,
    showVIN,
    watch,
}) {
    const {t} = useTranslation();

    let formattedRequiredAdvance = '-';
    switch (requiredAdvance) {
        case 'FIXED':
            formattedRequiredAdvance = moneyFormat(500);
            break;
        case 'FIXED_NEW':
            formattedRequiredAdvance = moneyFormat(1000);
            break;
        case 'PERCENTAGE':
            formattedRequiredAdvance = `10% (${moneyFormat(watch(`lines[${index}].price`) * 0.1)})`;
            break;
    }

    return (
        <div className="grid-table-row">
            {/* Hidden input to submit line type and ID to form hook */}
            <input className="d-none" defaultValue={field.type} {...register(`lines[${index}].type`)} />
            <input className="d-none" defaultValue={field.car_id} {...register(`lines[${index}].car_id`)} />
            <div className="grid-table-cell">
                {field.type === 'CAR' && (
                    <Fragment>
                        <strong>
                            {car.type.model.make} {car.type.model.name}
                        </strong>
                        <p>{car.type.name}</p>
                        {showVIN && (
                            <a className="link--blue small" href={'/autos/' + car.car_id}>
                                {car.car_id} &ndash; {car.vin}
                            </a>
                        )}
                        <p className="small">{t('enum.subsidiary.' + car.subsidiary)}</p>
                    </Fragment>
                )}
                {field.type === 'EXTRA' && (
                    <div className="form-group flex-grow-1">
                        <input
                            autoComplete="off"
                            className="form-control "
                            defaultValue={field.description}
                            disabled={disabled}
                            placeholder={t('general.description')}
                            {...register(`lines[${index}].description`)}
                        />
                    </div>
                )}
                {field.type === 'TRANSPORT' && <span>{t('general.transport')}</span>}
            </div>
            <div className="grid-table-cell">{field.type === 'CAR' && !watch('show_similar') && formattedRequiredAdvance}</div>
            <div className="grid-table-cell numerical">
                {field.type === 'CAR' && car && (
                    <PricingSelect
                        customOption={pricing === 'CUSTOM' ? 'CUSTOM' : undefined}
                        disabled={disabled}
                        onChange={setPricing}
                        value={pricing}
                    />
                )}
            </div>
            <div className="grid-table-cell numerical">
                <div className="form-group">
                    <Controller
                        control={control}
                        defaultValue={field.price}
                        disabled={disabled || (field.type === 'CAR' && !enableCustomPrice)}
                        name={`lines[${index}].price`}
                        render={({field}) => {
                            const {onChange, ...fieldProps} = field;

                            return (
                                <Money
                                    {...fieldProps}
                                    onChange={(value) => {
                                        setPricing('CUSTOM');

                                        onChange(!isNaN(value) ? value : null);
                                    }}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            <div className="grid-table-cell numerical">
                {allowDelete && !disabled && (
                    <a
                        className="btn--delete"
                        onClick={() => {
                            remove(index);
                        }}
                    >
                        <i className="fa fa-trash" />
                    </a>
                )}
            </div>
        </div>
    );
}

export default FormRow;
