import React from 'react';
import {useTranslation} from 'react-i18next';

const TopNavItem = ({className, title}) => {
    const {t} = useTranslation();

    return (
        <li className={className}>
            <a href={`#${title}`}>{t(`car.nav.${title}`)}</a>
        </li>
    );
};

export default React.memo(TopNavItem);
