import moment from 'moment';
import React, {useState} from 'react';
import DatePicker from '../../../../components/Form/DatePicker';
import DocumentDeliveryEnum from '../../../../components/Form/DocumentDeliveryEnum';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useModal} from '../../../../lib/UseModal';
import {useTranslation} from 'react-i18next';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/NewModal';
import Select from '../../../../components/Form/Select';
import ListWarning from '../../Detail/Components/ListWarning';

const EditDelivery = ({cars, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const [saving, setSaving] = useState(false);
    const [formState, setFormState] = useState({
        is_delivered: null,
        delivery_on: moment().format('YYYY-MM-DD'),
        documents1: null,
        documents2: null,
        coc: null,
        warranty: null,
        keys: null,
    });

    const deliveredCars = cars.filter((car) => {
        return car.localisation.delivered_on !== null;
    });

    const yetToArriveCars = cars.filter((car) => {
        return car.localisation.arrival_status !== 'ARRIVED';
    });

    const bookletYetToArriveCars = cars.filter((car) => {
        return !(car.documents.warranty_booklet === 'ARRIVED' || car.documents.warranty_booklet === 'UNNECESSARY');
    });

    const keysYetToArriveCars = cars.filter((car) => {
        return car.documents.keys_delivery === 'REQUESTED' || car.documents.keys_delivery === 'UNNECESSARY';
    });

    const POR1YetToArriveCars = cars.filter((car) => {
        return !(
            car.documents.proof_of_registration_p1 === 'ARRIVED' || car.documents.proof_of_registration_p1 === 'UNNECESSARY'
        );
    });

    const POR2YetToArriveCars = cars.filter((car) => {
        return !(
            car.documents.proof_of_registration_p2 === 'ARRIVED' || car.documents.proof_of_registration_p2 === 'UNNECESSARY'
        );
    });

    const COCYetToArriveCars = cars.filter((car) => {
        return !(car.documents.coc === 'ARRIVED' || car.documents.coc === 'UNNECESSARY');
    });

    const handleSave = (e) => {
        e.preventDefault();
        setSaving(true);

        const patch = cars.map((car) => {
            const data = {
                car_id: car.car_id,
                localisation: {},
                documents: {
                    registration: {},
                },
            };

            if (formState.is_delivered !== null) {
                data.localisation = {delivery: {is_delivered: formState.is_delivered}};

                if (formState.delivery_on) {
                    if (formState.is_delivered) {
                        data.localisation.delivery.on = formState.delivery_on;
                    } else {
                        data.localisation.delivery.expected = formState.delivery_on;
                    }
                }
            }

            if (formState.e705 || formState.documents1 || formState.documents2) {
                if (car.documents.registration.second) {
                    data.documents.registration.second = {
                        e705: {},
                        proof_of_registration_part_1: {},
                        proof_of_registration_part_2: {},
                    };
                } else if (car.documents.registration.first) {
                    data.documents.registration.first = {
                        e705: {},
                        proof_of_registration_part_1: {},
                        proof_of_registration_part_2: {},
                    };
                }
            }

            if (formState.e705) {
                if (car.documents.registration.second && car.documents.registration.second.e705 === 'ACCEPTED') {
                    data.documents.registration.second.e705 = {delivery: formState.e705};
                } else if (car.documents.registration.first && car.documents.registration.first.e705 === 'ACCEPTED') {
                    data.documents.registration.first.e705 = {delivery: formState.e705};
                }
            }

            if (formState.documents1) {
                if (car.documents.registration.second) {
                    data.documents.registration.second.proof_of_registration_part_1 = {delivery: formState.documents1};
                } else if (car.documents.registration.first) {
                    data.documents.registration.first.proof_of_registration_part_1 = {delivery: formState.documents1};
                }
            }

            if (formState.documents2) {
                if (car.documents.registration.second) {
                    data.documents.registration.second.proof_of_registration_part_2 = {delivery: formState.documents2};
                } else if (car.documents.registration.first) {
                    data.documents.registration.first.proof_of_registration_part_2 = {delivery: formState.documents2};
                }
            }

            if (formState.warranty) {
                data.documents.warranty_booklet = {delivery: formState.warranty};
            }

            if (formState.coc) {
                data.documents.certificate_of_conformity = {delivery: formState.coc};
            }

            if (formState.keys) {
                data.documents.keys_delivery = formState.keys;
            }

            return data;
        });

        authenticatedHttp()
            .patch('/cars', patch)
            .then(() => {
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('search.edit_delivery')}>
            <form onSubmit={handleSave}>
                <Modal.Body>
                    <table className="table table-documents">
                        <tbody className="form-group">
                            <tr className="form-group__subtitle-container">
                                <td colSpan={3}>
                                    <h3 className="form-group__subtitle">{t('general.car')}</h3>

                                    {formState.is_delivered && deliveredCars.length > 0 && <ListWarning data={deliveredCars} />}
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    <strong>{t('car.localisation.delivery_status')}</strong>
                                </th>

                                <td>
                                    <Select
                                        disabled={yetToArriveCars.length > 0}
                                        onChange={(value) => setFormState({...formState, is_delivered: value})}
                                        options={[
                                            {label: t('car.localisation.is_delivered'), value: null},
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        value={formState.is_delivered}
                                    />
                                </td>
                            </tr>

                            {formState.is_delivered !== null && (
                                <tr>
                                    <th>
                                        <strong>
                                            {formState.is_delivered ? t('search.delivered_on') : t('search.expected_on')}
                                        </strong>
                                    </th>

                                    <td>
                                        <DatePicker
                                            onChange={(delivery_on) => setFormState({...formState, delivery_on})}
                                            value={formState.delivery_on}
                                        />
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <th>
                                    <strong>{t('car.documents.warranty_booklet')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={bookletYetToArriveCars.length > 0}
                                        documentType="warranty_booklet"
                                        onChange={(warranty) => setFormState({...formState, warranty})}
                                        value={formState.warranty}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    <strong>{t('car.documents.keys')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={keysYetToArriveCars.length > 0}
                                        documentType="keys"
                                        onChange={(keys) => setFormState({...formState, keys})}
                                        value={formState.keys}
                                    />
                                </td>
                            </tr>

                            <tr className="form-group__subtitle-container">
                                <td colSpan={3}>
                                    <h3 className="form-group__subtitle">{t('search.documents')}</h3>
                                </td>
                            </tr>

                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.e705')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={POR1YetToArriveCars.length > 0}
                                        documentType="e705"
                                        onChange={(e705) => setFormState({...formState, e705})}
                                        value={formState.e705}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th width="200">
                                    <strong>{t('car.documents.proof_of_registration_part_1')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={POR1YetToArriveCars.length > 0}
                                        documentType="proof_of_registration_part_1"
                                        onChange={(documents1) => setFormState({...formState, documents1})}
                                        value={formState.documents1}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    <strong>{t('car.documents.proof_of_registration_part_2')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={POR2YetToArriveCars.length > 0}
                                        documentType="proof_of_registration_part_2"
                                        onChange={(documents2) => setFormState({...formState, documents2})}
                                        value={formState.documents2}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    <strong>{t('car.documents.certificate_of_conformity')}</strong>
                                </th>

                                <td>
                                    <DocumentDeliveryEnum
                                        disabled={COCYetToArriveCars.length > 0}
                                        documentType="certificate_of_conformity"
                                        onChange={(coc) => setFormState({...formState, coc})}
                                        value={formState.coc}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Modal.Body>

                <Modal.Footer>
                    <Button color="blue" submit>
                        {t('search.edit_delivery')}
                    </Button>

                    {saving && <span style={{lineHeight: '33px', marginLeft: '5px'}}>{t('general.saving')}</span>}
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditDelivery;
