"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sale_status = exports.not_for_sale_in_locations = exports.price_options = exports.contact_position = exports.registration_status_segment = exports.registration_status = exports.body_type_segment = exports.body_type = exports.make = exports.language = exports.external_partner_service = exports.isEuropeanUnionCountry = exports.european_union_country = exports.country = exports.reason_for_rejection_status = exports.reason_for_deactivation_status = exports.website_status = exports.customer_sector = exports.customer_type = exports.company_type = void 0;
exports.company_type = ['CUSTOMER', 'SUPPLIER', 'TRANSPORTER', 'FACILITY', 'EXTERNAL', 'CUSTOMS', 'COMPETITOR'];
exports.customer_type = [
    'BULK_DEALER',
    'RETAIL_DEALER',
    'PRIVATE_WITHOUT_VAT',
    'PRIVATE_WITH_VAT',
    'PRIVATE_INDEPENDENT',
];
exports.customer_sector = ['DEALER', 'DISTRIBUTOR', 'LEASING', 'RENTAL', 'IMPORT', 'EXPORT'];
exports.website_status = ['NEW', 'NO_WEBSITE_USER', 'PENDING', 'INVITED', 'DENIED', 'ACTIVE', 'DEACTIVATED'];
exports.reason_for_deactivation_status = [
    'BREACH_GENERAL_CONDITIONS_PRICES',
    'BREACH_GENERAL_CONDITIONS_PHOTOS_TEXT',
    'BREACH_GENERAL_CONDITIONS_NO_TRADER',
    'BREACH_GENERAL_CONDITIONS_LOGIN_MISUSE',
    'ALREADY_HAS_ACCOUNT',
    'NO_ACTIVE_COMPANY',
    'COMPETITION',
    'FORMER_EMPLOYEE',
    'OTHER',
];
exports.reason_for_rejection_status = [
    'PRIVATE',
    'NO_CAR_ACTIVITIES',
    'ALREADY_HAS_ACCOUNT',
    'NO_ACTIVE_COMPANY',
    'COMPETITION',
    'OTHER',
];
exports.country = [
    'AL',
    'DZ',
    'AD',
    'AO',
    'AT',
    'BE',
    'BG',
    'CF',
    'CR',
    'CI',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'GF',
    'PF',
    'TF',
    'DE',
    'GI',
    'GR',
    'GN',
    'HU',
    'IE',
    'IS',
    'IT',
    'LV',
    'LB',
    'LT',
    'LU',
    'MF',
    'MT',
    'MQ',
    'MC',
    'ME',
    'NL',
    'MK',
    'NO',
    'PL',
    'PT',
    'RO',
    'RS',
    'SK',
    'SI',
    'ZA',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'AE',
    'GB',
    'US',
    'ZZ',
];
exports.european_union_country = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
];
const isEuropeanUnionCountry = (country) => exports.european_union_country.includes(country);
exports.isEuropeanUnionCountry = isEuropeanUnionCountry;
exports.external_partner_service = [
    'REAR_VIEW_CAMERA',
    'TYRES',
    'BODY_SHOP',
    'CAR_WASH',
    'WINDOW_TINTING',
    'HOMOLOGATION',
    'MECHANIC',
    'MAINTENANCE',
    'PARKING_SENSORS',
    'TOW_HITCH',
    'RIMS',
    'WRAP',
];
exports.language = ['NL', 'FR', 'EN', 'DE', 'ES', 'IT'];
exports.make = [
    'ALFA ROMEO',
    'AUDI',
    'BMW',
    'DACIA',
    'FIAT',
    'FORD',
    'HONDA',
    'HYUNDAI',
    'JAGUAR',
    'JEEP',
    'KIA',
    'LAND ROVER',
    'MAZDA',
    'MERCEDES',
    'MINI',
    'NISSAN',
    'OPEL',
    'PEUGEOT',
    'RENAULT',
    'SEAT',
    'SKODA',
    'SMART',
    'TOYOTA',
    'VOLVO',
    'VW',
    'CADILLAC',
    'CHRYSLER',
    'DODGE',
    'INFINITI',
    'LANCIA',
    'LEXUS',
    'MITSUBISHI',
    'SSANG YONG',
    'SUBARU',
    'SUZUKI',
    'TESLA',
];
exports.body_type = [
    'PASSENGER_CAR',
    'STATION_WAGON',
    'CONVERTIBLE',
    'COMMERCIAL',
    'MINIVAN',
    'SUV',
    'COUPE',
    'PICKUP',
    'CAMPER',
    'BERLINE',
];
exports.body_type_segment = ['PASSENGER_CAR', 'COMMERCIAL', 'CAMPER'];
exports.registration_status = ['NEW', 'NEW_WITH_REGISTRATION', 'SECOND_HAND', 'ZERO_KILOMETERS'];
exports.registration_status_segment = ['NEW', 'SECOND_HAND'];
exports.contact_position = ['ADMINISTRATION', 'MANAGEMENT', 'LOGISTICS', 'SALES'];
exports.price_options = [
    'WEBSITE_PRICING_BE',
    'WEBSITE_PRICING_CH',
    'WEBSITE_PRICING_DE',
    'WEBSITE_PRICING_FR',
    'WEBSITE_PRICING_IT',
    'WEBSITE_PRICING_NL',
    'WEBSITE_PRICING_ROW',
    'WEBSITE_PRICING_PRIV',
    'CONSIGNMENT',
    'ELN',
    'T1',
    'TRUCK',
];
exports.not_for_sale_in_locations = ['OUTSIDE_OF_EU'];
exports.sale_status = ['FOR_SALE', 'SOLD', 'INVOICED', 'NOT_FOR_SALE'];
