import React from 'react';
import {moneyFormat} from '../../lib/helpers';

const RequiredAdvance = ({line}) => {
    let requiredAdvance = '';
    if (line.type === 'CAR') {
        switch (line.required_advance) {
            case 'NONE':
                requiredAdvance = '-';
                break;
            case 'FIXED':
                requiredAdvance = moneyFormat(500);
                break;
            case 'FIXED_NEW':
                requiredAdvance = moneyFormat(1000);
                break;
            case 'PERCENTAGE':
                requiredAdvance = `10% (${moneyFormat(line.price * 0.1)})`;
                break;
        }
    }

    return <span>{requiredAdvance}</span>;
};

export default RequiredAdvance;
