import React, {Fragment, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Enum from '../../../../components/Form/Enum';

import useFetchData from '../../../../lib/UseFetchData';
import {useTranslation} from 'react-i18next';
import {moneyFormat} from '../../../../lib/helpers';
import {useAuth} from '../../../../lib/UseAuth';

import DatePicker from '../../../../components/Form/DatePicker';
import Input from '../../../../components/Form/Input';
import Money from '../../../../components/Form/Money';
import Section from './Section';
import Select from '../../../../components/Form/Select';
import SupplierDetail from '../../../../components/CarDetail/SupplierDetail';
import Autocomplete from '../../../../components/Form/Autocomplete';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';
import VerifiableMoney from '../../../../components/Form/VerifiableMoney';
import Decimal from '@agrora/decimal';
import SupplierPaymentStatus from '../Components/SupplierPaymentStatus';
import TransportOrganizedBy from '../Components/TransportOrganizedBy';

const DATE_FORMAT = 'YYYY-MM-DD';

const PurchasePricing = ({car, disabled, form, pricingTotals, refresh, setLoadingMessage}) => {
    const {i18n, t} = useTranslation();
    const {user} = useAuth();
    const [users] = useFetchData('/users');

    const transporterFromSupplierId = form.data.purchase_pricing.transport.from_supplier.by;
    const [transporterFromSupplier] = useFetchData('/companies/' + transporterFromSupplierId, !!transporterFromSupplierId);

    const transporterFromEcrId = form.data.purchase_pricing.transport.from_ecr
        ? form.data.purchase_pricing.transport.from_ecr.by
        : false;
    const [transporterFromEcr] = useFetchData('/companies/' + transporterFromEcrId, !!transporterFromEcrId);

    const userOptions = useMemo(() => {
        return users
            ? users.map((user) => ({
                  value: user.id,
                  label: user.full_name,
              }))
            : [];
    }, [users]);

    const [commissionTo, setCommissionTo] = useState(false);

    useEffect(() => {
        setCommissionTo(false);
        if (form.data.purchase_pricing.commission_to) {
            authenticatedHttp()
                .get('/companies/' + form.data.purchase_pricing.commission_to)
                .then((response) => {
                    setCommissionTo(response.data.data);
                });
        }
    }, [form.data.purchase_pricing.commission_to]);

    const hasMargins = car && car.margins && (car.margins.parking_cost || car.margins.financing_cost);
    const isCommissionVerified = form.data.purchase_pricing.commission_verified;
    const isImportDutiesVerified = form.data.purchase_pricing.import_duties_verified;
    const isTransportFromEcrVerified = form.data.purchase_pricing.transport.from_ecr
        ? form.data.purchase_pricing.transport.from_ecr.verified
        : false;
    const isTransportFromSupplierVerified = form.data.purchase_pricing.transport.from_supplier
        ? form.data.purchase_pricing.transport.from_supplier.verified
        : false;
    const hasContractNumber =
        form.data.purchase_pricing.costs.purchase_price.currency !== 'EUR' &&
        (form.data.purchase_pricing.costs.purchase_price.contract_number === null ||
            form.data.purchase_pricing.costs.purchase_price.contract_number === '');

    const purchaseDocuments = (car && car.purchase_documents) || [];
    const disablePurchasePrice = purchaseDocuments.length > 0 || disabled;

    useEffect(() => {
        if (form.data.purchase_pricing.costs.purchase_price.currency !== 'EUR') {
            if (!form.data.purchase_pricing.costs.purchase_price.contract_number) {
                form.setData((prevState) => {
                    return {
                        ...prevState,
                        purchase_pricing: {
                            ...prevState?.purchase_pricing,
                            costs: {
                                ...prevState?.purchase_pricing?.costs,
                                purchase_price: {
                                    ...prevState?.purchase_pricing?.costs?.purchase_price,
                                    internal_contract_number: null,
                                    contract_expiry_date: null,
                                },
                            },
                        },
                    };
                });
            }
        }
    }, [form.data.purchase_pricing.costs.purchase_price.contract_number]);

    useEffect(() => {
        if (
            form.data.purchase_pricing.costs.purchase_price.currency === 'EUR' &&
            (form.data.purchase_pricing.costs.purchase_price.internal_contract_number !== null ||
                form.data.purchase_pricing.costs.purchase_price.currency_rate !== null ||
                form.data.purchase_pricing.costs.purchase_price.contract_number !== null ||
                form.data.purchase_pricing.costs.purchase_price.contract_expiry_date !== null)
        ) {
            form.setData((prevState) => {
                return {
                    ...prevState,
                    purchase_pricing: {
                        ...prevState?.purchase_pricing,
                        costs: {
                            ...prevState?.purchase_pricing?.costs,
                            purchase_price: {
                                ...prevState?.purchase_pricing?.costs?.purchase_price,
                                internal_contract_number: null,
                                currency_rate: null,
                                contract_number: null,
                                contract_expiry_date: null,
                            },
                        },
                    },
                };
            });
        }
    }, [form.data.purchase_pricing.costs.purchase_price.currency]);

    let amountInEuro = 0;
    if (
        form.data.purchase_pricing.costs.purchase_price.amount &&
        form.data.purchase_pricing.costs.purchase_price.currency_rate &&
        !isNaN(parseFloat(form.data.purchase_pricing.costs.purchase_price.currency_rate)) &&
        form.data.purchase_pricing.costs.purchase_price.currency !== 'EUR'
    ) {
        const amount = Decimal.fromNumber(parseFloat(form.data.purchase_pricing.costs.purchase_price.amount));
        const currencyRate = Decimal.fromNumber(
            parseFloat(form.data.purchase_pricing.costs.purchase_price.currency_rate.replace(',', '.'))
        );
        amountInEuro = currencyRate.toFloat() ? amount.divide(currencyRate).toFloat() : 0;
    }

    return (
        <Section name="purchase_data">
            <div style={{display: 'flex'}}>
                <div style={{width: '60%', marginBottom: '60px'}}>
                    <ul className="breakdown-grid columns-3 form-group">
                        <li className="breakdown-head">
                            <h3 className="colspan-3">{t('car.nav.purchase_pricing')}</h3>
                        </li>
                        {user.permissions.CAN_VIEW_PURCHASING_INFORMATION ? (
                            <>
                                {form.fields.purchase_pricing.costs.purchase_price.amount && (
                                    <li>
                                        <div
                                            style={{
                                                background: 'none',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flexGrow: 0,
                                            }}
                                        >
                                            <strong className={'me-2'}>{t('car.purchase_pricing.purchase_price')}</strong>
                                            {car && (
                                                <div style={{marginTop: -14}}>
                                                    <SupplierPaymentStatus
                                                        purchasePricing={form.data.purchase_pricing}
                                                        refresh={refresh}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className={'d-flex d-flex-row'}>
                                            <Money
                                                {...form.fields.purchase_pricing.costs.purchase_price.amount()}
                                                currency={form.data.purchase_pricing.costs.purchase_price.currency}
                                                disabled={disablePurchasePrice}
                                            />
                                            <div style={{width: 150}}>
                                                <Enum
                                                    disabled={disablePurchasePrice}
                                                    enumName={'currency'}
                                                    placeholder={t('general.currency')}
                                                    {...form.fields.purchase_pricing.costs.purchase_price.currency()}
                                                />
                                            </div>
                                        </div>
                                        <span>
                                            <Select
                                                allowClear
                                                disabled={disabled}
                                                options={userOptions}
                                                placeholder={t('car.purchase_pricing.purchased_by')}
                                                {...form.fields.purchase_pricing.purchased_by()}
                                            />
                                        </span>
                                    </li>
                                )}
                                {form.data.purchase_pricing.costs.purchase_price.currency !== 'EUR' && (
                                    <>
                                        <li>
                                            <strong>{t('car.purchase_price_in_euro')}</strong>
                                            <div className={'d-flex d-flex-row'} id={'vin'}>
                                                {hasContractNumber && (
                                                    <div className={'ms-2'} id="tooltip">
                                                        <i
                                                            className="fas fa-exclamation-circle pt-3 pl-2"
                                                            style={{color: 'red'}}
                                                        />
                                                        <p>{t('car.indicative_price')}</p>
                                                    </div>
                                                )}
                                                <div className={`w-100 ${hasContractNumber ? 'ms-3' : ''}`}>
                                                    <Money disabled={true} value={amountInEuro} />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <strong>{t('car.contract_number')}</strong>
                                            <div className={'d-flex d-flex-row'}>
                                                <Input
                                                    textAlign={'right'}
                                                    {...form.fields.purchase_pricing.costs.purchase_price.contract_number()}
                                                />
                                            </div>
                                            <div className={'d-flex d-flex-row'}>
                                                <Input
                                                    allowZeroWhileEditting={true}
                                                    textAlign={'right'}
                                                    {...form.fields.purchase_pricing.costs.purchase_price.currency_rate()}
                                                    formatter={(value) => value.replace('.', ',')}
                                                    placeholder={t('car.expected_currency_rate')}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <strong>{t('car.internal_contract_number')}</strong>
                                            <div className={'d-flex d-flex-row'}>
                                                <Input
                                                    disabled={!form.data.purchase_pricing.costs.purchase_price.contract_number}
                                                    textAlign={'right'}
                                                    {...form.fields.purchase_pricing.costs.purchase_price.internal_contract_number()}
                                                />
                                            </div>
                                            <div className={'d-flex d-flex-row'}>
                                                {form.data.purchase_pricing.costs.purchase_price.contract_number ? (
                                                    <DatePicker
                                                        disabled={
                                                            !form.data.purchase_pricing.costs.purchase_price.contract_number
                                                        }
                                                        {...form.fields.purchase_pricing.costs.purchase_price.contract_expiry_date()}
                                                        placeholder={t('general.expiry_date')}
                                                        textRight
                                                    />
                                                ) : (
                                                    <Input disabled={true} />
                                                )}
                                            </div>
                                        </li>
                                    </>
                                )}
                            </>
                        ) : null}
                        {(user.permissions.CAN_VIEW_SUPPLIER_DATA || user.permissions.CAN_VERIFY_OUTGOING_COSTS) &&
                            form.fields.purchase_pricing.transport.from_supplier.cost && (
                                <li className="height-44">
                                    <strong>
                                        {t('car.purchase_pricing.transport_from_supplier')}
                                        <div style={{marginTop: -14}}>
                                            <TransportOrganizedBy form={form} />
                                        </div>
                                    </strong>
                                    <VerifiableMoney
                                        checkboxProps={
                                            form.fields.purchase_pricing.transport.from_supplier.verified
                                                ? {
                                                      ...form.fields.purchase_pricing.transport.from_supplier.verified({
                                                          onChange(name, value, draft) {
                                                              draft.purchase_pricing.transport.from_supplier.verified_on =
                                                                  moment().format(DATE_FORMAT);
                                                          },
                                                      }),
                                                      disabled: isTransportFromSupplierVerified,
                                                      value: isTransportFromSupplierVerified,
                                                  }
                                                : undefined
                                        }
                                        moneyProps={{
                                            ...form.fields.purchase_pricing.transport.from_supplier.cost(),
                                            disabled: disabled,
                                        }}
                                        verifiedBy={form.data.purchase_pricing.transport.from_supplier.verified_by}
                                        verifiedOn={form.data.purchase_pricing.transport.from_supplier.verified_on}
                                    />
                                    <span>
                                        {car &&
                                            transporterFromSupplier &&
                                            form.data.purchase_pricing.transport.from_supplier.id && (
                                                <div className={'d-flex'}>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!confirm(t('general.cancel_transport_order'))) {
                                                                return;
                                                            }

                                                            authenticatedHttp()
                                                                .delete(
                                                                    '/transports/' +
                                                                        form.data.purchase_pricing.transport.from_supplier.id
                                                                )
                                                                .then(() => {
                                                                    refresh();
                                                                });
                                                        }}
                                                        style={{marginRight: '.5em'}}
                                                    >
                                                        <i className="fas fa-times-circle" />
                                                    </button>
                                                    <a
                                                        href={`/autos/lijst?query={"subsidiary":["ECRENT"], "sale_status":["ALL_SALES_STATUSES"],"supplier_transport_number":"${form.data.purchase_pricing.transport.from_supplier.id}"}`}
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            flexGrow: 1,
                                                            width: 40,
                                                        }}
                                                        target="_blank"
                                                        title={`#${form.data.purchase_pricing.transport.from_supplier.id}: ${transporterFromSupplier.label}`}
                                                    >
                                                        #{form.data.purchase_pricing.transport.from_supplier.id}:{' '}
                                                        {transporterFromSupplier.label}
                                                    </a>
                                                    <a
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setLoadingMessage(t('general.generating_document'));

                                                            downloadFile(
                                                                '/download/transport-request',
                                                                {
                                                                    transport_id:
                                                                        form.data.purchase_pricing.transport.from_supplier.id,
                                                                    language: i18n.language,
                                                                },
                                                                t('general.transport_contract').toLowerCase() +
                                                                    '_' +
                                                                    form.data.car_id
                                                            ).then(() => {
                                                                setLoadingMessage(null);
                                                            });
                                                        }}
                                                    >
                                                        <i aria-hidden="true" className="ms-3 fa fa-download"></i>
                                                    </a>
                                                </div>
                                            )}
                                    </span>
                                </li>
                            )}
                        {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && user.permissions.CAN_VERIFY_OUTGOING_COSTS ? (
                            <>
                                {form.fields.purchase_pricing.costs.commission && (
                                    <li className="height-44">
                                        <strong>{t('car.purchase_pricing.commission')}</strong>
                                        <VerifiableMoney
                                            checkboxProps={
                                                form.fields.purchase_pricing.commission_verified
                                                    ? {
                                                          ...form.fields.purchase_pricing.commission_verified({
                                                              onChange(name, value, draft) {
                                                                  draft.purchase_pricing.commission_verified_on =
                                                                      moment().format(DATE_FORMAT);
                                                              },
                                                          }),
                                                          disabled: isCommissionVerified,
                                                          value: isCommissionVerified,
                                                      }
                                                    : undefined
                                            }
                                            moneyProps={{
                                                ...form.fields.purchase_pricing.costs.commission(),
                                                disabled: disabled,
                                            }}
                                            verifiedBy={form.data.purchase_pricing.commission_verified_by}
                                            verifiedOn={form.data.purchase_pricing.commission_verified_on}
                                        />
                                        <span>
                                            <Autocomplete
                                                {...form.fields.purchase_pricing.commission_to()}
                                                allowClear
                                                autocompleteURI="/suppliers/autocomplete?"
                                                disabled={disabled}
                                                placeholder={t('car.purchase_pricing.commission_to')}
                                                value={commissionTo ? {value: commissionTo.id, label: commissionTo.label} : ''}
                                            />
                                        </span>
                                    </li>
                                )}
                                {form.fields.purchase_pricing.costs.import_duties && (
                                    <li className="height-44">
                                        <strong>{t('car.purchase_pricing.import_duties')}</strong>
                                        <VerifiableMoney
                                            checkboxProps={
                                                form.fields.purchase_pricing.import_duties_verified
                                                    ? {
                                                          ...form.fields.purchase_pricing.import_duties_verified({
                                                              onChange(name, value, draft) {
                                                                  draft.purchase_pricing.import_duties_verified_on =
                                                                      moment().format(DATE_FORMAT);
                                                              },
                                                          }),
                                                          disabled: isImportDutiesVerified,
                                                          value: isImportDutiesVerified,
                                                      }
                                                    : undefined
                                            }
                                            moneyProps={{
                                                ...form.fields.purchase_pricing.costs.import_duties(),
                                                disabled: disabled,
                                            }}
                                            verifiedBy={form.data.purchase_pricing.import_duties_verified_by}
                                            verifiedOn={form.data.purchase_pricing.import_duties_verified_on}
                                        />
                                        <span />
                                    </li>
                                )}
                                <li className="breakdown-foot">
                                    <div className={'d-flex d-flex-row bg-white justify-content-between'}>
                                        <strong>{t('car.purchase_pricing.total_purchase_price')}</strong>
                                    </div>
                                    <div className={'d-flex d-flex-row'}>
                                        {hasContractNumber && (
                                            <div className={'ms-2'} id="tooltip">
                                                <i className="fas fa-exclamation-circle pt-3 pl-2" style={{color: 'red'}} />
                                                <p>{t('car.indicative_price')}</p>
                                            </div>
                                        )}
                                        <span className="text-end w-100">{moneyFormat(pricingTotals.costs)}</span>
                                    </div>
                                </li>
                            </>
                        ) : null}
                    </ul>
                </div>
                {user.permissions.CAN_VIEW_SUPPLIER_DATA && (
                    <div style={{width: '40%'}}>
                        <SupplierDetail car={car} form={form} refresh={refresh} />
                    </div>
                )}
            </div>

            {user.permissions.CAN_VIEW_SUPPLIER_DATA && form.fields.purchase_pricing.accessories && (
                <ul className="breakdown-grid columns-5 form-group">
                    <li className="breakdown-head">
                        <h3>{t('car.purchase_pricing.post_placement_accessories')}</h3>
                        <h3 className="text-end">{t('car.purchase_pricing.difference')}</h3>
                        <h3 className="text-end">{t('car.purchase_pricing.purchased')}</h3>
                        <h3 className="text-end">{t('car.purchase_pricing.sold')}</h3>
                    </li>

                    <li>
                        <strong>
                            <Input
                                className="accessory"
                                disabled={disabled}
                                placeholder={t('car.purchase_pricing.accessories.accessory') + ' 1'}
                                {...form.fields.purchase_pricing.accessories.accessory_1.name()}
                            />
                        </strong>
                        <span className="text-end">
                            {moneyFormat(
                                form.data.purchase_pricing.accessories.accessory_1.cost -
                                    form.data.purchase_pricing.accessories.accessory_1.invoiced
                            )}
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_1.cost()} disabled={disabled} />
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_1.invoiced()} disabled={disabled} />
                        </span>
                    </li>

                    <li>
                        <strong>
                            <Input
                                className="accessory"
                                disabled={disabled}
                                placeholder={t('car.purchase_pricing.accessories.accessory') + ' 2'}
                                {...form.fields.purchase_pricing.accessories.accessory_2.name()}
                            />
                        </strong>
                        <span className="text-end">
                            {moneyFormat(
                                form.data.purchase_pricing.accessories.accessory_2.cost -
                                    form.data.purchase_pricing.accessories.accessory_2.invoiced
                            )}
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_2.cost()} disabled={disabled} />
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_2.invoiced()} disabled={disabled} />
                        </span>
                    </li>

                    <li>
                        <strong>
                            <Input
                                className="accessory"
                                disabled={disabled}
                                placeholder={t('car.purchase_pricing.accessories.accessory') + ' 3'}
                                {...form.fields.purchase_pricing.accessories.accessory_3.name()}
                            />
                        </strong>
                        <span className="text-end">
                            {moneyFormat(
                                form.data.purchase_pricing.accessories.accessory_3.cost -
                                    form.data.purchase_pricing.accessories.accessory_3.invoiced
                            )}
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_3.cost()} disabled={disabled} />
                        </span>
                        <span>
                            <Money {...form.fields.purchase_pricing.accessories.accessory_3.invoiced()} disabled={disabled} />
                        </span>
                    </li>

                    <li className="breakdown-foot" style={{gridTemplateColumns: 'repeat(4, 1fr)', width: 'calc(80% - 1px)'}}>
                        <strong>{t('car.purchase_pricing.accessories.total')}</strong>
                        <span className="text-end">
                            {moneyFormat(pricingTotals.accessories - pricingTotals.accessories_invoiced)}
                        </span>
                        <span className="text-end">{moneyFormat(pricingTotals.accessories)}</span>
                        <span className="text-end">{moneyFormat(pricingTotals.accessories_invoiced)}</span>
                    </li>
                </ul>
            )}

            {user.permissions.CAN_VIEW_SUPPLIER_DATA && form.fields.purchase_pricing.transport.from_ecr && (
                <ul className="breakdown-grid columns-5 form-group">
                    <li className="breakdown-head">
                        <h3>
                            {t('car.purchase_pricing.transport_from_ecr')}
                            <span
                                className={classnames({
                                    badge: true,
                                    'bg-secondary': !form.data.purchase_pricing.transport.from_ecr.required,
                                    'bg-primary': form.data.purchase_pricing.transport.from_ecr.required,
                                })}
                            >
                                {form.data.purchase_pricing.transport.from_ecr.required
                                    ? t('general.required')
                                    : t('general.not_required')}
                            </span>
                        </h3>
                        <h3 className="text-end">{t('car.purchase_pricing.difference')}</h3>
                        <h3 className="text-end">{t('car.purchase_pricing.purchased')}</h3>
                        <h3 className="text-end">{t('car.purchase_pricing.sold')}</h3>
                    </li>
                    {form.data.sales_pricing.direct_delivery ? (
                        <li>
                            <strong />
                            <span className="text-end">{t('car.sales_pricing.direct_delivery')}</span>
                        </li>
                    ) : (
                        <Fragment>
                            <li className="height-44" style={{gridTemplateColumns: 'repeat(5, 1fr)'}}>
                                <strong />
                                <span className="text-end">
                                    {moneyFormat(
                                        form.data.purchase_pricing.transport.from_ecr.cost -
                                            form.data.purchase_pricing.transport.from_ecr.invoiced
                                    )}
                                </span>
                                <span>
                                    <VerifiableMoney
                                        checkboxProps={
                                            form.fields.purchase_pricing.transport.from_ecr.verified
                                                ? {
                                                      ...form.fields.purchase_pricing.transport.from_ecr.verified({
                                                          onChange(name, value, draft) {
                                                              draft.purchase_pricing.transport.from_ecr.verified_on =
                                                                  moment().format(DATE_FORMAT);
                                                          },
                                                      }),
                                                      disabled: isTransportFromEcrVerified,
                                                      value: isTransportFromEcrVerified,
                                                  }
                                                : undefined
                                        }
                                        moneyProps={{
                                            ...form.fields.purchase_pricing.transport.from_ecr.cost(),
                                            disabled: disabled,
                                        }}
                                        verifiedBy={form.data.purchase_pricing.transport.from_ecr.verified_by}
                                        verifiedOn={form.data.purchase_pricing.transport.from_ecr.verified_on}
                                    />
                                </span>
                                <span>
                                    <Money {...form.fields.purchase_pricing.transport.from_ecr.invoiced()} disabled={disabled} />
                                </span>
                            </li>
                            <li>
                                <strong />
                                <span>
                                    <DatePicker {...form.fields.purchase_pricing.transport.from_ecr.on()} disabled={disabled} />
                                </span>
                                <span>
                                    {transporterFromEcr && (
                                        <Fragment>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (!confirm(t('general.cancel_transport_order'))) {
                                                        return;
                                                    }

                                                    authenticatedHttp()
                                                        .delete('/transports/' + form.data.purchase_pricing.transport.from_ecr.id)
                                                        .then(() => {
                                                            refresh();
                                                        });
                                                }}
                                                style={{marginRight: '.5em'}}
                                            >
                                                <i className="fas fa-times-circle" />
                                            </button>
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setLoadingMessage(t('general.generating_document'));

                                                    downloadFile(
                                                        '/download/transport-request',
                                                        {
                                                            transport_id: form.data.purchase_pricing.transport.from_ecr.id,
                                                            language: i18n.language,
                                                        },
                                                        t('general.transport_contract').toLowerCase() + '_' + form.data.car_id
                                                    ).then(() => {
                                                        setLoadingMessage(null);
                                                    });
                                                }}
                                            >
                                                {transporterFromEcr.label}
                                            </a>
                                        </Fragment>
                                    )}
                                </span>
                                <span />
                            </li>
                        </Fragment>
                    )}
                </ul>
            )}

            {hasMargins && user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                <ul className="breakdown-grid columns-5">
                    <li className="breakdown-head">
                        <h3 className="colspan-3">{t('general.margins')}</h3>
                    </li>
                    {car.margins.parking_cost && (
                        <li>
                            <strong>{t('general.parking_cost')}</strong>
                            <span className="text-end">{moneyFormat(car.margins.parking_cost)}</span>
                        </li>
                    )}
                    {car.margins.financing_cost && (
                        <li>
                            <strong>{t('general.financing_cost')}</strong>
                            <span className="text-end">{moneyFormat(car.margins.financing_cost)}</span>
                        </li>
                    )}
                    {car.margins.parking_cost && car.margins.financing_cost && (
                        <li className="breakdown-foot">
                            <strong>{t('car.purchase_pricing.margins_total')}</strong>
                            <span className="text-end">{moneyFormat(car.margins.parking_cost + car.margins.financing_cost)}</span>
                        </li>
                    )}
                </ul>
            )}

            {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                <ul className="breakdown-grid breakdown-big columns-5">
                    <li className="breakdown-foot">
                        <strong>{t('car.purchase_pricing.grand_total')}</strong>
                        <span className="text-end">{moneyFormat(pricingTotals.grand_total)}</span>
                    </li>
                </ul>
            )}
        </Section>
    );
};

export default PurchasePricing;
