import React, {useEffect} from 'react';
import Layout from '../../components/Layout/Layout';
import Spinner from '../../components/Spinner/Spinner';
import {setPageTitle} from '../../lib/helpers';
import useFetchData from '../../lib/UseFetchData';

import './Lists.css';
import ListSelection from './ListSelection';
import {useAuth} from '../../lib/UseAuth';

const SelectList = () => {
    const {user} = useAuth();

    useEffect(() => {
        setPageTitle('Lijsten');
    }, []);
    const [lists, isLoadingLists] = useFetchData('/lists');

    if (isLoadingLists || !user.permissions.CAN_ACCESS_LISTS) {
        return (
            <Layout header={() => <h1>Lijsten</h1>}>
                <Spinner />
            </Layout>
        );
    }

    return (
        <Layout header={() => <h1>Lijsten</h1>}>
            <ListSelection lists={lists} />
        </Layout>
    );
};

export default SelectList;
