// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 40px;
    -moz-column-gap: 40px;
         column-gap: 40px;
    grid-row-gap: 40px;
    row-gap: 40px;
}

    .grid .edit-image {
        cursor: move;
        background: white;
        width: 100%;
        height: 250px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    .grid .edit-image img {
            -o-object-fit: contain;
               object-fit: contain;
            width: 100%;
            height: 200px;
            pointer-events: none;
        }

    .grid .edit-image:hover,
        .grid .edit-image:focus { cursor: move; }

.edit_images .checkbox {
  margin-top: 20px !important;
}

`, "",{"version":3,"sources":["webpack://./src/js/views/Cars/Management/EditImagesModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,qBAAgB;IAAhB,qBAAgB;SAAhB,gBAAgB;IAChB,kBAAa;IAAb,aAAa;AAwBjB;;IAtBI;QACI,YAAY;QACZ,iBAAiB;QACjB,WAAW;QACX,aAAa;QACb,kBAAkB;QAClB,aAAa;QACb,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;QACnB,kBAAkB;IAWtB;;IATI;YACI,sBAAmB;eAAnB,mBAAmB;YACnB,WAAW;YACX,aAAa;YACb,oBAAoB;QACxB;;IAEA;kCACU,YAAY,EAAE;;AAIhC;EACE,2BAA2B;AAC7B","sourcesContent":[".grid {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    column-gap: 40px;\n    row-gap: 40px;\n\n    .edit-image {\n        cursor: move;\n        background: white;\n        width: 100%;\n        height: 250px;\n        border-radius: 5px;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        align-items: center;\n        position: relative;\n\n        img {\n            object-fit: contain;\n            width: 100%;\n            height: 200px;\n            pointer-events: none;\n        }\n\n        &:hover,\n        &:focus { cursor: move; }\n    }\n}\n\n.edit_images .checkbox {\n  margin-top: 20px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
