import classnames from 'classnames';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

function CarInfoCell({car, isQuote = false, showIsReadyForInvoiceStatus = false, showLink, showSubText}) {
    const {t} = useTranslation();

    return (
        <Fragment>
            <div className={'d-flex align-items-center'}>
                {showIsReadyForInvoiceStatus && (
                    <i
                        className={classnames({
                            dot: true,
                            'small-dot': true,
                            'dot-green': car.sale.status === 'INVOICED',
                            'dot-yellow': car.sale.status !== 'INVOICED' && car.invoice.is_ready_for_invoice,
                            'dot-red': car.sale.status !== 'INVOICED' && !car.invoice.is_ready_for_invoice,
                        })}
                        data-tip={t(
                            `${
                                car.sale.status === 'INVOICED'
                                    ? 'enum.sale_status.INVOICED'
                                    : car.invoice.is_ready_for_invoice
                                    ? 'general.ready_for_invoice'
                                    : 'general.not_ready_for_invoice'
                            }`
                        )}
                    />
                )}
                <p>
                    <strong>
                        {car.type.model.make} {car.type.model.name}
                    </strong>
                </p>
            </div>
            <p>
                {car.type.name} {!isQuote ? ` - ${car.body_color}` : ''}
            </p>
            {showLink && (
                <a className="link--blue small" href={'/autos/' + car.car_id}>
                    {car.car_id} &ndash; {car.vin}
                </a>
            )}

            {showSubText && <p className="small">{t('enum.subsidiary.' + car.subsidiary)}</p>}
        </Fragment>
    );
}

export default CarInfoCell;
