import React from 'react';
import {useTranslation} from 'react-i18next';

import Modal from '../../../../components/Modal/NewModal';
import Spinner from '../../../../components/Spinner/Spinner';
import useFetchData from '../../../../lib/UseFetchData';
import BookTransportForm from './BookTransportForm';

function getSupplierId(car) {
    if (typeof car.supplied_by === 'string') {
        return car.supplied_by;
    }

    return car.supplied_by.data.id;
}

const BookTransportFromSupplier = ({cars, refresh = false, showOverview = true}) => {
    const {t} = useTranslation();

    const sameSubsidiary = cars.every((car) => car.subsidiary === cars[0].subsidiary);
    const sameDelivery = cars.every((car) => car.delivery.direct === cars[0].delivery.direct);
    const sameSupplier = cars.every((car) => getSupplierId(car) === getSupplierId(cars[0]));
    const sameCustomer = cars.every((car) => car.sale.sold_to === cars[0].sale.sold_to);
    const isDirectDelivery = cars[0].delivery.direct;

    const supplierId = getSupplierId(cars[0]);
    const hasSupplier = supplierId !== undefined;
    const [supplier, isLoadingSupplier] = useFetchData('/companies/' + supplierId, hasSupplier);
    const [customer, isLoadingCustomer] = useFetchData('/customers/' + cars[0].sale.sold_to, isDirectDelivery);

    if (!hasSupplier) {
        return (
            <Modal title={t('general.book_transport.from_supplier')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <ul>
                            <li>{t('search.supplier_required')}</li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    if (isLoadingCustomer || isLoadingSupplier) {
        return <Spinner message={t('general.loading')} />;
    }

    if (!sameSubsidiary || !sameDelivery || !sameSupplier || (isDirectDelivery && !sameCustomer)) {
        return (
            <Modal title={t('general.book_transport.from_supplier')}>
                <Modal.Body>
                    <div className="alert alert-danger">
                        <ul>
                            {!sameSubsidiary && <li>{t('search.same_subsidiary_required')}</li>}
                            {!sameSupplier && <li>{t('search.same_supplier_required')}</li>}
                            {!sameDelivery && <li>{t('search.same_delivery_required')}</li>}
                            {isDirectDelivery && !sameCustomer && <li>{t('search.same_customer_required')}</li>}
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    const loadingAddress = {name: supplier.name, phone: supplier.phone, fax: supplier.fax, ...supplier.address};
    const deliveryAddress = !isDirectDelivery
        ? {
              name: 'EC Rent NV',
              address_line: 'Boomsesteenweg 934',
              postal_code: '2610',
              locality: 'Wilrijk',
              country_code: 'BE',
              phone: '03/870.76.75',
              fax: '03/870.76.72',
          }
        : {name: customer.name, phone: customer.phone, fax: customer.fax, ...customer.address};

    const defaultInstruction = isDirectDelivery ? t('general.attention_mandatory_transport') : '';

    return (
        <Modal title={t('general.book_transport.from_supplier')}>
            <BookTransportForm
                cars={cars}
                defaultInstruction={defaultInstruction}
                deliveryAddress={deliveryAddress}
                loadingAddress={loadingAddress}
                refresh={refresh}
                showOverview={showOverview}
                type="FROM_SUPPLIER"
            />
        </Modal>
    );
};

export default BookTransportFromSupplier;
