import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import lscache from 'lscache';

import {authenticatedHttp} from '../../../lib/zaza-client';
import {useAuth} from '../../../lib/UseAuth';
import NotFound from '../../NotFound/NotFound';
import Update from './Update';
import Spinner from '../../../components/Spinner/Spinner';

const LoadUpdate = () => {
    const {carId} = useParams();
    const location = useLocation();
    let navigate = useNavigate();
    const {user} = useAuth();
    const [{car, state}, setCarState] = useState({state: 'loading'});

    const refreshCar = () => {
        if (state !== 'loading') {
            setCarState({state: 'loading'});
        }
        authenticatedHttp()
            .get('/cars/' + carId)
            .then((response) => {
                setCarState({car: response.data.data, state: 'success'});
                if (location.state) {
                    const anchor = document.createElement('a');
                    anchor.href = `#${location.state[0]}`;
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                }
            })
            .catch(() => {
                setCarState({state: 'error'});
            });
    };

    useEffect(() => {
        if (/^\d+$/.test(carId)) {
            refreshCar();
        }
    }, [carId]);

    if (car && car.sale.status === 'NOT_FOR_SALE' && !user.permissions.CAN_VIEW_NOT_FOR_SALE_CARS) {
        navigate('/autos/lijst');
    }

    const searchResults = useMemo(() => lscache.get('search_results') || [], []);
    const previousCarId = useMemo(() => {
        const currentIndex = searchResults.findIndex((c) => c === parseInt(carId));

        return searchResults[currentIndex - 1] || null;
    }, [carId, searchResults]);
    const nextCarId = useMemo(() => {
        const currentIndex = searchResults.findIndex((c) => c === parseInt(carId));

        return searchResults[currentIndex + 1] || null;
    }, [carId, searchResults]);

    if (!/^\d+$/.test(carId)) {
        return <NotFound />;
    }

    if (state === 'loading') {
        return <Spinner />;
    }
    if (state === 'error') {
        return <p>Could not load</p>;
    }

    function handleKeyDown(e) {
        switch (e.keyCode) {
            case 27:
                navigate('/autos/lijst');
                break;
            case 37:
                previousCarId && navigate('/autos/' + previousCarId);
                break;
            case 39:
                nextCarId && navigate('/autos/' + nextCarId);
                break;
        }
    }

    return (
        <div onKeyDown={handleKeyDown} ref={(node) => node && node.focus()} tabIndex="0">
            <Update car={car} nextCarId={nextCarId} previousCarId={previousCarId} refreshCar={refreshCar} />
        </div>
    );
};

export default LoadUpdate;
