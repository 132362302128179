"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const zaza_client_1 = require("./zaza-client");
const useFetchData = (url, doFetch = true) => {
    const [state, setState] = (0, react_1.useState)([
        null,
        doFetch ? 'loading' : 'idle',
    ]);
    const loadData = () => {
        if (doFetch) {
            (0, zaza_client_1.authenticatedHttp)()
                .get(url)
                .then((response) => {
                setState([response.data.data, 'success']);
            })
                .catch(() => {
                setState([null, 'error']);
            });
        }
    };
    (0, react_1.useEffect)(loadData, [url]);
    return [...state, loadData];
};
exports.default = useFetchData;
