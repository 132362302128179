import React from 'react';
import {useTranslation} from 'react-i18next';
import Select from '../../components/Form/Select';
import PriceOptions from '../../enums/price_options.json';

const PricingSelect = ({customOption, value, ...props}) => {
    const {t} = useTranslation();

    const options = PriceOptions.map((key) => ({value: key, label: t(`enum.price_options.${key}`)}));

    if (customOption) {
        options.unshift({value: customOption, label: t(`enum.price_options.${customOption}`)});
    }

    return <Select options={options} value={customOption || value} {...props} />;
};

export default PricingSelect;
