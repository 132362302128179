import React, {useEffect, useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {authenticatedHttp} from '../../../../lib/zaza-client';

import Autocomplete from '../../../../components/Form/Autocomplete';
import Checkbox from '../../../../components/Form/Checkbox';

const CreditNoteCustomerSelect = ({
    control,
    customer: [customer, setCustomer],
    customerId: initialCustomerId,
    defaultFormValues,
    setValue,
}) => {
    const {t} = useTranslation();
    const [customerId, setCustomerId] = useState(initialCustomerId);
    const [loadingCustomer, toggleLoadingCustomer] = useState(false);

    const allowEdit = useRef(!initialCustomerId);

    useEffect(() => {
        if (!customerId) {
            return;
        }

        toggleLoadingCustomer(true);
        authenticatedHttp()
            .get(`/customers/${customerId}?include=contacts`)
            .then((response) => {
                let customerTL = response.data.data;
                customerTL.contacts = customerTL.contacts.data;

                setValue('language', customerTL.language);
                setCustomer(customerTL);
                toggleLoadingCustomer(false);
            });
    }, [customerId]);

    if (loadingCustomer) {
        return (
            <section>
                <strong>{t('general.send_invoice_to')}</strong>
                <p>{t('general.loading')}</p>
            </section>
        );
    }

    if (!customer) {
        return (
            <section>
                <div className="mb-4 search-customers">
                    <div className="form-group form-group-vertical">
                        <label>{t('general.search_customer')}</label>
                        <Autocomplete
                            allowClear
                            autocompleteURI="/customers/autocomplete?"
                            onChange={setCustomerId}
                            placeholder={t('general.search_customer')}
                            value={customer ? {value: customer.id, label: customer.label} : ''}
                        />
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section>
            <div className="recipients">
                <strong>{t('general.send_invoice_to')}</strong>
                <div className="customer" key={customer.id}>
                    {allowEdit.current ? (
                        <a
                            onClick={() => {
                                setCustomer(null);
                                setCustomerId(null);
                            }}
                        >
                            {customer.label}
                            <i className="far fa-times-circle" />
                        </a>
                    ) : (
                        <p>{customer.label}</p>
                    )}
                    {customer.contacts.length > 0 && (
                        <div className="contacts">
                            {customer.contacts.map((contact) => (
                                <Controller
                                    control={control}
                                    defaultValue={
                                        (contact.email && contact.type === 'PURCHASING') ||
                                        defaultFormValues.selectedContacts.includes(contact.id)
                                    }
                                    disabled={!contact.email}
                                    key={contact.id}
                                    name={`contacts[${customer.id}][${contact.id}]`}
                                    render={({field}) => (
                                        <Checkbox
                                            {...field}
                                            label={`
                                                ${contact.full_name}
                                                ${contact.email ? `(${contact.email})` : ''}
                                            `}
                                        />
                                    )}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default CreditNoteCustomerSelect;
