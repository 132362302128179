import React, {Fragment, useEffect, useMemo, useState} from 'react';
import lscache from 'lscache';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Form/Checkbox';
import DocumentDeliveryEnum from '../../../components/Form/DocumentDeliveryEnum';

import Layout from '../../../components/Layout/Layout';
import Input from '../../../components/Form/Input';
import DatePicker from '../../../components/Form/DatePicker';
import Enum from '../../../components/Form/Enum';
import Autocomplete from '../../../components/Form/Autocomplete';
import Select from '../../../components/Form/Select';

import defaultSearchParameters from '../../../models/search_form.json';

import './Search.scss';
import useForm from '../../../lib/UseForm';
import useFetchData from '../../../lib/UseFetchData';
import {authenticatedHttp} from '../../../lib/zaza-client';
import {capitalize, setPageTitle} from '../../../lib/helpers';
import {useAuth} from '../../../lib/UseAuth';

import baseColors from '../../../enums/base_color.json';

const salesStatusesWithDateField = {
    SOLD: 'sold_on',
    INVOICED: 'invoiced_on',
    FOR_SALE: 'listed_for_sale_on',
    NOT_FOR_SALE: 'unlisted_for_sale_on',
};
const arrivalStatusesWithDateField = {ARRIVED: 'arrived_on', EXPECTED: 'expected_on', ON_TRANSPORT: 'transport_on'};
const registrationsWithDateField = ['NEW_WITH_REGISTRATION', 'SECOND_HAND'];
const inspectionStatusesWithDateField = ['PLANNED', 'INSPECTED'];
const fieldsWithDateMap = {
    proof_of_registration_part_1_status: 'proof_of_registration_part_1_status_changed_on',
    proof_of_registration_part_1_delivery: 'proof_of_registration_part_1_delivery_changed_on',
    proof_of_registration_part_2_status: 'proof_of_registration_part_2_status_changed_on',
    proof_of_registration_part_2_delivery: 'proof_of_registration_part_2_delivery_changed_on',
    e705_status: 'e705_status_changed_on',
    e705_delivery: 'e705_delivery_changed_on',
    certificate_of_conformity_status: 'certificate_of_conformity_status_changed_on',
    certificate_of_conformity_delivery: 'certificate_of_conformity_delivery_changed_on',
    warranty_booklet_status: 'warranty_booklet_status_changed_on',
    warranty_booklet_delivery: 'warranty_booklet_delivery_changed_on',
    is_paid_to_supplier: 'supplier_paid_on',
    is_paid_by_customer: 'customer_paid_on',
    is_delivered_to_customer: 'delivered_on',
    location: 'location_changed_on',
    notice_of_objection_status: 'notice_of_objection_changed_on',
    has_purchase_invoice: 'purchase_invoice_on',
};

const baseColorOptions = baseColors.map((baseColor) => ({value: baseColor, label: capitalize(baseColor)}));

const Search = () => {
    useEffect(() => {
        setPageTitle(t('nav.search'));
    }, []);

    let navigate = useNavigate();
    const {user} = useAuth();
    const {t} = useTranslation();

    // eslint-disable-next-line
    const {similar_to, ...storedSearchParameters} = lscache.get('search_parameters') || {};
    const searchParameters = storedSearchParameters
        ? {...defaultSearchParameters, ...storedSearchParameters}
        : defaultSearchParameters;

    const form = useForm(searchParameters, {
        onSave: ({data}) => {
            let searchParams = {...data};
            Object.entries(searchParams).map(([key, value]) => {
                if (typeof value === 'string') {
                    searchParams[key] = value.trim();
                }
                if (value === '' || value.length === 0) {
                    delete searchParams[key];
                }
            });

            [
                'car_id',
                'vin',
                'model',
                'internal_order_code',
                'supplier_order_number',
                'supplier_transport_number',
            ].forEach((key) => {
                if (searchParams[key] && searchParams[key].includes(',')) {
                    searchParams[key] = searchParams[key]
                        .split(',')
                        .map((value) => value.trim())
                        .filter(Boolean); // will remove '' from array
                }
            });

            searchParams.date_fields = dateFields.filter((field) => !disabledDateFields.includes(field));

            const queryParams = JSON.stringify(searchParams);
            navigate({pathname: `/autos/lijst`, search: '?query=' + queryParams});
        },
    });

    const dateFields = useMemo(() => {
        const dateFields = [];
        for (const [saleStatus, date] of Object.entries(salesStatusesWithDateField)) {
            if (form.data.sale_status.includes(saleStatus)) {
                dateFields.push(date);
            }
        }
        for (const [arrivalStatus, date] of Object.entries(arrivalStatusesWithDateField)) {
            if (form.data.arrival_status.includes(arrivalStatus)) {
                dateFields.push(date);
            }
        }
        if (form.data.registration_status.some((status) => registrationsWithDateField.includes(status))) {
            dateFields.push('first_used_on');
        }
        if (form.data.inspection_status.some((status) => inspectionStatusesWithDateField.includes(status))) {
            dateFields.push('inspection_date');
        }
        for (const [field, date] of Object.entries(fieldsWithDateMap)) {
            if (
                (Array.isArray(form.data[field]) && form.data[field].length > 0) ||
                (!Array.isArray(form.data[field]) && form.data[field])
            ) {
                if (form.data[field] !== 'NOT_PAID') {
                    dateFields.push(date);
                }
            }
        }

        return dateFields;
    }, [form.data]);

    const [disabledDateFields, setDisabledDateFields] = useState(() => lscache.get('disabled_date_fields') || []);
    useEffect(() => {
        lscache.set('disabled_date_fields', disabledDateFields);
    }, [disabledDateFields]);

    const filledOutParameters =
        Object.entries(form.data).filter((entry) => entry[1] && entry[1] !== '' && entry[1].length !== 0) || [];
    const filledOutParametersCount = filledOutParameters.length;

    const [users] = useFetchData('/users');
    const [locations] = useFetchData('/locations');
    const locationOptions = useMemo(() => {
        return locations ? locations.map((location) => ({value: location.id, label: t('enum.location.' + location.id)})) : [];
    }, [locations]);

    const basicSaleStatusOptions = [
        {value: 'ALL_SALES_STATUSES', label: t('enum.sale_status.ALL_SALES_STATUSES')},
        {value: 'FOR_SALE', label: t('enum.sale_status.FOR_SALE')},
        {value: 'SOLD', label: t('enum.sale_status.SOLD')},
        {value: 'INVOICED', label: t('enum.sale_status.INVOICED')},
    ];
    const extraSaleStatusOptions = [{value: 'NOT_FOR_SALE', label: t('enum.sale_status.NOT_FOR_SALE')}];
    const saleStatusOptions = user.permissions.CAN_VIEW_NOT_FOR_SALE_CARS
        ? [...basicSaleStatusOptions, ...extraSaleStatusOptions]
        : [...basicSaleStatusOptions];

    const [makes] = useFetchData('/makes');
    const makeOptions = useMemo(() => {
        if (makes) {
            return makes.map((make) => ({value: make.name, label: make.name}));
        }

        return [];
    }, [makes]);
    const [colors] = useFetchData('/colors');
    const colorOptions = useMemo(() => {
        if (colors) {
            return colors.map((color) => ({value: color.id, label: color.description}));
        }

        return [];
    }, [colors]);
    const websiteOptions = [
        {value: 'PRIV', label: t('enum.price_options.WEBSITE_PRICING_PRIV')},
        {value: 'BE', label: t('enum.price_options.WEBSITE_PRICING_BE')},
        {value: 'CH', label: t('enum.price_options.WEBSITE_PRICING_CH')},
        {value: 'DE', label: t('enum.price_options.WEBSITE_PRICING_DE')},
        {value: 'FR', label: t('enum.price_options.WEBSITE_PRICING_FR')},
        {value: 'IT', label: t('enum.price_options.WEBSITE_PRICING_IT')},
        {value: 'NL', label: t('enum.price_options.WEBSITE_PRICING_NL')},
        {value: 'ROW', label: t('enum.price_options.WEBSITE_PRICING_ROW')},
    ];

    const [customerSoldTo, setCustomerSoldTo] = useState(false);

    useEffect(() => {
        setCustomerSoldTo(false);
        if (form.data.sold_to) {
            authenticatedHttp()
                .get(`/customers/${form.data.sold_to}`)
                .then((response) => {
                    setCustomerSoldTo(response.data.data);
                });
        }
    }, [form.data.sold_to]);

    const [transportFromSupplierBy, setTransportFromSupplierBy] = useState(false);

    useEffect(() => {
        setTransportFromSupplierBy(false);
        if (form.data.transport_from_supplier_by) {
            authenticatedHttp()
                .get('/companies/' + form.data.transport_from_supplier_by)
                .then((response) => {
                    setTransportFromSupplierBy(response.data.data);
                });
        }
    }, [form.data.transport_from_supplier_by]);

    const [suppliedBy, setSuppliedBy] = useState(false);

    useEffect(() => {
        setSuppliedBy(false);
        if (form.data.supplied_by) {
            authenticatedHttp()
                .get('/companies/' + form.data.supplied_by)
                .then((response) => {
                    setSuppliedBy(response.data.data);
                });
        }
    }, [form.data.supplied_by]);

    const handleClear = (e) => {
        e.preventDefault();
        form.setData(defaultSearchParameters);
        lscache.remove('search_parameters');
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                form.handleSubmit();
            }}
        >
            <Layout
                className="search"
                header={() => (
                    <Fragment>
                        <h1>{t('nav.search')}</h1>

                        <span className="search-summary">{t('search.fields_filled_out', {count: filledOutParametersCount})}</span>
                        {filledOutParametersCount > 0 && (
                            <a className="search-clear" onClick={handleClear}>
                                {t('search.clear')}
                                <i className="fas fa-times-circle" />
                            </a>
                        )}
                        <Button equalHeight icon={<i className="fas fa-search" />} submit>
                            {t('nav.search')}
                        </Button>
                    </Fragment>
                )}
            >
                <div className="row">
                    <div className="col-4">
                        <section>
                            <h3>{t('search.general')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.sale_status()}
                                        allowMultiple
                                        options={saleStatusOptions}
                                        placeholder={t('search.sale_status')}
                                    />
                                    <label>{t('search.sale_status')}</label>
                                </li>
                                <li className="form-group">
                                    <Input {...form.fields.vin()} allowZeroWhileEditting placeholder={t('search.vin')} />
                                    <label>{t('search.vin')}</label>
                                </li>
                                <li className="form-group">
                                    <Input {...form.fields.car_id()} placeholder={t('search.car_id')} />
                                    <label>{t('search.car_id')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        allowMultiple
                                        {...form.fields.make()}
                                        options={makeOptions}
                                        placeholder={t('search.make') + ' ' + t('search.make_example')}
                                    />
                                    <label>
                                        {t('search.make')} {t('search.make_example')}
                                    </label>
                                </li>
                                <li className="form-group">
                                    <Input
                                        {...form.fields.model()}
                                        placeholder={t('search.model') + ' ' + t('search.model_example')}
                                    />
                                    <label>
                                        {t('search.model')} {t('search.model_example')}
                                    </label>
                                </li>
                                <li className="form-group">
                                    <Input
                                        {...form.fields.type()}
                                        placeholder={t('search.type') + ' ' + t('search.type_example')}
                                    />
                                    <label>
                                        {t('search.type')} {t('search.type_example')}
                                    </label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.body_type()}
                                        allowClear
                                        allowMultiple
                                        enumName="body_type"
                                        placeholder={t('search.body_type')}
                                    />
                                    <label>{t('search.body_type')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.registration_status()}
                                        allowMultiple
                                        enumName="registration_status"
                                        placeholder={t('search.registration_status')}
                                    />
                                    <label>{t('search.registration_status')}</label>
                                </li>
                                {user.permissions.CAN_VIEW_DELETED_CARS && (
                                    <li className="form-group">
                                        <Select
                                            {...form.fields.deleted_cars()}
                                            allowClear
                                            options={[
                                                {label: t('search.only_deleted_cars'), value: true},
                                                {label: t('search.only_not_deleted_cars'), value: false},
                                            ]}
                                            placeholder={t('search.deleted_cars')}
                                        />
                                        <label>{t('search.deleted_cars')}</label>
                                    </li>
                                )}
                                <li className="form-group">
                                    <Select
                                        {...form.fields.subsidiary()}
                                        allowMultiple
                                        options={user.subsidiaries.map((subsidiary) => ({
                                            value: subsidiary,
                                            label: t('enum.subsidiary.' + subsidiary),
                                        }))}
                                        placeholder={t('search.subsidiary')}
                                    />
                                    <label>{t('search.subsidiary')}</label>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>{t('search.sale')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.sold_by()}
                                        allowClear
                                        options={(users || []).map((user) => ({
                                            value: user.id,
                                            label: user.full_name,
                                        }))}
                                        placeholder={t('search.sold_by')}
                                    />
                                    <label>{t('search.sold_by')}</label>
                                </li>
                                <li className="form-group">
                                    <Autocomplete
                                        {...form.fields.sold_to()}
                                        allowClear
                                        autocompleteURI="/customers/autocomplete?"
                                        placeholder={t('search.sold_to')}
                                        value={customerSoldTo ? {value: customerSoldTo.id, label: customerSoldTo.label} : ''}
                                    />
                                    <label>{t('search.sold_to')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        allowClear
                                        allowMultiple
                                        options={[
                                            {label: t('search.payment_completed'), value: 'PAYMENT_COMPLETED'},
                                            {label: t('search.payment_registered'), value: 'PAYMENT_REGISTERED'},
                                            {label: t('search.partially_paid'), value: 'PAYMENT_NOT_FULLY_PAID'},
                                            {label: t('general.payment_not_paid'), value: 'PAYMENT_NOT_PAID'},
                                        ]}
                                        placeholder={t('search.is_paid_by_customer')}
                                        {...form.fields.is_paid_by_customer()}
                                    />
                                    <label>{t('search.is_paid_by_customer')}</label>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Select
                                                allowClear
                                                allowMultiple
                                                options={websiteOptions}
                                                placeholder={t('search.published_websites')}
                                                {...form.fields.published_websites()}
                                            />
                                            <label>{t('search.published_websites')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Select
                                                allowClear
                                                allowMultiple
                                                options={websiteOptions}
                                                placeholder={t('search.not_published_websites')}
                                                {...form.fields.not_published_websites()}
                                            />
                                            <label>{t('search.not_published_websites')}</label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>{t('search.period')}</h3>
                            <ul>
                                <li className="form-group">
                                    <DatePicker
                                        {...form.fields.period_from()}
                                        disabled={dateFields.length === 0}
                                        placeholder={t('search.period_from')}
                                    />
                                    <label>{t('search.period_from')}</label>
                                </li>
                                <li className="form-group">
                                    <DatePicker
                                        {...form.fields.period_to()}
                                        disabled={dateFields.length === 0}
                                        placeholder={t('search.period_to')}
                                    />
                                    <label>{t('search.period_to')}</label>
                                </li>
                            </ul>
                            {dateFields.length > 0 && (
                                <Fragment>
                                    <p>{t('search.period_explainer')}</p>
                                    <ul>
                                        {dateFields.map((field) => (
                                            <li key={field}>
                                                <Checkbox
                                                    label={t(`search.${field}`)}
                                                    onChange={(checked) => {
                                                        if (checked) {
                                                            setDisabledDateFields(
                                                                disabledDateFields.filter((otherField) => otherField !== field)
                                                            );
                                                        } else {
                                                            setDisabledDateFields([...disabledDateFields, field]);
                                                        }
                                                    }}
                                                    value={!disabledDateFields.includes(field)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Fragment>
                            )}
                        </section>
                    </div>
                    <div className="col-4">
                        <section>
                            <h3>{t('search.car_specifications')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.transmission()}
                                        allowClear
                                        enumName="transmission"
                                        placeholder={t('search.transmission')}
                                    />
                                    <label>{t('search.transmission')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.fuel()}
                                        allowClear
                                        allowMultiple
                                        enumName="fuel"
                                        placeholder={t('search.fuel')}
                                    />
                                    <label>{t('search.fuel')}</label>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.minimum_mileage()} placeholder={t('search.minimum_mileage')} />
                                            <label>{t('search.minimum_mileage')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.maximum_mileage()} placeholder={t('search.maximum_mileage')} />
                                            <label>{t('search.maximum_mileage')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.minimum_horse_power()} placeholder={t('search.minimum_horse_power')} />
                                            <label>{t('search.minimum_horse_power')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.maximum_horse_power()} placeholder={t('search.maximum_horse_power')} />
                                            <label>{t('search.maximum_horse_power')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.minimum_cc()} placeholder={t('search.minimum_cc')} />
                                            <label>{t('search.minimum_cc')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.maximum_cc()} placeholder={t('search.maximum_cc')} />
                                            <label>{t('search.maximum_cc')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.minimum_co2()} placeholder={t('search.minimum_co2')} />
                                            <label>{t('search.minimum_co2')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Input {...form.fields.maximum_co2()} placeholder={t('search.maximum_co2')} />
                                            <label>{t('search.maximum_co2')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.emissions_standard()}
                                        allowClear
                                        enumName="emissions_standard"
                                        placeholder={t('search.emissions_standard')}
                                    />
                                    <label>{t('search.emissions_standard')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum {...form.fields.doors()} allowClear enumName="doors" placeholder={t('search.doors')} />
                                    <label>{t('search.doors')}</label>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Select
                                                {...form.fields.body_base_color()}
                                                allowClear
                                                allowMultiple
                                                options={baseColorOptions}
                                                placeholder={t('search.body_base_color')}
                                            />
                                            <label>{t('search.body_base_color')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Select
                                                {...form.fields.body_color()}
                                                allowClear
                                                allowMultiple
                                                options={colorOptions}
                                                placeholder={t('search.body_color')}
                                            />
                                            <label>{t('search.body_color')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Select
                                                {...form.fields.interior_base_color()}
                                                allowClear
                                                allowMultiple
                                                options={baseColorOptions}
                                                placeholder={t('search.interior_base_color')}
                                            />
                                            <label>{t('search.interior_base_color')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <Select
                                                {...form.fields.roof_base_color()}
                                                allowClear
                                                allowMultiple
                                                options={baseColorOptions}
                                                placeholder={t('search.roof_body_color')}
                                            />
                                            <label>{t('search.roof_base_color')}</label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>{t('search.documents')}</h3>
                            <ul>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Enum
                                                {...form.fields.warranty_booklet_status()}
                                                allowClear
                                                allowMultiple
                                                enumName="warranty_booklet_status"
                                                placeholder={t('search.warranty_booklet_status')}
                                            />
                                            <label>{t('search.warranty_booklet_status')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DocumentDeliveryEnum
                                                {...form.fields.warranty_booklet_delivery()}
                                                allowClear
                                                allowMultiple
                                                documentType="warranty_booklet"
                                                placeholder={t('search.warranty_booklet_delivery')}
                                            />
                                            <label>{t('search.warranty_booklet_delivery')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Enum
                                                {...form.fields.proof_of_registration_part_1_status()}
                                                allowClear
                                                allowMultiple
                                                enumName="proof_of_registration_status"
                                                placeholder={t('search.proof_of_registration_part_1_status')}
                                            />
                                            <label>{t('search.proof_of_registration_part_1_status')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DocumentDeliveryEnum
                                                {...form.fields.proof_of_registration_part_1_delivery()}
                                                allowClear
                                                allowMultiple
                                                documentType="proof_of_registration_part_1"
                                                placeholder={t('search.proof_of_registration_part_1_delivery')}
                                            />
                                            <label>{t('search.proof_of_registration_part_1_delivery')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Enum
                                                {...form.fields.proof_of_registration_part_2_status()}
                                                allowClear
                                                allowMultiple
                                                enumName="proof_of_registration_status"
                                                placeholder={t('search.proof_of_registration_part_2_status')}
                                            />
                                            <label>{t('search.proof_of_registration_part_2_status')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DocumentDeliveryEnum
                                                {...form.fields.proof_of_registration_part_2_delivery()}
                                                allowClear
                                                allowMultiple
                                                documentType="proof_of_registration_part_2"
                                                placeholder={t('search.proof_of_registration_part_2_delivery')}
                                            />
                                            <label>{t('search.proof_of_registration_part_2_delivery')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Enum
                                                {...form.fields.certificate_of_conformity_status()}
                                                allowClear
                                                allowMultiple
                                                enumName="certificate_of_conformity_status"
                                                placeholder={t('search.certificate_of_conformity_status')}
                                            />
                                            <label>{t('search.certificate_of_conformity_status')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DocumentDeliveryEnum
                                                {...form.fields.certificate_of_conformity_delivery()}
                                                allowClear
                                                allowMultiple
                                                documentType="certificate_of_conformity"
                                                placeholder={t('search.certificate_of_conformity_delivery')}
                                            />
                                            <label>{t('search.certificate_of_conformity_delivery')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <Enum
                                                {...form.fields.e705_status()}
                                                allowClear
                                                allowMultiple
                                                enumName="e705_status"
                                                placeholder={t('search.e705_status')}
                                            />
                                            <label>{t('search.e705_status')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DocumentDeliveryEnum
                                                {...form.fields.e705_delivery()}
                                                allowClear
                                                allowMultiple
                                                documentType="e705"
                                                placeholder={t('search.e705_delivery')}
                                            />
                                            <label>{t('search.e705_delivery')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.preregistration()}
                                        allowClear
                                        allowMultiple
                                        enumName="preregistration_status"
                                        placeholder={t('search.preregistration')}
                                    />
                                    <label>{t('search.preregistration')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.inspection_status()}
                                        allowClear
                                        allowMultiple
                                        enumName="inspection_status"
                                        placeholder={t('search.inspection_status')}
                                    />
                                    <label>{t('search.inspection_status')}</label>
                                </li>
                                <li className="form-group">
                                    <Input placeholder={t('search.license_plate')} {...form.fields.license_plate()} />
                                    <label>{t('search.license_plate')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.import_documents_status()}
                                        allowClear
                                        enumName="import_documents_status"
                                        placeholder={t('search.import_documents_status')}
                                    />
                                    <label>{t('search.import_documents_status')}</label>
                                </li>
                                <li className="form-group">
                                    <Input placeholder={t('search.dkm_case_number')} {...form.fields.dkm_case_number()} />
                                    <label>{t('search.dkm_case_number')}</label>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>{t('search.finance')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Input {...form.fields.contract_number()} placeholder={t('search.contract_number')} />
                                    <label>{t('search.contract_number')}</label>
                                </li>
                                <li className="form-group">
                                    <Input
                                        {...form.fields.internal_contract_number()}
                                        placeholder={t('search.internal_contract_number')}
                                    />
                                    <label>{t('search.internal_contract_number')}</label>
                                </li>
                                <li className="form-group">
                                    <DatePicker
                                        {...form.fields.contract_expiry_from()}
                                        placeholder={t('search.contract_expiry_from')}
                                    />
                                    <label>{t('search.contract_expiry_from')}</label>
                                </li>
                                <li className="form-group">
                                    <DatePicker
                                        {...form.fields.contract_expiry_to()}
                                        placeholder={t('search.contract_expiry_to')}
                                    />
                                    <label>{t('search.contract_expiry_to')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.notice_of_objection_status()}
                                        allowClear
                                        enumName="notice_of_objection_status"
                                        placeholder={t('car.documents.notice_of_objection')}
                                    />
                                    <label>{t('car.documents.notice_of_objection')}</label>
                                </li>
                                <li className="form-group">
                                    <Input
                                        placeholder={t('search.bank_leasing_case_number')}
                                        {...form.fields.bank_leasing_case_number()}
                                    />
                                    <label>{t('search.bank_leasing_case_number')}</label>
                                </li>
                            </ul>
                        </section>
                    </div>
                    <div className="col-4">
                        <section>
                            <h3>{t('search.purchase')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.purchased_by()}
                                        allowClear
                                        options={(users || []).map((user) => ({
                                            value: user.id,
                                            label: user.full_name,
                                        }))}
                                        placeholder={t('search.purchased_by')}
                                    />
                                    <label>{t('search.purchased_by')}</label>
                                </li>
                                {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                                    <li className="form-group">
                                        <Autocomplete
                                            {...form.fields.supplied_by()}
                                            allowClear
                                            autocompleteURI="/suppliers/autocomplete?"
                                            placeholder={t('search.supplied_by')}
                                            value={suppliedBy ? {value: suppliedBy.id, label: suppliedBy.label} : ''}
                                        />
                                        <label>{t('search.supplied_by')}</label>
                                    </li>
                                )}
                                <li className="form-group">
                                    <Input
                                        placeholder={t('search.internal_order_code')}
                                        {...form.fields.internal_order_code()}
                                    />
                                    <label>{t('search.internal_order_code')}</label>
                                </li>
                                <li className="form-group">
                                    <Input
                                        placeholder={t('search.supplier_order_number')}
                                        {...form.fields.supplier_order_number()}
                                    />
                                    <label>{t('search.supplier_order_number')}</label>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <DatePicker {...form.fields.production_date_from()} placeholder={t('search.production_date_from')} />
                                            <label>{t('search.production_date_from')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DatePicker {...form.fields.production_date_to()} placeholder={t('search.production_date_to')} />
                                            <label>{t('search.production_date_to')}</label>
                                        </div>
                                    </div>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.is_paid_to_supplier()}
                                        allowClear
                                        allowMultiple
                                        options={[
                                            {label: t('search.payment_completed'), value: 'PAYMENT_COMPLETED'},
                                            {label: t('search.payment_registered'), value: 'PAYMENT_REGISTERED'},
                                            {label: t('search.partially_paid'), value: 'PAYMENT_NOT_FULLY_PAID'},
                                            {label: t('general.payment_not_paid'), value: 'PAYMENT_NOT_PAID'},
                                        ]}
                                        placeholder={t('search.is_paid_to_supplier')}
                                    />
                                    <label>{t('search.is_paid_to_supplier')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.has_purchase_invoice()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.registered_purchase_order_invoice')}
                                    />
                                    <label>{t('search.has_purchase_invoice')}</label>
                                </li>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.purchase_type()}
                                        allowClear
                                        enumName="purchase_type"
                                        placeholder={t('search.purchase_type')}
                                    />
                                    <label>{t('search.purchase_type')}</label>
                                </li>
                                <li className="form-group">
                                    <div className={'d-flex gap-3'}>
                                        <div className={'w-100'}>
                                            <DatePicker {...form.fields.created_on_from()} placeholder={t('search.created_on_from')} />
                                            <label>{t('search.created_on_from')}</label>
                                        </div>
                                        <div className={'w-100'}>
                                            <DatePicker {...form.fields.created_on_to()} placeholder={t('search.created_on_to')} />
                                            <label>{t('search.created_on_to')}</label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <section>
                            <h3>{t('general.logistics')}</h3>
                            <ul>
                                <li className="form-group">
                                    <Enum
                                        {...form.fields.arrival_status()}
                                        allowClear
                                        allowMultiple
                                        enumName="arrival_status"
                                        placeholder={t('search.arrival_status')}
                                    />
                                    <label>{t('search.arrival_status')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.location()}
                                        allowMultiple
                                        options={locationOptions}
                                        placeholder={t('search.location')}
                                    />
                                    <label>{t('search.location')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.is_delivered_to_customer()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.is_delivered_to_customer')}
                                    />
                                    <label>{t('search.is_delivered_to_customer')}</label>
                                </li>
                                {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                                    <li className="form-group">
                                        <Autocomplete
                                            {...form.fields.transport_from_supplier_by()}
                                            allowClear
                                            autocompleteURI="/transporters/autocomplete?"
                                            placeholder={t('search.transport_from_supplier_by')}
                                            value={
                                                transportFromSupplierBy
                                                    ? {
                                                        value: transportFromSupplierBy.id,
                                                        label: transportFromSupplierBy.label,
                                                    }
                                                    : ''
                                            }
                                        />
                                        <label>{t('search.transport_from_supplier_by')}</label>
                                    </li>
                                )}
                                <li className="form-group">
                                    <Input {...form.fields.supplier_transport_number()} placeholder={t('general.transport_number')} />
                                    <label>{t('general.transport_number')}</label>
                                </li>
                                {user.permissions.CAN_VIEW_PURCHASING_INFORMATION && (
                                    <li className="form-group">
                                        <Select
                                            {...form.fields.transport_from_supplier_verified()}
                                            allowClear
                                            options={[
                                                {label: t('search.yes'), value: true},
                                                {label: t('search.no'), value: false},
                                            ]}
                                            placeholder={t('search.transport_from_supplier_verified')}
                                        />
                                        <label>{t('search.transport_from_supplier_verified')}</label>
                                    </li>
                                )}
                                <li className="form-group">
                                    <Select
                                        {...form.fields.direct_delivery()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.direct_delivery')}
                                    />
                                    <label>{t('search.direct_delivery')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.transport_from_ecr_required()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.transport_from_ecr_required')}
                                    />
                                    <label>{t('search.transport_from_ecr_required')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.transport_from_ecr_verified()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.transport_from_ecr_verified')}
                                    />
                                    <label>{t('search.transport_from_ecr_verified')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.pre_delivery_inspection_needed()}
                                        allowClear
                                        options={[
                                            {label: t('search.yes'), value: true},
                                            {label: t('search.no'), value: false},
                                        ]}
                                        placeholder={t('search.pre_delivery_inspection_needed')}
                                    />
                                    <label>{t('search.pre_delivery_inspection_needed')}</label>
                                </li>
                                <li className="form-group">
                                    <Select
                                        {...form.fields.cars_without_images()}
                                        allowClear
                                        options={[
                                            {label: t('search.cars_without_images'), value: true},
                                            {label: t('search.cars_with_images'), value: false},
                                        ]}
                                        placeholder={t('search.cars_without_images')}
                                    />
                                    <label>{t('search.cars_without_images')}</label>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
            </Layout>
        </form>
    );
};

export default Search;
