import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import IconSave from '../../../../../assets/svg/icon-save.svg';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Form/Checkbox';

import Layout from '../../../../components/Layout/Layout';
import EditMailModal from '../../../../components/Modal/EditMailModal';
import Spinner from '../../../../components/Spinner/Spinner';
import CarInfoCell from '../../Components/CarInfoCell';
import MailHistory from '../../Invoices/Detail/MailHistory';
import PaymentHistory from '../../PaymentHistory';
import RegisterRefund from './RegisterRefund';
import {dateFormat, moneyFormat} from '../../../../lib/helpers';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';

import '../../Documents.scss';

const Detail = ({creditNote, refresh, users}) => {
    const {t} = useTranslation();
    const {showModal} = useModal();
    let navigate = useNavigate();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [singleSubsidiary, setSingleSubsidiary] = useState(true);
    const [invoiceCancellationMail, setInvoiceCancellationMail] = useState(creditNote.is_invoice_cancellation);
    const [invoice] = useFetchData('/invoices/' + creditNote.invoice_id);
    const rerenderCountRef = useRef(1);

    const combinedRefresh = () => {
        refresh();
        rerenderCountRef.current++;
    };

    const handleSend = (mailText) => {
        setLoadingMessage(t('nav.submit') + '…');
        authenticatedHttp()
            .post('/credit-notes/send', {
                mail_text: mailText,
                ids: [creditNote.id],
                is_invoice_cancellation: invoiceCancellationMail,
            })
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    const editMailText = () => {
        showModal(<EditMailModal documents={[creditNote]} handleSend={handleSend} />);
    };

    useEffect(() => {
        const carLines = creditNote.lines.filter((line) => line.type === 'CAR');
        if (carLines.length === 0) {
            return;
        }
        const firstCarSubsidiary = carLines[0].car.subsidiary;
        const singleSubsidiary = carLines.every((line) => line.car.subsidiary === firstCarSubsidiary);
        setSingleSubsidiary(singleSubsidiary);
        if (!singleSubsidiary) {
            alert(t('validation.different_subsidiaries'));
        }
    }, [creditNote]);

    const carIds = creditNote.lines.filter((line) => line.type === 'CAR').map((line) => line.car.car_id);

    return (
        <Fragment>
            <Layout
                className="documents documents-detail creditNote-detail"
                header={() => (
                    <Fragment>
                        <div className="title">
                            <h1>{t('general.credit_note')}</h1>
                            <h2>{t('enum.document_status.' + creditNote.status)}</h2>
                        </div>
                        <div>
                            {creditNote.status !== 'DRAFT' && (
                                <Button
                                    icon={<i className="fas fa-check" />}
                                    onClick={() =>
                                        showModal(<RegisterRefund creditNote={creditNote} refresh={combinedRefresh} />)
                                    }
                                    outline
                                >
                                    {t('general.register_incoming_payment')}
                                </Button>
                            )}
                            {creditNote.status === 'DRAFT' && (
                                <Button
                                    icon={<i className="far fa-edit" />}
                                    onClick={() => navigate(`/creditnotas/${creditNote.id}/bewerken`)}
                                    outline
                                >
                                    {t('general.edit')}
                                </Button>
                            )}
                            <Button
                                icon={<IconSave />}
                                onClick={() => {
                                    setLoadingMessage(t('general.generating_document'));
                                    downloadFile(
                                        '/download/credit-note',
                                        {credit_note_id: creditNote.id},
                                        t('general.credit_note').toLowerCase() + '_' + creditNote.id
                                    ).then(() => {
                                        setLoadingMessage(null);
                                    });
                                }}
                            >
                                {t('general.download_as_pdf')}
                            </Button>
                        </div>
                    </Fragment>
                )}
            >
                {loadingMessage && <Spinner message={loadingMessage} />}
                <Fragment>
                    <div className="row">
                        <div className="col">
                            <section>
                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="form-group form-group-vertical  ">
                                            <label>{t('general.invoice')}</label>
                                            {invoice ? (
                                                <Link to={'/facturen/' + invoice.id}>{invoice.invoice_number}</Link>
                                            ) : (
                                                <span>{t('general.loading')}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('search.subsidiary')}</label>
                                            <span>{t('enum.subsidiary.' + creditNote.subsidiary)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('car.seller')}</label>
                                            <span>{users.find((u) => u.id === creditNote.user_id).full_name}</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.language')}</label>
                                            <span>{t('enum.language.' + creditNote.language)}</span>
                                        </div>
                                    </div>
                                </div>
                                {creditNote.status !== 'DRAFT' && (
                                    <div className="form-group form-group-vertical mb-4">
                                        <label>{t('general.credit_note_number')}</label>
                                        <span>{creditNote.credit_note_number}</span>
                                    </div>
                                )}
                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('car.date')}</label>
                                            <span>{dateFormat(creditNote.date)}</span>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.due_date')}</label>
                                            <span>{dateFormat(creditNote.due_date)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <label>{t('general.mail_text')}</label>
                                            <p className="render-line-breaks">{creditNote.mail_text}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group form-group-vertical">
                                            <Checkbox
                                                label={t('general.use_is_invoice_cancellation_mail')}
                                                onChange={() => setInvoiceCancellationMail(!invoiceCancellationMail)}
                                                value={invoiceCancellationMail}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col">
                            <section>
                                <div className="recipients">
                                    <div className="form-group form-group-vertical">
                                        <label>{t('general.credit_note_sent_to')}</label>
                                        <div className="customer">
                                            <a
                                                href={`/bedrijven/${creditNote.customer.data.id}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {creditNote.customer.data.label}
                                            </a>
                                            <div className="contacts">
                                                {creditNote.contacts.map((contact, index) => (
                                                    <a
                                                        className="contact"
                                                        href={`/contacten/${contact.id}`}
                                                        key={index}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {contact.full_name} {contact.email ? `(${contact.email})` : ''}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {creditNote.is_imported ? (
                                    <h2>{t('general.ecinda_import')}</h2>
                                ) : (
                                    <Button color="blue" onClick={editMailText}>
                                        {creditNote.status === 'SENT' ? t('general.resend') : t('general.send')}
                                    </Button>
                                )}
                            </section>
                            <MailHistory document={creditNote} />
                        </div>
                    </div>
                    <section className="no-padding items">
                        <div className="grid-table">
                            <div className="grid-table-row">
                                <div className="grid-table-cell">{t('general.items')}</div>
                                <div className="grid-table-cell numerical">{t('api.vat')}</div>
                                <div className="grid-table-cell numerical">{t('car.pricing.price')}</div>
                            </div>
                            {creditNote.lines.map((line) => (
                                <div className="grid-table-row" key={line.id}>
                                    <div className="grid-table-cell">
                                        {line.type === 'CAR' && (
                                            <CarInfoCell car={line.car} showLink showSubText={!singleSubsidiary} />
                                        )}
                                        {line.type === 'EXTRA' && <span>{line.description}</span>}
                                        {line.type === 'TRANSPORT' && <span>{t('general.transport')}</span>}
                                    </div>
                                    <div className="grid-table-cell numerical">{line.vat_rate}%</div>
                                    <div className="grid-table-cell numerical">- {moneyFormat(line.price)}</div>
                                </div>
                            ))}
                        </div>
                        <p className="car-count">
                            {t('car.number_of_cars')}: {creditNote.lines.filter((line) => line.type === 'CAR').length}
                        </p>
                        <div className="lines-footer">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td className="text-end">
                                            <strong>{t('api.subtotal')}</strong>
                                        </td>
                                        <td className="text-end" width="130">
                                            -&nbsp;{moneyFormat(creditNote.total)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">
                                            <strong>{t('api.vat')}</strong>
                                        </td>
                                        <td className="text-end" width="130">
                                            -&nbsp;{moneyFormat(creditNote.vat)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">
                                            <h3>
                                                <span>{t('general.total')}</span>
                                            </h3>
                                        </td>
                                        <td className="text-end" width="130">
                                            <h3>-&nbsp;{moneyFormat(creditNote.total_incl_vat)}</h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-end">
                                            <strong>{t('search.outstanding_debt')}</strong>
                                        </td>
                                        <td className="text-end">- {moneyFormat(creditNote.outstanding_debt)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div className="row">
                        <div className="col-6">
                            <PaymentHistory
                                carIds={carIds}
                                document="credit-notes"
                                documentId={creditNote.id}
                                key={rerenderCountRef.current}
                            />
                        </div>
                    </div>
                </Fragment>
            </Layout>
        </Fragment>
    );
};

export default React.memo(Detail);
