import React from 'react';
import {useTranslation} from 'react-i18next';

const ListWarning = ({data}) => {
    const carIds = [];
    const {t} = useTranslation();

    data.forEach((car) => {
        carIds.push(car.car_id);
    });

    return (
        <div className="alert alert-danger">
            <p className="list-warning">
                {t('general.already_delivered_warning', {count: carIds.length, ids: carIds.join(', ')})}
            </p>
        </div>
    );
};

export default ListWarning;
