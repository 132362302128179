import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';

import UploadModal from '../../components/Modal/UploadModal';

const baseStyle = {
    borderWidth: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const dropzone = ({carId, refreshCar}) => {
    const {t} = useTranslation();
    const [dropped, setDropped] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        setDropped((prevState) => [...prevState, ...acceptedFiles]);
        setShowModal(true);
    }, []);

    const {getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject} = useDropzone({onDrop});

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    const clearDropped = () => {
        setDropped([]);
    };

    return (
        <Fragment>
            <div className={`dropzone ${loading ? 'loading' : ''}`} {...getRootProps({style})}>
                <input {...getInputProps()} />

                <p>{t(`general.drag_drop_files`)}</p>

                {loading && (
                    <div className="loading-message">
                        <p>{t(`general.uploading_files`)}</p>
                    </div>
                )}
            </div>
            <UploadModal
                carId={carId}
                clearDropped={clearDropped}
                files={dropped}
                isOpen={showModal}
                onRequestClose={() => setShowModal(null)}
                refreshCar={refreshCar}
                setLoading={setLoading}
            />
        </Fragment>
    );
};

export default dropzone;
