import React from 'react';
import {useTranslation} from 'react-i18next';
import Autocomplete from '../Form/Autocomplete';

const EditSupplier = ({className = '', form, onChange, suppliedBy}) => {
    const {t} = useTranslation();

    return (
        <div className={`row ${className}`}>
            <div className="col-12 p-0">
                <Autocomplete
                    {...form.fields.supplier_id()}
                    autocompleteURI="/suppliers/autocomplete?"
                    onChange={onChange}
                    placeholder={t('search.supplied_by')}
                    value={suppliedBy ? {value: suppliedBy.id, label: suppliedBy.label} : ''}
                />
            </div>
        </div>
    );
};

export default EditSupplier;
