import React from 'react';
import {useTranslation} from 'react-i18next';

const NoImages = ({carId}) => {
    const {t} = useTranslation();
    return (
        <div className="missing-images">
            <i className="far fa-image fa-4x" />
            <p>{t('car.no_images')}</p>
            <p className="small">{t('car.no_images_note', {car_id: carId})}</p>
        </div>
    );
};

export default React.memo(NoImages);
