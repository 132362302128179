import React from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from '../../../components/Modal/NewModal';
import {useModal} from '../../../lib/UseModal';

import './FullScreenImageModal.scss';

const FullScreenImageModal = ({currentIndex, images}) => {
    const {closeModal} = useModal;

    function handleKeyDown(e) {
        e.stopPropagation();
        if (e.keyCode === 27) closeModal();
    }

    return (
        <Modal>
            <div className="fullscreen-image-modal-container">
                <ImageGallery
                    items={images.map((image) => ({
                        original: image.medium_url,
                        fullscreen: image.large_url,
                        thumbnail: image.thumbnail_url,
                    }))}
                    onKeyDown={handleKeyDown}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showThumbnails={false}
                    startIndex={currentIndex}
                />
            </div>
        </Modal>
    );
};

export default FullScreenImageModal;
