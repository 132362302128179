import classnames from 'classnames';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {dateFormat, moneyFormat} from '../../../lib/helpers';
import useFetchData from '../../../lib/UseFetchData';
import StatusLabel from '../../../components/CarDetail/StatusLabel';
import List from '../../../components/List';

const Status = ({car}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const sale = car.sale;

    const [customer] = useFetchData(`/customers/${sale.sold_to}`, sale.sold_to !== null);
    const [seller] = useFetchData('/users/' + sale.sold_by, sale.sold_by !== null);

    return (
        <div className="section__head">
            <div className="row">
                <div className="col-8">
                    <ul className="list-advertisement">
                        <li>
                            <button
                                className={classnames({
                                    'btn-hint': true,
                                    'btn-disabled': sale.status !== 'SOLD' && sale.status !== 'INVOICED',
                                    'btn-hint--red': sale.status === 'NOT_FOR_SALE',
                                    'btn-hint--gray': sale.status === 'FOR_SALE',
                                    'btn-hint--yellow': sale.status === 'SOLD',
                                    'btn-hint--green': sale.status === 'INVOICED',
                                })}
                                onClick={() => {
                                    if (sale.status === 'SOLD') {
                                        if (!car.purchase_order_id) {
                                            alert('Geen bestelbon gevonden');
                                            return;
                                        }
                                        navigate('/bestelbonnen/' + car.purchase_order_id);
                                    }

                                    if (sale.status === 'INVOICED') {
                                        if (!car.invoice.id) {
                                            alert('Geen factuur gevonden, auto ouder dan 5 jaar?');
                                            return;
                                        }
                                        navigate('/facturen/' + car.invoice.id);
                                    }
                                }}
                            >
                                {t(`enum.sale_status.${sale.status}`)}
                                {(sale.status === 'SOLD' || sale.status === 'INVOICED') && <i className="fa fa-download ms-2" />}
                            </button>

                            {sale.status === 'SOLD' && sale.sold_on && (
                                <p>
                                    {t(`general.since`)} {dateFormat(sale.sold_on)}
                                </p>
                            )}
                        </li>

                        {(sale.status === 'SOLD' || sale.status === 'INVOICED') && (
                            <Fragment>
                                {!!car.sales_pricing.required_advance && (
                                    <li>
                                        <strong>{t('general.outstanding_required_advance')}</strong>
                                        &nbsp;{moneyFormat(car.sales_pricing.outstanding_advance)}
                                    </li>
                                )}
                                <li>
                                    <strong>{t('search.outstanding_debt')}</strong>
                                    &nbsp;{moneyFormat(car.sales_pricing.outstanding_debt)}
                                </li>
                            </Fragment>
                        )}

                        {customer && (
                            <li>
                                <strong>{t('car.customer')}</strong>
                                <a
                                    className="link link--blue"
                                    href={`/bedrijven/${customer.id}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <span>{customer.label}</span>
                                </a>
                            </li>
                        )}

                        {['SOLD', 'INVOICED'].includes(sale.status) && seller && (
                            <li>
                                <strong>{t('car.seller')}</strong>
                                <p>{seller.full_name}</p>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="col-4 pdi-check">
                    <p
                        className={classnames({
                            'btn-hint': true,
                            'btn-disabled': true,
                            'btn-hint--red': car.delivery.status === 'NOT_READY',
                            'btn-hint--yellow': car.delivery.status === 'READY',
                            'btn-hint--green': car.delivery.status === 'DELIVERED',
                        })}
                    >
                        {t(`enum.delivery_status.${car.delivery.status}`)}
                    </p>
                    <List className={'shadow'}>
                        {Object.entries(car.delivery.conditions).map(([key, status]) => (
                            <StatusLabel key={key} status={status}>
                                {t('delivery_condition.' + key)}
                            </StatusLabel>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Status);
