"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("react-hook-form");
const zod_1 = require("@hookform/resolvers/zod");
const z = __importStar(require("zod"));
const zod_i18n_map_1 = require("zod-i18n-map");
const Form = ({ children, defaultValues, onSubmit, onSuccess, schema }) => {
    const methods = (0, react_hook_form_1.useForm)({
        defaultValues,
        resolver: (0, zod_1.zodResolver)(schema),
    });
    const [errors, setErrors] = (0, react_1.useState)([]);
    z.setErrorMap(zod_i18n_map_1.zodI18nMap);
    const handleSubmit = (data) => {
        onSubmit(data)
            .then((response) => {
            onSuccess(response);
        })
            .catch((error) => setErrors(error.response.data.errors || [error.response.data.message]));
    };
    return (react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, methods),
        errors.length > 0 && (react_1.default.createElement("div", { className: "alert alert-danger alert-top" },
            react_1.default.createElement("ul", null, errors.map((error, index) => (react_1.default.createElement("li", { key: index }, error)))))),
        react_1.default.createElement("form", { onSubmit: methods.handleSubmit(handleSubmit) }, children(Object.assign({}, methods)))));
};
exports.default = Form;
