import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/NewModal';
import {moneyFormat} from '../../lib/helpers';
import {useAuth} from '../../lib/UseAuth';
import {useModal} from '../../lib/UseModal';
import {authenticatedHttp} from '../../lib/zaza-client';

const PaymentDetailModal = ({document, payment, refresh}) => {
    const {t} = useTranslation();
    const {closeModal} = useModal();
    const {user} = useAuth();
    const [deleting, setDeleting] = useState(false);

    const handleUndo = (paymentId) => () => {
        if (!confirm(t('general.confirm'))) {
            return;
        }
        setDeleting(true);

        authenticatedHttp()
            .delete(`/${document}/payments/${paymentId}`)
            .then(() => {
                closeModal();
                refresh();
            });
    };

    return (
        <Modal title={t('general.payment_detail')}>
            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('general.purchase_order_nr')}</th>
                            <th>{t('general.invoice_nr')}</th>
                            <th className="text-end">{t('general.sum')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payment.details.map((detail) => (
                            <tr className={detail.undone_on ? 'strikethrough' : ''} key={detail.id}>
                                <td>
                                    <a href={`/bestelbonnen/${detail.purchase_order}`}>{detail.purchase_order}</a>
                                </td>
                                <td>
                                    <a href={`/facturen/${detail.invoice_id}`}>{detail.invoice_number}</a>
                                </td>
                                <td className="text-end">{moneyFormat(detail.amount)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2} />
                            <td className="text-end">
                                {moneyFormat(
                                    payment.details
                                        .filter((detail) => !detail.undone_on)
                                        .reduce((sum, detail) => detail.amount + sum, 0)
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </Modal.Body>
            {!payment.details.every((detail) => !!detail.undone_on) && user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS && (
                <Modal.Footer>
                    <Button color="red" disabled={deleting} onClick={handleUndo(payment.id)}>
                        {t(`general.undo_payment`)}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default PaymentDetailModal;
