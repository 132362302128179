import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryParams, StringParam, NumberParam, withDefault} from 'use-query-params';
import EditMailModal from '../../../../components/Modal/EditMailModal';
import useFetchData from '../../../../lib/UseFetchData';
import {useModal} from '../../../../lib/UseModal';
import {authenticatedHttp, downloadFile} from '../../../../lib/zaza-client';
import {setPageTitle} from '../../../../lib/helpers';

import Dropdown from '../../../../components/Button/Dropdown';
import Checkbox from '../../../../components/Form/Checkbox';
import Layout from '../../../../components/Layout/Layout';
import Spinner from '../../../../components/Spinner/Spinner';
import ListRow from './ListRow';
import Filters from '../../Filters';

import '../../Documents.scss';
import './List.css';
import $ from 'jquery';

const List = () => {
    useEffect(() => {
        setPageTitle(t('general.credit_notes'));
    }, []);

    const {t} = useTranslation();
    const {showModal} = useModal();

    const [loadingMessage, setLoadingMessage] = useState(null);
    const [isError, setIsError] = useState(false);
    const [totals, setTotals] = useState({pages: 0, cars: 0});
    const [filters, setFilters] = useQueryParams({
        status: StringParam,
        customer: StringParam,
        user: NumberParam,
        date_from: StringParam,
        date_to: StringParam,
        number_from: StringParam,
        number_to: StringParam,
        subsidiary: StringParam,
        page: withDefault(NumberParam, 1),
        perPage: withDefault(NumberParam, 50),
        path: withDefault(StringParam, 'cn.date'),
        direction: withDefault(StringParam, 'DESC'),
    });

    const [selectedCreditNoteIds, setSelectedCreditNoteIds] = useState([]);
    const [users] = useFetchData('/users?include_inactive');

    const [creditNotes, setCreditNotes] = useState(null);
    const fetchCreditNotes = () => {
        setLoadingMessage(t('general.loading'));

        let query = '/credit-notes?';

        if (filters.subsidiary) {
            query += '&subsidiary=' + filters.subsidiary;
        }
        if (filters.status) {
            query += '&status=' + filters.status;
        }
        if (filters.customer) {
            query += '&customer_id=' + filters.customer;
        }
        if (filters.user) {
            query += '&user_id=' + filters.user;
        }
        if (filters.date_from) {
            query += '&date_from=' + filters.date_from;
        }
        if (filters.date_to) {
            query += '&date_to=' + filters.date_to;
        }
        if (filters.number_from) {
            query += '&number_from=' + filters.number_from;
        }
        if (filters.number_to) {
            query += '&number_to=' + filters.number_to;
        }

        query += '&order_by=' + filters.path + ',' + filters.direction;
        query += '&page=' + filters.page + '&per_page=' + filters.perPage;

        authenticatedHttp()
            .get(query)
            .then((response) => {
                setCreditNotes(response.data.data);
                setLoadingMessage(null);
                if (filters.page !== response.data.meta.page) {
                    setFilters({...filters, page: response.data.meta.page});
                }
                setTotals({
                    pages: response.data.meta.pages,
                    items: response.data.meta.total,
                });
            })
            .catch(() => {
                setIsError(true);
            });
    };

    useEffect(fetchCreditNotes, [filters]);

    const stickyColumnHeader = () => {
        let height = window.innerHeight - 60;
        height -= $('header').outerHeight();
        height -= $('#filters').outerHeight();
        height -= $('#dropdown-row').outerHeight();
        if ($('.grid-table').outerHeight() > height) {
            $('section').height(height);
        }
    };

    useEffect(stickyColumnHeader, [creditNotes]);

    const orderBySetter = (path) => {
        if (filters.path === path) {
            // If path is the same as current sorting path, flip the direction
            setFilters({
                ...filters,
                path,
                direction: filters.direction === 'ASC' ? 'DESC' : 'ASC',
                page: 1,
            })
        } else {
            // If path is different, update it and reset direction to descending
            setFilters({
                ...filters,
                path,
                direction: 'ASC',
                page: 1, // Reset page
            });
        }
    };

    const selectedCreditNotes = useMemo(() => {
        if (!creditNotes) {
            return [];
        }

        return creditNotes.filter((creditNote) => selectedCreditNoteIds.includes(creditNote.id));
    }, [creditNotes, selectedCreditNoteIds]);

    if (isError) {
        return <Layout header={() => <h1>{t('general.loading_error')}</h1>} />;
    }

    if (!creditNotes) {
        return <Spinner />;
    }

    const handleBulkSend = (mailText) => {
        setLoadingMessage(t('nav.submit') + '…');
        const isInvoiceCancellation = selectedCreditNotes.every((creditNote) => creditNote.is_invoice_cancellation === true);
        authenticatedHttp()
            .post('/credit-notes/send', {
                mail_text: mailText,
                ids: selectedCreditNoteIds,
                is_invoice_cancellation: isInvoiceCancellation,
            })
            .then(() => {
                document.location.reload();
            })
            .catch((error) => {
                setLoadingMessage(null);
                alert(error.response.data.message);
            });
    };

    return (
        <Layout
            className="documents documents-list credit-note-list"
            header={() => (
                <div className="header-wrapper">
                    <div className="header-pagination">
                        <a
                            className={'btn-arrow btn-arrow-left ' + (filters.page > 1 ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page - 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                        <div>
                            <h1 className="mb-0">
                                {t('general.credit_notes')} ({totals.items})
                            </h1>
                            <p className="mb-0 small">
                                {t('general.page')} {filters.page} {t('general.of')} {totals.pages}
                            </p>
                        </div>
                        <a
                            className={'btn-arrow btn-arrow-right ' + (filters.page < totals.pages ? '' : 'btn-arrow-disabled')}
                            onClick={() => {
                                const newPage = filters.page + 1;
                                setFilters({...filters, page: newPage});
                            }}
                        />
                    </div>
                </div>
            )}
        >
            {loadingMessage && <Spinner message={loadingMessage} />}
            <div className="filter-container">
                <Dropdown
                    actions={{
                        [t('general.send')]: (e) => {
                            e.preventDefault();
                            showModal(<EditMailModal documents={selectedCreditNotes} handleSend={handleBulkSend} />);
                        },
                        [t('nav.download')]: (e) => {
                            e.preventDefault();
                            setLoadingMessage(t('general.generating_document'));
                            const downloadableCreditNoteIds = selectedCreditNoteIds.filter(
                                (creditNoteId) => creditNotes.find((c) => c.id === creditNoteId).downloadable
                            );
                            if (selectedCreditNoteIds.length > downloadableCreditNoteIds.length) {
                                alert(t('general.document_download_warning'));
                                setLoadingMessage(null);
                                return;
                            }
                            downloadFile(`/credit-notes/download`, {credit_note_ids: downloadableCreditNoteIds}).then(() => {
                                setLoadingMessage(null);
                            });
                        },
                    }}
                    buttonProps={{
                        label: t('general.actions'),
                    }}
                    disabled={selectedCreditNoteIds.length === 0}
                />
                {selectedCreditNoteIds.length > 0 && (
                    <span style={{lineHeight: '38px', marginLeft: '1em', verticalAlign: 'sub'}}>
                        {t('general.selected', {count: selectedCreditNoteIds.length})}
                    </span>
                )}
                <Filters filters={filters} setFilters={setFilters} showPaidStatus />
            </div>
            <section className="no-padding">
                <div className="grid-table">
                    <div className="grid-table-row">
                        <div className="grid-table-cell checkbox">
                            <Checkbox
                                field="selection-toggle"
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedCreditNoteIds(creditNotes.map((creditNote) => creditNote.id));
                                    } else {
                                        setSelectedCreditNoteIds([]);
                                    }
                                }}
                                semiChecked={selectedCreditNoteIds.length > 0}
                                value={selectedCreditNoteIds.length === creditNotes.length}
                            />
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('cn.creditNoteNumber');
                            }}
                        >
                            <span>{t('general.credit_note_nr')}</span>
                            {filters.path === 'cn.creditNoteNumber' && filters.direction === 'ASC' && (
                                <i className="fa fa-caret-up" />
                            )}
                            {filters.path === 'cn.creditNoteNumber' && filters.direction === 'DESC' && (
                                <i className="fa fa-caret-down" />
                            )}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('cn.subsidiary');
                            }}
                        >
                            <span>{t('search.subsidiary')}</span>
                            {filters.key === 'cn.subsidiary' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.key === 'cn.subsidiary' && filters.direction === 'DESC' && (
                                <i className="fa fa-caret-down" />
                            )}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('cn.status');
                            }}
                        >
                            <span>{t('general.status')}</span>
                            {filters.path === 'cn.status' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'cn.status' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('cn.date');
                            }}
                        >
                            <span>{t('car.date')}</span>
                            {filters.path === 'cn.date' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'cn.date' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div
                            className="grid-table-cell clickable sortable"
                            onClick={() => {
                                orderBySetter('i.invoiceNumber');
                            }}
                        >
                            <span>{t('general.invoice_nr')}</span>
                            {filters.path === 'i.invoiceNumber' && filters.direction === 'ASC' && <i className="fa fa-caret-up" />}
                            {filters.path === 'i.invoiceNumber' && filters.direction === 'DESC' && <i className="fa fa-caret-down" />}
                        </div>
                        <div className="grid-table-cell">{t('car.customer')}</div>
                        <div className="grid-table-cell">{t('car.seller')}</div>
                        <div className="grid-table-cell">{t('general.items')}</div>
                        <div className="grid-table-cell numerical">
                            <span className={'me-3'}>{t('general.total_incl')}</span>
                        </div>
                    </div>
                    {creditNotes.map((creditNote) => (
                        <ListRow
                            creditNote={creditNote}
                            key={creditNote.id}
                            selectedCreditNoteIds={selectedCreditNoteIds}
                            setSelectedCreditNoteIds={setSelectedCreditNoteIds}
                            users={users}
                        />
                    ))}
                </div>
            </section>
        </Layout>
    );
};

export default React.memo(List);
