/* eslint-disable react/jsx-key */
import React from 'react';
import MainTable from '../../components/Table/MainTable';
import {moneyFormat, roundDecimals} from '../../lib/helpers';

const ListData = ({data, listConfiguration, subtitle}) => {
    if (data === undefined || data.length === 0) {
        return (
            <div>
                {subtitle && <h3>{subtitle}</h3>}
                <section>
                    <p>Geen gegevens</p>
                </section>
            </div>
        );
    }

    const tableData = React.useMemo(() => data, [data]);
    const columns = React.useMemo(() => {
        let columns = [];

        Object.keys(data[0] || []).forEach((key, i) => {
            let length = tableData.length;
            if (['sale_credit_notes', 'purchase_credit_notes'].includes(listConfiguration.key)) {
                length = '-' + length;
            }
            let column = {Header: key, accessor: key, Footer: i === 0 ? 'Aantal: ' + length : ''};
            const {column: {[key]: {footer, format, url} = {}} = {}} = listConfiguration;

            // Format cells
            let formatter;
            switch (format) {
                case 'money':
                    formatter = (value) => (value ? <span className="d-block text-end">{moneyFormat(value, null)}</span> : ''); // skip currency symbol
                    break;
                case 'numeric':
                    formatter = (value) => (value ? <span className="d-block text-end">{roundDecimals(value)}</span> : '');
                    break;
                case 'absolute':
                    formatter = (value) => (value ? <span className="d-block text-end">{Math.abs(value)}</span> : '');
                    break;
                case 'percent':
                    formatter = (value) => (
                        <span className="d-block text-end">{roundDecimals(parseFloat(value || 0) * 100) + '%'}</span>
                    );
                    break;
                case 'url':
                    formatter = (value) =>
                        value ? (
                            <a href={url.replace('{value}', value)} rel="noopener noreferrer" target="_blank">
                                {value}
                            </a>
                        ) : (
                            ''
                        );
                    break;
                case 'purchaseInvoiceNumber':
                    formatter = (value) =>
                        value ? (
                            <a href={url.replace('{value}', value.split(',')[1])} rel="noopener noreferrer" target="_blank">
                                {value.split(',')[0]}
                            </a>
                        ) : (
                            ''
                        );
                    break;
                default:
                    formatter = (value) => value;
            }
            column.Cell = ({value}) => formatter(value);

            // Add footers
            if (footer) {
                column.Footer = (info) => {
                    return React.useMemo(() => {
                        let footerValue;
                        switch (footer) {
                            case 'sum':
                                footerValue = info.rows.reduce((sum, row) => parseFloat(row.values[key]) + sum, 0);
                                break;
                            case 'average':
                                footerValue =
                                    info.rows.reduce((sum, row) => parseFloat(row.values[key]) + sum, 0) / info.rows.length;
                                break;
                        }

                        return formatter(footerValue);
                    }, [info.rows]);
                };
            }

            // Some extra manual footers
            if (listConfiguration.key === 'stock' && key === 'Transport geverifieerd') {
                column.Footer = (info) => {
                    return React.useMemo(() => {
                        const total = info.rows
                            .filter((row) => row.values[key] === '🟩')
                            .reduce((sum, row) => parseFloat(row.values['Transp vanaf leverancier']) + sum, 0);

                        return <div className="text-end">{moneyFormat(total)} Provisie</div>;
                    }, [info.rows]);
                };
            }
            if (listConfiguration.key === 'stock' && key === 'Commissie geverifieerd') {
                column.Footer = (info) => {
                    return React.useMemo(() => {
                        const total = info.rows
                            .filter((row) => row.values[key] === '🟩')
                            .reduce((sum, row) => parseFloat(row.values['Commissie']) + sum, 0);

                        return <div className="text-end">{moneyFormat(total)} Provisie</div>;
                    }, [info.rows]);
                };
            }

            columns.push(column);
        });

        return columns;
    }, [data]);

    return (
        <div>
            {subtitle && <h3>{subtitle}</h3>}
            <MainTable columns={columns} hasFooter listConfiguration={listConfiguration} tableData={tableData} />
        </div>
    );
};

export default ListData;
