import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {authenticatedHttp} from '../../../../lib/zaza-client';
import {useAuth} from '../../../../lib/UseAuth';

import InvoiceForm from './InvoiceForm';
import Spinner from '../../../../components/Spinner/Spinner';

const LoadUpdate = () => {
    const {invoiceId} = useParams();
    let navigate = useNavigate();
    const {user} = useAuth();
    if (!user.permissions.CAN_EDIT_INVOICES) {
        return;
    }

    const [invoice, setInvoice] = useState(null);
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        authenticatedHttp()
            .get('/invoices/' + invoiceId)
            .then((response) => {
                const invoice = response.data.data;
                if (invoice.status !== 'DRAFT') {
                    navigate('/facturen/' + invoiceId);
                    return;
                }
                setInvoice(invoice);
            })
            .catch(() => {
                navigate('/facturen');
            });
    }, []);

    if (!invoice) {
        return <Spinner />;
    }

    const lines = invoice.lines.map((line) => ({
        car_id: line.car ? line.car.car_id : null,
        type: line.type,
        description: line.description,
        price: line.price,
        required_advance: line.required_advance,
        sale_status: line.car ? line.car.sale.status : null,
    }));

    let defaultCarPricing = {};

    invoice.lines
        .filter((line) => line.type === 'CAR')
        .forEach((line) => {
            defaultCarPricing[line.car.car_id] = line.base_pricing;
        });

    let selectedContacts = [];
    invoice.contacts.forEach((contactId) => {
        selectedContacts.push(contactId);
    });

    const cars = {};
    invoice.lines
        .filter((line) => line.type === 'CAR')
        .forEach((line) => {
            cars[line.car.car_id] = line.car;
        });

    return (
        <InvoiceForm
            cars={cars}
            customer={[customer, setCustomer]}
            customerId={invoice.customer.data.id}
            defaultCarPricing={defaultCarPricing}
            defaultFormValues={{
                createPurchaseOrder: invoice.create_purchase_order,
                purchaseOrderChannel: invoice.purchase_order_channel,
                date: invoice.date,
                dueDate: invoice.due_date,
                language: invoice.language,
                mailText: invoice.mail_text,
                selectedContacts,
                subsidiary: invoice.subsidiary,
                userId: invoice.user_id,
            }}
            editMode
            lines={lines}
            submitUrl={`/invoices/${invoice.id}`}
        />
    );
};

export default LoadUpdate;
