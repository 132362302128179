import lscache from 'lscache';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Spinner from '../components/Spinner/Spinner';
import {http} from './zaza-client';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
    const {i18n} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(lscache.get('zazinda_token'));

    const login = ({password, username}) => {
        lscache.set('zazinda_username', username);
        setLoading(true);
        setError(null);
        const params = {
            username,
            password,
            grant_type: 'password',
            client_id: 'ecrent',
            client_secret: '!2u4f}V25k',
        };
        http.post(`/access_token`, params)
            .then(({data}) => {
                lscache.set('zazinda_token', data, 12 * 60); // token is valid for 12 hours (see API)
                setToken(data);
            })
            .catch((error) => {
                setLoading(false);
                setError(error.response.data.message);
            });
    };

    const logout = () => {
        if (user) {
            http.get(`${process.env.API_URL}/logout`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            }).then(() => {
                lscache.remove('zazinda_token');
                setUser(null);
            });
        }
    };

    useEffect(() => {
        if (token) {
            http.get('/users/me', {
                headers: {
                    Authorization: 'Bearer ' + token.access_token,
                },
            })
                .then(({data: {data}}) => {
                    setUser(data);
                    i18n.changeLanguage(data.language);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setError(error.response.data.message + '\n' + error.response.data.hint);
                });
        } else {
            setLoading(false);
        }
    }, [token]);

    if (loading) {
        return <Spinner />;
    }

    return <AuthContext.Provider value={{error, login, logout, user}} {...props} />;
};

const useAuth = () => React.useContext(AuthContext);

export {AuthProvider, useAuth};
