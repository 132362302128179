import React from 'react';
import {useNavigate} from 'react-router-dom';

import Dropdown from '../../components/Button/Dropdown';

const ListSelection = ({children, handleSubmit = (e) => e.preventDefault(), lists}) => {
    const navigate = useNavigate();

    let actions = {};
    lists.forEach((list) => {
        actions[list.title] = (e) => {
            e.preventDefault();
            navigate('/lijsten/' + list.key);
        };
    });

    return (
        <form onSubmit={handleSubmit}>
            <div className="list-filter-container">
                <Dropdown
                    actions={actions}
                    buttonProps={{
                        label: 'Lijsten',
                    }}
                />
                <div className="filters list-filters form-group">{children}</div>
            </div>
        </form>
    );
};

export default ListSelection;
