import React from 'react';
import {useLocation} from 'react-router-dom';
import copyCar from '../../../lib/copyCar';
import useFetchData from '../../../lib/UseFetchData';

import Create from './Create';
import Spinner from '../../../components/Spinner/Spinner';

import emptyCar from '../../../models/car.json';

const LoadCreate = () => {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const copyFrom = urlParams.get('from');
    const copyType = urlParams.has('copy') ? urlParams.get('copy') : 'base';

    const [car, isLoading] = useFetchData('/cars/' + copyFrom, copyFrom);

    if (isLoading) {
        return <Spinner />;
    }

    const newCar = copyFrom ? copyCar(copyType, emptyCar, car) : emptyCar;

    return <Create car={newCar} />;
};

export default LoadCreate;
