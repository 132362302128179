/* eslint-disable react/jsx-key */
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {dateFormat, moneyFormat} from '../../lib/helpers';
import useFetchData from '../../lib/UseFetchData';
import {useModal} from '../../lib/UseModal';
import classnames from 'classnames';
import PaymentDetailModal from './PaymentDetailModal';
import {useAuth} from '../../lib/UseAuth';
import {authenticatedHttp} from '../../lib/zaza-client';

const PaymentHistory = ({carIds, document, documentId}) => {
    const {user} = useAuth();
    const {t} = useTranslation();
    const {showModal} = useModal();
    const [payments, isLoading] = useFetchData(`/${document}/${documentId}/payments`);

    const data = useMemo(() => {
        if (!payments) {
            return [];
        }

        const rows = [];
        payments.forEach((payment) => {
            const {amount, bank, bank_statement, date, id, payment_method, type} = payment;

            if (type === 'ADVANCE') {
                payment.details.forEach((detail) => {
                    if (!carIds.includes(detail.car_id)) {
                        return;
                    }

                    rows.push({
                        id,
                        detail_id: detail.id,
                        type,
                        amount,
                        date,
                        payment_method,
                        bank,
                        bank_statement,
                        car_id: detail.car_id,
                        is_undone: !!detail.undone_on,
                    });
                });
            } else {
                const documentRows = payment.details.filter(
                    (detail) => detail.invoice_id === documentId || detail.credit_note_id === documentId
                );
                const undoneRows = documentRows.filter((detail) => !!detail.undone_on);
                const activeRows = documentRows.filter((detail) => !detail.undone_on);

                if (undoneRows.length) {
                    rows.push({
                        id,
                        type,
                        amount: undoneRows.reduce((total, detail) => total + detail.amount, 0),
                        date,
                        payment_method,
                        bank,
                        bank_statement,
                        car_id: null,
                        is_undone: true,
                    });
                }
                if (activeRows.length) {
                    rows.push({
                        id,
                        type,
                        amount: activeRows.reduce((total, detail) => total + detail.amount, 0),
                        date,
                        payment_method,
                        bank,
                        bank_statement,
                        car_id: null,
                        is_undone: false,
                    });
                }
            }
        });

        return rows;
    }, [payments]);

    const undoPayment = (paymentId, detailId) => {
        if (!confirm(t('general.confirm'))) {
            return;
        }

        authenticatedHttp()
            .delete(`/${document}/payments/${paymentId}/details/${detailId}`)
            .then(() => {
                window.location.reload(true);
            });
    };

    if (isLoading) {
        return (
            <section>
                <h3>{t('general.loading')}</h3>
            </section>
        );
    }

    return (
        <section>
            <h3>{t('general.history')}</h3>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('api.type')}</th>
                        <th>{t('general.date')}</th>
                        <th>{t('general.payment_method')}</th>
                        <th>{t('general.bank')}</th>
                        <th>{t('general.bank_statement')}</th>
                        <th>{t('search.car_id')}</th>
                        <th className="text-end">{t('general.sum')}</th>
                        {user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS && <th />}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr key={`${row.id}-${row.type}-${row.car_id}`}>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        showModal(
                                            <PaymentDetailModal
                                                document={document}
                                                payment={payments.find((payment) => payment.id === row.id)}
                                                refresh={() => {
                                                    window.location.reload(true);
                                                }}
                                            />
                                        );
                                    }}
                                >
                                    {t('enum.payment_type.' + row.type)}
                                </a>
                            </td>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {dateFormat(row.date)}
                            </td>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {t('enum.payment_method.' + row.payment_method)}
                            </td>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {row.bank ? t('enum.bank.' + row.bank) : null}
                            </td>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {row.bank_statement}
                            </td>
                            <td
                                className={classnames({
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {row.car_id}
                            </td>
                            <td
                                className={classnames({
                                    'text-end': true,
                                    strikethrough: row.is_undone,
                                })}
                            >
                                {moneyFormat(row.amount)}
                            </td>
                            {user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS && (
                                <td>
                                    {!row.is_undone && row.type === 'ADVANCE' && (
                                        <button onClick={() => undoPayment(row.id, row.detail_id)}>
                                            <i className="fas fa-times-circle" />
                                        </button>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6} />
                        <td className="text-end">
                            {moneyFormat(data.filter((row) => !row.is_undone).reduce((sum, row) => row.amount + sum, 0))}
                        </td>

                        {user.permissions.CAN_UNDO_PAYMENTS_AND_RELIST_CARS && <td />}
                    </tr>
                </tfoot>
            </table>
        </section>
    );
};

export default PaymentHistory;
