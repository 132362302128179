import classnames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import {format, isValid, parse} from 'date-fns';
import FocusTrap from 'focus-trap-react';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {useTranslation} from 'react-i18next';
import {usePopper} from 'react-popper';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/nl';
import fr from 'date-fns/locale/nl';

const DayPickerInput = ({disabled = false, error, onChange, placeholder, selected, textRight}) => {
    const {i18n, t} = useTranslation();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (selected && isValid(selected)) {
            setInputValue(format(selected, 'dd/MM/yyyy'));
        }
    }, [selected]);

    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const popperRef = useRef(null);
    const buttonRef = useRef(null);
    const [popperElement, setPopperElement] = useState(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    });

    const closePopper = () => {
        setIsPopperOpen(false);
        buttonRef?.current?.focus();
    };

    const handleInputChange = (e) => {
        setInputValue(e.currentTarget.value);
    };

    const handleBlur = (e) => {
        const date = parse(e.currentTarget.value, 'dd/MM/yyyy', new Date());
        if (isValid(date)) {
            onChange(date);
        } else {
            onChange(undefined);
            setInputValue('');
        }
    };

    const handleButtonClick = () => {
        setIsPopperOpen(true);
    };

    const handleDaySelect = (date) => {
        onChange(date);
        if (date) {
            setInputValue(format(date, 'dd/MM/yyyy'));
            closePopper();
        } else {
            setInputValue('');
        }
    };

    return (
        <div>
            <div ref={popperRef}>
                <input
                    className={classnames({
                        'form-control': true,
                        'has-error': error,
                        'has-value': selected,
                        'text-end': textRight,
                    })}
                    disabled={disabled}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                    onClick={handleButtonClick}
                    placeholder={placeholder || t('general.placeholder_datepicker')}
                    style={{fontSize: 14}}
                    type="text"
                    value={inputValue}
                />
            </div>
            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                    }}
                >
                    <div
                        className="dialog-sheet"
                        style={popper.styles.popper}
                        tabIndex={-1}
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker
                            defaultMonth={selected}
                            footer={
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDaySelect(new Date());
                                    }}
                                >
                                    {t('car.today')}
                                </button>
                            }
                            locale={i18n.language === 'nl' ? nl : i18n.language === 'fr' ? fr : en}
                            mode="single"
                            onSelect={(day) => handleDaySelect(day)}
                            selected={selected}
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
    );
};

export default DayPickerInput;
